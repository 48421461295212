import React          from "react";
import { CarteType }  from "../../../types/components/ville/CarteType";
import SvgCase        from "./SvgCase";
import { ZoneMapDTO } from "../../../types/models/zoneMap.dto";


export function TraceExpedition(props: { zone: ZoneMapDTO; carte: CarteType; typeCarte: string; listExpe: { [key: string]: number[] }, selectedArray: string[] }) {
    
    const carte = props.carte;
    const typeCarte = props.typeCarte;
    const listExpe = props.listExpe;
    
    let bordureFleche = "n";
    if ((carte.theme ?? "light") === "dark") {
        bordureFleche = "b";
    }
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tabExpe = Object.entries(listExpe).filter(([key, _expe]) => props.selectedArray.includes(key)).map(([key, exp]: [string, number[]]) => {
        
        return <React.Fragment key={"frag_exp_" + key}>
            {exp.map((caseType: number, index: number) => {
                    const valeurFleche = caseType - 1;
                    return <SvgCase classPrincipale={"expe_" + key + " selected_expe"} typeCarte={typeCarte} classOption={"expedition_carte"} svg={"fleche"}
                                    idSvg={"fleche_" + valeurFleche + "_" + bordureFleche} style={{ color: carte.listExpCouleur[key] }} key={"case_" + key + "_" + index} />;
                },
            )}
        </React.Fragment>;
        
    });
    
    return <React.Fragment>{tabExpe}</React.Fragment>;
}

export function TraceExpeditionAppercu(props: { zone: ZoneMapDTO; theme: string; typeCarte: string; listExpe: number[] }) {
    
    const typeCarte = props.typeCarte;
    const listExpe = props.listExpe;
    
    let bordureFleche = "n";
    if ((props.theme ?? "light") === "dark") {
        bordureFleche = "b";
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tabExpe = Object.values(listExpe).map((exp, index) => {
        const valeurFleche = exp - 1;
        return <React.Fragment key={"frag_exp_" + index}>
            <SvgCase classPrincipale={"expe_" + index + " selected_expe"} typeCarte={typeCarte} classOption={"expedition_carte"} svg={"fleche"}
                     idSvg={"fleche_" + valeurFleche + "_" + bordureFleche} key={"case_" + index + "_" + index} />
        </React.Fragment>;
        
    });
    
    return <React.Fragment>{tabExpe}</React.Fragment>;
}

export function TraceExpeditionHordes(props: { zone: ZoneMapDTO; carte: CarteType; typeCarte: string; listExpe: { [key: string]: number[] }, selectedArray: string[] }) {
    
    const carte = props.carte;
    const typeCarte = props.typeCarte;
    const listExpe = props.listExpe;
    let bordureFleche = "n";
    if ((carte.theme ?? "light") === "dark") {
        bordureFleche = "b";
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tabExpe = Object.entries(listExpe).filter(([key, expe]) => props.selectedArray.includes(parseInt(key, 10).toString())).map(([key, exp]: [string, number[]]) => {
        
        
        return <React.Fragment key={"frag_exp_" + key}>
            {exp.map((caseType: number, index: number) => {
                    const valeurFleche = caseType - 1;
                    return <SvgCase classPrincipale={"expe_" + key + " selected_expe"} typeCarte={typeCarte} classOption={"expedition_carte"} svg={"fleche"}
                                    idSvg={"fleche_" + valeurFleche + "_" + bordureFleche} style={{ color: carte.couleurHordes[key] }} key={"case_" + key + "_" + index} />;
                },
            )}
        </React.Fragment>;
        
    });
    
    return <React.Fragment>{tabExpe}</React.Fragment>;
}

export function TraceExpeditionManuel(props: { zone: ZoneMapDTO; carte: CarteType; typeCarte: string; carteExpe: number[], couleur: string }) {
    
    const carte = props.carte;
    const typeCarte = props.typeCarte;
    const couleurExpe = props.couleur;
    let carteExpe = props.carteExpe;
    
    let bordureFleche = "n";
    if ((carte.theme ?? "light") === "dark") {
        bordureFleche = "b";
    }
    
    if (!Array.isArray(carteExpe)) {
        carteExpe = [carteExpe];
    }
    
    return <React.Fragment key={"frag_exp_manuel"}>
        {carteExpe.map((caseType: number, index: number) => {
                const valeurFleche = caseType - 1;
                return <SvgCase classPrincipale={"expe_manuel selected_expe"} typeCarte={typeCarte} classOption={"expedition_carte"} svg={"fleche"}
                                idSvg={"fleche_" + valeurFleche + "_" + bordureFleche} style={{ color: couleurExpe }} key={"case_manuel_" + index} />;
            },
        )}
    </React.Fragment>;
}