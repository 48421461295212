import { GeneralType }      from "../../types/components/Generality/GeneralType";
import { formatInTimeZone } from "date-fns-tz";
import { Link }             from "react-router-dom";
import HTMLParser           from "html-react-parser";
import React                from "react";
import { useTranslation }   from "react-i18next";
import Modal                from "react-bootstrap/Modal";

export default function Footer({ general }: { general: GeneralType }) {
    const { t } = useTranslation();
    const [showScript, setShowScript] = React.useState(false);
    const [showRemerciement, setShowRemerciement] = React.useState(false);
    return <footer>
        <p className={"d-flex justify-content-center align-items-center flex-wrap gap-2"}>
            <span><a href={general.lienGit}>Gest'Hordes</a> {t("codé par", { ns: "app" })} <a href={"https://myhordes.eu/jx/soul/239"}>Eragony</a></span>
            <span>-</span>
            <span>{t("Version du {date}", { ns: "app" }).replace("{date}", formatInTimeZone(general.version.date, general.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" })))}</span>
            <span>-</span>
            <span><Link to={"changelog"}>V.{general.version.version}</Link></span>
            <span>-</span>
            <span>{t("Version du jeu : {version}", { ns: "app" }).replace("{version}", general.versionJeu)}.</span>
            <span>{t("Ce site n'est pas affilié à Eternal Twin, MyHordes ou Motion Twin - certains éléments graphiques appartiennent à Motion Twin et MyHordes.", { ns: "app" })}</span>
        </p>
        <p className={"d-flex justify-content-center align-items-center flex-wrap gap-2 mt-1"}>
            <span><a href={general.lienCrowdin}>{t("Gestion des traductions", { ns: "app" })}</a></span>
            <span>-</span>
            <span><a onClick={() => setShowScript(true)} style={{ cursor: "pointer" }}>{t("Script de mise à jour", { ns: "app" })}</a></span>
            <span>-</span>
            <span><a onClick={() => setShowRemerciement(true)} style={{ cursor: "pointer" }}>{t("Remerciements", { ns: "app" })}</a></span>
        </p>
        <p id={"suggestion_discord"}>{t("Une suggestion, un bug rencontré, une anomalie, un seul endroit :", { ns: "app" })} <a href={general.lienDiscord}>{t("rejoignez le discord !", { ns: "app" })}</a></p>
        <Modal show={showScript} onHide={() => setShowScript(false)} centered={true}>
            <Modal.Header className={"modal-header-succes"}>
                <Modal.Title>{t("Script de mise à jour", { ns: "app" })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t("Tu as plusieurs script à ta disposition afin de pouvoir mettre à jour depuis MyHordes, utilise Tampermonkey ou Greasemonkey (Google Chrome et Firefox))", { ns: "app" })}</p>
                <h3>{t("MHE :", { ns: "app" })}</h3>
                <p><a href={general.lienMHE}>{t("clique-ici pour récupérer le script", { ns: "app" })}</a> {t("(Merci à Ludo)", { ns: "app" })}</p>
                <h3>{t("MHOA :", { ns: "app" })}</h3>
                <p><a href={general.lienMHO}>{t("clique-ici pour récupérer le script", { ns: "app" })}</a> {t("(Merci à Zerah et Renack)", { ns: "app" })}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => setShowScript(false)}>{t("Fermer", { ns: "app" })}</button>
            </Modal.Footer>
        </Modal>
        <Modal show={showRemerciement} onHide={() => setShowRemerciement(false)} centered={true}>
            <Modal.Header className={".modal-header-succes"}>
                <Modal.Title>{t("Remerciements", { ns: "app" })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t("Les thèmes ont été pensés en grande partie par Katt, un grand merci à elle !", { ns: "app" })}</p>
                <p id={"remerciement_maj"}>{HTMLParser(t("Suite à la mise à jour de la saison 16, Gest'Hordes a été rapidement actualisé grâce aux retours des joueurs. Il reste encore des choses à faire, mais un grand merci à : <span id='listPseudoRemerciement'>{listPseudo}</span>. Si j'ai oublié quelqu'un, n'hésitez pas à me le signaler, cela n'était pas intentionnel !", { ns: "app" }).replace("{listPseudo}", "Arendil, Cliche, Elara, Eliam, Emy, Etoile de Feu, HordeMaVue, Kraky, Manech, Psykokwak, Renya, SilAfyen, Tristana, Wheely, Zerah, Zomzom"))}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => setShowRemerciement(false)}>{t("Fermer", { ns: "app" })}</button>
            </Modal.Footer>
        </Modal>
    </footer>;
}