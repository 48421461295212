import React, { useEffect, useState }                     from "react";
import { AffNbrIcon }                                     from "../../../components/generality/ComposantGeneral";
import { ListingItemsNews }                               from "../../../components/Transverse/ListingItemsNew";
import { PopUpMajSacExpeditionnaireProps, SacRapideType } from "../../../types/components/Outils/OutilsExpeditionType";
import { controleCapaciteSac }                            from "./ExpeditionUtils";
import Tabs                                               from "react-bootstrap/Tabs";
import Tab                                                from "react-bootstrap/Tab";
import { ListingStockItems }                              from "../../../components/Transverse/ListingStockItems";
import { ItemPrototypeDTO }                               from "../../../types/models/itemPrototype.dto";
import { OuvriersDTO }                                    from "../../../types/models/ouvriers.dto";
import { SacExpeditionnaireDTO }                          from "../../../types/models/sacExpeditionnaire.dto";
import { useTranslation }                                 from "react-i18next";

interface PopUpMajManuelProps {
    popUpMaj: PopUpMajSacExpeditionnaireProps;
    ouvrier: OuvriersDTO;
    ouvrierInitial: OuvriersDTO;
    onSaveCase: (ouvrier: OuvriersDTO) => void;
    onCancel: (ouvrier: OuvriersDTO) => void;
    sacRapide: SacRapideType[];
    stock?: number[];
}

export default function PopUpMajSacOuvrier({ popUpMaj, ouvrier, onSaveCase, onCancel, ouvrierInitial, sacRapide, stock }: PopUpMajManuelProps) {
    const { t } = useTranslation();
    const [ouvrierPopUp, setOuvrierPopUp] = useState(ouvrier);
    const [retourControle, setRetourControle] = useState<number>(0);
    const [idSacRapide, setIdSacRapide] = useState<number>(0);
    const [onglet, setOnglet] = useState<string>("std");
    const [stockItems, setStockItems] = useState<number[]>(stock ?? []);
    
    useEffect(() => {
        setOuvrierPopUp(ouvrier);
    }, [ouvrier]);
    useEffect(() => {
        setStockItems(stock);
    }, [stock]);
    
    const handleDecrementItems = (itemMod: ItemPrototypeDTO, broken: boolean) => {
        const itemsSacMod = [...ouvrierPopUp.sac];
        
        const retourControle = controleCapaciteSac(itemsSacMod, itemMod, false);
        if (retourControle === 0) {
            setRetourControle(0);
        } else {
            setRetourControle(retourControle);
        }
        setOuvrierPopUp((prevState) => {
            const sac = prevState.sac.map((itemsSac) => {
                if (
                    itemsSac.item.id === itemMod.id &&
                    itemsSac.broken === broken &&
                    itemsSac.nbr > 0
                ) {
                    const stockItemsMod = { ...stockItems };
                    stockItemsMod[itemMod.id * 10 + (broken ? 1 : 0)] += 1;
                    setStockItems(stockItemsMod);
                    return { ...itemsSac, nbr: itemsSac.nbr - 1 };
                }
                return itemsSac;
            }).filter((itemsSac) => itemsSac.nbr > 0); // Retirer les éléments avec count = 0
            
            return {
                ...prevState,
                sac: sac,
            };
        });
    };
    
    const handleIncrementItems = (itemMod: ItemPrototypeDTO, broken: boolean) => {
        const itemsSac = [...ouvrierPopUp.sac];
        const stockItemsMod = { ...stockItems };
        
        const retourControle = controleCapaciteSac(itemsSac, itemMod, true);
        if (retourControle === 0) {
            setRetourControle(0);
        } else {
            setRetourControle(retourControle);
        }
        
        const index = itemsSac.findIndex((item) => item.item.id === itemMod.id && item.broken === broken);
        if (index === -1) {
            itemsSac.push({ item: itemMod, broken: broken, nbr: 1 });
        } else {
            itemsSac[index].nbr++;
        }
        stockItemsMod[itemMod.id * 10 + (broken ? 1 : 0)] -= 1;
        
        itemsSac.sort((a, b) => a.item.nom.localeCompare(b.item.nom)); // Tri par ordre alphabétique de la propriété "name"
        
        setOuvrierPopUp((prevState) => {
            return {
                ...prevState,
                sac: itemsSac,
            };
        });
        setStockItems(stockItemsMod);
    };
    
    const handleChoixSacRapide = (id: number) => {
        const stockItemsMod = { ...stock };
        setIdSacRapide(id);
        // On récupère le sac rapide à partir de l'id
        const sacRapideChoix = sacRapide.find((sac) => sac.id === id);
        
        // On récupère les items du sac rapide, et on les ajoute au sac de l'ouvrier
        if (sacRapideChoix) {
            // Si le sac est remplis, on vide le sac, et on met les items du sac rapide
            const sac = [];
            sacRapideChoix.items.map((item) => {
                sac.push({ item: item, broken: false, nbr: 1 });
                stockItemsMod[item.id * 10] = (stockItemsMod[item.id * 10] ?? 0) - 1;
            });
            setStockItems(stockItemsMod);
            setOuvrierPopUp((prevState) => {
                return {
                    ...prevState,
                    sac: sac,
                };
            });
        }
    };
    
    const phraseRetourControle = () => {
        switch (retourControle) {
            case 1:
                return t("Extension de sac incompatible", { ns: "outils" });
            case 2:
                return t("Trop d'extension de sac", { ns: "outils" });
            case 3:
                return t("Trop d'objet encombrant", { ns: "outils" });
            case 4:
                return t("Limite d'objet atteinte", { ns: "outils" });
            case 5:
                return t("Limite atteinte de ce genre objet atteinte", { ns: "outils" });
            default:
                return "";
        }
    };
    
    const listCatetogies = popUpMaj.listCategorie;
    const listItems = popUpMaj.listItems;
    
    return (
        <React.Fragment>
            <div id="zoneInfoSacOuvrier">
                <div>
                    <h2>{t("Objet(s) dans le sac", { ns: "outils" })} </h2>
                </div>
                <div id="objetSacMaj">
                    {Object.values(ouvrierPopUp.sac).sort((a: SacExpeditionnaireDTO, b: SacExpeditionnaireDTO) => {
                        const aNom = t(a.item.nom, { ns: "items" });
                        const bNom = t(b.item.nom, { ns: "items" });
                        return aNom.localeCompare(bNom);
                    }).map((item) => (
                        <AffNbrIcon
                            item={item.item}
                            broken={item.broken}
                            nbr={item.nbr}
                            key={"obj_" + item.item.id + "_" + (item.broken ? 1 : 0)}
                            onDecrementItem={handleDecrementItems}
                        />
                    ))}
                </div>
                {retourControle !== 0 && <div id="retourControleSac">
                    <span>{phraseRetourControle()}</span>
                </div>}
                <div id="boutonMajSacOuvrier">
                    <button
                        className={"btn btn-success btn-xs"}
                        id="enrSacOuvrierObjet"
                        onClick={() => onSaveCase(ouvrierPopUp)}
                        disabled={retourControle !== 0}
                    >
                        {t("Valider et fermer", { ns: "outils" })}
                    </button>
                    <button className={"btn btn-warning btn-xs"} id="annulSacOuvrierObjet" onClick={() => {
                        onCancel(ouvrierInitial);
                    }}>
                        {t("Annuler et fermer", { ns: "outils" })}
                    </button>
                </div>
                <div id={"zoneSacRapideOuvrier"}>
                    <label htmlFor={"sacRapide"}>{t("Sac rapide :", { ns: "outils" })}</label>
                    <select value={idSacRapide} id={"sacRapide"} name={"sacRapide"} onChange={(event) => handleChoixSacRapide(parseInt(event.target.value))}>
                        <option value={0}>{t("choisir un sac rapide", { ns: "outils" })}</option>
                        {sacRapide.map((sac, index) => (
                            <option key={index} value={sac.id}>{t(sac.nom, { ns: "outils" })}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div id="listingObjetSacOuvrierObjet">
                <Tabs onSelect={setOnglet} activeKey={onglet}>
                    <Tab eventKey="std" title={t("Liste objets", { ns: "outils" })}>
                        <div className={"listingItem_categorie"}>
                            <ListingItemsNews
                                listCategory={listCatetogies}
                                listItems={listItems}
                                itemSelected={handleIncrementItems}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="stock" title={t("Liste objets en stock", { ns: "outils" })}>
                        <div className={"listingItem_categorie"}>
                            <ListingStockItems
                                listCategory={listCatetogies}
                                listItems={listItems}
                                itemSelected={handleIncrementItems}
                                stock={stockItems}
                            />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </React.Fragment>
    );
}

