import React, { useCallback }       from "react";
import { OutilsChantiersTypeProps } from "../../../../types/components/Outils/OutilsChantiers";
import SvgIcone                     from "../../../../components/generality/SvgIcone";
import Form                         from "react-bootstrap/Form";
import Select, { components }       from "react-select";
import { v4 as uuid }               from "uuid";
import { useOutilsChantiers }       from "../TabsOutilsChantiers";
import { RepaArmeDTO }              from "../../../../types/models/repaArme.dto";
import { ItemPrototypeDTO }         from "../../../../types/models/itemPrototype.dto";
import { BanqueDTO }                from "../../../../types/models/banque.dto";
import { useTranslation }           from "react-i18next";

const { SingleValue, Option, GroupHeading } = components;

export default function RepaArme(props: { outils: OutilsChantiersTypeProps }) {
    const { t } = useTranslation();
    const { outilsChantiers, setOutilsChantiers } = useOutilsChantiers();
    const outils: OutilsChantiersTypeProps = props.outils;
    const listArmes = Object.values(outils.option.list_armes).sort((arme_1: ItemPrototypeDTO, arme_2: ItemPrototypeDTO) => {
        const a_arme = t(arme_1.nom, { ns: "items" });
        const b_arme = t(arme_2.nom, { ns: "items" });
        return a_arme.localeCompare(b_arme);
    });
    
    // Définissez les composants personnalisés ici en utilisant `useCallback` si nécessaire.
    const CustomOption = useCallback((props) => (
        <components.Option {...props}>
            <SvgIcone icone={props.data.icon} />
            <span>{props.data.nom}</span>
        </components.Option>
    ), []);
    
    const CustomSingleValue = useCallback((props) => (
        <components.SingleValue {...props}>
            <SvgIcone icone={props.data.icon} />
            <span>{props.data.nom}</span>
        </components.SingleValue>
    ), []);
    
    
    const customStyles = {
        option           : (provided, state) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: state.isDisabled ? "lightgrey" : "white",
            color          : state.isDisabled ? "grey" : "black",
            ":active"      : {
                ...provided[":active"],
                backgroundColor: !state.isDisabled && (state.isSelected ? "data.color" : "white"),
            },
        }),
        singleValue      : (provided) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        control          : (provided) => ({
            ...provided,
            minHeight: "24px",
            height   : "24px",
        }),
        input            : (provided) => ({
            ...provided,
            gridTemplateColumns: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "0",
        }),
        valueContainer   : (provided) => ({
            ...provided,
            padding: "0px 8px",
        }),
        menu             : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuList         : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuPortal       : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
    };
    
    // Fonction pour ajouter un nouveau chantier
    const ajouterArmeAreparer = () => {
        
        // Vous devez définir la structure de l'objet chantier par défaut qui sera ajouté
        const nouvelArme = {
            uuid            : uuid(),
            item            : null,
            nombre_a_reparer: 0,
            nombre_broked   : 0,
        };
        
        // Mettre à jour l'état avec le nouveau chantier
        setOutilsChantiers({
            ...outilsChantiers,
            repa_armes: [...outilsChantiers.repa_armes, nouvelArme],
        });
    };
    
    const handleArmeChange = (uuid: string, updateArme: Partial<RepaArmeDTO>) => {
        // Mettre à jour l'état d'outilsChantiers avec les nouvelles informations des armes à réparer
        const armesUpdated = outilsChantiers.repa_armes.map(arme => {
            if (arme.uuid === uuid) {
                return {
                    ...arme,
                    ...updateArme,
                };
            }
            return arme;
        });
        
        setOutilsChantiers({ ...outilsChantiers, repa_armes: armesUpdated });
    };
    
    // Fonction pour trouver la valeur actuelle du Select basée sur item. Chantier
    const findItemValue = (itemId) => {
        const foundOption = listArmes.find(option => option.id === itemId);
        if (foundOption) {
            return foundOption;
        }
        return null;
    };
    
    const deleteReparationArme = (uuid: string) => {
        const repaArmesFiltre = outilsChantiers.repa_armes.filter(arme => arme.uuid !== uuid);
        setOutilsChantiers({ ...outilsChantiers, repa_armes: repaArmesFiltre });
    };
    
    return <div id={"reparationArme"}>
        <div id={"bloc_repa"}>
            <table className={"tab_armesRepa"}>
                <thead>
                <tr className={"fondBlack02"}>
                    <th className={"nom"}>{t("Armes", { ns: "outils" })}</th>
                    <th className={"nbr"}>{t("Nombre cassé", { ns: "outils" })}</th>
                </tr>
                </thead>
                <tbody>
                {Object.values(outils.listAReparer).sort((item_1: BanqueDTO, item_2: BanqueDTO) => item_1.nombre > item_2.nombre ? -1 : 1).map((itemARepa) => {
                    return <tr key={itemARepa.item.id}>
                        <td className={"nom"}><span><SvgIcone icone={itemARepa.item.icon} />{t(itemARepa.item.nom, { ns: "items" })}</span></td>
                        <td className={"nbr"}>{itemARepa.nombre}</td>
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
        <div id={"option_repa"}>
            <div id={"bloc_option_repa"}>
                <div className={"option_repaArme"}>
                    <label htmlFor={"option_kit_vert"}>{t("Utiliser des kits verts", { ns: "outils" })}</label>
                    <Form.Check
                        name={"option_kit_vert"}
                        id={"option_kit_vert"}
                        type="switch"
                        checked={outilsChantiers.bricot_vert ?? false}
                        onChange={(event) => setOutilsChantiers({ ...outilsChantiers, bricot_vert: event.target.checked })}
                    />
                </div>
                {outilsChantiers.bricot_vert && <div className={"option_repaArme"}>
                    <label htmlFor={"input_nbr_vert"}>{t("Nombre de kit vert à utiliser", { ns: "outils" })}</label>
                    <input id={"input_nbr_vert"} type={"number"} value={outilsChantiers.nbr_kit_vert} onChange={(event) => setOutilsChantiers({ ...outilsChantiers, nbr_kit_vert: parseInt(event.target.value, 10) })} />
                </div>}
            </div>
            <div id={"bloc_list_repa_arme"}>
                <table className={"tab_armesARepa"}>
                    <thead>
                    <tr className={"fondBlack02"}>
                        <th className={"nom"}>{t("Armes", { ns: "outils" })}</th>
                        <th className={"nbr"}>{t("Nombre cassé", { ns: "outils" })}</th>
                        <th className={"nbr"}>{t("Nombre à réparer", { ns: "outils" })}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.values(outilsChantiers.repa_armes).map((arme) => {
                        return <tr key={arme.uuid}>
                            <td className={"nom"}>
                                <Select
                                    onChange={(selectedOption: ItemPrototypeDTO) => handleArmeChange(arme.uuid, { item: selectedOption })}
                                    options={listArmes}
                                    components={{
                                        Option     : CustomOption,
                                        SingleValue: CustomSingleValue,
                                    }}
                                    styles={customStyles}
                                    value={findItemValue(arme.item?.id)}
                                /></td>
                            <td className={"nbr"}><input type={"number"} value={arme.nombre_broked} onChange={(event) => handleArmeChange(arme.uuid, { nombre_broked: parseInt(event.target.value, 10) })} /></td>
                            <td className={"nbr"}><input type={"number"} value={arme.nombre_a_reparer} onChange={(event) => handleArmeChange(arme.uuid, { nombre_a_reparer: parseInt(event.target.value, 10) })} /></td>
                            <td>
                                <button onClick={() => deleteReparationArme(arme.uuid)}><i className="fa-solid fa-trash color-red"></i></button>
                            </td>
                        </tr>;
                    })}
                    </tbody>
                </table>
                <button type={"button"} className={"btn btn-primary btn-sm"} onClick={ajouterArmeAreparer}>{t("Ajouter une arme à réparer", { ns: "outils" })}</button>
            </div>
        </div>
    </div>;
}

