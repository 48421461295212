import React, { useEffect, useState }                                      from "react";
import Select, { components, OptionProps, SingleValueProps, StylesConfig } from "react-select";
import { HerosSkillTypeDTO }                                               from "../../types/models/herosSkillType.dto";
import { HerosSkillLevelDTO }                                              from "../../types/models/herosSkillLevel.dto";
import { useGeneralContext }                                               from "../../types/Context/GeneralContext";
import CompetenceBadge                                                     from "../Citoyens/CompetencesBadge";
import chroma                                                              from "chroma-js";
import { useTranslation }                                                  from "react-i18next";
import { ThemeUserDTO }                                                    from "../../types/models/themeUser.dto";

interface SkillsManagerProps {
    listSkillType: HerosSkillTypeDTO[];
    availableSkills: HerosSkillLevelDTO[];
    skillTypes: HerosSkillLevelDTO[];
    savedUserSkills: HerosSkillLevelDTO[];
    onSave: (skill: HerosSkillLevelDTO) => void;
    feedback: number;
}

const CustomOption = (props: OptionProps<HerosSkillLevelDTO>) => {
    const { data } = props;
    const { general } = useGeneralContext();
    
    return (
        <components.Option {...props}>
            <div><CompetenceBadge skillLevel={data} personnalisation={general.themeUser} /></div>
        </components.Option>
    );
};

const CustomSingleValue = (props: SingleValueProps<HerosSkillLevelDTO>) => {
    const { general } = useGeneralContext();
    const { data } = props;
    return (
        <components.SingleValue {...props}>
            <div>
                <CompetenceBadge skillLevel={data} personnalisation={general.themeUser} />
            </div>
        </components.SingleValue>
    );
};

const customStylesSkill = (theme: ThemeUserDTO, feedBack?: boolean): StylesConfig => {
    
    if (!feedBack) {
        feedBack = false;
    }
    
    return {
        container        : (provided) => ({
            ...provided,
            width       : "40px",
            height      : "20px",
            marginBottom: "4px",
        }),
        option           : (provided: any, { isSelected, isFocused }) => {
            const color = chroma("#373a40");
            return {
                ...provided,
                display        : "flex",
                flexDirection  : "row",
                alignItems     : "center",
                backgroundColor: isFocused ? color.alpha(0.5).css() : "white",
                color          : isFocused ? "white" : "black",
                width          : "24px",
                height         : "20px",
                overflow       : "hidden",
                margin         : "3px",
                cursor         : "pointer",
                padding        : "0",
            };
        },
        singleValue      : (provided) => ({
            ...provided,
            borderRadius   : 5,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            justifyContent : "flex-start",
            alignContent   : "center",
            backgroundColor: "white",
            background     : "none",
            color          : "black",
            width          : "40px",
            padding        : "0",
            height         : "20px",
            overflow       : "hidden",
            textOverflow   : "ellipsis",
            whiteSpace     : "nowrap",
        }),
        control          : (provided) => ({
            ...provided,
            minHeight     : "20px",
            height        : "20px",
            width         : "45px",
            display       : "flex",
            alignItems    : "center",
            justifyContent: "center",
            alignContent  : "center",
            padding       : "0",
            overflow      : "hidden",
            border        : feedBack ? `3px solid ${theme.succes_color}` : "1px solid black",
        }),
        input            : (provided) => ({
            ...provided,
            gridTemplateColumns: "none",
            width              : "30px",
            padding            : "0",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "0",
            width  : "20px",
            height : "20px",
        }),
        valueContainer   : (provided) => ({
            ...provided,
            padding : 0,
            width   : "50px",
            height  : "20px",
            overflow: "hidden",
        }),
        menu             : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            width          : "30px",
            padding        : "0",
        }),
        menuList         : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            width          : "30px", // Limite la largeur de la liste déroulante
            overflow       : "hidden",
            padding        : "0",
        }),
        menuPortal       : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            cursor         : "pointer",
            padding        : "0",
        }),
    };
};

export default function SkillsManager({ listSkillType, availableSkills, skillTypes, savedUserSkills, onSave, feedback }: SkillsManagerProps) {
    const { t } = useTranslation();
    const [selectedSkills, setSelectedSkills] = useState<HerosSkillLevelDTO[]>(savedUserSkills || []);
    const { general } = useGeneralContext();
    
    useEffect(() => {
        // Si aucune compétence n'a été trouvée en bdd pour le joueur, on prend les types de base du jeu → lvl 1.
        if (selectedSkills.length === 0) {
            // On initialise les compétences par défaut avec les compétences types du joueurs si elles existent
            if (skillTypes.length > 0) {
                setSelectedSkills(skillTypes);
                return;
            }
            const defaultSkills = [];
            // On balaye les types de compétences
            listSkillType.map(skillType => {
                const skill = availableSkills.filter(skill => skill.heros_skill_type.id === skillType.id).find(skillLevel => skillLevel.lvl === 1);
                if (skill) {
                    defaultSkills.push(skill);
                }
            });
            
            setSelectedSkills(defaultSkills);
        }
        
    }, [selectedSkills]);
    
    // Convertir les compétences disponibles en options pour react-select
    const getSkillOptions = (skillTypeId: number): HerosSkillLevelDTO[] => {
        return availableSkills.filter(skill => {
            return skill.heros_skill_type.id === skillTypeId;
        });
    };
    
    const getCurrentValue = (skillTypeId: number): HerosSkillLevelDTO | null => {
        
        // On récupère la compétence sauvegarder du joueur si elle existe
        const skillSave = (savedUserSkills ?? []).find(skill => skill.heros_skill_type.id === skillTypeId);
        if (skillSave) {
            return skillSave;
        }
        
        const skill = selectedSkills.find(
            s => s.heros_skill_type.id === skillTypeId,
        );
        // Si aucune compétence n'a été trouvée en bdd pour le joueur, on prend les types de base du joueur
        if (skill) {
            return skill;
        } else {
            const skill_user = skillTypes.find(skill => skill.heros_skill_type?.id === skillTypeId);
            // Si aucune compétence n'a été trouvée en bdd pour le joueur, on prend les types de base du jeu → lvl 1.
            if (skill_user) {
                return skill_user;
            } else {
                return availableSkills.filter(skill => skill.heros_skill_type.id === skillTypeId).find(skillLevel => skillLevel.lvl === 1) ?? null;
            }
        }
    };
    
    const handleSkillChange = (
        option: HerosSkillLevelDTO | null,
        skillType: HerosSkillTypeDTO,
    ) => {
        // On effectue une copie de l'array des compétences du joueur
        const updatedSkills = [...selectedSkills];
        
        // On supprime la compétence du joueur
        updatedSkills.splice(updatedSkills.findIndex(skill => skill.heros_skill_type?.id === skillType.id), 1);
        
        // On ajoute la nouvelle compétence
        updatedSkills.push(option);
        
        setSelectedSkills(updatedSkills);
        onSave(option);
    };
    
    return (
        <div className={"d-flex gap-4 justify-content-start align-items-center"}>
            {listSkillType.map(skillType => (
                <div key={skillType.id} className={"d-flex gap-1 justify-content-center align-items-center"}>
                    <label>{skillType.name} :</label>
                    <Select
                        value={getCurrentValue(skillType.id)}
                        options={getSkillOptions(skillType.id)}
                        onChange={(option: HerosSkillLevelDTO) => handleSkillChange(option, skillType)}
                        placeholder="Sélectionner une compétence"
                        styles={customStylesSkill(general.themeUser, feedback === skillType.id)}
                        components={{
                            Option     : CustomOption,
                            SingleValue: CustomSingleValue,
                        }}
                    />
                </div>
            ))}
        </div>
    );
}