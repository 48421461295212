import React, { useEffect, useRef } from "react";
import { Chart, registerables }     from "chart.js";
import { UserDTO }                  from "../types/models/user.dto";
import { ThemeUserDTO }             from "../types/models/themeUser.dto";
import CollapsibleFieldset          from "../components/utils/CollapsibleFieldset";
import { useTranslation }           from "react-i18next";

interface ChartProps {
    data: {
        legend: string[],
        nbr: number[][],
        total: number[],
        legend_titre: string[],
    },
    id: string,
    refChart: string,
    user?: UserDTO
    width?: number,
    height?: number
    backgroundColor: string[],
    translate?: {
        titre: string,
        table: {
            legend: string,
            nbr: string,
            pct: string,
        },
    },
    themeUser?: ThemeUserDTO
}

const ChartMultiBarComponant = ({ data, id, refChart, user = null, width = 500, height = 500, backgroundColor, translate = { titre: "", table: { legend: "", nbr: "", pct: "" } }, themeUser = null }: ChartProps) => {
    const { t } = useTranslation();
    const chartPie = useRef<HTMLCanvasElement>(null);
    
    let colorFont = themeUser?.stats_font_color ?? "#ffffff";
    let colorGrid = themeUser?.stats_border_color ?? "rgba(255,255,255,0.15)";
    
    
    const buildChartTheme = () => {
        Chart.register(...registerables);
        const myChartRef = chartPie.current?.getContext("2d");
        const myChart = Chart.getChart(refChart);
        if (myChart) {
            myChart.destroy();
        }
        
        const datasets = Object.values(data.nbr).map((nbrArray, index) => {
            return {
                backgroundColor: backgroundColor[index],
                data           : nbrArray,
                label          : Object.values(data.legend_titre)[index],
            };
        });
        
        new Chart(myChartRef!, {
            type   : "bar",
            data   : {
                labels  : Object.values(data.legend),
                datasets: datasets,
            },
            options: {
                scales : {
                    y: {
                        stacked: true,
                        ticks  : {
                            color: colorFont,
                        },
                        grid   : {
                            color: colorGrid,
                        },
                        title  : {
                            display: true,
                            text   : translate.table.legend,
                            color  : colorFont,
                        },
                    },
                    x: {
                        stacked: true,
                        ticks  : {
                            color: colorFont,
                        },
                        grid   : {
                            color: colorGrid,
                        },
                        title  : {
                            display: true,
                            text   : translate.table.nbr,
                            color  : colorFont,
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: "top",
                        labels  : {
                            color: colorFont,
                        },
                    },
                    title : {
                        display: true,
                        text   : translate.titre,
                        color  : colorFont,
                    },
                },
            },
        });
    };
    
    useEffect(() => {
        buildChartTheme();
    }, [data]);
    
    return (
        <div id={id + "-groupe-stats-graph-table"}>
            <div id={id + "-graph-theme"}>
                <canvas ref={chartPie} id={refChart} width={width} height={height}></canvas>
            </div>
            <CollapsibleFieldset title={t("Données de :", { ns: "stats" }) + " " + translate.titre}>
                <div id={id + "-table-stats-theme"}>
                    <table>
                        <thead>
                        <tr>
                            <th rowSpan={2}>{translate.table.legend}</th>
                            {Object.values(data.legend_titre).map((value, index) => {
                                return <th key={value + index} colSpan={2}>{value}</th>;
                            })}
                        </tr>
                        <tr>
                            {Object.values(data.legend_titre).map((value, index) => {
                                return <React.Fragment key={"legend_" + index}>
                                    <th rowSpan={1}>{translate.table.nbr}</th>
                                    <th rowSpan={1}>{translate.table.pct}</th>
                                </React.Fragment>;
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {Object.values(data.legend).map((jour, index) => {
                            return (
                                <tr key={"nbr_" + index}>
                                    <td>{jour}</td>
                                    {Object.values(data.legend_titre).map((legend, index2) => {
                                        
                                        const nbr = Object.values(data.nbr)[index2][index];
                                        
                                        return (
                                            <React.Fragment key={"nbr_" + index + "_" + index2}>
                                                <td>{nbr}</td>
                                                <td>{Math.round(nbr / data.total[index2] * 10000) / 100}%</td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </CollapsibleFieldset>
        </div>
    );
};

export default ChartMultiBarComponant;