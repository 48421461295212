import { useCallback }                               from "react";
import { ColorConfigExport, ColorThemeConfigExport } from "../../types/components/OptionsPerso/OptionsPersoType";

// Hook personnalisé pour gérer les couleurs et les champs spécifiques
export const useOptionPerso = () => {
    const colorWithAlpha = useCallback((couleur: string): string => {
        return (couleur.length === 7) ? couleur + "FF" : couleur;
    }, []);
    
    const controleCouleur = useCallback((couleur: string): boolean => {
        // Vérifie que la chaîne commence par "#" et fait 7 ou 9 caractères
        const hexColorPattern = /^#[0-9A-Fa-f]{6}([0-9A-Fa-f]{2})?$/;
        return hexColorPattern.test(couleur);
    }, []);
    
    // Liste des clés spécifiques qui ne sont pas des couleurs
    const nonCouleurKeys: (keyof ColorConfigExport)[] = [
        "carteTextured", "colorMap", "alphaColorMap",
    ];
    
    // Valide l'objet ColorConfigExport
    const validateCouleurConfig = useCallback((config: ColorConfigExport): boolean => {
        for (const key in config) {
            const value = config[key as keyof ColorConfigExport];
            
            // Si le champ est optionnel et absent, on le saute
            if (value === undefined || value === null) {
                continue;
            }
            
            // Si le champ fait partie des champs spécifiques
            if (nonCouleurKeys.includes(key as keyof ColorConfigExport)) {
                if (key === "carteTextured" && typeof value !== "string") {
                    console.error(`Invalid value for ${key}: ${value}. It must be a string.`);
                    return false;
                }
                if ((key === "alphaColorMap") && (typeof value !== "string" && typeof value !== "number")) {
                    console.error(`Invalid value for ${key}: ${value}. It must be a string or a number.`);
                    return false;
                }
                continue;
            }
            
            // Pour les autres champs, vérifier si c'est une couleur
            if (!controleCouleur(value)) {
                console.error(`Invalid color format for ${key}: ${value}`);
                return false;
            }
        }
        return true;
    }, [controleCouleur]);
    
    // Valide l'objet ColorThemeConfigExport, incluant couleurPerso
    const validateThemeConfig = useCallback((themeConfig: ColorThemeConfigExport): boolean => {
        for (const key in themeConfig) {
            const value = themeConfig[key as keyof ColorThemeConfigExport];
            
            // Ignorer si c'est `couleurPerso` car il sera validé séparément
            if (key === "couleurPerso") {
                if (!validateCouleurConfig(value as ColorConfigExport)) {
                    console.error(`Invalid ColorConfigExport in ${key}`);
                    return false;
                }
                continue;
            }
            
            // Si le champ est le nom
            if (key === "nom" || key === "baseTheme") {
                continue;
            }
            
            // Validation des couleurs pour les autres champs
            if (typeof value === "string" && !controleCouleur(value)) {
                console.error(`Invalid color format for ${key}: ${value}`);
                return false;
            }
        }
        return true;
    }, [controleCouleur, validateCouleurConfig]);
    
    return { colorWithAlpha, controleCouleur, validateCouleurConfig, validateThemeConfig };
};