import React, { useEffect, useState } from "react";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { PopUpMaj }                   from "../../../types/components/ville/CarteType";
import { ZoneMaj }                    from "../../../types/components/ville/Zone";
import { ItemPrototypeDTO }           from "../../../types/models/itemPrototype.dto";
import { CategoryObjetDTO }           from "../../../types/models/categoryObjet.dto";
import { MapItemDTO }                 from "../../../types/models/mapItem.dto";
import { useTranslation }             from "react-i18next";
import { ListingItemsNews }           from "../../../components/Transverse/ListingItemsNew";
import TooltipGH                      from "../../../components/utils/TooltipGH";

interface PopUpMajManuelProps {
    popUpMaj: PopUpMaj;
    zoneMaj: ZoneMaj;
    retourMajPopUp: string | null;
    typeRetourMajPopup: number | null;
    onSaveCase: (zoneAMaj: ZoneMaj) => void;
    onCancel: () => void;
}

export default function PopUpMajManuel(props: PopUpMajManuelProps) {
    const { t } = useTranslation();
    const [zoneMaj, setZoneMaj] = useState(props.zoneMaj);
    
    useEffect(() => {
        setZoneMaj(props.zoneMaj);
    }, [props.zoneMaj]);
    
    const handleDecrementItems = (itemMod: ItemPrototypeDTO, broked: boolean) => {
        setZoneMaj((prevState) => {
            const items = prevState.items
                .map((item) => {
                    if (
                        item.item.id === itemMod.id &&
                        item.broked === broked &&
                        item.nombre > 0
                    ) {
                        return { ...item, nombre: item.nombre - 1 };
                    }
                    return item;
                })
                .filter((item) => item.nombre > 0);
            return {
                ...prevState,
                items: items,
            };
        });
    };
    
    const handleIncrementItems = (itemMod: ItemPrototypeDTO, broked: boolean) => {
        const itemList = [...zoneMaj.items];
        const index = itemList.findIndex(
            (item) =>
                item.item.id === itemMod.id && item.broked === broked,
        );
        if (index === -1) {
            itemList.push({ item: itemMod, broked: broked, nombre: 1 });
        } else {
            itemList[index].nombre++;
        }
        
        itemList.sort((a, b) => {
            const aName = t(a.item.nom, { ns: "items" });
            const bName = t(b.item.nom, { ns: "items" });
            const aStartsWithQuote = aName.startsWith("«");
            const bStartsWithQuote = bName.startsWith("«");
            if (aStartsWithQuote && bStartsWithQuote) {
                return aName.localeCompare(bName);
            }
            if (aStartsWithQuote && !bStartsWithQuote) {
                return 1;
            }
            if (!aStartsWithQuote && bStartsWithQuote) {
                return -1;
            }
            return aName.localeCompare(bName);
        });
        setZoneMaj((prevState) => ({
            ...prevState,
            items: itemList,
        }));
    };
    
    const listCatetogies = props.popUpMaj.listCategorie.filter(
        (categorie: CategoryObjetDTO) => {
            if (zoneMaj.vue === -1) {
                return categorie.id === 9;
            } else {
                return true;
            }
        },
    );
    const listItems = props.popUpMaj.listItems;
    
    const listeZombieSelect = [];
    listeZombieSelect.push(<option key={"zombie_null"} value={-1}></option>);
    for (let i = 0; i <= 50; i++) {
        listeZombieSelect.push(
            <option key={"zombie_" + i} value={i}>
                {i}
            </option>,
        );
    }
    const listeBaliseSelect = [];
    listeBaliseSelect.push(<option key={"balise_null"} value={-1}></option>);
    for (let i = 0; i <= 5; i++) {
        listeBaliseSelect.push(
            <option key={"balise_" + i} value={i}>
                {i}
            </option>,
        );
    }
    
    return (
        <div id={"popUpMajCarte" + (zoneMaj.vue === -1 ? "_marqueurs" : "")}>
            <div id={"zoneInfoCase" + (zoneMaj.vue === -1 ? "_marqueurs" : "")}>
                <div>
                    <h2>{t("Mise à jour de la case", { ns: "ville" })}</h2>
                </div>
                <div id="infoCaseMaj">
                    <p>
                        <span className="labelInfoCase">{t("Coordonnée :", { ns: "ville" })}</span>
                        <span id="coordInfoCaseMaj">({zoneMaj.xRel}<span style={{ margin: "0 2px" }}>/</span>{zoneMaj.yRel})</span>
                    </p>
                    <p>
                        <span className="labelInfoCase">{t("Distance :", { ns: "ville" })}</span>
                        <span id="distanceInfoCaseMaj">{zoneMaj.distance} km ({zoneMaj.paAller}<span style={{ margin: "0 2px" }}>/</span>{zoneMaj.paRetour} <SvgIcone icone={"h_pa"} />)</span>
                    </p>
                    {zoneMaj.vue !== -1 && <p>
                        <label className="labelInfoCase" htmlFor={"popUpCase_balisage"}>{t("Balisage :", { ns: "ville" })}</label>
                        <select
                            name="popUpCase_balisage"
                            id="popUpCase_balisage"
                            value={zoneMaj.balise ?? -1}
                            onChange={(event) => {
                                setZoneMaj((zoneMaj) => ({
                                    ...zoneMaj,
                                    balise: parseInt(event.target.value, 10),
                                }));
                            }}
                        >
                            {listeBaliseSelect}
                        </select>
                    </p>}
                    {zoneMaj.vue !== -1 && <p>
                        <label className="labelInfoCase" htmlFor={"popUpCase_zombie"}>{t("Zombie :", { ns: "ville" })}</label>
                        <select
                            name="popUpCase_zombie"
                            id="popUpCase_zombie"
                            value={zoneMaj.zombie ?? -1}
                            onChange={(event) => {
                                setZoneMaj((zoneMaj) => ({
                                    ...zoneMaj,
                                    zombie: parseInt(event.target.value, 10),
                                }));
                            }}
                        >
                            {listeZombieSelect}
                        </select>
                    </p>}
                    {zoneMaj.vue === -1 && <React.Fragment>
                        <p>
                            <label className="labelInfoCase" htmlFor={"popUpCase_zombie_min"}>{t("Zombie mini :", { ns: "ville" })}</label>
                            <select
                                name="popUpCase_zombie_min"
                                id="popUpCase_zombie_min"
                                value={zoneMaj.zombie_min ?? -1}
                                onChange={(event) => {
                                    setZoneMaj((zoneMaj) => ({
                                        ...zoneMaj,
                                        zombie_min: parseInt(event.target.value, 10),
                                    }));
                                }}
                            >
                                {listeZombieSelect}
                            </select>
                        </p>
                        <p>
                            <label className="labelInfoCase" htmlFor={"popUpCase_zombie_max"}>{t("Zombie maxi :", { ns: "ville" })}</label>
                            <select
                                name="popUpCase_zombie_max"
                                id="popUpCase_zombie_max"
                                value={zoneMaj.zombie_max ?? -1}
                                onChange={(event) => {
                                    setZoneMaj((zoneMaj) => ({
                                        ...zoneMaj,
                                        zombie_max: parseInt(event.target.value, 10),
                                    }));
                                }}
                            >
                                {listeZombieSelect}
                            </select>
                        </p>
                    </React.Fragment>
                    }
                    {zoneMaj.vue !== -1 && (
                        <div className="lignEpuise">
                            <span className="labelInfoCase">{t("Case épuisée :", { ns: "ville" })}</span>
                            <input
                                type="radio"
                                id="case_epuise_ok"
                                name="zoneMaj_dried"
                                value="1"
                                checked={zoneMaj.dried === 1}
                                onChange={(event) => {
                                    setZoneMaj((zoneMaj) => ({
                                        ...zoneMaj,
                                        dried: parseInt(event.target.value, 10),
                                    }));
                                }}
                            />
                            <label htmlFor="case_epuise_ok">
                                {t("Oui", { ns: "app" })}
                            </label>
                            <input
                                type="radio"
                                id="case_epuise_ko"
                                name="zoneMaj_dried"
                                value="0"
                                checked={zoneMaj.dried === 0}
                                onChange={(event) => {
                                    setZoneMaj((zoneMaj) => ({
                                        ...zoneMaj,
                                        dried: parseInt(event.target.value, 10),
                                    }));
                                }}
                            />
                            <label htmlFor="case_epuise_ko">
                                {t("Non", { ns: "app" })}
                            </label>
                        </div>
                    )}
                </div>
                <p id={"lignePhraseObjetSol"}>{t("Obiet(s) au sol :", { ns: "ville" })}</p>
                <div id="objetCaseMaj">
                    {zoneMaj.items.map((item) => (
                        <AffNbrIcon
                            item={item}
                            key={"obj_" + item.item.id + "_" + (item.broked ? 1 : 0)}
                            onDecrementItem={handleDecrementItems}
                        />
                    ))}
                </div>
                <div id="boutonMajCase">
                    <button className={"btn btn-xs btn-success"} onClick={() => props.onSaveCase(zoneMaj)}>
                        {t("Mettre à jour", { ns: "ville" })}
                    </button>
                    <button className={"btn btn-xs btn-warning"} onClick={props.onCancel}>
                        {t("Annuler", { ns: "app" })}
                    </button>
                </div>
                {props.retourMajPopUp !== null && <div id="retourAjaxMajCaseCarte" className={props.typeRetourMajPopup === 0 ? "color-green" : "color-red" }>{props.retourMajPopUp}</div>}
            </div>
            <div id={"listingObjetMajCarte" + (zoneMaj.vue === -1 ? "_marqueurs" : "")}>
                <ListingItemsNews
                    listCategory={listCatetogies}
                    listItems={listItems}
                    itemSelected={handleIncrementItems}
                />
            </div>
        </div>
    );
}

function AffNbrIcon(props: { item: MapItemDTO; onDecrementItem: (item: ItemPrototypeDTO, broked: boolean) => void; }) {
    const { t } = useTranslation();
    let typeName = "";
    let classImage;
    
    const item = props.item;
    
    if (item.item.type_objet?.id === 3) {
        classImage = "videImg4";
        typeName = t("(Marqueur)", { ns: "items" });
    } else {
        if (item.item.id >= 2000 && item.item.id < 3000) {
            classImage = "videImg3";
            typeName = t("empoisonné(e)", { ns: "items" });
        } else {
            if (item.broked) {
                classImage = "videImg2";
                typeName = t("cassé(e)", { ns: "items" });
            } else {
                classImage = "videImg";
            }
        }
    }
    
    const infoBulle = (<span className={"info"}>
            {t(item.item.nom, { ns: "items" })} {typeName} (id : {item.item.id})
    </span>);
    
    return (
        <div
            className={"videItem"}
            onClick={() => props.onDecrementItem(item.item, item.broked)}
        >
            <span className={"nbrItems"}>{item.nombre}</span>
            <TooltipGH>
                <span className={classImage}>
                    <span className={"infoBulle"}>
                        <SvgIcone icone={item.item.icon} />
                    </span>
                </span>
                {infoBulle}
            </TooltipGH>
        </div>
    );
}