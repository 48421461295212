import React, { useState }        from "react";
import { ClassementAme }          from "../../types/components/Ame/ClassementAme";
import { ClassementPicto }        from "../../types/components/Ame/HistoriquePictos";
import SvgIcone                   from "../../components/generality/SvgIcone";
import Modal                      from "react-bootstrap/Modal";
import Button                     from "react-bootstrap/Button";
import { ClassementAmeApi }       from "../../services/api/ClassementAmeApi";
import { Link }                   from "react-router-dom";
import { useGeneralContext }      from "../../types/Context/GeneralContext";
import { Status_error, usePopUp } from "../../types/Context/PopUpContext";
import { PictoPrototypeDTO }      from "../../types/models/pictoPrototype.dto";
import { UserDTO }                from "../../types/models/user.dto";
import { useTranslation }         from "react-i18next";
import CustomSwitchToggle         from "../../components/generality/CustomSwitchToggle";
import TooltipGH                  from "../../components/utils/TooltipGH";

interface ClassementAmeProps {
    classementAme: ClassementAme;
}

export default function ClassementAme({ classementAme }: ClassementAmeProps) {
    const { t } = useTranslation();
    const [typeAff, setTypeAff] = useState("general");
    const [classementPicto, setClassementPicto] = useState([]);
    const [pictoShow, setPictoShow] = useState(null);
    const [showPopPicto, setShowPopPicto] = useState(false);
    const [filtreSaisonAme, setFiltreSaisonAme] = useState("-1");
    const [classPicto, setClassPicto] = useState<ClassementPicto[][]>(classementAme.classPicto);
    const [classPictoVille, setClassPictoVille] = useState<ClassementPicto[][]>(classementAme.classPictoVille);
    const { setStatus, setShowPop, setMessagePopUp } = usePopUp();
    const { general } = useGeneralContext();
    
    const inVille = general.inVille;
    const user = general.user;
    const listPicto = classementAme.listPicto;
    
    const handleGeneral = (picto: PictoPrototypeDTO) => {
        const classementApi = new ClassementAmeApi();
        if (filtreSaisonAme === "-1") {
            classementApi.recup_picto_general(picto).then((response) => {
                if (response.data.classement !== undefined) {
                    setShowPopPicto(true);
                    setClassementPicto(response.data.classement);
                    setPictoShow(response.data.picto);
                }
            }).catch((error) => {
                setShowPop(true);
                setMessagePopUp(error.message);
                setStatus(Status_error);
            });
        } else {
            classementApi.recup_picto_general_saison(picto, parseInt(filtreSaisonAme)).then((response) => {
                if (response.data.classement !== undefined) {
                    setShowPopPicto(true);
                    setClassementPicto(response.data.classement);
                    setPictoShow(response.data.picto);
                }
            }).catch((error) => {
                setShowPop(true);
                setMessagePopUp(error.message);
                setStatus(Status_error);
            });
        }
    };
    const handleVille = (picto: PictoPrototypeDTO) => {
        const classementApi = new ClassementAmeApi();
        if (filtreSaisonAme === "-1") {
            classementApi.recup_picto_ville(picto).then((response) => {
                
                document.body.style.overflow = "auto";
                document.body.style.paddingRight = "0";
                
                setShowPopPicto(true);
                setClassementPicto(response.data.classement);
                setPictoShow(response.data.picto);
            }).catch((error) => {
                setShowPop(true);
                setMessagePopUp(error.data.error);
                setStatus(Status_error);
            });
        } else {
            classementApi.recup_picto_ville_saison(picto, parseInt(filtreSaisonAme)).then((response) => {
                
                document.body.style.overflow = "auto";
                document.body.style.paddingRight = "0";
                
                setShowPopPicto(true);
                setClassementPicto(response.data.classement);
                setPictoShow(response.data.picto);
            }).catch((error) => {
                setShowPop(true);
                setMessagePopUp(error.data.error);
                setStatus(Status_error);
            });
        }
    };
    const handleClosePicto = () => {
        setPictoShow(null);
        setShowPopPicto(false);
        setClassementPicto([]);
    };
    const handleFiltreSaisonAme = (saison: string) => {
        if (saison === "-1") {
            setFiltreSaisonAme(saison);
            setClassPicto(classementAme.classPicto);
            setClassPictoVille(classementAme.classPictoVille);
        } else {
            setFiltreSaisonAme(saison);
            const ameApi = new ClassementAmeApi();
            ameApi.classementSaison(parseInt(saison)).then((response) => {
                setClassPicto(response.data.classPicto);
                setClassPictoVille(response.data.classPictoVille);
            }).catch((error) => {
                setShowPop(true);
                setMessagePopUp(error.data.error);
                setStatus(Status_error);
            });
        }
    };
    
    const genereBlocPicto = (picto: PictoPrototypeDTO, listClassement: ClassementPicto[], type_aff: string, user: UserDTO) => {
        return <div className={((picto.rare) ? "pictoRare" : "pictoCommun") + " tablePicto"} key={"bloc_picto_" + picto.id}>
            <div className="entete_classement_picto">
                <a onClick={(type_aff === "ville") ? () => handleVille(picto) : () => handleGeneral(picto)}>
                    <span className={"d-flex align-items-center justify-content-start gap-1 ms-1 me-1"}>
                        <SvgIcone icone={picto.img} />
                        <span>{t(picto.name, { ns: "game" })}</span>
                        <TooltipGH>
                            <span className="infoBulle">
                                <i className="fa-solid fa-circle-info"></i>
                            </span>
                            <span className="info">{t(picto.description, {ns: "game"})}</span>
                        </TooltipGH>
                    </span>
                </a>
            </div>
            <div>
                <table>
                    <tbody>
                    {Object.values(listClassement).map((classement, index) => {
                        return <tr className={(classement.user.id === user.id) ? "myLine" : ""} key={"class_" + index}>
                            <td className="tab_classement_picto_id">{index + 1}</td>
                            <td className="tab_classement_picto_pseudo"><Link to={"/ame/" + classement.user.id_my_hordes}>{classement.user.pseudo}</Link></td>
                            <td className="tab_classement_picto_nombre">{classement.nombre}</td>
                        </tr>;
                    })}
                    </tbody>
                </table>
            </div>
        </div>;
    };
    
    return <div id="corps_picto">
        <div id="choix_listing" className={"d-flex gap-3 justify-content-center align-items-center"}>
            {inVille && <div className={"d-flex gap-1 justify-content-center align-items-center"}>
                <label htmlFor="choix_classement_picto">{t("Choix d'affichage du classement :", { ns: "ame" })}</label>
                <CustomSwitchToggle size={"sm"} width={100} checked={typeAff === "general"} onlabel={t("Général", { ns: "ame" })} offlabel={t("Ma ville", { ns: "ame" })} onChange={(value) => {
                    setTypeAff(value ? "general" : "ville");
                }} />
            </div>}
            <div className={"d-flex gap-1 justify-content-center align-items-center"}>
                <span>{t("Classement pour :", { ns: "ame" })}</span>
                <select
                    value={filtreSaisonAme}
                    onChange={(event) => {
                        handleFiltreSaisonAme(event.target.value);
                    }}
                >
                    {Object.values(classementAme.listeSaison).map((saison) => {
                        return <option key={saison.id} value={saison.id}>{saison.nom}</option>;
                    })}
                </select>
            </div>
        </div>
        <div id="zoneListingPictosClassement">
            <div id="classementPicto_general">
                {typeAff === "general" && Object.values(listPicto).map((picto) => {
                    return genereBlocPicto(picto, classPicto[picto.id], typeAff, user);
                })}
                {typeAff === "ville" && Object.values(listPicto).map((picto) => {
                    return genereBlocPicto(picto, classPictoVille[picto.id], typeAff, user);
                })}
            </div>
        </div>
        <Modal show={showPopPicto} onHide={handleClosePicto} centered={true} scrollable={true}>
            <Modal.Header>
                <Modal.Title>
                    <span className={"d-flex justify-content-center align-items-center gap-1"}>
                        <span>{((typeAff === "ville") ? t("Classement de la ville pour :", { ns: "ame" }) : t("Classement pour :", { ns: "ame" })) + " "}</span>
                        {(pictoShow === null ? null : (<span className={"d-flex justify-content-center align-items-center gap-1"}>
                            <SvgIcone icone={pictoShow.img} />
                            <span>{t(pictoShow.name, { ns: "game" })}</span>
                        </span>))}
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="classement_picto_part">
                    <table>
                        <thead>
                        <tr>
                            <th>{t("Pos", { ns: "ame" })}</th>
                            <th>{t("Pseudo", { ns: "ame" })}</th>
                            <th>{t("Nombre", { ns: "ame" })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.values(classementPicto).map((classement, index) => {
                            return <tr className={(classement.user.id === user.id) ? "myLine" : ""} key={"class_" + index}>
                                <td className="tab_classement_picto_id">{index + 1}</td>
                                <td className="tab_classement_picto_pseudo"><Link to={"/ame/" + classement.user.id_my_hordes}>{classement.user.pseudo}</Link></td>
                                <td className="tab_classement_picto_nombre">{classement.nombre}</td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePicto}>{t("Fermer", { ns: "app" })}</Button>
            </Modal.Footer>
        </Modal>
    </div>
        ;
}

