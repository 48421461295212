import { BanqueType }       from "../../types/components/ville/BanqueType";
import { GeneralType }      from "../../types/components/Generality/GeneralType";
import { FetchGH }          from "../../components/generality/FetchGH";
import { ItemPrototypeDTO } from "../../types/models/itemPrototype.dto";


export class BanqueApi {
    
    private fetch: FetchGH;
    
    constructor(mapId?: number) {
        this.fetch = new FetchGH("banque", mapId ?? 0);
    }
    
    public general(mapId?: string): Promise<AffichageBanque> {
        return this.fetch.get("/" + ((mapId) ? mapId : ""));
    }
    
    public getDetailItem(id: number): Promise<AffichageBanqueItemDetail> {
        return this.fetch.get("/item/" + id);
    }
    
}

export type AffichageBanque = {
    data: {
        banque?: BanqueType;
        general?: GeneralType,
        error?: string,
    },
    status?: number,
    message?: string,
}
export type AffichageBanqueItemDetail = {
    data: {
        item?: ItemPrototypeDTO;
        error?: string,
    },
    status?: number,
    message?: string,
}