import React, { useEffect, useState }        from "react";
import { AffNbrIcon }                        from "../../../components/generality/ComposantGeneral";
import { ListingItemsNews }                  from "../../../components/Transverse/ListingItemsNew";
import { CaseRuine, PopUpMajCaseRuineProps } from "../../../types/components/ville/RuinesType";
import { ItemPrototypeDTO }                  from "../../../types/models/itemPrototype.dto";
import { useTranslation }                    from "react-i18next";
import { RuinesObjetsDTO }                   from "../../../types/models/ruinesObjets.dto";

interface PopUpMajManuelProps {
    popUpMaj: PopUpMajCaseRuineProps;
    caseRuine: CaseRuine;
    retourMajPopUp: string | null;
    typeRetourMajPopup: number | null;
    onSaveCase: (caseRuine: CaseRuine) => void;
    onCancel: () => void;
}

export default function PopUpMajCaseRuine(props: PopUpMajManuelProps) {
    const [caseRuine, setCaseRuine] = useState(props.caseRuine);
    const { t } = useTranslation();
    
    useEffect(() => {
        setCaseRuine(props.caseRuine);
    }, [props.caseRuine]);
    
    const handleDecrementItems = (itemMod: ItemPrototypeDTO, broken: boolean) => {
        setCaseRuine((prevState) => {
            const items = prevState.items
                .map((itemSol) => {
                    if (
                        itemSol.item.id === itemMod.id &&
                        itemSol.broken === broken &&
                        itemSol.nombre > 0
                    ) {
                        return { ...itemSol, nombre: itemSol.nombre - 1 };
                    }
                    return itemSol;
                })
                .filter((itemSol) => itemSol.nombre > 0); // Retirer les éléments avec count = 0
            return {
                ...prevState,
                items: items,
            };
        });
    };
    
    const handleIncrementItems = (itemMod: ItemPrototypeDTO, broken: boolean) => {
        const itemSol = [...caseRuine.items];
        const index = itemSol.findIndex(
            (item) =>
                item.item.id === itemMod.id && item.broken === broken,
        );
        if (index === -1) {
            itemSol.push({ item: itemMod, broken: broken, nombre: 1 });
        } else {
            itemSol[index].nombre++;
        }
        
        itemSol.sort((a, b) => a.item.nom.localeCompare(b.item.nom)); // Tri par ordre alphabétique de la propriété "name"
        
        setCaseRuine((prevState) => {
            return {
                ...prevState,
                items: itemSol,
            };
        });
    };
    
    const listCatetogies = props.popUpMaj.listCategorie;
    const listItems = props.popUpMaj.listItems;
    
    return (
        <React.Fragment>
            <div id="zoneInfoCaseRuine">
                <div>
                    <h2>{t("Mise à jour de la case", { ns: "ville" })} </h2>
                </div>
                <p>{t("Obiet(s) au sol :", { ns: "ville" })}</p>
                <div id="objetCoffreMaj">
                    {Object.values(caseRuine.items).sort((a: RuinesObjetsDTO, b: RuinesObjetsDTO) => {
                        return t(a.item.nom, { ns: "items" }).localeCompare(t(b.item.nom, { ns: "items" }));
                    }).map((item) => (
                        <AffNbrIcon
                            item={item.item}
                            broken={item.broken}
                            nbr={item.nombre}
                            key={"obj_" + item.item.id + "_" + (item.broken ? 1 : 0)}
                            onDecrementItem={handleDecrementItems}
                        />
                    ))}
                </div>
                <div id="boutonMajCaseRuine">
                    {(props.typeRetourMajPopup === null || (props.typeRetourMajPopup !== 0)) && (
                        <React.Fragment>
                            <button id="enrCaseRuineObjet" onClick={() => props.onSaveCase(caseRuine)}>{t("Enregistrer", { ns: "ville" })}</button>
                            <button id="annulCaseRuineObjet" onClick={props.onCancel}>{t("Annuler", { ns: "ville" })}</button>
                        </React.Fragment>
                    )}
                    {props.typeRetourMajPopup !== null && props.typeRetourMajPopup === 0 && (<button id="fermerCoffre" onClick={props.onCancel}>{t("Fermer", { ns: "ville" })}</button>)}
                </div>
                
                {props.retourMajPopUp !== null && (<div id="retourAjaxMajCaseRuineObjet" className={ props.typeRetourMajPopup === 0 ? "color-green" : "color-red" }>{props.retourMajPopUp}</div>)}
            </div>
            <div id="listingObjetMajCaseRuineObjet">
                <ListingItemsNews
                    listCategory={listCatetogies}
                    listItems={listItems}
                    itemSelected={handleIncrementItems}
                />
            </div>
        </React.Fragment>
    );
}
