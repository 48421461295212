import { GeneralType }                      from "../../types/components/Generality/GeneralType";
import { RuineGameMainType, RuineGameType } from "../../types/components/RuineGame/RuineGameType";
import { FetchGH }                          from "../../components/generality/FetchGH";
import { CaseRuine }                        from "../../types/components/ville/RuinesType";


export class RuineGameApi {
    
    private fetchGH: FetchGH;
    
    constructor(mapId?: number) {
        this.fetchGH = new FetchGH("ruineGame", mapId ?? 0);
    }
    
    
    public main(): Promise<RetourRuineGameMain> {
        return this.fetchGH.get("/");
    }
    
    public getMaze(data: { nombre_etage: number, oxygene: number, type_plan: number, nb_zombie: number }): Promise<RetourRuineGameMaze> {
        return this.fetchGH.post({ path: "/getRuineMaze", data: data });
    }
    
    public deplacement(data: {
        idUser: number, case: CaseRuine, id_ruine_game: string, case_suivante: CaseRuine, case_fuite: boolean
    }): Promise<RetourRuineGameDeplacement> {
        return this.fetchGH.post({ path: "/deplacementRuine", data: data });
    }
    
    public deplacementPiece(data: { idUser: number, case: CaseRuine, id_ruine_game: string, in_piece: boolean }): Promise<RetourRuineGameFouille> {
        return this.fetchGH.post({ path: "/deplacementPiece", data: data });
    }
    
    public fuiteRuine(data: { idUser: number, case: CaseRuine, idRuineGame: string, caseFuite: boolean }): Promise<RetourRuineGameDeplacement> {
        return this.fetchGH.post({ path: "/fuiteRuine", data: data });
    }
    
    public killZombie(data: { idUser: number, case: CaseRuine, idRuineGame: string }): Promise<RetourRuineGameKill> {
        return this.fetchGH.post({ path: "/killZombie", data: data });
    }
    
    public fouillePiece(data: { idUser: number, case: CaseRuine, idRuineGame: string }): Promise<RetourRuineGameFouille> {
        return this.fetchGH.post({ path: "/fouillePiece", data: data });
    }
    
    public endGame(data: {
        idUser: number, plan_ruine: CaseRuine[][][], id_ruine_game: string, oxygene: number, mana: number, ejecter: boolean
    }): Promise<RetourEndGame> {
        return this.fetchGH.post({ path: "/endGame", data: data });
    }
    
    public historique(): Promise<RetourRuineGameHistorique> {
        return this.fetchGH.get("/historique");
    }
    
    public getOldMaze(idRuineGame: string): Promise<RetourRuineGameMazeOld> {
        return this.fetchGH.get("/getOldMaze/" + idRuineGame);
    }
    
    
}

export interface RetourRuineGameMain {
    data: {
        general?: GeneralType,
        ruine_game?: RuineGameMainType,
        error?: string,
    },
    status?: number,
    message?: string,
}

export interface RetourRuineGameHistorique {
    data: {
        list_historique?: RuineGameType[],
        traduction_ruine_game?: string[],
        error?: string,
    },
    status?: number,
    message?: string,
}

export interface RetourRuineGameMaze {
    data: {
        ruine_maze?: CaseRuine[][][],
        ruine_maze_vide?: CaseRuine[][][],
        game?: RuineGameType,
        error?: string,
    },
    status?: number,
    message?: string,
}

export interface RetourRuineGameMazeOld {
    data: {
        ruine_plan?: CaseRuine[][][],
        error?: string,
    },
    status?: number,
    message?: string,
}

export interface RetourRuineGameDeplacement {
    data: {
        oxygen_lost_total?: number,
        perte_oxy?: number,
        error?: string,
    },
    status?: number,
    message?: string,
}

export interface RetourRuineGameFouille {
    data: {
        error?: string,
    },
    status?: number,
    message?: string,
}

export interface RetourRuineGameKill {
    data: {
        zombie_restant?: number,
        zombie_kill?: number,
        mana_kill?: number,
        error?: string,
    },
    status?: number,
    message?: string,
}

export interface RetourEndGame {
    data: {
        score: {
            pct_kill?: number,
            pct_explo?: number,
            pct_fouille?: number,
            ejected?: boolean,
            oxygen_restant?: number,
        }
        error?: string,
    },
    status?: number,
    message?: string,
}