import { configureStore }   from "@reduxjs/toolkit";
import menuAdminReducer     from "./admin/menuAdminSlice";
import menuOptionsReducer   from "./options/menuOptionsSlice";
import menuCarteReducer     from "./ville/menuCarteSlice";
import plansChantierReducer from "./hotel/plansChantierSlice";
import gestionVillesReducer from "./villes/gestionVillesSlice";

// Création du store en combinant tous les reducers
export const store = configureStore({
    reducer: {
        menuAdmin    : menuAdminReducer,
        menuOptions  : menuOptionsReducer,
        menuCarte    : menuCarteReducer,
        plansChantier: plansChantierReducer,
        gestionVilles: gestionVillesReducer,
        // Tu pourrais ajouter d'autres reducers ici si nécessaire
    },
});

// Exportation du type racine pour l'état du store
export type RootState = ReturnType<typeof store.getState>;

// Exportation du type dispatch
export type AppDispatch = typeof store.dispatch;
