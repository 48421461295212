import { FieldTitle, useInput, Validator } from "ra-core";
import React                               from "react";
import reactCSS                            from "reactcss";
import "react-color-palette/css";
import ColorPickerGH                       from "../../../../components/generality/ColorPickerGH";

interface ColorInputProps {
    name?: string;
    resource?: string;
    label?: string;
    options?: {
        disableAlpha: false
    };
    disabled?: boolean;
    source: string;
    helperText?: string;
    className?: string;
    isRequired?: boolean;
    validate?: Validator | Validator[] | undefined;
}

export const ColorInput = (props: ColorInputProps) => {
    
    const { label, source, options, resource, validate, disabled = false } = props;
    const { field, isRequired } = useInput({ ...props, validate });
    
    const styles = reactCSS({
        "default": {
            color  : {
                width       : "36px",
                height      : "14px",
                borderRadius: "2px",
                background  : `${field.value}`,
            },
            swatch : {
                padding     : "5px",
                background  : "#fff",
                borderRadius: "1px",
                boxShadow   : "0 0 0 1px rgba(0,0,0,.1)",
                display     : "inline-block",
                cursor      : "pointer",
            },
            popover: {
                position: "absolute" as "absolute",
                zIndex  : "2",
            },
            cover  : {
                position: "fixed" as "fixed",
                top     : "0px",
                right   : "0px",
                bottom  : "0px",
                left    : "0px",
            },
            label  : {
                display       : "flex" as "flex",
                alignItems    : "center" as "center",
                justifyContent: "space-arround" as "space-arround",
                gap           : "10px",
                marginBottom  : "5px",
            },
            display: {
                padding     : "5px",
                background  : "#fff",
                borderRadius: "1px",
                boxShadow   : "0 0 0 1px rgba(0,0,0,.1)",
                display     : "inline-block",
            },
        },
    });
    
    
    return (
        <div style={styles.label}>
            <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
            <ColorPickerGH color={field.value} onChangeColor={(color) => field.onChange(color)} />
        </div>
    );
};