import React, { PropsWithChildren } from "react";
import TooltipGH                    from "./TooltipGH";


type ButtonProps = {
    label: string;
}

export default function TooltipsInfo(props: PropsWithChildren<ButtonProps>) {
    return <TooltipGH>
        <span className="infoBulle">
            <i className="fas fa-info-circle"></i>
        </span>
        <span className="info">{props.label}</span>
    </TooltipGH>;
}