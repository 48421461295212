import React, { useEffect, useState }            from "react";
import { StatsType }                             from "../../types/components/General/StatsType";
import { Tabs }                                  from "react-bootstrap";
import Tab                                       from "react-bootstrap/Tab";
import ChartPieComponant                         from "../../utils/ChartPieComponant";
import ChartBoxPlotComponant                     from "../../utils/ChartBoxPlotComponant";
import ChartMultiBarComponant                    from "../../utils/ChartMultiBarComponant";
import { GeneralApi, RetourStatistiques }        from "../../services/api/GeneralApi";
import TriangleSort                              from "../../components/generality/ComposantGeneral/TriangleSort";
import { getSortIndicatorClass, handleSortData } from "../../components/generality/ComposantGeneral";
import { Status_error, usePopUp }                from "../../types/Context/PopUpContext";
import { useGHContext }                          from "../../types/Context/GHContext";
import { useGeneralContext }                     from "../../types/Context/GeneralContext";
import { useTranslation }                        from "react-i18next";

interface StatsProps {
    stats: StatsType;
}

export function Stats({ stats }: StatsProps) {
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState("users");
    const [saison, setSaison] = useState(-1);
    const [statsData, setStatsData] = useState(stats.statsData);
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const { setIsOnRefresh, setIsOnUpdate } = useGHContext();
    const { general, setGeneral } = useGeneralContext();
    const [sortedColumnBuilding, setSortedColumnBuilding] = useState("");
    const [sortOrderBuilding, setSortOrderBuilding] = useState("");
    const [sortedColumnRuin, setSortedColumnRuin] = useState("");
    const [sortOrderRuin, setSortOrderRuin] = useState("");
    const [sortedColumnScrut, setSortedColumnScrut] = useState<string>("");
    const [sortOrderScrut, setSortOrderScrut] = useState("");
    
    const backgroundColorTheme = [
        "rgb(255, 99, 132)",
        "rgb(54, 162, 235)",
        "rgb(255, 205, 86)",
        "rgb(28,255,0)",
    ];
    
    const handleChangeSaison = (idSaison: number) => {
        setSaison(idSaison);
        const generalApi = new GeneralApi();
        generalApi.statistiques_saison(idSaison).then((response: RetourStatistiques) => {
            if (response.data.stats !== undefined) {
                setStatsData(response.data.stats.statsData);
                setGeneral(response.data.general);
                setIsOnRefresh(false);
                setIsOnUpdate(false);
            }
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.message);
        });
    };
    
    
    const sortedLists = (list: {
        nom: string
        nbr: number;
        nbrRne: number;
        nbrRe: number;
        nbrPande: number;
    }[], sortedColumn: string, orderColumn: string) => {
        const sortFunctions = {
            "nbr"     : (a, b) => a.nbr - b.nbr || a.nom.localeCompare(b.nom),
            "nbrRne"  : (a, b) => a.nbrRne - b.nbrRne || a.nom.localeCompare(b.nom),
            "nbrRe"   : (a, b) => a.nbrRe - b.nbrRe || a.nom.localeCompare(b.nom),
            "nbrPande": (a, b) => a.nbrPande - b.nbrPande || a.nom.localeCompare(b.nom),
            "name"    : (a, b) => a.nom.localeCompare(b.nom),
            "default" : (a, b) => a.nom.localeCompare(b.nom),
        };
        const sortFunction = sortFunctions[sortedColumn] || sortFunctions["default"];
        return Object.values(list).sort((a, b) => {
            const result = sortFunction(a, b);
            return orderColumn === "asc" ? result : -result;
        });
    };
    
    useEffect(() => {
        setStatsData(stats.statsData);
    }, [stats]);
    
    return <div id={"statistique_site"}>
        <Tabs defaultActiveKey={activeKey} id="uncontrolled-tab-example" onSelect={(eventKey) => setActiveKey(eventKey)}>
            <Tab eventKey={"users"} title={t("Utilisateurs", { ns: "app" })}>
                <div className={"container-tabs"}>
                    <div id={"theme-pouvoir-stats"}>
                        <ChartPieComponant
                            data={statsData.users.themes} id={"stats"} refChart={"statTheme"} user={general.user} width={300} height={300}
                            backgroundColor={backgroundColorTheme}
                            translate={{
                                titre: t("Repartition des thèmes utilisés", { ns: "stats" }),
                                table: { legend: t("Thème", { ns: "stats" }), nbr: t("Nombre", { ns: "stats" }), pct: t("Pourcentage", { ns: "stats" }) },
                            }}
                            displayLegend={true} />
                        <div className={"stats-vertical-separator"} id={"stats-user-seperator"}></div>
                        <ChartPieComponant
                            data={statsData.users.power} id={"power"} refChart={"statPower"} user={general.user} width={300} height={300}
                            backgroundColor={backgroundColorTheme}
                            translate={{
                                titre: t("Repartition des pouvoirs héros", { ns: "stats" }),
                                table: { legend: t("Pouvoir héros", { ns: "stats" }), nbr: t("Nombre", { ns: "stats" }), pct: t("Pourcentage", { ns: "stats" }) },
                            }}
                            displayLegend={false} />
                    </div>
                </div>
            </Tab>
            <Tab eventKey={"cities"} title={t("Villes", { ns: "app" })}>
                <div className={"container-tabs"}>
                    <div className={"stats_zone_choix_saison"}>
                        <label htmlFor={"stats_choix_saison"}>{t("Statistiques pour : ", { ns: "stats" })}</label>
                        <select name={"stats_choix_saison"} id={"stats_choix_saison"} value={saison}
                                onChange={(event) => handleChangeSaison(parseInt(event.target.value))}>
                            {statsData.listSaison.map((saison, index) => {
                                return <option value={saison.idSaison} key={index}>{saison.nom}</option>;
                            })}
                        </select>
                    </div>
                    <div id={"theme-cities-part1"}>
                        <ChartPieComponant
                            data={statsData.cities.jobs} id={"jobs"} refChart={"statJob"} user={general.user} width={300} height={300}
                            backgroundColor={backgroundColorTheme} translate={{
                            titre: t("Repartition des métiers", { ns: "stats" }),
                            table: { legend: t("Métier", { ns: "stats" }), nbr: t("Nombre", { ns: "stats" }), pct: t("Pourcentage", { ns: "stats" }), total: t("Durée moyenne (J)", { ns: "stats" }) },
                        }}
                            displayLegend={false} />
                        <div className={"stats-vertical-separator"} id={"stats-cities-seperator"}></div>
                        <ChartPieComponant
                            data={statsData.cities.typeMort} id={"typeMort"} refChart={"typeMort"} user={general.user} width={300} height={300}
                            backgroundColor={backgroundColorTheme}
                            translate={{
                                titre: t("Repartition des types de morts", { ns: "stats" }),
                                table: { legend: t("Type de mort", { ns: "stats" }), nbr: t("Nombre", { ns: "stats" }), pct: t("Pourcentage", { ns: "stats" }) },
                            }} displayLegend={false} />
                        <div className={"stats-vertical-separator"} id={"stats-cities-seperator"}></div>
                        <ChartPieComponant
                            data={statsData.cities.typeVille} id={"typeVille"} refChart={"typeVille"} user={general.user} width={300} height={300}
                            backgroundColor={backgroundColorTheme}
                            translate={{
                                titre: t("Repartition des types de villes", { ns: "stats" }),
                                table: { legend: t("Type de ville", { ns: "stats" }), nbr: t("Nombre", { ns: "stats" }), pct: t("Pourcentage", { ns: "stats" }) },
                            }} displayLegend={false} />
                    </div>
                    <ChartMultiBarComponant data={statsData.cities.dayVille} id={"day_ville"} refChart={"day_ville"} user={general.user} width={1200} height={400}
                                            backgroundColor={backgroundColorTheme}
                                            translate={{
                                                titre: t("Repartition des villes par jour", { ns: "stats" }),
                                                table: { legend: t("Jour", { ns: "stats" }), nbr: t("Nombre", { ns: "stats" }), pct: t("Pourcentage", { ns: "stats" }) },
                                            }}
                    />
                    <ChartBoxPlotComponant
                        data={statsData.cities.mort} id={"morts"} refChart={"mortJob"} user={general.user} width={1200} height={400}
                        backgroundColor={backgroundColorTheme}
                        translate={{
                            titre: t("Repartition des morts par jour", { ns: "stats" }),
                            table: { legend: t("Mort", { ns: "stats" }), nbr: t("Nombre", { ns: "stats" }), pct: t("Pourcentage", { ns: "stats" }) },
                        }} />
                </div>
            </Tab>
            <Tab title={t("Chantiers", { ns: "app" })} eventKey={"buildings"}>
                <div className={"container-tabs"}>
                    <div className={"stats_zone_choix_saison"}>
                        <label htmlFor={"stats_choix_saison"}>{t("Statistiques pour : ", { ns: "stats" })}</label>
                        <select name={"stats_choix_saison"} id={"stats_choix_saison"} value={saison}
                                onChange={(event) => handleChangeSaison(parseInt(event.target.value))}>
                            {statsData.listSaison.map((saison, index) => {
                                return <option value={saison.idSaison} key={index}>{saison.nom}</option>;
                            })}
                        </select>
                    </div>
                    <div id={"building_stats_1"}>
                        <div>
                            <h2>{t("Répartitions des Chantiers", { ns: "app" })}</h2>
                            <table>
                                <thead>
                                <tr>
                                    <th rowSpan={2}>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("name", sortedColumnBuilding, sortOrderBuilding, setSortedColumnBuilding,
                                                 setSortOrderBuilding)}>
                                            <div id={"entete_stats_buildings_name"}>{t("Nom du chantier", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("name", sortedColumnBuilding, sortOrderBuilding)} />
                                        </div>
                                    </th>
                                    <th colSpan={4}>{t("Nombre", { ns: "stats" })}</th>
                                </tr>
                                <tr>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbr", sortedColumnBuilding, sortOrderBuilding, setSortedColumnBuilding,
                                                 setSortOrderBuilding)}>
                                            <div id={"entete_stats_buildings_nbr"}>{t("Tous types", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbr", sortedColumnBuilding, sortOrderBuilding)} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbrRne", sortedColumnBuilding, sortOrderBuilding, setSortedColumnBuilding,
                                                 setSortOrderBuilding)}>
                                            <div id={"entete_stats_buildings_nbrRne"}>{t("RNE", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbrRne", sortedColumnBuilding, sortOrderBuilding)} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbrRe", sortedColumnBuilding, sortOrderBuilding, setSortedColumnBuilding,
                                                 setSortOrderBuilding)}>
                                            <div id={"entete_stats_buildings_nbrRe"}>{t("RE", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbrRe", sortedColumnBuilding, sortOrderBuilding)} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbrPande", sortedColumnBuilding, sortOrderBuilding, setSortedColumnBuilding,
                                                 setSortOrderBuilding)}>
                                            <div id={"entete_stats_buildings_nbrPande"}>{t("Pandemonium", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbrPande", sortedColumnBuilding, sortOrderBuilding)} />
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.values(sortedLists(statsData.buildings.work.tabsWork, sortedColumnBuilding, sortOrderBuilding)).map((building, index) => {
                                    return <tr key={index}>
                                        <td>{building.nom}</td>
                                        <td className={"numberStats"}>{building.nbr}</td>
                                        <td className={"numberStats"}>{building.nbrRne}</td>
                                        <td className={"numberStats"}>{building.nbrRe}</td>
                                        <td className={"numberStats"}>{building.nbrPande}</td>
                                    </tr>;
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>{t("Total", { ns: "stats" })}</td>
                                    <td className={"numberStats"}>{statsData.buildings.work.total.nbr}</td>
                                    <td className={"numberStats"}>{statsData.buildings.work.total.nbrRne}</td>
                                    <td className={"numberStats"}>{statsData.buildings.work.total.nbrRe}</td>
                                    <td className={"numberStats"}>{statsData.buildings.work.total.nbrPande}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </Tab>
            <Tab title={t("Carte", { ns: "app" })} eventKey={"maps"}>
                <div className={"container-tabs"}>
                    <div className={"stats_zone_choix_saison"}>
                        <label htmlFor={"stats_choix_saison"}>{t("Statistiques pour : ", { ns: "stats" })}</label>
                        <select name={"stats_choix_saison"} id={"stats_choix_saison"} value={saison}
                                onChange={(event) => handleChangeSaison(parseInt(event.target.value))}>
                            {statsData.listSaison.map((saison, index) => {
                                return <option value={saison.idSaison} key={index}>{saison.nom}</option>;
                            })}
                        </select>
                    </div>
                    <div id={"ruins_stats_1"}>
                        <div>
                            <h2>{t("Répartition des bâtiments trouvés", { ns: "stats" })}</h2>
                            <table>
                                <thead>
                                <tr>
                                    <th rowSpan={2}>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("name", sortedColumnRuin, sortOrderRuin, setSortedColumnRuin, setSortOrderRuin)}>
                                            <div id={"entete_stats_ruins_name"}>{t("Nom du bâtiment", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("name", sortedColumnRuin, sortOrderRuin)} />
                                        </div>
                                    </th>
                                    <th colSpan={4}>{t("Nombre", { ns: "stats" })}</th>
                                </tr>
                                <tr>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbr", sortedColumnRuin, sortOrderRuin, setSortedColumnRuin, setSortOrderRuin)}>
                                            <div id={"entete_stats_ruins_nbr"}>{t("Tous types", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbr", sortedColumnRuin, sortOrderRuin)} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbrRne", sortedColumnRuin, sortOrderRuin, setSortedColumnRuin, setSortOrderRuin)}>
                                            <div id={"entete_stats_ruins_nbrRne"}>{t("RNE", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbrRne", sortedColumnRuin, sortOrderRuin)} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbrRe", sortedColumnRuin, sortOrderRuin, setSortedColumnRuin, setSortOrderRuin)}>
                                            <div id={"entete_stats_ruins_nbrRe"}>{t("RE", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbrRe", sortedColumnRuin, sortOrderRuin)} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbrPande", sortedColumnRuin, sortOrderRuin, setSortedColumnRuin, setSortOrderRuin)}>
                                            <div id={"entete_stats_ruins_nbrPande"}>{t("Pandemonium", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbrPande", sortedColumnRuin, sortOrderRuin)} />
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.values(sortedLists(statsData.maps.ruins.tabsRuins, sortedColumnRuin, sortOrderRuin)).map((building, index) => {
                                    return <tr key={index}>
                                        <td>{building.nom}</td>
                                        <td className={"numberStats"}>{building.nbr}</td>
                                        <td className={"numberStats"}>{building.nbrRne}</td>
                                        <td className={"numberStats"}>{building.nbrRe}</td>
                                        <td className={"numberStats"}>{building.nbrPande}</td>
                                    </tr>;
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>{t("Total", { ns: "stats" })}</td>
                                    <td className={"numberStats"}>{statsData.maps.ruins.total.nbr}</td>
                                    <td className={"numberStats"}>{statsData.maps.ruins.total.nbrRne}</td>
                                    <td className={"numberStats"}>{statsData.maps.ruins.total.nbrRe}</td>
                                    <td className={"numberStats"}>{statsData.maps.ruins.total.nbrPande}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className={"stats-vertical-separator"} id={"stats-ruins-seperator"}></div>
                        <div>
                            <h2>{t("Répartition des directions du scrutateur", { ns: "stats" })}</h2>
                            <table>
                                <thead>
                                <tr>
                                    <th rowSpan={2}>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("name", sortedColumnScrut, sortOrderScrut, setSortedColumnScrut, setSortOrderScrut)}>
                                            <div id={"entete_stats_scrut_name"}>{t("Direction du scrutateur", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("name", sortedColumnScrut, sortOrderScrut)} />
                                        </div>
                                    </th>
                                    <th colSpan={4}>{t("Nombre", { ns: "stats" })}</th>
                                </tr>
                                <tr>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbr", sortedColumnScrut, sortOrderScrut, setSortedColumnScrut, setSortOrderScrut)}>
                                            <div id={"entete_stats_scrut_nbr"}>{t("Tous types", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbr", sortedColumnScrut, sortOrderScrut)} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbrRne", sortedColumnScrut, sortOrderScrut, setSortedColumnScrut, setSortOrderScrut)}>
                                            <div id={"entete_stats_scrut_nbrRne"}>{t("RNE", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbrRne", sortedColumnScrut, sortOrderScrut)} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbrRe", sortedColumnScrut, sortOrderScrut, setSortedColumnScrut, setSortOrderScrut)}>
                                            <div id={"entete_stats_scrut_nbrRe"}>{t("RE", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbrRe", sortedColumnScrut, sortOrderScrut)} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className={"entete_tri"}
                                             onClick={() => handleSortData("nbrPande", sortedColumnScrut, sortOrderScrut, setSortedColumnScrut, setSortOrderScrut)}>
                                            <div id={"entete_stats_scrut_nbrPande"}>{t("Pandemonium", { ns: "stats" })}</div>
                                            <TriangleSort direction={getSortIndicatorClass("nbrPande", sortedColumnScrut, sortOrderScrut)} />
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.values(sortedLists(statsData.maps.scrut.tabsScrut, sortedColumnScrut, sortOrderScrut)).map((building, index) => {
                                    return <tr key={index}>
                                        <td>{building.nom}</td>
                                        <td className={"numberStats"}>{building.nbr}</td>
                                        <td className={"numberStats"}>{building.nbrRne}</td>
                                        <td className={"numberStats"}>{building.nbrRe}</td>
                                        <td className={"numberStats"}>{building.nbrPande}</td>
                                    </tr>;
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>{t("Total", { ns: "stats" })}</td>
                                    <td className={"numberStats"}>{statsData.maps.scrut.total.nbr}</td>
                                    <td className={"numberStats"}>{statsData.maps.scrut.total.nbrRne}</td>
                                    <td className={"numberStats"}>{statsData.maps.scrut.total.nbrRe}</td>
                                    <td className={"numberStats"}>{statsData.maps.scrut.total.nbrPande}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </Tab>
        </Tabs>
    </div>;
    
    
}