import React                       from "react";
import { useTranslation }          from "react-i18next";
import { CaracteristiquesItemDTO } from "../../types/models/caracteristiquesItem.dto";
import Badge                       from "react-bootstrap/Badge";
import { Stack }                   from "react-bootstrap";
import SvgIcone                    from "../generality/SvgIcone";


export default function BadgeCaracteristiqueItem({ caracteristique }: { caracteristique: CaracteristiquesItemDTO }) {
    const { t } = useTranslation();
    
    const listColorVariant = ["primary", "secondary", "success", "warning", "danger"];
    
    // Fonction pour calculer l'index basé sur l'ID du type de caractéristique
    const getColorVariant = (id: number | undefined) => {
        if (id === undefined) {
            return "primary";
        } // Valeur par défaut si l'ID est indéfini
        return listColorVariant[id % listColorVariant.length];
    };
    
    return <Badge pill bg={getColorVariant(caracteristique?.type_carac?.id)}>
        <Stack gap={2} direction={"horizontal"}>
            <span><SvgIcone icone={caracteristique?.type_carac?.icon ?? ""} /></span>
            <span>{t(caracteristique?.type_carac?.nom ?? "", { ns: "items" })}</span>
            {caracteristique?.type_carac?.id === 9 && <span className={"d-flex justify-content-center align-items-center"}>
                <span>({caracteristique?.value}</span>
                <SvgIcone icone={"h_pa"} />
                <span>)</span>
            </span>}
            {caracteristique?.type_carac?.id === 16 && <span className={"d-flex justify-content-center align-items-center"}>
                <span>({caracteristique?.value}</span>
                <SvgIcone icone={caracteristique.type_carac.icon} />
                <span>)</span>
            </span>}
            {(caracteristique?.type_carac?.id === 28 || caracteristique?.type_carac?.id === 29) && <span className={"d-flex justify-content-center align-items-center"}>
                <span>({caracteristique?.value}</span>
                <SvgIcone icone={"h_pe"} />
                <span>)</span>
            </span>}
        </Stack>
    </Badge>;
}
