import React, { useEffect, useState } from "react";
import TriangleSort                   from "../../../components/generality/ComposantGeneral/TriangleSort";
import { ItemPrototypeDTO }           from "../../../types/models/itemPrototype.dto";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { ListAssemblageDTO }          from "../../../types/models/listAssemblage.dto";
import { TypeActionAssemblageDTO }    from "../../../types/models/typeActionAssemblage.dto";
import { EncyclopedieObjetType }      from "../../../types/components/Encyclopedie/EncyclopedieObjetType";
import { useTranslation }             from "react-i18next";
import ListeAssemblage                from "./ListeAssemblage";
import BadgeCaracteristiqueItem       from "../../../components/encyclopedie/BadgeCaracteristiqueItem";
import { TypeCaracteristiqueDTO }     from "../../../types/models/typeCaracteristique.dto";
import { useNormalizedSearch }        from "../../../services/hook/useNormalizedSearch";
import TranslationsDisplay            from "../../../components/utils/TranslationsDisplay";

export default function ListObjets({ objets, search_objet, listObjetsPrototype, filteredCarac, isTrad, lng }: { objets: EncyclopedieObjetType, search_objet: string, listObjetsPrototype: ItemPrototypeDTO[], filteredCarac: TypeCaracteristiqueDTO[], isTrad: boolean , lng: string}) {
    const { t } = useTranslation();
    const [listObjets, setListObjets] = useState(objets.listObjets);
    const [sortedColumnO, setSortedColumnO] = useState("");
    const [sortOrderO, setSortOrderO] = useState("");
    const listObtains: ListAssemblageDTO[] = objets.listObtains;
    const listNeeds: ListAssemblageDTO[] = objets.listNeeds;
    const listAction: TypeActionAssemblageDTO[] = objets.listAction;
    const { normalizeSearch } = useNormalizedSearch();
    
    useEffect(() => {
        setListObjets(objets.listObjets);
    }, [objets]);
    
    const filterLists = () => {
        const filteredObjets = Object.values(objets.listObjets).filter((objet) => {
            // Vérifie si l'objet correspond aux caractéristiques filtrées
            const matchesCarac = filteredCarac.length === 0 || filteredCarac.every((carac) => {
                return objet.caracteristiques.some((carac_objet) => (carac_objet?.type_carac?.id ?? 0) === carac.id);
            });
            // console.log("matchesCarac", matchesCarac);
            
            // Vérifie si l'objet correspond à la recherche textuelle
            const matchesSearch = normalizeSearch(search_objet.trim(), t(objet.nom ?? "", { ns: "items" }));
            
            // Combine les deux conditions
            return matchesCarac && matchesSearch;
        });
        
        setListObjets(filteredObjets);
    };
    
    useEffect(() => {
        filterLists();
    }, [search_objet, filteredCarac]);
    
    
    const getSortIndicatorClassObjets = (column: string) => {
        if (column === sortedColumnO) {
            return sortOrderO === "asc" ? "asc" : "desc";
        } else {
            return "default"; // Retourne une chaîne vide si la colonne n'est pas triée
        }
    };
    
    const handleSortDataObjets = (column: string) => {
        // Si la colonne cliquée est la même que la colonne triée précédemment,
        // on inverse l'ordre de tri.
        const order = column === sortedColumnO && sortOrderO === "asc" ? "desc" : (sortOrderO === "desc" ? "default" : "asc");
        
        if (order === "default") {
            column = "";
        }
        
        // On met à jour l'état avec la colonne actuellement triée et l'ordre de tri.
        setSortedColumnO(column);
        setSortOrderO(order);
    };
    
    const sortedListsObjets = () => {
        if (sortedColumnO === "name") {
            return Object.values(listObjets).sort((a: ItemPrototypeDTO, b: ItemPrototypeDTO) => {
                const aName = t(a.nom ?? "", { ns: "items" });
                const bName = t(b.nom ?? "", { ns: "items" });
                if (sortOrderO === "asc") {
                    return aName.localeCompare(bName);
                } else {
                    return bName.localeCompare(aName);
                }
            });
        } else if (sortedColumnO === "categorie") {
            return Object.values(listObjets).sort((a: ItemPrototypeDTO, b: ItemPrototypeDTO) => {
                const aName = t(a.category_objet?.nom ?? "", { ns: "items" });
                const bName = t(b.category_objet?.nom ?? "", { ns: "items" });
                if (aName === bName) {
                    return t(a.nom ?? "", { ns: "items" }).localeCompare(t(b.nom ?? "", { ns: "items" }));
                } else if (sortOrderO === "asc") {
                    return aName.localeCompare(bName);
                } else {
                    return bName.localeCompare(aName);
                }
            });
        } else {
            return Object.values(listObjets).sort((a: ItemPrototypeDTO, b: ItemPrototypeDTO) => t(a.nom ?? "", { ns: "items" }).localeCompare(t(b.nom ?? "", { ns: "items" })));
        }
    };
    
    const objetsTri: ItemPrototypeDTO[] = sortedListsObjets();
    
    return (
        <div id="ency_items">
            <div className={"container-tabs"}>
                <table>
                    <thead>
                    <tr>
                        <th className="tab_ency_items_icone">{t("Image", { ns: "ency" })}</th>
                        <th className="tab_ency_items_nom">
                            <div className={"entete_tri"} onClick={() => handleSortDataObjets("name")}>
                                <div id={"entete_ency_items_name"}>{t("Nom", { ns: "ency" })}</div>
                                <TriangleSort direction={getSortIndicatorClassObjets("name")} />
                            </div>
                        </th>
                        <th className="tab_ency_items_cat">
                            <div className={"entete_tri"} onClick={() => handleSortDataObjets("categorie")}>
                                <div id={"entete_ency_items_categorie"}>{t("Categorie", { ns: "ency" })}</div>
                                <TriangleSort direction={getSortIndicatorClassObjets("categorie")} />
                            </div>
                        </th>
                        <th className="tab_ency_items_desc">{t("Description", { ns: "ency" })}</th>
                        <th className="tab_ency_items_act">{t("Action", { ns: "ency" })}</th>
                        <th className="tab_ency_items_caract">{t("Caracteristiques", { ns: "ency" })}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {objetsTri.map((item: ItemPrototypeDTO) => {
                        if ((item.id ?? 0) >= 2000 || !item.actif) {
                            return null;
                        }
                        return <tr key={"objet_" + item.id} className={"ligne_ency_item"} id={"items_" + item.id}>
                            <td className="tab_ency_items_icone">
                                <div>
                                    <div className="iconItems"><SvgIcone icone={item.icon ?? ""} /></div>
                                </div>
                            </td>
                            <td className="tab_ency_items_nom">{isTrad?<TranslationsDisplay translationKey={item.nom} namespace={"items"} userLang={lng} />:t(item.nom ?? "", { ns: "items" })}</td>
                            <td className="tab_ency_items_cat">{t(item.category_objet?.nom ?? "", { ns: "items" })}</td>
                            <td className="tab_ency_items_desc">{t(item.description ?? "", { ns: "items" })}</td>
                            <td className="tab_ency_items_act">
                                <ListeAssemblage item={item} listItems={listObjetsPrototype} listNeed={listNeeds} listObtains={listObtains} listAction={listAction} />
                            </td>
                            <td className="tab_ency_items_caract">
                                {(item.caracteristiques ?? []).length > 0 && <div className={"d-flex gap-2 align-items-center justify-content-center flex-wrap m-2"}>
                                    {item.caracteristiques.sort((carac_a, carac_b) => {
                                        return t(carac_a?.type_carac?.nom ?? "", { ns: "items" }).localeCompare(t(carac_b?.type_carac?.nom ?? "", { ns: "items" }));
                                    }).map((caracteristique, index) => {
                                        return <BadgeCaracteristiqueItem caracteristique={caracteristique} key={item.id + "_" + caracteristique.id + "_" + index} />;
                                    })}
                                </div>}
                            </td>
                        </tr>;
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}




