import React, { useState }       from "react";
import { EncyclopedieVilleType } from "../../types/components/Encyclopedie/EncyclopedieVilleType";
import Tabs                      from "react-bootstrap/Tabs";
import Cookies                   from "js-cookie";
import Tab                       from "react-bootstrap/Tab";
import SvgIcone                  from "../../components/generality/SvgIcone";
import { AffNbrIcon }            from "../../components/generality/ComposantGeneral";
import { RessourceHomeDTO }      from "../../types/models/ressourceHome.dto";
import { useTranslation }        from "react-i18next";
import { RessourceUpHomeDTO }    from "../../types/models/ressourceUpHome.dto";
import { useCapitalized }        from "../../services/hook/useCapitalized";

interface EncyclopedieVilleProps {
    villes: EncyclopedieVilleType;
}

export default function EncyVille(props: EncyclopedieVilleProps) {
    const { t } = useTranslation();
    const listHabitation = props.villes.listHabitation;
    const listUpHome = props.villes.listUpHome;
    const storedActiveTab = Cookies.get("activeTab_ency_villes");
    const [activeTab, setActiveTab] = useState(storedActiveTab || "habs");
    const { capitalizeFirstLetter } = useCapitalized();
    
    // Fonction pour changer d'onglet et enregistrer le cookie
    const handleTabChange = (eventKey: string) => {
        setActiveTab(eventKey);
        Cookies.set("activeTab_ency_villes", eventKey, { expires: 365 }); // Le cookie expirera dans 365 jours
    };
    
    const getRowClass = (index: number) => {
        return index % 2 === 0 ? "even-upHome" : "odd-upHome";
    };
    
    return <div id={"encyclopedie_villes"}>
        <Tabs activeKey={activeTab} onSelect={handleTabChange}>
            <Tab eventKey={"habs"} title={t("Habitations", { ns: "ency" })}>
                <div id="ency_habs">
                    <table>
                        <thead>
                        <tr>
                            <th className="tab_ency_habs_icone" rowSpan={2}>{t("Image", { ns: "ency" })}</th>
                            <th className="tab_ency_habs_nom" rowSpan={2}>{t("Nom", { ns: "ency" })}</th>
                            <th className="tab_ency_habs_def" rowSpan={2}>{t("Def", { ns: "ency" })}</th>
                            <th className="tab_ency_habs_cout" colSpan={2}>{t("Coût", { ns: "ency" })}</th>
                            <th className="tab_ency_habs_cout" colSpan={2}>{t("Coût avec le chantier plan d'urbanisme", { ns: "ency" })}</th>
                        </tr>
                        <tr>
                            <th className="tab_ency_habs_pa">{t("en PA", { ns: "ency" })}</th>
                            <th className="tab_ency_habs_ress">{t("en ressources", { ns: "ency" })}</th>
                            <th className="tab_ency_habs_pa">{t("en PA", { ns: "ency" })}</th>
                            <th className="tab_ency_habs_ress">{t("en ressources", { ns: "ency" })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.values(listHabitation).map((hab, index) => {
                            return <tr key={index}>
                                <td className="tab_ency_habs_icone"><span className={"d-flex gap-1 align-items-center justify-content-center"}><SvgIcone icone={"home_" + hab.icon} /></span></td>
                                <td className="tab_ency_habs_nom">{t(hab.nom, { ns: "game" })}</td>
                                <td className="tab_ency_habs_def">{hab.def}</td>
                                <td className="tab_ency_habs_cout">{hab.pa}</td>
                                <td className="tab_ency_habs_ress">
                                    <div>
                                        {hab.ressources.sort((a: RessourceHomeDTO, b: RessourceHomeDTO) => t(a.item.nom, { ns: "items" }).localeCompare(t(b.item.nom, { ns: "items" }))).map((ressource, indexRess) => {
                                            return <AffNbrIcon item={ressource.item} broken={false} nbr={ressource.nombre} classSpecifique={"item_ency"} key={"ress_" + indexRess} />;
                                        })}
                                    </div>
                                
                                </td>
                                <td className="tab_ency_habs_cout">{hab.pa_urba}</td>
                                <td className="tab_ency_habs_ress">
                                    <div>
                                        {hab.ressources_urba.sort((a: RessourceHomeDTO, b: RessourceHomeDTO) => t(a.item.nom, { ns: "items" }).localeCompare(t(b.item.nom, { ns: "items" }))).map((ressource, indexRess) => {
                                            return <AffNbrIcon item={ressource.item} broken={false} nbr={ressource.nombre} classSpecifique={"item_ency"} key={"ress_urb_" + indexRess} />;
                                        })}
                                    </div>
                                </td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </div>
            </Tab>
            <Tab eventKey={"amel"} title={t("Améliorations", { ns: "ency" })}>
                <div id="ency_ups">
                    <table>
                        <thead>
                        <tr>
                            <th className="tab_ency_ups_icone" rowSpan={2}>{t("Image", { ns: "ency" })}</th>
                            <th className="tab_ency_ups_nom" rowSpan={2}>{t("Nom", { ns: "ency" })}</th>
                            <th className="tab_ency_ups_def" rowSpan={2}>{t("Level", { ns: "ency" })}</th>
                            <th className="tab_ency_ups_cout" colSpan={2}>{t("Coût", { ns: "ency" })}</th>
                        </tr>
                        <tr>
                            <th className="tab_ency_ups_pa">{t("en PA", { ns: "ency" })}</th>
                            <th className="tab_ency_ups_ress">{t("en ressources", { ns: "ency" })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.values(listUpHome).sort((upHome_a, upHome_b) => {
                            return t(upHome_a.label, { ns: "game" }).localeCompare(t(upHome_b.label, { ns: "game" }));
                        }).map((upHome, index) => (
                            <React.Fragment key={index}>
                                {upHome.levels.map((level, index_l) => (
                                    <tr key={`${level.level}_${index_l}`} className={getRowClass(index_l === 0 ? index : index + index_l)}>
                                        {index_l === 0 && (
                                            <>
                                                <td className="tab_ency_ups_icone" rowSpan={upHome.levels.length}>
                                                    <span className={"d-flex gap-1 align-items-center justify-content-center"}><SvgIcone icone={`item_${upHome.icon}`} /></span>
                                                </td>
                                                <td className="tab_ency_ups_nom" rowSpan={upHome.levels.length}>
                                                    {capitalizeFirstLetter(t(upHome.label, { ns: "game" }))}
                                                </td>
                                            </>
                                        )}
                                        <td className="tab_ency_ups_def">{level.level}</td>
                                        <td className="tab_ency_ups_cout">{level.pa}</td>
                                        <td className="tab_ency_ups_ress">
                                            <div>
                                                {level.ressources.sort((a: RessourceUpHomeDTO, b: RessourceUpHomeDTO) => t(a.item.nom, { ns: "items" }).localeCompare(t(b.item.nom, { ns: "items" }))).map((ressource, indexRess) => {
                                                    return <AffNbrIcon item={ressource.item} broken={false} nbr={ressource.nombre} classSpecifique={"item_ency"} key={"ress_" + indexRess} />;
                                                })}
                                            </div>
                                        
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                        </tbody>
                    </table>
                </div>
            </Tab>
        </Tabs>
    </div>;
    
}