import React, {useEffect, useState}       from "react";
import {BanqueItemPlans, ListPlansObtenu} from "../../../types/components/Hotel/PlansChantierType";
import SvgIcone                           from "../../../components/generality/SvgIcone";
import Cookies                            from "js-cookie";
import Collapse                           from "react-bootstrap/Collapse";
import LigneChantier                      from "./LigneChantier";
import LigneChantierTri                   from "./LigneChantierTri";
import {EncyChantierContext}              from "../../../types/Context/Encyclopedie/Chantier/EncyChantierContext";
import {ChantierPrototypeDTO}             from "../../../types/models/chantierPrototype.dto";
import {ChantiersDTO}                     from "../../../types/models/chantiers.dto";
import {RessourceChantierDTO}             from "../../../types/models/ressourceChantier.dto";
import {useTranslation}                   from "react-i18next";
import Form                               from "react-bootstrap/Form";
import {UserPersoCouleurDTO}              from "../../../types/models/userPersoCouleur.dto";
import {Helmet}                           from "react-helmet-async";
import {useGeneralContext}                from "../../../types/Context/GeneralContext";
import TooltipGH                          from "../../../components/utils/TooltipGH";

interface ListChantiersProps {
    listChantier: ChantierPrototypeDTO[],
    banque?: BanqueItemPlans[],
    listPlansVille?: ListPlansObtenu[],
    listChantiersVille?: ChantiersDTO[],
    listAvancement?: {
        pa: number | null
    }[],
    reductionAtelier?: number,
    isTrad?: boolean,
}


const generateCSSUser = (userPerso: UserPersoCouleurDTO) => {
    return `
		.chCons { background-color: ${userPerso.couleur_chantier_construit}; }
		.chEndom { background-color: ${userPerso.couleur_chantier_a_repa}; }
		.chEnCons { background-color: ${userPerso.couleur_chantier_en_construction}; }
		.plObte { background-color: ${userPerso.couleur_chantier_dispo}; }
		.chBlock { background-color: ${userPerso.couleur_plan_manquant}; }
		`;
};

export default function ListChantiers({
    listChantier,
    banque,
    listPlansVille = [],
    listChantiersVille = [],
    listAvancement = [],
    reductionAtelier = 0
}: ListChantiersProps) {
    const {t} = useTranslation();
    const {general} = useGeneralContext();
    const generic: boolean = banque === undefined;
    const selectChantierCookie = Cookies.get("selectedChantier");
    const tablSelect = generic ? {
        aff_construit   : true,
        aff_reparer     : true,
        aff_construction: true,
        aff_obtenu      : true,
        aff_bloque      : true,
    } : (selectChantierCookie ? JSON.parse(selectChantierCookie) : {
        aff_construit   : true,
        aff_reparer     : true,
        aff_construction: true,
        aff_obtenu      : true,
        aff_bloque      : true,
    });
    
    const [gestionAff, setGestionAff] = useState(tablSelect);
    const [categorie, setCategorie] = useState([]);
    const [itemSearch, setItemSearch] = useState("");
    const [triChantier, setTriChantier] = useState("arbre");
    const [orderTri, setOrderTri] = useState("asc");
    const [cumulRessourceSelect, setCumulRessourceSelect] = useState(0);
    const [cumulRessource, setCumulRessource] = useState<RessourceChantierDTO[]>([]);
    const [listChantierEntier, setListChantierEntier] = useState<ChantierPrototypeDTO[]>([]);
    const [listChantierChoisis, setListChantierChoisis] = useState<number[]>([]);
    const [maskTemp, setMaskTemp] = useState(false);
    const [activeTrad, setActiveTrad] = useState(localStorage.getItem("activeTrad") === "true");
    
    useEffect(() => {
        if (!generic) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 365);
            
            Cookies.set("selectedChantier", JSON.stringify(gestionAff), {
                expires: expirationDate,
                path   : "/",
                secure : true,
            });
        }
    }, [gestionAff]);
    
    useEffect(() => {
        // Déplié l'arbre chantier dans un tableau à plat avec une méthode récurssive pour les enfants des enfants etc
        const listChantierTmp: ChantierPrototypeDTO[] = [];
        const depliageChantier = (chantier: ChantierPrototypeDTO) => {
            listChantierTmp.push(chantier);
            if (chantier.children) {
                chantier.children.forEach((child) => depliageChantier(child));
            }
        };
        
        listChantier.forEach((chantier) => depliageChantier(chantier));
        setListChantierEntier(listChantierTmp);
    }, [listChantier]);
    
    useEffect(() => {
        calculCumulRessource();
    }, [cumulRessourceSelect]);
    
    const handleClickVision = (affichage) => {
        setGestionAff((prevGestionAff) => {
            return {
                ...prevGestionAff,
                [affichage]: !prevGestionAff[affichage],
            };
        });
    };
    
    const handleOpenClose = (categorieChantier) => {
        setCategorie((prevCategorie) => {
            if (prevCategorie.includes(categorieChantier)) {
                return prevCategorie.filter((value) => value !== categorieChantier);
            } else {
                return [...prevCategorie, categorieChantier];
            }
        });
    };
    
    const calculCumulRessource = () => {
        // Calcul du cumul des ressources selon cumulRessourceSelect
        const cumulRessourceTmp: RessourceChantierDTO[] = [];
        // si cumulRessourceSelect = 0, on ne fait rien
        
        // On filtre les chantiers pour supprimer les chantiers non actifs
        const listChantierFilter = listChantierEntier.filter((chantier) => {
            return chantier.actif;
        });
        
        switch (cumulRessourceSelect) {
            case 1:
                // si cumulRessourceSelect = 1, on cumule toutes les ressources
                listChantierFilter.forEach((chantier) => {
                    if (chantier.ressources) {
                        chantier.ressources.forEach((ressource) => {
                            const index = cumulRessourceTmp.findIndex((ressourceTmp) => ressourceTmp.item.id === ressource.item.id);
                            if (index === -1) {
                                cumulRessourceTmp.push({
                                    nombre: ressource.nombre,
                                    item  : ressource.item,
                                });
                            } else {
                                cumulRessourceTmp[index].nombre += ressource.nombre;
                            }
                        });
                    }
                });
                break;
            case 2:
                // si cumulRessourceSelect = 2, on cumule les ressources de chaque chantier choisit
                listChantierFilter.forEach((chantier) => {
                    if (listChantierChoisis.includes(chantier.id) && chantier.ressources) {
                        chantier.ressources.forEach((ressource) => {
                            const index = cumulRessourceTmp.findIndex((ressourceTmp) => ressourceTmp.item.id === ressource.item.id);
                            if (index === -1) {
                                cumulRessourceTmp.push({
                                    nombre: ressource.nombre,
                                    item  : ressource.item,
                                });
                            } else {
                                cumulRessourceTmp[index].nombre += ressource.nombre;
                            }
                        });
                    }
                });
                break;
            case 3:
                // si cumulRessourceSelect = 3, on cumule les ressources de chaque chantier de défense
                listChantierFilter.forEach((chantier) => {
                    if (chantier.def > 0 && chantier.ressources) {
                        chantier.ressources.forEach((ressource) => {
                            const index = cumulRessourceTmp.findIndex((ressourceTmp) => ressourceTmp.item.id === ressource.item.id);
                            if (index === -1) {
                                cumulRessourceTmp.push({
                                    nombre: ressource.nombre,
                                    item  : ressource.item,
                                });
                            } else {
                                cumulRessourceTmp[index].nombre += ressource.nombre;
                            }
                        });
                    }
                });
                break;
            case 4:
                // si cumulRessourceSelect = 4, on cumule les ressources de chaque chantier de confort
                break;
            case 5:
                // si cumulRessourceSelect = 5, on cumule les ressources de chaque chantier temporaire
                listChantierFilter.forEach((chantier) => {
                    if (chantier.temp && chantier.ressources) {
                        chantier.ressources.forEach((ressource) => {
                            const index = cumulRessourceTmp.findIndex((ressourceTmp) => ressourceTmp.item.id === ressource.item.id);
                            if (index === -1) {
                                cumulRessourceTmp.push({
                                    nombre: ressource.nombre,
                                    item  : ressource.item,
                                });
                            } else {
                                cumulRessourceTmp[index].nombre += ressource.nombre;
                            }
                        });
                    }
                });
                break;
            case 6:
                // si cumulRessourceSelect = 6, on cumule les ressources de chaque chantier apportant de l'eau
                listChantierFilter.forEach((chantier) => {
                    if (chantier.water > 0 && chantier.ressources) {
                        chantier.ressources.forEach((ressource) => {
                            const index = cumulRessourceTmp.findIndex((ressourceTmp) => ressourceTmp.item.id === ressource.item.id);
                            if (index === -1) {
                                cumulRessourceTmp.push({
                                    nombre: ressource.nombre,
                                    item  : ressource.item,
                                });
                            } else {
                                cumulRessourceTmp[index].nombre += ressource.nombre;
                            }
                        });
                    }
                });
                break;
            case 7:
                // si cumulRessourceSelect = 6, on cumule les ressources de chaque chantier restant à construire
                listChantierFilter.forEach((chantier) => {
                    if (!listChantiersVille[chantier.id] && chantier.ressources) {
                        chantier.ressources.forEach((ressource) => {
                            const index = cumulRessourceTmp.findIndex((ressourceTmp) => ressourceTmp.item.id === ressource.item.id);
                            if (index === -1) {
                                cumulRessourceTmp.push({
                                    nombre: ressource.nombre,
                                    item  : ressource.item,
                                });
                            } else {
                                cumulRessourceTmp[index].nombre += ressource.nombre;
                            }
                        });
                    }
                });
                break;
            case 8:
                // si cumulRessourceSelect = 8, on cumule les ressources de chaque chantier avec un plan obtenu ou débloquer
                listChantierFilter.forEach((chantier) => {
                    if (!listChantiersVille[chantier.id] && ((listPlansVille.find((chantierSearch) => chantierSearch.chantier.id === chantier.id) !== undefined && chantier.plan !== 0) || chantier.plan === 0) && chantier.ressources) {
                        chantier.ressources.forEach((ressource) => {
                            const index = cumulRessourceTmp.findIndex((ressourceTmp) => ressourceTmp.item.id === ressource.item.id);
                            if (index === -1) {
                                cumulRessourceTmp.push({
                                    nombre: ressource.nombre,
                                    item  : ressource.item,
                                });
                            } else {
                                cumulRessourceTmp[index].nombre += ressource.nombre;
                            }
                        });
                    }
                });
                break;
            case 9:
                // si cumulRessourceSelect = 6, on cumule les ressources de chaque chantier déjà construit
                listChantierFilter.forEach((chantier) => {
                    if (listChantiersVille[chantier.id] && chantier.ressources) {
                        chantier.ressources.forEach((ressource) => {
                            const index = cumulRessourceTmp.findIndex((ressourceTmp) => ressourceTmp.item.id === ressource.item.id);
                            if (index === -1) {
                                cumulRessourceTmp.push({
                                    nombre: ressource.nombre,
                                    item  : ressource.item,
                                });
                            } else {
                                cumulRessourceTmp[index].nombre += ressource.nombre;
                            }
                        });
                    }
                });
                break;
            default:
                break;
        }
        setCumulRessource(cumulRessourceTmp);
    };
    
    useEffect(() => {
        calculCumulRessource();
    }, [listChantierChoisis]);
    
    return <React.Fragment>
        <EncyChantierContext.Provider value={{listChantierChoisi: listChantierChoisis, setListChantierChoisi: setListChantierChoisis}}>
            <Helmet>
                <style>{generateCSSUser(general.themeUser.user_perso_couleur)}</style>
            </Helmet>
            <div>
                <fieldset>
                    <legend>{t("Filtre et tri", {ns: "outils"})}</legend>
                    <div>
                        <div className={"ligne_filtre_tri_option"}>
                            <label htmlFor="form_item">{t("Rechercher un objet", {ns: "outils"})}</label>
                            <input type={"search"}
                                   id="form_item"
                                   value={itemSearch}
                                   onClick={() => setItemSearch("")}
                                   onChange={(event) => setItemSearch(event.target.value)}
                            />
                        </div>
                        <div className={"ligne_filtre_tri_option"}>
                            <label htmlFor="form_tri">{t("Tri des chantiers par", {ns: "outils"})}</label>
                            <select id="form_tri" value={triChantier} onChange={(event) => setTriChantier(event.target.value)}>
                                <option value="arbre">{t("défaut", {ns: "outils"})}</option>
                                <option value="def">{t("défense", {ns: "outils"})}</option>
                                <option value="eau">{t("quantité d'eau", {ns: "outils"})}</option>
                                <option value="nom">{t("nom", {ns: "outils"})}</option>
                                <option value="pa">{t("PA", {ns: "outils"})}</option>
                                <option value="pv">{t("PV", {ns: "outils"})}</option>
                            </select>
                            {triChantier !== "arbre" && triChantier !== "nom" && <>
                            <span>
                                <input type={"radio"} id={"tri_asc"} value={"asc"} checked={orderTri === "asc"} onChange={(event) => setOrderTri(event.target.checked ? "asc" : "desc")}/>
                                <label htmlFor="tri_asc">{t("Croissant", {ns: "outils"})}</label>
                            </span>
                                <span>
                                <input type={"radio"} id={"tri_desc"} value={"desc"} checked={orderTri === "desc"} onChange={(event) => setOrderTri(event.target.checked ? "desc" : "asc")}/>
                                <label htmlFor="tri_desc">{t("Décroissant", {ns: "outils"})}</label>
                            </span>
                            </>}
                            {triChantier !== "arbre" && triChantier === "nom" && <>
                            <span>
                                <input type={"radio"} id={"tri_az"} value={"asc"} checked={orderTri === "asc"} onChange={(event) => setOrderTri(event.target.checked ? "asc" : "desc")}/>
                                <label htmlFor="tri_az">{t("A à Z", {ns: "outils"})}</label>
                            </span>
                                <span>
                                <input type={"radio"} id={"tri_za"} value={"desc"} checked={orderTri === "desc"} onChange={(event) => setOrderTri(event.target.checked ? "desc" : "asc")}/>
                                <label htmlFor="tri_za">{t("Z à A", {ns: "outils"})}</label>
                            </span>
                            </>}
                        </div>
                        <div className={"ligne_filtre_tri_option"}>
                            <label htmlFor="form_tempo">{t("Masquer les chantiers temporaires :", {ns: "outils"})}</label>
                            <Form.Check
                                type="switch"
                                checked={maskTemp}
                                onChange={(e) => setMaskTemp(e.target.checked)}
                            />
                        </div>
                        <div className={"d-flex justify-content-start align-items-center"}>
                            <label htmlFor="form_AfficheTrad" className="required">{t("Afficher les chantiers dans les 4 lanques :", {ns: "ency"})} </label>
                            <Form.Check
                                type="switch"
                                id="form_AfficheTrad"
                                checked={activeTrad}
                                onChange={(event) => {
                                    setActiveTrad(event.target.checked);
                                    // On stock le choix de l'utilisateur dans le storage
                                    localStorage.setItem("activeTrad", event.target.checked.toString());
                                }}
                                style={{marginLeft: "10px"}}
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
            {!generic &&
                <div className="legendeCh">
                    <span id={"label_legende"}>
                        <span>{t("Légende", {ns: "outils"})}</span>
                        <TooltipGH>
                            <span className={"infoBulle"}>
                                <i className="fa fa-question-circle"></i>
                            </span>
                            <span className={"info"}>{t("Cliquez sur la légende pour afficher ou masquer les chantiers correspondants", {ns: "outils"})}</span>
                        </TooltipGH>
                         : </span>
                    <span className={"bloc_legende"}>
                     <span className={"legende_chantier" + ((gestionAff.aff_construit) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_construit")}><span
                         className="legCh chCons"></span> {t("Construit", {ns: "outils"})}</span>
                     <span className={"legende_chantier" + ((gestionAff.aff_reparer) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_reparer")}><span
                         className="legCh chEndom"></span> {t("Construit mais à réparer", {ns: "outils"})}</span>
                     <span className={"legende_chantier" + ((gestionAff.aff_construction) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_construction")}><span
                         className="legCh chEnCons"></span> {t("En construction", {ns: "outils"})}</span>
                     <span className={"legende_chantier" + ((gestionAff.aff_obtenu) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_obtenu")}><span
                         className="legCh plObte"></span> {t("Chantier disponible ou Plan obtenu", {ns: "outils"})}</span>
                     <span className={"legende_chantier" + ((gestionAff.aff_bloque) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_bloque")}><span
                         className="legCh chBlock"></span> {t("Plan manquant", {ns: "outils"})}</span>
                 </span>
                </div>
            }
            {listChantier.sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => a.order_by_listing < b.order_by_listing ? -1 : 1)
                .map((categorieChantier: ChantierPrototypeDTO) => {
                        
                        const categorie_close = !categorie.includes(categorieChantier.id);
                        
                        return <div className={"groupChantierCategorie"} key={"chantier_categorie" + categorieChantier.id}>
                            <div className="titreCategorieChantier" onClick={() => handleOpenClose(categorieChantier.id)}>
                                {cumulRessourceSelect === 2 && <button className={"btn btn-xs btn-primary"} onClick={(event) => {
                                    event.stopPropagation();
                                    const listChantierChoisisMod = [...listChantierChoisis];
                                    const depliageChantierCategorie = (chantier: ChantierPrototypeDTO) => {
                                        if (listChantierChoisisMod.includes(chantier.id)) {
                                            listChantierChoisisMod.splice(listChantierChoisisMod.indexOf(chantier.id), 1);
                                        } else {
                                            listChantierChoisisMod.push(chantier.id);
                                        }
                                        if (chantier.children) {
                                            chantier.children.forEach((child) => depliageChantierCategorie(child));
                                        }
                                    };
                                    
                                    depliageChantierCategorie(categorieChantier);
                                    setListChantierChoisis(listChantierChoisisMod);
                                }}>{t("Sélectionner tout", {ns: "outils"})}</button>}
                                <span>{t(categorieChantier.nom, {ns: "chantiers"})}</span><i className={"fa-solid fa-chevron-" + ((categorie_close) ? "up" : "down")}></i>
                            </div>
                            <Collapse in={categorie_close} key={"col_" + categorieChantier.id}>
                                <table className="listChantier fondWhite02" id={"cat_" + categorieChantier.id}
                                       key={"categorie_" + categorieChantier.id}>
                                    <thead>
                                    <tr className={"ligneChantier categorie_" + categorieChantier.id}>
                                        <th className="chantier_nom">{t("Nom", {ns: "outils"})}</th>
                                        <th className="chantier_plan">
                                            <TooltipGH>
                                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                                                    <SvgIcone icone={"item_bplan_c"}/>
                                                </span>
                                                <span className="info">{t("Couleur du plan de chantier", {ns: "outils"})}</span>
                                            </TooltipGH>
                                        </th>
                                        <th className="chantier_temp">
                                            <TooltipGH>
                                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                                                    <SvgIcone icone={"tag_1"}/>
                                                </span>
                                                <span className="info">{t("Chantier temporaire", {ns: "outils"})}</span>
                                            </TooltipGH>
                                        </th>
                                        <th className="chantier_repa">
                                            <TooltipGH>
                                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                                                    <SvgIcone icone={"small_refine"}/>
                                                </span>
                                                <span className="info">{t("Chantier réparable", {ns: "outils"})}</span>
                                            </TooltipGH>
                                        </th>
                                        <th className="chantier_pa">
                                            <TooltipGH>
                                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                                                    <SvgIcone icone={"small_pa"}/>
                                                </span>
                                                <span className="info">{t("Coût en PA", {ns: "outils"})}</span>
                                            </TooltipGH>
                                        </th>
                                        {!generic && <th className="chantier_pa paRestant">
                                            <span className={"d-flex gap-1 align-items-center justify-content-center"}>
                                                <TooltipGH>
                                                    <span className="infoBulle">
                                                        <span>
                                                            <SvgIcone icone={"small_pa"}/>
                                                            <span>R</span>
                                                        </span>
                                                    </span>
                                                    <span className="info">{t("PA restant", {ns: "outils"})}</span>
                                                </TooltipGH>
                                            </span>
                                        </th>}
                                        <th className="chantier_pv">
                                            <TooltipGH>
                                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">PV</span>
                                                <span className="info">{t("Point de vie max du chantier", {ns: "outils"})}</span>
                                            </TooltipGH>
                                        </th>
                                        {!generic && <th className="chantier_pv paRestant">
                                            <TooltipGH>
                                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">PV R</span>
                                                <span className="info">{t("PV restant", {ns: "outils"})}</span>
                                            </TooltipGH>
                                        </th>}
                                        <th className="chantier_def">
                                            <TooltipGH>
                                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                                                    <SvgIcone icone={"small_def"}/>
                                                </span>
                                                <span className="info">{t("Apport en défense du chantier", {ns: "outils"})}</span>
                                            </TooltipGH>
                                        </th>
                                        <th className="chantier_eau">
                                            <TooltipGH>
                                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                                                    <SvgIcone icone={"small_water"}/>
                                                </span>
                                                <span className="info">{t("Apport en eau du chantier", {ns: "outils"})}</span>
                                            </TooltipGH>
                                        </th>
                                        <th className="chantier_ressources">{t("Ressources", {ns: "outils"})}</th>
                                    </tr>
                                    </thead>
                                    <tbody className={"categorie_" + categorieChantier.id}>
                                    {triChantier === "arbre" &&
                                        <LigneChantier chantier={categorieChantier}
                                                       niveau={0}
                                                       key={"ligneChantier_cat_" + categorieChantier.id}
                                                       banque={banque}
                                                       listPlansVille={listPlansVille}
                                                       listChantiersVille={listChantiersVille}
                                                       generic={generic}
                                                       listAvancement={listAvancement}
                                                       gestion_aff={gestionAff}
                                                       reductionPA={reductionAtelier}
                                                       filtre_ressource={itemSearch}
                                                       indicateurChoix={cumulRessourceSelect === 2}
                                                       maskTemp={maskTemp}
                                                       isTrad={activeTrad}
                                                       lng={general.user.lang}
                                        />}
                                    {triChantier !== "arbre" &&
                                        <LigneChantierTri chantier={categorieChantier} niveau={0} key={"ligneChantier_cat_" + categorieChantier.id}
                                                          banque={banque} listPlansVille={listPlansVille}
                                                          listChantiersVille={listChantiersVille} generic={generic}
                                                          listAvancement={listAvancement} gestion_aff={gestionAff} reductionPA={reductionAtelier}
                                                          filtre_ressource={itemSearch} triChantier={triChantier} orderTri={orderTri}
                                                          indicateurChoix={cumulRessourceSelect === 2}
                                                          maskTemp={maskTemp} isTrad={activeTrad} lng={general.user.lang}
                                        />
                                    }
                                    </tbody>
                                </table>
                            </Collapse>
                        </div>;
                    },
                )}
            {!generic &&
                <div className="legendeCh">
                    <span id={"label_legende"}>
                        <span>{t("Légende", {ns: "outils"})}</span>
                        <TooltipGH>
                            <span className={"infoBulle"}>
                                <i className="fa fa-question-circle"></i>
                            </span>
                            <span className={"info"}>{t("Cliquez sur la légende pour afficher ou masquer les chantiers correspondants", {ns: "outils"})}</span>
                        </TooltipGH>
                         : </span>
                    <span className={"bloc_legende"}>
                     <span className={"legende_chantier" + ((gestionAff.aff_construit) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_construit")}><span
                         className="legCh chCons"></span> {t("Construit", {ns: "outils"})}</span>
                     <span className={"legende_chantier" + ((gestionAff.aff_reparer) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_reparer")}><span
                         className="legCh chEndom"></span> {t("Construit mais à réparer", {ns: "outils"})}</span>
                     <span className={"legende_chantier" + ((gestionAff.aff_construction) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_construction")}><span
                         className="legCh chEnCons"></span> {t("En construction", {ns: "outils"})}</span>
                     <span className={"legende_chantier" + ((gestionAff.aff_obtenu) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_obtenu")}><span
                         className="legCh plObte"></span> {t("Chantier disponible ou Plan obtenu", {ns: "outils"})}</span>
                     <span className={"legende_chantier" + ((gestionAff.aff_bloque) ? "" : " chantier_nonaff")}
                           onClick={() => handleClickVision("aff_bloque")}><span
                         className="legCh chBlock"></span> {t("Plan manquant", {ns: "outils"})}</span>
                 </span>
                </div>
            }
            <div id={"cumul_ressources_chantier"}>
                <fieldset>
                    <legend>{t("Cumul des ressources", {ns: "outils"})}</legend>
                    <div>
                        <div id={"option_liste_cumul"}>
                            <label htmlFor="form_cumul">{t("Cumul des ressources pour", {ns: "outils"})}</label>
                            <select id="form_cumul" value={cumulRessourceSelect} onChange={(event) => setCumulRessourceSelect(parseInt(event.target.value))}>
                                <option value="0">{t("choisir le type de cumul", {ns: "outils"})}</option>
                                <option value="1">{t("tous les chantiers", {ns: "outils"})}</option>
                                <option value="2">{t("uniquement les chantiers choisis", {ns: "outils"})}</option>
                                <option value="3">{t("uniquement les chantiers de défense", {ns: "outils"})}</option>
                                {/*<option value="4">{t("uniquement les chantiers de confort", { ns: "outils" })}</option>*/}
                                <option value="5">{t("uniquement les chantiers temporaires", {ns: "outils"})}</option>
                                <option value="6">{t("uniquement les chantiers donnant de l'eau", {ns: "outils"})}</option>
                                {!generic && <option value="7">{t("uniquement les chantiers restant à construire", {ns: "outils"})}</option>}
                                {!generic && <option value="8">{t("uniquement les chantiers disponibles", {ns: "outils"})}</option>}
                                {!generic && <option value="9">{t("uniquement les chantiers déjà construit", {ns: "outils"})}</option>}
                            </select>
                        </div>
                        <div>
                            <table id={"cumulRessource"}>
                                <thead>
                                <tr>
                                    <th className={"chantier_ress"}>{t("Ressources", {ns: "outils"})}</th>
                                    <th className={"chantier_nbr"}>{t("Nombre", {ns: "outils"})}</th>
                                    {!generic && <th className={"chantier_nbr"}>{t("Banque", {ns: "outils"})}</th>}
                                </tr>
                                </thead>
                                <tbody>
                                {cumulRessource.sort((ress_a, ress_b) => {
                                    if (ress_a.nombre === ress_b.nombre) {
                                        return ress_a.item.nom.localeCompare(ress_b.item.nom);
                                    }
                                    return ress_a.nombre > ress_b.nombre ? -1 : 1;
                                }).map((ressource) => {
                                    let classSpan;
                                    let nombreItems = 0;
                                    if (generic) {
                                        classSpan = "ressourceChantier";
                                    } else {
                                        // Recherche de l'item en banque
                                        const itemBanque = banque[ressource.item.id];
                                        if (itemBanque === undefined) {
                                            nombreItems = 0;
                                        } else {
                                            nombreItems = itemBanque.nbrItem;
                                        }
                                        
                                        classSpan = ((nombreItems >= ressource.nombre) ? "ressOK" : "ressKO") + " ressourceChantierNotGeneric";
                                    }
                                    return <tr key={"cumulRessource_" + ressource.item.id}>
                                        <td className={"chantier_ress"}>
                                            <div>
                                                <SvgIcone icone={ressource.item.icon}/>
                                                <span>{ressource.item.nom}</span>
                                            </div>
                                        </td>
                                        <td className={"chantier_nbr"}>{ressource.nombre}</td>
                                        {!generic && <td className={"chantier_nbr"}>{nombreItems}</td>}
                                    </tr>;
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </fieldset>
            </div>
        </EncyChantierContext.Provider>
    </React.Fragment>;
}
