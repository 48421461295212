import React, { PropsWithChildren } from "react";
import TooltipGH                    from "./TooltipGH";


type ButtonProps = {
    label: string;
}

export default function Tooltips(props: PropsWithChildren<ButtonProps>) {
    return <TooltipGH>
        <span className="infoBulle">{props.children}</span>
        <span className="info">{props.label}</span>
    </TooltipGH>;
}