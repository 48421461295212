import React, { useEffect, useState }                               from "react";
import Tabs                                                         from "react-bootstrap/Tabs";
import Tab                                                          from "react-bootstrap/Tab";
import Form                                                         from "react-bootstrap/Form";
import RangeSlider                                                  from "react-bootstrap-range-slider";
import SvgIcone                                                     from "../../components/generality/SvgIcone";
import ColorPicker                                                  from "../../components/generality/ColorPickerGH";
import { ColorConfigExport, OptionsPersoType, SettingSectionPerso } from "../../types/components/OptionsPerso/OptionsPersoType";
import Carte                                                        from "../Ville/Carte/Carte";
import { OptionPersoApi }                                           from "../../services/api/OptionPersoApi";
import ColorMapVisu                                                 from "./ColorMapVisu";
import Cookies                                                      from "js-cookie";
import Select, { components, OptionProps, SingleValueProps }        from "react-select";
import HTMLParser                                                   from "html-react-parser";
import { formatInTimeZone }                                         from "date-fns-tz";
import SelectTimeZone                                               from "../../components/generality/ComposantGeneral/SelectTimeZone";
import GestionTheme                                                 from "./GestionTheme";
import { Status_error, Status_success, usePopUp }                   from "../../types/Context/PopUpContext";
import { useGeneralContext }                                        from "../../types/Context/GeneralContext";
import GestionDispo                                                 from "./GestionDispo";
import { UserDTO }                                                  from "../../types/models/user.dto";
import { ThemeUserDTO }                                             from "../../types/models/themeUser.dto";
import { useTranslation }                                           from "react-i18next";
import { HerosPrototypeDTO }                                        from "../../types/models/herosPrototype.dto";
import { CarteType }                                                from "../../types/components/ville/CarteType";
import { UserPersonnalisationDTO }                                  from "../../types/models/userPersonnalisation.dto";
import AppercuCarteTaille                                           from "../../components/OptionsPerso/AppercuCarteTaille";
import { useOptionPerso }                                           from "../../services/hook/useOptionPerso";
import CollapsibleFieldset                                          from "../../components/utils/CollapsibleFieldset";
import ColorPickerItem                                              from "../../components/OptionsPerso/ColorPickerItems";
import SwitchItems                                                  from "../../components/OptionsPerso/SwitchItems";
import TooltipsInfo                                                 from "../../components/utils/TooltipsInfo";
import { UserPersoCouleurDTO }                                      from "../../types/models/userPersoCouleur.dto";
import SvgDivers                                                    from "../../components/generality/SvgDivers";
import chroma                                                       from "chroma-js";
import MenuDesignerOptionsPerso                                     from "./MenuDesignerOptionsPerso";
import GestionSkill                                                 from "./GestionSkill";
import TooltipGH                                                    from "../../components/utils/TooltipGH";

const { SingleValue, Option } = components;

const getOptions = (t) => {
    const settings: SettingSectionPerso[] = [
        {
            title: t("Couleurs lignes", { ns: "perso" }),
            items: [
                { label: t("Couleur du scrut :", { ns: "perso" }), key: "couleur_scrut", type: "color" },
                { label: t("Couleur KM :", { ns: "perso" }), key: "couleur_km", type: "color" },
                { label: t("Couleur PA :", { ns: "perso" }), key: "couleur_p_a", type: "color" },
                { label: t("Couleur Zone PA :", { ns: "perso" }), key: "couleur_zone", type: "color" },
            ],
        },
        {
            title: t("Couleurs marqueurs", { ns: "perso" }),
            items: [
                { label: t("Couleur position :", { ns: "perso" }), key: "couleur_pos", type: "color" },
                { label: t("Couleur vue aujourd'hui :", { ns: "perso" }), key: "couleur_vue_auj", type: "color" },
                { label: t("Couleur vue 24h :", { ns: "perso" }), key: "couleur_vue24", type: "color" },
                { label: t("Couleur vue > 48h :", { ns: "perso" }), key: "couleur_vue48", type: "color" },
                { label: t("Couleur Zombie :", { ns: "perso" }), key: "color_zombie", type: "color" },
            ],
        },
        {
            title: t("Couleurs cases, bâtiments", { ns: "perso" }),
            items: [
                { label: t("Couleur du bâtiment :", { ns: "perso" }), key: "couleur_bat", type: "color" },
                { label: t("Couleur du bâtiment épuisé :", { ns: "perso" }), key: "color_bat_epuise", type: "color" },
                { label: t("Couleur de la ville (case) :", { ns: "perso" }), key: "color_town", type: "color" },
                { label: t("Couleur de la ville (picto) :", { ns: "perso" }), key: "color_city", type: "color" },
                { label: t("Couleur case non visitée :", { ns: "perso" }), key: "color_non_vu", type: "color" },
                { label: t("Couleur épuisée :", { ns: "perso" }), key: "couleur_epuise", type: "color" },
                { label: t("Motif case épuisée :", { ns: "perso" }), key: "motif_epuisement", type: "motif", source: "perso" },
                { label: t("Couleur danger 0 :", { ns: "perso" }), key: "couleur_danger0", type: "color" },
                { label: t("Couleur danger 1 :", { ns: "perso" }), key: "couleur_danger1", type: "color" },
                { label: t("Couleur danger 2 :", { ns: "perso" }), key: "couleur_danger2", type: "color" },
                { label: t("Couleur danger 3 :", { ns: "perso" }), key: "couleur_danger3", type: "color" },
                { label: t("Couleur controle OK :", { ns: "perso" }), key: "couleur_controle_ok", type: "color" },
                { label: t("Couleur controle Non OK :", { ns: "perso" }), key: "couleur_contole_nok", type: "color" },
                { label: t("Couleur de fond de carte :", { ns: "perso" }), key: "couleur_carte", tooltip: t("Laissez transparent si vous voulez ne pas mettre de couleur supplémentaire", { ns: "perso" }), type: "color" },
            ],
        },
        {
            title: t("Couleurs de sélection (bordure, ligne,...)", { ns: "perso" }),
            items: [
                { label: t("Couleur sélection Objet :", { ns: "perso" }), key: "couleur_select_obj", type: "color" },
                { label: t("Couleur sélection Batiment :", { ns: "perso" }), key: "couleur_select_bat", type: "color" },
                { label: t("Couleur sélection Citoyen :", { ns: "perso" }), key: "couleur_select_cit", type: "color" },
                { label: t("Couleur des expés sélectionnés (dans le listing) :", { ns: "perso" }), key: "color_sel_exp", type: "color" },
                { label: t("Couleur des expéditions où vous êtes inscrit :", { ns: "perso" }), key: "color_my_exp", type: "color" },
                { label: t("Couleur de bordure pour afficher le détail de la case :", { ns: "perso" }), key: "couleur_sel_case_maj", type: "color" },
            ],
        },
        {
            title: t("Options supplémentaires", { ns: "perso" }),
            items: [
                { label: t("Ajouter de la texture à la carte :", { ns: "perso" }), key: "carte_textured", type: "switch" },
                { label: t("Zombie discret : ", { ns: "perso" }), key: "zombie_discret", type: "switch", source: "perso" },
                { label: t("Couleur du drapeau des consignes :", { ns: "perso" }), key: "color_flag", type: "color" },
                { label: t("Couleur du drapeau des consignes finis :", { ns: "perso" }), key: "color_flag_finish", type: "color" },
                { label: t("Afficher les bâtiments en image :", { ns: "perso" }), key: "affichage_bat", type: "switch", source: "perso", tooltip: t("Permet d'afficher l'image du bâtiment au lieu d'un carré.", { ns: "perso" }) },
                { label: t("Activer le mode avancé de la carte alternative :", { ns: "perso" }), key: "action_diff_carte_alter", type: "switch", source: "perso", tooltip: t("Permet de prendre en compte vos paramètres d'affichages (sauf le danger et le contrôle de case)", { ns: "perso" }) },
                { label: t("Afficher le nombre de citoyens :", { ns: "perso" }), key: "affichage_nb_citoyen", type: "switch", source: "perso", tooltip: t("Permet d'afficher le nombre de citoyen sur une case", { ns: "perso" }) },
                { label: t("Couleur du nombre de citoyens :", { ns: "perso" }), key: "couleur_nbr_citoyen", type: "color" },
                { label: t("Afficher le nombre d'objets :", { ns: "perso" }), key: "afficher_nbr_items_sol", type: "switch", source: "perso", tooltip: t("Permet d'afficher le nombre d'objet sur une case", { ns: "perso" }) },
                { label: t("Couleur du nombre d'objets' :", { ns: "perso" }), key: "couleur_nbr_items_sol", type: "color" },
            ],
        },
    ];
    
    return settings;
};
const getOptionsChantiers = (t) => {
    const settings: SettingSectionPerso[] = [
        {
            title: t("Couleurs des chantiers", { ns: "perso" }),
            items: [
                { label: t("Couleur du chantier construit :", { ns: "perso" }), key: "couleur_chantier_construit", type: "color" },
                { label: t("Couleur du chantier endommagé :", { ns: "perso" }), key: "couleur_chantier_a_repa", type: "color" },
                { label: t("Couleur du chantier en construction :", { ns: "perso" }), key: "couleur_chantier_en_construction", type: "color" },
                { label: t("Couleur du chantier avec plan obtenu :", { ns: "perso" }), key: "couleur_chantier_dispo", type: "color" },
                { label: t("Couleur du plan manquant :", { ns: "perso" }), key: "couleur_plan_manquant", type: "color" },
            ],
        },
    ];
    
    return settings;
};

const IconSingleValue = (props: SingleValueProps<HerosPrototypeDTO>) => {
    const { t } = useTranslation();
    return <SingleValue {...props}>
        <SvgIcone icone={props.data.icon} />
        <span>{t(props.data.nom, { ns: "game" })}</span>
    </SingleValue>;
};
const MotifSingleValue = (props: SingleValueProps<{ value: string, label: string }>) => {
    return <SingleValue {...props}>
        <SvgDivers icone={props.data.value} />
    </SingleValue>;
};
const IconOption = (props: OptionProps<HerosPrototypeDTO>) => {
    const { t } = useTranslation();
    return <Option {...props}>
        <SvgIcone icone={props.data.icon} />
        <span>{t(props.data.nom, { ns: "game" })}</span>
    </Option>;
};
const MotifOption = (props: OptionProps<{ value: string, label: string }>) => {
    return <Option {...props}>
        <SvgDivers icone={props.data.value} />
    </Option>;
};
const ColorSingleValue = (props: any) => {
    return <SingleValue {...props}>
        <ColorMapVisu colormapName={props.data.label} />
        <span>{props.data.label}</span>
    </SingleValue>;
};
const ColorOption = (props: any) => {
    return <Option {...props}>
        <ColorMapVisu colormapName={props.data.label} />
        <span>{props.data.label}</span>
    </Option>;
};
const customStyles = {
    option           : (provided) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    singleValue      : (provided) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    control          : (provided) => ({
        ...provided,
        minHeight: "24px",
        height   : "24px",
    }),
    input            : (provided) => ({
        ...provided,
        gridTemplateColumns: "none",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0",
    }),
    valueContainer   : (provided) => ({
        ...provided,
        padding: "0px 8px",
    }),
    menu             : (provided) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
    menuList         : (provided) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
    menuPortal       : (provided) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
};
const customStylesMotif = {
    container        : (provided) => ({
        ...provided,
        width       : "70px",
        height      : "20px",
        marginBottom: "4px",
    }),
    option           : (provided: any, { isSelected, isFocused }) => {
        const color = chroma("#373a40");
        return {
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: isFocused ? color.alpha(0.5).css() : "white",
            color          : isFocused ? "white" : "black",
            width          : "44px",
            height         : "20px",
            overflow       : "hidden",
            margin         : "3px",
            cursor         : "pointer",
            padding        : "0",
        };
    },
    singleValue      : (provided) => ({
        ...provided,
        borderRadius   : 5,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        justifyContent : "flex-start",
        alignContent   : "center",
        backgroundColor: "white",
        background     : "none",
        color          : "black",
        width          : "50px",
        padding        : "0",
        height         : "20px",
        overflow       : "hidden",
        textOverflow   : "ellipsis",
        whiteSpace     : "nowrap",
    }),
    control          : (provided) => ({
        ...provided,
        minHeight     : "20px",
        height        : "20px",
        width         : "50px",
        display       : "flex",
        alignItems    : "center",
        justifyContent: "center",
        alignContent  : "center",
        padding       : "0",
        overflow      : "hidden",
    }),
    input            : (provided) => ({
        ...provided,
        gridTemplateColumns: "none",
        width              : "50px",
        padding            : "0",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0",
        width  : "20px",
        height : "20px",
    }),
    valueContainer   : (provided) => ({
        ...provided,
        padding : 0,
        width   : "70px",
        height  : "20px",
        overflow: "hidden",
    }),
    menu             : (provided) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
        width          : "50px",
        padding        : "0",
    }),
    menuList         : (provided) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
        width          : "50px", // Limite la largeur de la liste déroulante
        overflow       : "hidden",
        padding        : "0",
    }),
    menuPortal       : (provided) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
        cursor         : "pointer",
        padding        : "0",
    }),
};

export default function OptionsPerso(props: { options: OptionsPersoType }) {
    const { t } = useTranslation();
    const { colorWithAlpha, validateCouleurConfig } = useOptionPerso();
    const storedActiveTab = Cookies.get("activeTab_options_perso");
    
    const options = props.options.options;
    
    const [activeTab, setActiveTab] = useState(storedActiveTab || "perso");
    const [activeAlternative, setActiveAlternative] = useState(false);
    const [activeEstimation, setActiveEstimation] = useState(false);
    const [carte, setCarte] = useState<CarteType>(props.options.carte);
    const [exportColor, setExportColor] = useState("");
    const [isCopy, setIsCopy] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isErrorCopy, setIsErrorCopy] = useState(false);
    const [isErrorImport, setIsErrorImport] = useState(false);
    const [user, setUser] = useState<UserDTO>(props.options.user);
    const [theme, setTheme] = useState(user.theme);
    const [themeId, setThemeId] = useState(user.themes_user.find((theme: ThemeUserDTO) => theme.selected).id);
    const [showAttention, setShowAttention] = useState(false);
    const [showGestionTheme, setShowGestionTheme] = useState(false);
    const [showApercuTaille, setShowApercuTaille] = useState(false);
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const { general, setGeneral } = useGeneralContext();
    
    useEffect(() => {
        setCarte(props.options.carte);
        setUser(props.options.user);
    }, [props.options]);
    
    
    const handleClose = () => {
        setMessagePopUp("");
        setShowPop(false);
        setStatus(Status_success);
    };
    
    const handleTabChange = (eventkey) => {
        setActiveTab(eventkey);
        Cookies.set("activeTab_options_perso", eventkey, { expires: 365 });
    };
    
    const handleUserChange = (updatedProperties: Partial<UserDTO>) => {
        // On met à jour les propriétés de l'utilisateur
        Object.assign(user, updatedProperties);
        setUser(user);
        
        const carteMod = { ...carte, ...{ user: user } };
        setCarte(carteMod);
        setShowAttention(true);
    };
    
    const handleUserPersoChange = (updatedProperties: Partial<UserPersonnalisationDTO>) => {
        // On met à jour les propriétés de l'utilisateur
        // on effectue une copie de l'objet user
        const userMod = { ...user };
        // on effectue une copie de l'objet user_personnalisation
        const userPersoMod = { ...user.user_personnalisation };
        // on met à jour les propriétés de l'objet user_personnalisation
        Object.assign(userPersoMod, updatedProperties);
        // on met à jour les propriétés de l'objet user
        userMod.user_personnalisation = userPersoMod;
        setUser(userMod);
        
        const carteMod = { ...carte, ...{ user: userMod } };
        setCarte(carteMod);
        setShowAttention(true);
    };
    
    const handleUserPersoCouleurChange = (updatedProperties: Partial<UserPersoCouleurDTO>) => {
        // On met à jour les propriétés de l'utilisateur
        // on effectue une copie de l'objet user
        const userMod = { ...user };
        // On vient remplacer le theme selectionné par le theme modifié
        userMod.themes_user = userMod.themes_user.map((theme) => {
            if (theme.selected) {
                return {
                    ...theme,
                    ...{
                        user_perso_couleur: {
                            ...theme.user_perso_couleur,
                            ...updatedProperties,
                        },
                    },
                };
            } else {
                return theme;
            }
        });
        setUser(userMod);
        // On met à jour également le thème de l'utilisateur dans le general
        const generalMod = { ...general };
        generalMod.themeUser = {
            ...general.themeUser,
            ...{
                user_perso_couleur: {
                    ...general.themeUser.user_perso_couleur,
                    ...updatedProperties,
                },
            },
        };
        setGeneral(generalMod);
        const carteMod = { ...carte, ...{ user: userMod } };
        setCarte(carteMod);
        setShowAttention(true);
    };
    
    const handleExportCouleur = async () => {
        const couleurExport: ColorConfigExport = {
            couleurScrut      : general.themeUser.user_perso_couleur.couleur_scrut,
            couleurKm         : general.themeUser.user_perso_couleur.couleur_km,
            couleurPos        : general.themeUser.user_perso_couleur.couleur_pos,
            couleurBat        : general.themeUser.user_perso_couleur.couleur_bat,
            couleurSelectObj  : general.themeUser.user_perso_couleur.couleur_select_obj,
            couleurSelectBat  : general.themeUser.user_perso_couleur.couleur_select_bat,
            couleurSelectCit  : general.themeUser.user_perso_couleur.couleur_select_cit,
            couleurVueAuj     : general.themeUser.user_perso_couleur.couleur_vue_auj,
            couleurVue24      : general.themeUser.user_perso_couleur.couleur_vue24,
            couleurVue48      : general.themeUser.user_perso_couleur.couleur_vue48,
            couleurPA         : general.themeUser.user_perso_couleur.couleur_p_a,
            couleurZone       : general.themeUser.user_perso_couleur.couleur_zone,
            colorSelExp       : general.themeUser.user_perso_couleur.color_sel_exp,
            couleurSelCaseMaj : general.themeUser.user_perso_couleur.couleur_sel_case_maj,
            couleurDanger0    : general.themeUser.user_perso_couleur.couleur_danger0,
            couleurDanger1    : general.themeUser.user_perso_couleur.couleur_danger1,
            couleurDanger2    : general.themeUser.user_perso_couleur.couleur_danger2,
            couleurDanger3    : general.themeUser.user_perso_couleur.couleur_danger3,
            couleurEpuise     : general.themeUser.user_perso_couleur.couleur_epuise,
            couleurCarte      : general.themeUser.user_perso_couleur.couleur_carte,
            couleurZombie     : general.themeUser.user_perso_couleur.color_zombie,
            couleurEstimZombie: general.themeUser.user_perso_couleur.color_estimation_zombie,
            couleurFlag       : general.themeUser.user_perso_couleur.color_flag,
            couleurFlagFinish : general.themeUser.user_perso_couleur.color_flag_finish,
            couleurExpeInscrit: general.themeUser.user_perso_couleur.color_my_exp,
            couleurTown       : general.themeUser.user_perso_couleur.color_town,
            couleurCity       : general.themeUser.user_perso_couleur.color_city,
            couleurNonVu      : general.themeUser.user_perso_couleur.color_non_vu,
            couleurControleOk : general.themeUser.user_perso_couleur.couleur_controle_ok,
            couleurContoleNok : general.themeUser.user_perso_couleur.couleur_contole_nok,
            colorBatEpuise    : general.themeUser.user_perso_couleur.color_bat_epuise,
            couleurNbrCitoyen : general.themeUser.user_perso_couleur.couleur_nbr_citoyen,
            couleurNbrItemsSol: general.themeUser.user_perso_couleur.couleur_nbr_items_sol,
        };
        const textExport = JSON.stringify(couleurExport);
        setExportColor(textExport);
        try {
            await navigator.clipboard.writeText(textExport);
            setIsCopy(true);
            setTimeout(() => setIsCopy(false), 1500);
        } catch (error) {
            setIsErrorCopy(true);
        }
    };
    
    const handleImportCouleur = async () => {
        const couleur: ColorConfigExport = JSON.parse(exportColor);
        if (validateCouleurConfig(couleur)) {
            // On modifie les couleurs de la personnalisation de l'utilisateur
            const generalMod = {
                ...general,
                themeUser: {
                    ...general.themeUser,
                    ...{
                        user_perso_couleur: {
                            ...general.themeUser.user_perso_couleur,
                            ...{
                                couleur_scrut          : colorWithAlpha(couleur.couleurScrut),
                                couleur_km             : colorWithAlpha(couleur.couleurKm),
                                couleur_pos            : colorWithAlpha(couleur.couleurPos),
                                couleur_bat            : colorWithAlpha(couleur.couleurBat),
                                couleur_select_obj     : colorWithAlpha(couleur.couleurSelectObj),
                                couleur_select_bat     : colorWithAlpha(couleur.couleurSelectBat),
                                couleur_select_cit     : colorWithAlpha(couleur.couleurSelectCit),
                                couleur_vue_auj        : colorWithAlpha(couleur.couleurVueAuj),
                                couleur_vue24          : colorWithAlpha(couleur.couleurVue24),
                                couleur_vue48          : colorWithAlpha(couleur.couleurVue48),
                                couleur_p_a            : colorWithAlpha(couleur.couleurPA),
                                couleur_zone           : colorWithAlpha(couleur.couleurZone),
                                color_sel_exp          : colorWithAlpha(couleur.colorSelExp),
                                couleur_sel_case_maj   : colorWithAlpha(couleur.couleurSelCaseMaj),
                                couleur_danger0        : colorWithAlpha(couleur.couleurDanger0),
                                couleur_danger1        : colorWithAlpha(couleur.couleurDanger1),
                                couleur_danger2        : colorWithAlpha(couleur.couleurDanger2),
                                couleur_danger3        : colorWithAlpha(couleur.couleurDanger3),
                                couleur_epuise         : colorWithAlpha(couleur.couleurEpuise),
                                couleur_carte          : colorWithAlpha(couleur.couleurCarte),
                                color_zombie           : colorWithAlpha(couleur.couleurZombie),
                                color_estimation_zombie: colorWithAlpha(couleur.couleurEstimZombie),
                                color_flag             : colorWithAlpha(couleur.couleurFlag),
                                color_my_exp           : colorWithAlpha(couleur.couleurExpeInscrit),
                                color_flag_finish      : colorWithAlpha(couleur.couleurFlagFinish),
                                color_town             : colorWithAlpha(couleur.couleurTown),
                                color_city             : colorWithAlpha(couleur.couleurCity),
                                color_non_vu           : colorWithAlpha(couleur.couleurNonVu),
                                couleur_controle_ok    : colorWithAlpha(couleur.couleurControleOk),
                                couleur_contole_nok    : colorWithAlpha(couleur.couleurContoleNok),
                                color_bat_epuise       : colorWithAlpha(couleur.colorBatEpuise),
                                couleur_nbr_citoyen    : colorWithAlpha(couleur.couleurNbrCitoyen),
                                couleur_nbr_items_sol  : colorWithAlpha(couleur.couleurNbrItemsSol),
                            },
                        },
                    },
                },
            };
            // On met également à jour le theme selectionné de l'utilisateur
            const userMod = { ...user };
            // On vient remplacer le theme selectionné par le theme modifié
            userMod.themes_user = userMod.themes_user.map((theme) => {
                if (theme.selected) {
                    return {
                        ...theme,
                        ...{
                            user_perso_couleur: {
                                ...theme.user_perso_couleur,
                                ...{
                                    couleur_scrut          : colorWithAlpha(couleur.couleurScrut),
                                    couleur_km             : colorWithAlpha(couleur.couleurKm),
                                    couleur_pos            : colorWithAlpha(couleur.couleurPos),
                                    couleur_bat            : colorWithAlpha(couleur.couleurBat),
                                    couleur_select_obj     : colorWithAlpha(couleur.couleurSelectObj),
                                    couleur_select_bat     : colorWithAlpha(couleur.couleurSelectBat),
                                    couleur_select_cit     : colorWithAlpha(couleur.couleurSelectCit),
                                    couleur_vue_auj        : colorWithAlpha(couleur.couleurVueAuj),
                                    couleur_vue24          : colorWithAlpha(couleur.couleurVue24),
                                    couleur_vue48          : colorWithAlpha(couleur.couleurVue48),
                                    couleur_p_a            : colorWithAlpha(couleur.couleurPA),
                                    couleur_zone           : colorWithAlpha(couleur.couleurZone),
                                    color_sel_exp          : colorWithAlpha(couleur.colorSelExp),
                                    couleur_sel_case_maj   : colorWithAlpha(couleur.couleurSelCaseMaj),
                                    couleur_danger0        : colorWithAlpha(couleur.couleurDanger0),
                                    couleur_danger1        : colorWithAlpha(couleur.couleurDanger1),
                                    couleur_danger2        : colorWithAlpha(couleur.couleurDanger2),
                                    couleur_danger3        : colorWithAlpha(couleur.couleurDanger3),
                                    couleur_epuise         : colorWithAlpha(couleur.couleurEpuise),
                                    couleur_carte          : colorWithAlpha(couleur.couleurCarte),
                                    color_zombie           : colorWithAlpha(couleur.couleurZombie),
                                    color_estimation_zombie: colorWithAlpha(couleur.couleurEstimZombie),
                                    color_flag             : colorWithAlpha(couleur.couleurFlag),
                                    color_my_exp           : colorWithAlpha(couleur.couleurExpeInscrit),
                                    color_flag_finish      : colorWithAlpha(couleur.couleurFlagFinish),
                                    color_town             : colorWithAlpha(couleur.couleurTown),
                                    color_city             : colorWithAlpha(couleur.couleurCity),
                                    color_non_vu           : colorWithAlpha(couleur.couleurNonVu),
                                    couleur_controle_ok    : colorWithAlpha(couleur.couleurControleOk),
                                    couleur_contole_nok    : colorWithAlpha(couleur.couleurContoleNok),
                                },
                            },
                        },
                    };
                } else {
                    return theme;
                }
            });
            setUser(userMod);
            const carteMod = { ...carte, ...{ user: userMod } };
            setCarte(carteMod);
            setShowAttention(true);
            setGeneral(generalMod);
            setIsImport(true);
            window.setTimeout(function() {
                setIsImport(false);
            }, 1500);
            
        } else {
            setIsErrorImport(true);
            window.setTimeout(function() {
                setIsErrorImport(false);
            }, 5000);
        }
    };
    
    const choixDispo = (type: number, id_creneau: number) => {
        return <select name={"dispo_user_" + type + "_" + id_creneau} id={"dispo_user_" + type + "_" + id_creneau}
                       value={Object.values(user.dispo_types).find(d => {
                           return (type * 100 + id_creneau) === d.id;
                       })?.dispo?.id || 0}
                       onChange={(event) => {
                           const selectedDispoId = parseInt(event.target.value, 10);
                           const selectedDispo = options.dispoList.find((dispo) => dispo.id === selectedDispoId);
                           
                           if (selectedDispo) {
                               const updatedUser = { ...user };
                               
                               // Créez une copie de dispo_types pour mettre à jour l'élément spécifique
                               updatedUser.dispo_types = user.dispo_types;
                               
                               // Recherchez l'index correspondant dans le tableau dispo_types
                               const index = type * 100 + id_creneau;
                               
                               // Mettez à jour l'élément spécifique avec le nouvel objet disponible
                               updatedUser.dispo_types[index] = {
                                   ...updatedUser.dispo_types[index],
                                   dispo: selectedDispo,
                               };
                               
                               // Mettez à jour l'état du composant avec le nouvel objet user
                               setUser(updatedUser);
                               setShowAttention(true);
                           }
                       }}>
            {options.dispoList.map((dispo) => {
                return <option value={dispo.id} key={"dispo_" + type + "_" + id_creneau + "_" + dispo.id}>{t(dispo.nom, { ns: "jump" })}</option>;
            })}
        </select>;
    };
    
    useEffect(() => {
        document.documentElement.setAttribute("data-theme", theme);
    }, [theme]);
    const onSaveUser = () => {
        const optionPersoApi = new OptionPersoApi();
        optionPersoApi.majUser({ user: user, themeId: themeId }).then(response => {
            setUser(response.data.user);
            setStatus(Status_success);
            setShowPop(true);
            setMessagePopUp(response.data.libRetour);
            setShowAttention(false);
            setGeneral(response.data.general);
            setTimeout(() => {
                handleClose();
            }, 1000);
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.data.error);
        });
    };
    
    const colormapObject = props.options.options.colormapList.map((option) => ({
        value: option,
        label: option,
    }));
    
    const customStylesFuseau = {
        option           : (provided, state) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: state.isDisabled ? "lightgrey" : "white",
            color          : state.isDisabled ? "grey" : "black",
            ":active"      : {
                ...provided[":active"],
                backgroundColor: !state.isDisabled && (state.isSelected ? "data.color" : "white"),
            },
        }),
        singleValue      : (provided) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        control          : (provided) => ({
            ...provided,
            minHeight: "24px",
            height   : "24px",
            width    : "400px",
        }),
        input            : (provided) => ({
            ...provided,
            gridTemplateColumns: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "0",
        }),
        valueContainer   : (provided) => ({
            ...provided,
            padding: "0px 8px",
        }),
        menu             : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            width          : "400px",
        }),
        menuList         : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuPortal       : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
    };
    
    const motifEpuisementOptions = options.motifEpuisement.map((motif) => ({
        value: motif,
        label: motif,
    }));
    
    return <div className="espacePerso">
        {showAttention && <h4 className={"color-red"} style={{
            display       : "flex",
            alignContent  : "center",
            alignItems    : "center",
            gap           : "5px",
            justifyContent: "center",
            margin        : 0,
            textAlign     : "center",
            width         : "100%",
        }}>
            <i className="fa-solid fa-triangle-exclamation"></i>
            <span>{t("Attention, pensez bien à sauvegarder vos modifications !", { ns: "perso" })}</span>
            <i className="fa-solid fa-triangle-exclamation"></i>
        </h4>}
        <div id="info_gestion_perso">
            <div id="zone_option">
                <Tabs activeKey={activeTab} onSelect={handleTabChange}>
                    <Tab eventKey="perso" title={t("Perso", { ns: "perso" })}>
                        <div id="zone_perso">
                            <div id="zonePerso">
                                <fieldset>
                                    <legend><h2>{t("Gestion des informations personnelles", { ns: "perso" })}</h2></legend>
                                    <GestionSkill user={user} listSkillType={options.skillType} listSkillLevel={options.levelSkill} handleUserchange={handleUserChange} handleUserPersoCouleurChange={handleUserPersoCouleurChange} themeUser={general.themeUser} />
                                    <div id={"groupement_form_option_perso"}>
                                        {/*                                        <div className={"option_perso_form"}>
                                         <span>{t("Dernier pouvoir héros :", { ns: "perso" })}</span>
                                         <Select value={user.der_pouv} id={"option_der_pouv"} name={"option_der_pouv"} options={props.options.options.heroList}
                                         components={{ SingleValue: IconSingleValue, Option: IconOption }} getOptionLabel={(hero) => hero.nom}
                                         onChange={(optionSelected: HerosPrototypeDTO) => handleUserChange({ der_pouv: optionSelected })}
                                         styles={customStyles} />
                                         </div>*/}
                                        {<div className={"option_perso_form"} id={"regroupement_nb_comp"}>
                                            <label htmlFor={"nb_pts_comp"}>{t("Nombre de points de compétence acquis :", { ns: "perso" })}</label>
                                            <input type="number" id={"nb_pts_comp"} name={"nb_pts_comp"} min={0} value={user.nbr_points_competence} onChange={(event) => handleUserChange({ nbr_points_competence: (parseInt(event.target.value, 10) < 0) ? 0 : parseInt(event.target.value, 10) })} />
                                        </div>}
                                        {<div className={"option_perso_form"}>
                                            <label htmlFor={"option_legend"}>{t("Légendaire :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_legend"} id={"option_legend"} type="switch" checked={user.legend}
                                                        onChange={(event) => handleUserChange({ legend: event.target.checked })} />
                                            <div>
                                                <TooltipGH>
                                                    <span className="infoBulle">
                                                        <i className="fa fa-circle-info"></i>
                                                    </span>
                                                    <span className="infoHelpPerso">{t("Vous êtes légendaire quand vous possédez un réveil, c'est-à-dire que vous avez été dans une ville qui a finit première dans le classement RE et/ou Pandémonium", { ns: "perso" })}</span>
                                                </TooltipGH>
                                            </div>
                                        </div>}
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_temArma"}>{t("Temoin Arma :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_temArma"} id={"option_temArma"} type="switch" checked={user.tem_arma}
                                                        onChange={(event) => handleUserChange({ tem_arma: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"} id="regroupement_charge_op">
                                            <label htmlFor={"option_nbChargeCamaraderie"}>{t("Nombre de charge de camaraderie disponible :", { ns: "perso" })}</label>
                                            <input type="number" id={"option_nbChargeCamaraderie"} name={"option_nbChargeCamaraderie"} min={0}
                                                   value={user.nb_charge_camaraderie} onChange={(event) => handleUserChange(
                                                { nb_charge_camaraderie: (parseInt(event.target.value, 10) < 0) ? 0 : parseInt(event.target.value, 10) })} />
                                            <div id="aide_charge_op">
                                                <TooltipGH>
                                                    <span className="infoBulle">
                                                        <i className="fa fa-circle-info"></i>
                                                    </span>
                                                    <span
                                                        className="infoHelpPerso">{t("Le nombre de charge ne sera pas visible, seul le fait que vous possédez une charge de disponible le sera sur la page Citoyens. Par ailleurs, il se décrémentera à chaque fois que vous utiliserez une charge (en mettant à jour votre utilisation sur la page Citoyens).", { ns: "perso" })}</span>
                                                </TooltipGH>
                                            </div>
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_show_histo"}>{t("Afficher votre historique de picto aux autres joueurs :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_show_histo"} id={"option_show_histo"} type="switch" checked={user.show_histo_picto}
                                                        onChange={(event) => handleUserChange({ show_histo_picto: event.target.checked })} />
                                            <div>
                                                <span className="infoBulle">
                                                    <i className="fa fa-circle-info"></i>
                                                    <span className="infoHelpPerso">{t("Option vous permettant d'afficher ou non à tous les autres joueurs vos gains de picto ville par ville lorsque des joueurs visitent votre âme.", { ns: "perso" })}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <span>{t("Couleur ville commune :", { ns: "perso" })}</span>
                                            <ColorPicker color={general.themeUser.user_perso_couleur.color_ville_commune} onChangeColor={(color) => handleUserPersoCouleurChange({ color_ville_commune: color })} />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Gestion des paramètres du site", { ns: "perso" })}</h2></legend>
                                    <div id={"groupement_theme_option_perso"}>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_maj_co"}>{t("Mettre à jour à la connexion (via l'annuaire MyHordes) :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_maj_co"} id={"option_maj_co"} type="switch" checked={user.user_personnalisation.maj_co}
                                                        onChange={(event) => handleUserPersoChange({ maj_co: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_keep_open"}>{t("Garder le menu du site ouvert tout le temps :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_keep_open"} id={"option_keep_open"} type="switch" checked={user.user_personnalisation.keep_menu_open}
                                                        onChange={(event) => handleUserPersoChange({ keep_menu_open: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_menu_hor"}>{t("Menu sous forme de bandeau horizontal en haut de page au lieu du menu \"Burger\" :", { ns: "perso" })}</label>
                                            <Form.Check
                                                name={"option_menu_hor"}
                                                id={"option_menu_hor"}
                                                type="switch"
                                                checked={user.user_personnalisation.menu_bandeau}
                                                onChange={(event) => handleUserPersoChange({ menu_bandeau: event.target.checked })}
                                            />
                                        </div>
                                    </div>
                                    <div id={"groupe_fuseau"}>
                                        <label>{t("Fuseau horaire préféré (modifie l'intégralité des heures sur GH) :", { ns: "perso" })}</label>
                                        <SelectTimeZone timeZone={user.fuseau} disabled={false} style={customStylesFuseau}
                                                        onUpdate={(timezone) => handleUserChange({ fuseau: timezone })} />
                                    </div>
                                    <fieldset>
                                        <legend>{t("Gestion des thèmes", { ns: "perso" })}</legend>
                                        <div id={"groupement_theme_gestion_option_perso"}>
                                            <div className={"option_perso_form"}>
                                                <label htmlFor={"options_theme"}>{t("Choix theme :", { ns: "perso" })}</label>
                                                <select id={"options_theme"} name={"options_theme"} value={user.themes_user.find((theme: ThemeUserDTO) => theme.selected).id}
                                                        onChange={(event) => {
                                                            const themeIdChoice = parseInt(event.target.value, 10);
                                                            setThemeId(themeIdChoice);
                                                            const themeChoice = user.themes_user.find((themeList: ThemeUserDTO) => themeList.id === themeIdChoice);
                                                            if (themeChoice) {
                                                                setTheme(themeChoice.base_theme);
                                                                // On désélectionne tous les thèmes pour ne sélectionner que celui choisi
                                                                const updatedUser = { ...user };
                                                                updatedUser.themes_user.forEach((themeList: ThemeUserDTO) => {
                                                                    themeList.selected = themeList.id === themeIdChoice;
                                                                });
                                                                updatedUser.theme = themeChoice.base_theme;
                                                                setUser(updatedUser);
                                                                // Il faut mettre à jour également les valeurs dans le general par rapport au theme choisi
                                                                const updatedGeneral = { ...general };
                                                                updatedGeneral.themeUser = themeChoice;
                                                                setGeneral(updatedGeneral);
                                                                setShowAttention(true);
                                                            }
                                                            
                                                        }}>
                                                    {Object.entries(user.themes_user).map(([index, theme]: [string, ThemeUserDTO]) => {
                                                        return <option value={theme.id} key={"theme_" + index}>{theme.nom}</option>;
                                                    })}
                                                </select>
                                            </div>
                                            <div className={"option_perso_form"}>
                                                <button type="button" id="gestion_theme" className="btn-sm btn btn-primary"
                                                        onClick={() => setShowGestionTheme(!showGestionTheme)}>{showGestionTheme ? t("Masquer la gestion des thèmes", { ns: "perso" }) : t("Afficher la gestion des thèmes", { ns: "perso" })}</button>
                                            </div>
                                        </div>
                                        {showGestionTheme && <GestionTheme user={user} baseThemeList={options.baseThemeList} themeName={options.themeList} onMajUser={setUser} />}
                                    </fieldset>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Paramètre de la page citoyens", { ns: "perso" })}</h2></legend>
                                    <div id={"groupement_citoyen_option_perso"}>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_modeCompact"}>{t("Afficher la page citoyens en mode compact :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_modeCompact"} id={"option_modeCompact"} type="switch" checked={user.user_personnalisation.citoyens_mode_compact}
                                                        onChange={(event) => handleUserPersoChange({ citoyens_mode_compact: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_blocMaj"}>{t("Bloquer la mise à jour de ses informations de la page Citoyens par les autres joueurs :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_blocMaj"} id={"option_blocMaj"} type="switch" checked={user.user_personnalisation.bloc_maj_citoyens}
                                                        onChange={(event) => handleUserPersoChange({ bloc_maj_citoyens: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_blocMaj_other"}>{t("Bloquer la mise à jour des informations des autres joueurs de la page Citoyens :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_blocMaj_other"} id={"option_blocMajOhter"} type="switch" checked={user.user_personnalisation.bloc_maj_citoyen}
                                                        onChange={(event) => handleUserPersoChange({ bloc_maj_citoyen: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_citoyen_on_top"}>{t("Se positionner en premier dans la page citoyen :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_citoyen_on_top"} id={"option_citoyen_on_top"} type="switch" checked={user.user_personnalisation.on_top_citoyen}
                                                        onChange={(event) => handleUserPersoChange({ on_top_citoyen: event.target.checked })} />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Gestion des options de la page Ruine", { ns: "perso" })}</h2></legend>
                                    <div id={"gestion_option_ruine"}>
                                        <div className={"choix_color_ruine_option"}>
                                            <span>{t("Couleur des escaliers montants", { ns: "perso" })}</span>
                                            <ColorPicker color={general.themeUser.user_perso_couleur.color_stair_up} onChangeColor={(color) => handleUserPersoCouleurChange({ color_stair_up: color })} />
                                        </div>
                                        <div className={"choix_color_ruine_option"}>
                                            <span>{t("Couleur des escaliers descendants", { ns: "perso" })}</span>
                                            <ColorPicker color={general.themeUser.user_perso_couleur.color_stair_down} onChangeColor={(color) => handleUserPersoCouleurChange({ color_stair_down: color })} />
                                        </div>
                                        <div className={"choix_color_ruine_option"}>
                                            <span>{t("Couleur de sélection des objets dans la ruine", { ns: "perso" })}</span>
                                            <ColorPicker color={general.themeUser.user_perso_couleur.color_select_objet_ruine}
                                                         onChangeColor={(color) => handleUserPersoCouleurChange({ color_select_objet_ruine: color })} />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Gestion des couleurs sur les pages des chantiers", { ns: "perso" })}</h2></legend>
                                    <div id={"gestion_option_ruine"}>
                                        {getOptionsChantiers(t).map((section, index) => {
                                            return section.items.map((item, indexItems) => {
                                                switch (item.type) {
                                                    case "color":
                                                        return <ColorPickerItem
                                                            key={"color_" + indexItems}
                                                            label={item.label}
                                                            tooltip={item?.tooltip ?? null}
                                                            color={general.themeUser.user_perso_couleur[item.key] as string}
                                                            onChangeColor={(color) => handleUserPersoCouleurChange({ [item.key]: color })}
                                                        />;
                                                    default:
                                                        return null;
                                                }
                                            });
                                        })}
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="carte" title={t("Carte", { ns: "perso" })}>
                        <div id="zone_carte">
                            <div id="zoneCarte">
                                <fieldset id="gestionCouleurCarte">
                                    <legend><h2>{t("Gestion des différentes couleurs personnalisables sur la carte", { ns: "perso" })}</h2></legend>
                                    <p>{t("Modifiez les couleurs, elles changeront en même temps sur la mini carte à droite afin de prévisualiser directement les modifications.", { ns: "perso" })}</p>
                                    <div id="optionCouleurCarte">
                                        <div id="choixCouleurCarte">
                                            <div>
                                                <>
                                                    {getOptions(t).map((section, index) => (
                                                        <CollapsibleFieldset key={"collapse_" + index} title={section.title}>
                                                            {section.items.map((item, indexItems) => {
                                                                    switch (item.type) {
                                                                        case "switch":
                                                                            return <SwitchItems
                                                                                key={"switch_" + indexItems}
                                                                                label={item.label}
                                                                                forHtml={item.key}
                                                                                tooltip={item?.tooltip ?? null}
                                                                                checked={item.source === "perso" ? user.user_personnalisation[item.key] : general.themeUser.user_perso_couleur[item.key] as boolean}
                                                                                onChangeUser={(event) => item.source === "perso" ? handleUserPersoChange({ [item.key]: event }) : handleUserPersoCouleurChange({ [item.key]: event })}
                                                                            />;
                                                                        case "color":
                                                                            return <ColorPickerItem
                                                                                key={"color_" + indexItems}
                                                                                label={item.label}
                                                                                tooltip={item?.tooltip ?? null}
                                                                                color={general.themeUser.user_perso_couleur[item.key] as string}
                                                                                onChangeColor={(color) => handleUserPersoCouleurChange({ [item.key]: color })}
                                                                            />;
                                                                        case "motif":
                                                                            return <div className={"gestion_option_couleur_carte"} key={"motif_" + indexItems}>
                                                                                <span>{item.label}</span>
                                                                                <Select
                                                                                    value={motifEpuisementOptions.find((option) => option.value === user.user_personnalisation[item.key])}
                                                                                    id="option_motif"
                                                                                    name="option_motif"
                                                                                    options={motifEpuisementOptions}
                                                                                    components={{ SingleValue: MotifSingleValue, Option: MotifOption }}
                                                                                    onChange={(optionSelected: { value: string, label: string }) => handleUserPersoChange({ [item.key]: optionSelected.value })}
                                                                                    styles={customStylesMotif}
                                                                                />
                                                                            </div>;
                                                                        default:
                                                                            return null;
                                                                    }
                                                                },
                                                            )}
                                                        </CollapsibleFieldset>
                                                    ))}
                                                </>
                                            </div>
                                            <div id="import_export_couleur" className={"d-flex flex-column justify-content-center gap-1 align-self-center"}>
                                                <div id="zone_bouton_export">
                                                    <button type="button" id="export_couleur_perso" className="button btn-xs btn btn-success"
                                                            onClick={handleExportCouleur}>{(isErrorCopy) ? t("Appuyer sur \"Ctrl + C\" pour copier", { ns: "outils" }) : ((isCopy) ? t("Texte copié", { ns: "outils" }) : t("Exporter les couleurs et copier dans le presse-papier", { ns: "perso" }))}</button>
                                                    <button type="button" id="import_couleur_perso" className="button btn-xs btn btn-warning"
                                                            onClick={handleImportCouleur}>{(isErrorImport) ? t("Le texte fournit n'est pas dans le bon format attendu.", { ns: "outils" }) : ((isImport) ? t("Couleurs correctement importées, vous pouvez sauvegarder", { ns: "perso" }) : t("Importer les couleurs", { ns: "perso" }))}</button>
                                                </div>
                                                <div id="zone_text_import_export" className={"d-flex justify-content-center"}>
                                                    <textarea name="export_import_couleur_option" id="export_import_couleur_option" cols={60} rows={5} value={exportColor} onChange={(event) => setExportColor(event.target.value)}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="zonePrevisu">
                                            <div id={"option_gestion_carte_alter"}>
                                                <div>
                                                    <label htmlFor={"option_active_alternative"} className={"d-flex gap-1"}>
                                                        <span>{t("Activer la carte alternative", { ns: "perso" })}</span>
                                                        <TooltipsInfo label={t("L'affichage de cette carte permet de montrer les objets déchargeables, la carte aura une coloration selon le nombre d'objet à ramasser, vous pouvez régler la coloration dans vos options personnelles", { ns: "ville" })} />
                                                    </label>
                                                    <Form.Check name={"option_active_alternative"} id={"option_active_alternative"} type="switch" checked={activeAlternative}
                                                                onChange={(event) => {
                                                                    const carteMod = { ...carte, ...{ carteOptionPerso_alter: event.target.checked } };
                                                                    setCarte(carteMod);
                                                                    setActiveAlternative(event.target.checked);
                                                                }} />
                                                </div>
                                                {activeAlternative && <>
                                                    <div className={"option_perso_gestion_couleur"} style={{ position: "relative", zIndex: 1000 }}>
                                                        <span>{t("Choix de la colormap :", { ns: "perso" })}</span>
                                                        <Select value={{ value: general.themeUser.user_perso_couleur.colormap, label: general.themeUser.user_perso_couleur.colormap }} id={"option_colormap"} name={"option_colormap"}
                                                                options={Object.values(colormapObject)} components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                                                                onChange={(optionSelected) => handleUserPersoCouleurChange({ colormap: optionSelected.label })}
                                                                styles={customStyles} />
                                                    </div>
                                                    <div>
                                                        <span>{t("Transparence de la colormap :", { ns: "perso" })}</span>
                                                        <RangeSlider value={general.themeUser.user_perso_couleur.alpha_colormap}
                                                                     onChange={e => handleUserPersoCouleurChange({ alpha_colormap: parseInt(e.target.value, 10) })} min={0} max={100}
                                                                     step={1} />
                                                    </div>
                                                </>}
                                            </div>
                                            <div id="miniCartePrevisu">
                                                <Carte carte={carte} />
                                            </div>
                                            <div id={"option_gestion_carte_estim"}>
                                                <div>
                                                    <label htmlFor={"option_active_estim"}>{t("Activer l'estimation de zombie", { ns: "perso" })}</label>
                                                    <Form.Check
                                                        name={"option_active_estim"}
                                                        id={"option_active_estim"}
                                                        type="switch"
                                                        checked={activeEstimation}
                                                        onChange={(event) => {
                                                            const carteMod = { ...carte, ...{ carteOptionPerso_estim: event.target.checked } };
                                                            setCarte(carteMod);
                                                            setActiveEstimation(event.target.checked);
                                                        }}
                                                    />
                                                </div>
                                                {activeEstimation && <div>
                                                    <span>{t("Couleur zombie estimée :", { ns: "perso" })}</span>
                                                    <ColorPicker color={general.themeUser.user_perso_couleur.color_estimation_zombie}
                                                                 onChangeColor={(color) => handleUserPersoCouleurChange({ color_estimation_zombie: color })} />
                                                </div>}
                                            </div>
                                            <div id={"option_gestion_resizable_carte"}>
                                                <div id={"option_resizable_carte"}>
                                                    <label htmlFor={"option_active_taille"}>{t("Activer la taille personnalisée", { ns: "perso" })}</label>
                                                    <Form.Check
                                                        name={"option_active_taille"}
                                                        id={"option_active_taille"}
                                                        type="switch"
                                                        checked={user.user_personnalisation.resizabled}
                                                        onChange={(event) => handleUserPersoChange({ resizabled: event.target.checked })}
                                                    />
                                                </div>
                                                {user.user_personnalisation.resizabled && <div id={"option_form_resizable_carte"}>
                                                    <fieldset id={"option_resizable_carte_range"}>
                                                        <legend>{t("Taille de la case (en px)", { ns: "perso" })}</legend>
                                                        <RangeSlider value={user.user_personnalisation.width_case} onChange={(e) => handleUserPersoChange({ width_case: parseInt(e.target.value, 10) })} min={10} max={50} step={1} />
                                                    </fieldset>
                                                    <button id={"option_show_apercu_carte_btn"} className={"btn btn-xs btn-primary"} onClick={() => setShowApercuTaille(!showApercuTaille)}>{(showApercuTaille) ? t("Masquer les dimensions de la carte", { ns: "perso" }) : t("Visualiser les dimensions de la carte", { ns: "perso" })}</button>
                                                    {showApercuTaille && <div>
                                                        <div id={"zoneApercuTaille"}>
                                                            <div>{t("Légende", { ns: "perso" })}</div>
                                                            <div>
                                                                <span style={{ width: "25px", height: "10px", display: "block", backgroundColor: "blue" }}></span>
                                                                <span>{t("Pour villes RE ou Pandémonium", { ns: "perso" })}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{ width: "25px", height: "10px", display: "block", backgroundColor: "red" }}></span>
                                                                <span>{t("Pour villes RNE", { ns: "perso" })}</span>
                                                            </div>
                                                        </div>
                                                        <AppercuCarteTaille tailleCase={user.user_personnalisation.width_case} />
                                                    </div>}
                                                </div>}
                                            </div>
                                            <div id="miniTabExpePrevisu">
                                                <table id="trace_carte">
                                                    <thead>
                                                    <tr>
                                                        <th className="nom_coul_tb_trace">{t("Nom expédition - Couleur", { ns: "perso" })}</th>
                                                        <th className="jour_tb_trace">{t("J", { ns: "ville" })}</th>
                                                        <th className="pa_tb_trace"><SvgIcone icone={"h_pa"} /></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {options.color.map((colExp, index) => {
                                                        return <tr className={"visualiser_expe" + ((index === 1) ? " selectedExpe" : ((index === 2) ? " myExpe" : ""))} key={"expe_" + index}>
                                                            <td className="nom_coul_tb_trace">
                                                                <div>{"Exp_" + index} <span className="list_couleur_trace" style={{ backgroundColor: `${colExp}` }}></span>
                                                                </div>
                                                            </td>
                                                            <td className="jour_tb_trace">2</td>
                                                            <td className="pa_tb_trace">19</td>
                                                        </tr>;
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Choix de l'affichage des informations sur la case", { ns: "perso" })}</h2></legend>
                                    <div id={"groupement_form_gestion_affichage"}>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_popUpClick"}>{t("Afficher la pop-up uniquement au clic :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_popUpClick"} id={"option_popUpClick"} type="switch" checked={user.user_personnalisation.pop_up_click}
                                                        onChange={(event) => handleUserPersoChange({ pop_up_click: event.target.checked })} />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Choix de l'affichage des informations du menu", { ns: "perso" })}</h2></legend>
                                    <div id={"groupement_form_gestion_affichage"}>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_menuFiger"}>{t("Figer le menu de la carte sur l'écran pour qu'il passe au dessus de la carte :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_menuFiger"} id={"option_menuFiger"} type="switch" checked={user.user_personnalisation.fige_menu}
                                                        onChange={(event) => handleUserPersoChange({ fige_menu: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_itemCate"}>{t("Ne pas grouper les objets en catégories dans l'onglet Objets :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_itemCate"} id={"option_itemCate"} type="switch" checked={user.user_personnalisation.item_separed_cate}
                                                        onChange={(event) => handleUserPersoChange({ item_separed_cate: event.target.checked })} />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="jump" title={t("Jump", { ns: "perso" })}>
                        <div id="zone_opt_jump">
                            <div id="zoneoOptJump">
                                <div id="gestionDispoCreneau">
                                    <h2>{t("Gestion des dispos pour le préremplissage lors des inscriptions", { ns: "perso" })}</h2>
                                    <div className="intituleDispoOptionPerso">{t("Disponibilité type en semaine", { ns: "perso" })}</div>
                                    <div>
                                        <div className="ligneDispoOptionPerso">{Object.values(options.creneauList).map((creneau, index) => {
                                            return <div className="colonneDispoOptionPerso" key={"dispo_1_" + index}>
                                                <div><span>{HTMLParser(creneau.libelle)}</span></div>
                                                <div>{choixDispo(1, creneau.id)}</div>
                                            </div>;
                                        })}</div>
                                    </div>
                                    <div className="intituleDispoOptionPerso" id="titreDispoWeekendOptionPerso">{t("Disponibilité type en week-end", { ns: "perso" })}</div>
                                    <div>
                                        <div className="ligneDispoOptionPerso">{Object.values(options.creneauList).map((creneau, index) => {
                                            return <div className="colonneDispoOptionPerso" key={"dispo_2_" + index}>
                                                <div><span>{HTMLParser(creneau.libelle)}</span></div>
                                                <div>{choixDispo(2, creneau.id)}</div>
                                            </div>;
                                        })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="expe" title={t("Expédition", { ns: "perso" })}>
                        <GestionDispo user={user}
                                      onUserChange={(userPartial: Partial<UserDTO>) => handleUserChange(userPartial)}
                                      onUserPersoChange={(userPersoPartial: Partial<UserPersonnalisationDTO>) => handleUserPersoChange(userPersoPartial)}
                                      onUserPersoCouleurChange={(userPersoCouleurPartial: Partial<UserPersoCouleurDTO>) => handleUserPersoCouleurChange(userPersoCouleurPartial)}
                                      dispo={options.creneauDispoExpe.dispo}
                                      creneau={options.creneauDispoExpe.creneau}
                                      onUser={(userMod) => setUser(userMod)} />
                    </Tab>
                    <Tab eventKey="menu" title={t("Menu", { ns: "perso" })}>
                        <div id="zone_menu">
                            <MenuDesignerOptionsPerso menuProto={options.menuPrototype} menuUser={user.menu} />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
        <div className="zoneBoutonGestionOptionPerso">
            {activeTab !== "menu" && <div id={"option_bouton_gestion"}>
                <button className={"btn btn-primary btn-xs"} onClick={onSaveUser}>{t("Sauvegarder", { ns: "perso" })}</button>
                <button className={"btn btn-warning btn-xs"} onClick={() => {
                    setUser(props.options.user);
                    setShowAttention(false);
                }}>{t("Annuler", { ns: "perso" })}</button>
            </div>}
            {activeTab !== "menu" && user.date_maj !== null && <div id={"option_date_maj"}>
                <em>{t("Dernière mise à jour le {date}", { ns: "perso" }).replace("{date}",
                    formatInTimeZone(new Date(Date.parse(user.date_maj)), user.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" })))}</em>
            </div>}
        </div>
    </div>;
    
}