import Cookies                                  from "js-cookie";
import React, {useEffect, useRef, useState}     from "react";
import {CarteType, PopUpMaj}                    from "../../../types/components/ville/CarteType";
import {Expedition}                             from "../../../types/components/ville/Expedition";
import {InfoCamping}                            from "../../../types/components/ville/InfoCamping";
import {ParamCarte}                             from "../../../types/components/ville/ParamCarte";
import {SelectedArray}                          from "../../../types/components/ville/SelectedArray";
import {ZoneMaj}                                from "../../../types/components/ville/Zone";
import {CarteApi}                               from "../../../services/api/CarteApi";
import {ExpeditionApi}                          from "../../../services/api/ExpeditionApi";
import {Bordure}                                from "./Bordure";
import DetailCase                               from "./DetailCase";
import {Helmet}                                 from "react-helmet-async";
import MenuCarte                                from "./MenuCarte";
import PopUpMajManuel                           from "./PopUpMajManuel";
import {useGeneralContext}                      from "../../../types/Context/GeneralContext";
import {Status_error, Status_success, usePopUp} from "../../../types/Context/PopUpContext";
import {CarteContext}                           from "../../../types/Context/CarteContext";
import {CustomPagination}                       from "../../../components/generality/Pagination";
import Modal                                    from "react-bootstrap/Modal";
import Camping                                  from "./Camping";
import {UserDTO}                                from "../../../types/models/user.dto";
import {ZoneMapDTO}                             from "../../../types/models/zoneMap.dto";
import {useTranslation}                         from "react-i18next";
import {UserPersoCouleurDTO}                    from "../../../types/models/userPersoCouleur.dto";
import CaseCarte                                from "./CaseCarte";
import {VilleDTO}                               from "../../../types/models/ville.dto";
import TooltipCaseCarte                       from "./TooltipCaseCarte";

const generateCSSUser = (userPerso: UserPersoCouleurDTO) => {
    return `
		.Bat{color:${userPerso.couleur_select_bat};}
		.select_bat { background-color: ${userPerso.couleur_select_bat}; }
		.Cit{color:${userPerso.couleur_select_cit};}
		.select_cit { background-color: ${userPerso.couleur_select_cit}; }
		.Obj{color:${userPerso.couleur_select_obj};}
		.select_obj { background-color: ${userPerso.couleur_select_obj}; }
		.vueAuj { color: ${userPerso.couleur_vue_auj}; }
		.vue24 { color: ${userPerso.couleur_vue24}; }
		.vue48 { color: ${userPerso.couleur_vue48}; }
		.selectedExpe { background-color: ${userPerso.color_sel_exp}; }
		.myExpe { background-color: ${userPerso.color_my_exp}; }
		.myFlag { color: ${userPerso.color_flag}; }
		.myFlagFinis { color: ${userPerso.color_flag_finish}; }
		.visuCase { color: ${userPerso.couleur_sel_case_maj}; }
		.zone-danger0 { background-color: ${userPerso.couleur_danger0}; }
		.zone-danger1 { background-color: ${userPerso.couleur_danger1}; }
		.zone-danger2 { background-color: ${userPerso.couleur_danger2}; }
		.zone-danger3 { background-color: ${userPerso.couleur_danger3}; }
		.background_carte_color { background-color: ${userPerso.couleur_carte}; }
		.caseVille { background-color: ${userPerso.color_town}; }
		.zone-NonExplo { background-color: ${userPerso.color_non_vu}; }
		.zone-ctrlOk { background-color: ${userPerso.couleur_controle_ok}; }
		.zone-ctrlKo { background-color: ${userPerso.couleur_contole_nok}; }
		.bat_croix { color: ${userPerso.color_bat_epuise}; }
		.objetSolDiv{color:${userPerso.couleur_nbr_items_sol};}
		.citoyensVilleDiv{color:${userPerso.couleur_nbr_citoyen};}
		`;
};
const generateCSSVille = (ville: VilleDTO, optionPerso: boolean, userOption: UserDTO, userPerso: UserPersoCouleurDTO) => {
    let variableTaille: number;
    let variableTailleSpe: number;
    const tailleVille = optionPerso ? 24 : ville.height;
    
    let taillePicto;
    let tailleBat;
    let tailleSac;
    let tailleFontCitoyens;
    let tailleFontItems;
    
    if (userOption.user_personnalisation.resizabled) {
        let tailleChoisi = userOption.user_personnalisation.width_case ?? 43;
        // On vérifie que la taille ne dépasse pas 1144px
        
        if (tailleVille > 15) {
            if (tailleVille * userOption.user_personnalisation.width_case > 1144) {
                tailleChoisi = Math.ceil(1144 / tailleVille);
            }
            document.documentElement.style.setProperty("--tailleGd", `${tailleChoisi}px`);
            variableTailleSpe = Math.ceil(tailleChoisi / 4);
            document.documentElement.style.setProperty("--tailleGdSpe", `${variableTailleSpe}px`);
        } else {
            if (tailleVille * userOption.user_personnalisation.width_case > 1144) {
                tailleChoisi = Math.ceil(1144 / tailleVille);
            } else {
                tailleChoisi = Math.ceil(tailleChoisi * 1.35);
            }
            document.documentElement.style.setProperty("--taillePt", `${tailleChoisi}px`);
            variableTailleSpe = Math.ceil(tailleChoisi / 5);
            document.documentElement.style.setProperty("--tailleGdSpe", `${variableTailleSpe}px`);
        }
        
        // calcul de la taille du picto home
        taillePicto = Math.ceil(tailleChoisi / 2);
        
        // calcul de la taille du batiment sur la case
        tailleBat = Math.ceil(tailleChoisi * 0.65);
        
        // calcul de la taille du sac sur la case
        tailleSac = Math.ceil(tailleChoisi * 0.3);
        
        tailleFontCitoyens = Math.ceil(tailleChoisi * 0.3);
        tailleFontItems = Math.ceil(tailleChoisi * 0.26);
    } else {
        if (tailleVille > 15) {
            variableTaille = Math.ceil(1144 / tailleVille);
            document.documentElement.style.setProperty("--tailleGd", `${variableTaille}px`);
            variableTailleSpe = Math.ceil((10 / tailleVille) * 27);
            document.documentElement.style.setProperty("--tailleGdSpe", `${variableTailleSpe}px`);
        } else {
            variableTaille = Math.ceil(780 / tailleVille);
            document.documentElement.style.setProperty("--taillePt", `${variableTaille}px`);
            variableTailleSpe = Math.ceil((12 / tailleVille) * 15);
            document.documentElement.style.setProperty("--tailleGdSpe", `${variableTailleSpe}px`);
        }
        
        // calcul de la taille du picto home
        taillePicto = Math.ceil(variableTaille / 2);
        
        // calcul de la taille du batiment sur la case
        tailleBat = Math.ceil(variableTaille * 0.65);
        
        // calcul de la taille du sac sur la case
        tailleSac = Math.ceil(variableTaille * 0.3);
        
        tailleFontCitoyens = Math.ceil(variableTaille * 0.3);
        tailleFontItems = Math.ceil(variableTaille * 0.26);
    }
    
    
    return `
   .c${ville.height} {width : ${variableTaille * ville.height + 50}px;}
   .picto_home_ville {font-size : ${taillePicto}px; color: ${userPerso.color_city};}
   .bat_svg {width : ${tailleBat}px; height : ${tailleBat}px;}
   .itemsSol {width : ${tailleSac}px; height : ${tailleSac}px;}
   .citoyensVilleSpan {font-size : ${tailleFontCitoyens}px;}
   .itemsSolSpan {font-size : ${tailleFontItems}px;}
   `;
};
const initialDim = {
    height: 0,
    width : 0,
    top   : 0,
    left  : 0,
};
const selectOptionPerso = {
    obj : [2],
    bat : ["3_1", "5_3", "0_4"],
    cit : [1, 4],
    expe: [],
};
const paramCartePerso = (carteOptionPerso_alter: boolean, carteOptionPerso_estim: boolean) => {
    return {
        danger     : true,
        distance   : true,
        distance_pa: true,
        zonage     : true,
        scrutateur : true,
        zombie     : true,
        epuise     : true,
        objetSol   : true,
        objetMarq  : true,
        citoyen    : true,
        indicVisite: true,
        carteAlter : carteOptionPerso_alter,
        carteScrut : false,
        estimZombie: carteOptionPerso_estim,
        balisage   : false,
        arrKm      : [2, 4, 6, 8],
        arrPa      : [3, 5],
        arrZonage  : [7],
        arrAlter   : [112],
        ctrl       : true,
    };
};
const paramCarteInit = {
    danger     : true,
    distance   : true,
    distance_pa: false,
    zonage     : false,
    scrutateur : true,
    zombie     : true,
    epuise     : true,
    objetSol   : true,
    objetMarq  : true,
    citoyen    : true,
    indicVisite: true,
    carteAlter : false,
    carteScrut : false,
    estimZombie: false,
    balisage   : false,
    arrKm      : [2, 10, 11, 17],
    arrPa      : [3, 6, 9, 12],
    arrZonage  : [7, 19],
    arrAlter   : [112, 113, 114, 116, 117, 118],
    ctrl       : true,
};
const selectCarteInit = {obj: [], bat: [], cit: [], expe: []};

export default function Carte(props: {
    carte: CarteType;
    popUpMaj?: PopUpMaj;
    onRefresh?: () => void;
}) {
    const {t} = useTranslation();
    const paramCarteCookieOld = Cookies.get("paramCarteV7");
    const selectedCarteCookieOld = Cookies.get("selectionCarte");
    
    if (paramCarteCookieOld) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() - 1);
        
        Cookies.set("paramCarteV5", JSON.stringify([]), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
    }
    if (selectedCarteCookieOld) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() - 1);
        
        Cookies.set("selectionCarte", JSON.stringify([]), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
    }
    
    const paramCarteCookie = Cookies.get("paramCarteV8");
    const selectedCarteCookie = Cookies.get("selectionCarteV1");
    const selectedCarteCookieMapId = Cookies.get("selectionCarteMapId");
    const modeExpeCarteCookie = Cookies.get("mode_expe");
    const modeCollab = Cookies.get("mode_collab");
    const modeBiblio = Cookies.get("mode_biblio");
    const modePerso = Cookies.get("mode_perso");
    
    
    const myRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    
    const same_map: boolean = selectedCarteCookieMapId ? JSON.parse(selectedCarteCookieMapId) === props.carte.ville.map_id : false;
    const [infoCase, setInfoCase] = useState(null);
    const [dimensions, setDimensions] = useState(initialDim);
    const [showCampingCalculator, setShowCampingCalculator] = useState(false);
    const [showCaseMaj, setShowCaseMaj] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [idClick, setIdClick] = useState(null);
    const [popUpClick, setPopUpClick] = useState(false);
    const [carte, setCarte] = useState(props.carte);
    const [paramCarte, setParamCarte] = useState(props.carte.carteOptionPerso ? paramCartePerso(props.carte.carteOptionPerso_alter, props.carte.carteOptionPerso_estim) : (paramCarteCookie ? JSON.parse(paramCarteCookie) : paramCarteInit));
    const [selectedArray, setSelectedArray] = useState(props.carte.carteOptionPerso ? selectOptionPerso : (selectedCarteCookie ? (same_map ? JSON.parse(selectedCarteCookie) : selectCarteInit) : selectCarteInit));
    const [modeExpe, setModeExpe] = useState(props.carte.myVille || props.carte.isLead ? (modeExpeCarteCookie ? JSON.parse(modeExpeCarteCookie) : false) : false);
    const [carteTraceManuel, setCarteTraceManuel] = useState<Array<Array<(number | null)[]>>>([]);
    const [expeManuel, setExpeManuel] = useState<Expedition>({
        id              : "",
        nom             : "",
        created_by      : props.carte.user,
        modify_by       : null,
        collab          : (carte.outilsExpe === null && modeCollab) ? JSON.parse(modeCollab) === true : false,
        personnel       : (carte.outilsExpe === null && modePerso) ? JSON.parse(modePerso) === true : false,
        jour            : props.carte.ville.jour,
        coordonnee      : [],
        couleur         : "#" + (Math.floor(Math.random() * 16777215).toString(16) + "000000").slice(0, 6),
        pa              : 0,
        biblio          : (carte.outilsExpe !== null && modeBiblio) ? JSON.parse(modeBiblio) === true : false,
        brouillon       : carte.outilsExpe !== null,
        trace_expedition: false,
    });
    const [zoneMaj, setZoneMaj] = useState(null);
    const [retourMajPopUp, setRetourMajPopUp] = useState(null);
    const [typeRetourMajPopup, setTypeRetourMajPopup] = useState(null);
    const [isOnRefresh, setIsOnRefresh] = useState(false);
    const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
    const {setStatus, setMessagePopUp, setShowPop} = usePopUp();
    const [isSelCase, setIsSelCase] = useState(null);
    const [jourActuel, setJourActuel] = useState(props.carte.ville.jour);
    const {general} = useGeneralContext();
    const apiRef = new CarteApi(0);
    const expeditionApi = new ExpeditionApi(props.carte.ville.map_id);
    const [consigneExpes, setConsigneExpes] = useState<string[]>([]);
    
    const updateCarte = () => {
        const mapId = carte.ville.map_id;
        const userId = carte.user.id;
        
        // Vérifie si une API call est en cours, pour éviter les multiples appels
        if (isOnRefresh) {
            return;
        }
        setIsOnRefresh(true);
        
        apiRef.refresh({mapId: mapId, userId: userId, outils: carte.outilsExpe !== null}).then((result) => {
            setCarte(result.data.zoneRetour);
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.data.error);
        }).finally(() => {
            // Réinitialise l'état de l'API call
            setIsOnRefresh(false);
        });
    };
    
    const callUpdateZone = (zone: ZoneMapDTO) => {
        const x = zone.x;
        const y = zone.y;
        const mapId = props.carte.ville.map_id;
        
        apiRef.popUp({x: x, y: y, mapId: mapId}).then((result) => {
            setShowCaseMaj(true);
            setZoneMaj(result.data.zoneMaj);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error.data.error);
            setShowPop(true);
        });
    };
    
    const handleClosePopUp = () => {
        setCurrentId(null); // Mettez une valeur de test pour voir si elle est appliquée
        setPopUpClick(!popUpClick); // Inversez la valeur pour tester
    };
    
    const handleInfoCaseCamping = (info: InfoCamping) => {
        setInfoCase(info);
        setShowCampingCalculator(true);
    };
    
    const handleMouseClick = (id: string): void => {
        if (!showCampingCalculator) {
            if (isSelCase) {
                setIdClick(id);
            } else if (!modeExpe) {
                setPopUpClick(true);
            } else {
                const coord = id.split("_");
                const x = parseInt(coord[0]);
                const y = parseInt(coord[1]);
                const coordonneeTrace = [...expeManuel.coordonnee];
                if (
                    coordonneeTrace.length === 0 ||
                    coordonneeTrace[coordonneeTrace.length - 1][0] !== x ||
                    coordonneeTrace[coordonneeTrace.length - 1][1] !== y
                ) {
                    let coordInter = [];
                    if (
                        coordonneeTrace.length > 0 &&
                        coordonneeTrace[coordonneeTrace.length - 1][0] !== x &&
                        coordonneeTrace[coordonneeTrace.length - 1][1] !== y
                    ) {
                        coordInter = [x, coordonneeTrace[coordonneeTrace.length - 1][1]];
                    }
                    if (coordInter.length !== 0) {
                        coordonneeTrace.push(coordInter);
                    }
                    
                    coordonneeTrace.push([x, y]);
                    const expeMod = {...expeManuel};
                    expeMod.coordonnee = coordonneeTrace;
                    tracingExpe(expeMod);
                }
            }
        }
    };
    
    const handleMouseOut = () => {
        if (!popUpClick) {
            setCurrentId(null);
        }
    };
    
    const majScrut = () => {
        // Vérifie si une API call est en cours, pour éviter les multiples appels
        if (isApiCallInProgress) {
            return;
        }
        // Mettre à jour l'état pour indiquer que l'appel est en cours
        setIsApiCallInProgress(true);
        
        apiRef.majScrut({mapId: props.carte.ville.map_id}).then((response) => {
            // si c'est ok, on informe que c'est ok et refresh de la carte, qq ms après
            if (response.data.codeRetour === 1) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(response.data.libRetour);
                window.setTimeout(() => {
                    setStatus(Status_success);
                    setShowPop(false);
                    setMessagePopUp("");
                }, 1500);
                updateCarte();
            }
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.data.error);
        }).finally(() => {
            setIsApiCallInProgress(false);
        });
    };
    
    const recupExpe = (idExpe: string) => {
        expeditionApi.edit({expe_id: idExpe}).then((result) => {
            if (result.codeRetour === 0) {
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 365);
                Cookies.set("mode_expe", JSON.stringify(true), {
                    expires: expirationDate,
                    path   : "/",
                    secure : true,
                });
                
                setModeExpe(true);
                const expedMod = {
                    ...expeManuel,
                    ...{
                        id              : idExpe,
                        nom             : result.zoneRetour.nom,
                        created_by      : result.zoneRetour.created_by,
                        modify_by       : general.user,
                        collab          : result.zoneRetour.collab,
                        personnel       : result.zoneRetour.personnel,
                        jour            : result.zoneRetour.jour,
                        pa              : result.zoneRetour.pa,
                        couleur         : result.zoneRetour.couleur,
                        coordonnee      : result.zoneRetour.coordonnee,
                        biblio          : result.zoneRetour.biblio,
                        brouillon       : result.zoneRetour.brouillon,
                        trace_expedition: result.zoneRetour.trace_expedition,
                    },
                };
                
                tracingExpe(expedMod);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
            }
        });
    };
    
    const suppExpe = (idExpe: string) => {
        expeditionApi.suptrace({expe_id: idExpe}).then((result) => {
            if (result.codeRetour === 0) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
                
                if (result.codeRetour === 0) {
                    updateCarte();
                    window.setTimeout(() => {
                        setStatus(Status_success);
                        setShowPop(false);
                        setMessagePopUp("");
                    }, 1500);
                    
                }
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
            }
        });
    };
    
    const saveExpe = (expedition: Expedition) => {
        const data = {
            mapId     : props.carte.ville.map_id,
            id        : expedition.id,
            nom       : expedition.nom,
            crea_id   : expedition.created_by.id,
            mod_id    : expedition.modify_by?.id ?? null,
            pa_expe   : expedition.pa,
            jour_expe : expedition.jour,
            collab    : expedition.collab,
            couleur   : expedition.couleur,
            coordonnee: expedition.coordonnee,
            personnel : expedition.personnel,
            biblio    : expedition.biblio,
            brouillon : expedition.brouillon,
        };
        expeditionApi.sauvegarde(data).then((result) => {
            const modeCollab = Cookies.get("mode_collab");
            
            if (result.codeRetour === 0) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
                setCarteTraceManuel([]);
                const expeManuelMod = {
                    ...expeManuel,
                    ...{
                        id              : "",
                        nom             : "",
                        created_by      : props.carte.user,
                        modify_by       : null,
                        collab          : (carte.outilsExpe === null && modeCollab) ? JSON.parse(modeCollab) === true : false,
                        personnel       : (carte.outilsExpe === null && modePerso) ? JSON.parse(modePerso) === true : false,
                        jour            : props.carte.ville.jour,
                        coordonnee      : [],
                        couleur         : "#" + (Math.floor(Math.random() * 16777215).toString(16) + "000000").slice(0, 6),
                        pa              : 0,
                        biblio          : (carte.outilsExpe !== null && modeBiblio) ? JSON.parse(modeBiblio) === true : false,
                        brouillon       : carte.outilsExpe !== null,
                        trace_expedition: false,
                    },
                };
                setExpeManuel(expeManuelMod);
                updateExpedition(expeManuelMod, false);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
            }
            
            if (result.codeRetour === 0) {
                window.setTimeout(() => {
                    setStatus(Status_success);
                    setShowPop(false);
                    setMessagePopUp("");
                }, 1000);
                updateCarte();
            }
        });
    };
    
    const tracingExpe = (expeManuelMod: Expedition) => {
        const mapId = props.carte.ville.map_id;
        
        expeditionApi
            .tracing({mapId: mapId, coord: expeManuelMod.coordonnee})
            .then((result) => {
                if (result.codeRetour === 0) {
                    setCarteTraceManuel(result.zoneRetour.trace);
                    setExpeManuel(({...expeManuelMod, ...{pa: result.zoneRetour.pa}}));
                } else {
                    setStatus(Status_error);
                    setShowPop(true);
                    setMessagePopUp(result.libRetour);
                }
            });
    };
    
    const updateDimensions = () => {
        setDimensions({
            ...dimensions,
            width : window.innerWidth,
            height: window.innerHeight,
        });
    };
    
    const updateBatiment = (zoneMaj: ZoneMapDTO) => {
        const zones = {...carte.ville.zones};
        zones[zoneMaj.y * 100 + zoneMaj.x] = zoneMaj;
        setCarte({
            ...carte,
            ...{ville: {...carte.ville, ...{zones: zones}}},
        });
    };
    
    const updateParamCarte = (paramCarte: ParamCarte) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        setParamCarte(paramCarte);
        Cookies.set("paramCarteV8", JSON.stringify(paramCarte), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
    };
    
    const updateModeExpe = (mode_expe: boolean) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        setModeExpe(mode_expe);
        Cookies.set("mode_expe", JSON.stringify(mode_expe), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
    };
    
    const updateExpedition = (expedition: Expedition, retracage: boolean) => {
        if (retracage) {
            tracingExpe(expedition);
        } else {
            setExpeManuel(expedition);
        }
    };
    
    const updateSelectionCarte = (selectedArray: SelectedArray) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        setSelectedArray(selectedArray);
        Cookies.set("selectionCarteV1", JSON.stringify(selectedArray), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
        Cookies.set(
            "selectionCarteMapId",
            JSON.stringify(props.carte.ville.map_id),
            {
                expires: expirationDate,
                path   : "/",
                secure : true,
            },
        );
    };
    
    const updateZoneMaj = (zoneMaj: ZoneMaj) => {
        const mapId = props.carte.ville.map_id;
        const userId = props.carte.user.id;
        
        apiRef.majCase({mapid: mapId, userid: userId, zone_maj: zoneMaj}).then((result) => {
            setRetourMajPopUp(result.data.libRetour);
            setTypeRetourMajPopup(0);
            
            window.setTimeout(() => {
                setRetourMajPopUp(null);
                setShowCaseMaj(false);
                setPopUpClick(false);
                setTypeRetourMajPopup(null);
            }, 1000);
            updateCarte();
        }).catch((error) => {
            setRetourMajPopUp(error.data.error);
            setTypeRetourMajPopup(1);
        });
    };
    
    const applyFiltreObjet = (isFiltred: boolean, type_filtre: number, min: number, max: number) => {
        if (isFiltred) {
            const mapId = props.carte.ville.map_id;
            const userId = props.carte.user.id;
            apiRef.filtre_objet({mapId: mapId, userId: userId, typeFiltre: type_filtre, min: min, max: max}).then((response) => {
                setCarte({
                    ...carte,
                    ...{
                        listCategorie     : response.data.listCategorie,
                        listItemsSolBroken: response.data.listItemsSolBroken,
                        ville             : {...carte.ville, zones: response.data.zones},
                    },
                });
            }).catch((error) => {
                setRetourMajPopUp(error.data.error);
                setTypeRetourMajPopup(1);
            });
        } else {
            updateCarte();
        }
    };
    
    useEffect(() => {
        setCarte(props.carte);
    }, [props.carte]);
    
    useEffect(() => {
        setParamCarte({
            ...paramCarte,
            ...{carteAlter: props.carte.carteOptionPerso_alter},
        });
    }, [props.carte.carteOptionPerso_alter]);
    
    useEffect(() => {
        setParamCarte({
            ...paramCarte,
            ...{estimZombie: props.carte.carteOptionPerso_estim},
        });
    }, [props.carte.carteOptionPerso_estim]);
    
    useEffect(() => {
        
        
        // Code pour componentDidMount
        document.dispatchEvent(new Event("reactRendered"));
        // Ajoutez un écouteur d'événement pour le redimensionnement
        window.addEventListener("resize", updateDimensions);
        
        // Fonction de nettoyage pour componentWillUnmount
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);
    
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (myRef.current && !myRef.current.contains(event.target as Node)) {
                setShowCampingCalculator(false);
            }
        };
        
        // Ajoute un écouteur d'événement au clic à l'échelle de la page lors de l'ouverture du popup
        if (showCampingCalculator) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showCampingCalculator]);
    
    
    const updateEstimDay = (estim: number) => {
        const newCarte = {...carte};
        newCarte.estim_day = estim;
        setCarte(newCarte);
    };
    const ville = carte.ville;
    const zones = ville.zones;
    const user = carte.user;
    const selectedCarte = selectedArray;
    
    const typeCarte = ville.weight < 25 && ville.weight > 10 ? "ptCarte" : "gdCarte";
    const typeVille = ville.weight > 15 || ville.weight < 10 ? "gd" : "pt";
    
    const tailleVille = carte.carteOptionPerso ? 24 : ville.height;
    let tailleCase: number;
    if (tailleVille > 15) {
        tailleCase = Math.ceil(1144 / tailleVille);
    } else {
        tailleCase = Math.ceil(780 / tailleVille);
    }
    
    
    const maxObjet = Math.min(carte.maxAlterAll, paramCarte.arrAlter.reduce((acc: number, id: string | number) => acc + carte.maxAlter[id], 0));
    //const maxObjetFinal = maxObjet < 10 ? 9 : maxObjet + 1;
    
    
    const arrayCitoyen = [];
    if (selectedCarte.cit.length !== 0 && !ville.devast) {
        selectedCarte.cit.map((id: string | number) => {
            const citoyen = ville.list_citoyen_ville[id] ?? null;
            if (citoyen !== null) {
                arrayCitoyen.push(citoyen.x + "_" + citoyen.y);
            }
        });
    }
    
    const bordHaut = [];
    for (let i = 0; i < ville.weight; i++) {
        bordHaut.push(
            <Bordure classBordure={"bordHautBasCarte"} typeCarte={typeCarte} valeur={i - ville.pos_x} key={"bord_h_" + i}/>,
        );
    }
    const bordBas = [];
    for (let i = 0; i < ville.weight; i++) {
        bordBas.push(<Bordure classBordure={"bordHautBasCarte"} typeCarte={typeCarte} valeur={i - ville.pos_x} key={"bord_b_" + i}/>);
    }
    
    const borduresHaut = (
        <React.Fragment key={"frag_bord_haut"}>
            <tr className="ligneCarte" key={"bord_haut"}>
                <td className="fondNoir coinCarte"></td>
                {bordHaut}
                <td className="fondNoir coinCarte"></td>
            </tr>
        </React.Fragment>
    );
    const borduresBas = (
        <React.Fragment key={"frag_bord_bas"}>
            <tr className="ligneCarte" key={"bord_bas"}>
                <td className="fondNoir coinCarte"></td>
                {bordBas}
                <td className="fondNoir coinCarte"></td>
            </tr>
        </React.Fragment>
    );
    
    const estimZombieSupp = Math.max(carte.ville.jour, carte.estim_day) - carte.ville.jour;
    
    const listeExpeJour = carte.listExp.filter((expedition) => {
        return (expedition.jour === jourActuel && expedition.trace_expedition === true) || expedition.trace_expedition === false;
    }).map((expedition) => {
        return expedition.id;
    });
    
    const lignes = [];
    for (let j = 0; j < ville.height; j++) {
        const cases = [];
        for (let i = 0; i < ville.weight; i++) {
            
            const zone = zones[j * 100 + i];
            const idTs = zone.x + "_" + zone.y;
            
            cases.push(
                <TooltipCaseCarte key={"casemap_" + idTs}
                                  handleMouseClick={handleMouseClick}
                                  handleClosePop={handleClosePopUp}
                                  idTs={idTs}
                                  currentId={currentId}
                                  typeCarte={typeCarte}
                                  setCurrentId={setCurrentId}
                                  onClicked={!popUpClick && !props.carte.user.user_personnalisation.pop_up_click}
                                  isCalculPosition={!showCampingCalculator && !isSelCase && !modeExpe}
                >
                    <CaseCarte idTs={idTs}
                               currentId={currentId}
                               zone={zone}
                               maxObjet={maxObjet}
                               estimZombieSupp={estimZombieSupp}
                               typeVille={typeVille}
                               typeCarte={typeCarte}
                               tailleCase={tailleCase}
                               user={user}
                               paramCarte={paramCarte}
                               selectedCarte={selectedCarte}
                               modeExpe={modeExpe}
                               carteTraceManuel={carteTraceManuel}
                               expeManuel={expeManuel}
                               consigneExpes={consigneExpes}
                               listeExpeJour={listeExpeJour} carte={carte} general={general} ville={ville}/>
                    <DetailCase
                        typeCarte={typeCarte}
                        carte={carte}
                        zone={zone}
                        onSendInfoCamping={handleInfoCaseCamping}
                        closePopUp={handleClosePopUp}
                        onSendBatiment={updateBatiment}
                        onUpdateZone={callUpdateZone}
                        onRefresh={props.onRefresh}
                    />
                </TooltipCaseCarte>
            );
        }
        
        lignes.push(
            <React.Fragment key={"ligne_" + j}>
                <tr className="ligneCarte" key={"lign_" + j}>
                    <Bordure classBordure={"bordGaucheDroiteCarte"} typeCarte={typeCarte} valeur={ville.pos_y - j} key={"bord_g_" + j}/>
                    {cases}
                    <Bordure classBordure={"bordGaucheDroiteCarte"} typeCarte={typeCarte} valeur={ville.pos_y - j} key={"bord_d_" + j}/>
                </tr>
            </React.Fragment>,
        );
    }
    
    return (
        <>
            <CarteContext.Provider value={{carte, setCarte, idClick, setIdClick, isSelCase, setIsSelCase, jourActuel, setJourActuel, consigneExpes, setConsigneExpes}}>
                <Helmet>
                    <style>{generateCSSUser(general.themeUser.user_perso_couleur)}</style>
                    <style>{generateCSSVille(ville, carte.carteOptionPerso, user, general.themeUser.user_perso_couleur)}</style>
                </Helmet>
                {carte.outilsExpe && <div id={"zone_choix_jour"}>
                    <h2>{t("Jour", {ns: "ville"})}</h2>
                    <CustomPagination jour={jourActuel} jour_max={carte.ville.jour + 3} onChoixJour={(selectedDay) => {
                        if (selectedDay !== jourActuel) {
                            // On remet à zéros les sélections des expéditions
                            const selectedArrayMod = {...selectedArray};
                            selectedArrayMod.expe = [];
                            updateSelectionCarte(selectedArrayMod);
                        }
                        setJourActuel(selectedDay);
                    }}/>
                </div>}
                {!carte.carteOptionPerso && (
                    <MenuCarte
                        carte={carte}
                        paramCarte={paramCarte}
                        mode_expe={modeExpe}
                        selectedCarteArray={selectedArray}
                        expeManuel={expeManuel}
                        onUpdateParamCarte={updateParamCarte}
                        onUpdateSelectionCarte={updateSelectionCarte}
                        onUpdateModeExpe={updateModeExpe}
                        onUpdateExpedition={updateExpedition}
                        onEditExpedition={recupExpe}
                        onMajScrut={majScrut}
                        onRefresh={updateCarte}
                        onApplyFiltre={applyFiltreObjet}
                        onSaveExpedition={saveExpe}
                        onSuppExpedition={suppExpe}
                        isApiCallInProgress={isApiCallInProgress}
                        onMajEstimDay={updateEstimDay}
                        onShowCase={(idCase) => {
                            setPopUpClick(true);
                            setCurrentId(idCase);
                        }}
                        key={"carte_menu"}
                    />
                )}
                <div id={"zoneCarte"} className={"c" + ville.weight} key={"carte_div"}>
                    <div className={general.themeUser.user_perso_couleur.carte_textured || user.theme === "vintage" ? "background_carte_sable" : "background_carte_lisse"}>
                        <div className={"background_carte_color"}>
                            <table onMouseLeave={handleMouseOut} ref={tableRef}>
                                <tbody>
                                {borduresHaut}
                                {lignes}
                                {borduresBas}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <Modal show={showCampingCalculator} onHide={() => setShowCampingCalculator(false)} centered key={"modal_camping"}>
                        {showCampingCalculator && carte.campingActif && (
                            <div id="infoCamping" ref={myRef}>
                                <Camping infoCase={infoCase}/>
                                <button onClick={() => setShowCampingCalculator(false)} type={"button"}>
                                    {t("Fermer calcul camping", {ns: "ville"})}
                                </button>
                            </div>
                        )}
                    </Modal>
                    <Modal show={showCaseMaj} onHide={() => setShowCaseMaj(false)} centered key={"modal_maj_manuel"}>
                        {showCaseMaj && (
                            <PopUpMajManuel
                                popUpMaj={props.popUpMaj}
                                zoneMaj={zoneMaj}
                                retourMajPopUp={retourMajPopUp}
                                typeRetourMajPopup={typeRetourMajPopup}
                                onCancel={() => {
                                    setShowCaseMaj(false);
                                    setRetourMajPopUp(null);
                                    setTypeRetourMajPopup(null);
                                }}
                                onSaveCase={updateZoneMaj}
                            />
                        )}
                    </Modal>
                </div>
            </CarteContext.Provider>
        </>
    );
}
