import { InfoCamping }     from "../../../types/components/ville/InfoCamping";
import { useCalculKm }     from "./calculCarte";
import { HabitantId }      from "../../../types/components/Generality/Job";
import { CitoyensDTO }     from "../../../types/models/citoyens.dto";
import { JobPrototypeDTO } from "../../../types/models/jobPrototype.dto";
import { BatPrototypeDTO } from "../../../types/models/batPrototype.dto";

export interface InfoCaseCamping {
    phare: boolean;
    nuit: boolean;
    devast: boolean;
    nbrAmelioOD: any;
    zombie: number;
    idBat: number | null;
    nbrCampMax: number;
}

export interface Campeur {
    id?: string;
    citoyen: CitoyensDTO;
    tombe: boolean;
    capuche: boolean;
    objet_camping: number | null;
    chance_camping: number;
    nb_camping: number;
}

export interface CampeurAnonyme {
    id?: string;
    job: JobPrototypeDTO;
    campeur_pro: boolean;
    capuche: boolean;
    tombe: boolean;
    objet_camping: number | null;
    chance_camping: number;
    nb_camping: number;
}

export interface InfoCaseCampingAnonyme {
    phare: boolean;
    nuit: boolean;
    devast: boolean;
    nbrAmelioOD: any;
    zombie: number;
    bonus_camping: number | null;
    nbrCampMax: number;
    km: number;
    hard: boolean;
    nbTas: number;
}

export const comparePseudo = (a: CitoyensDTO, b: CitoyensDTO): number => {
    const aPseudo = a.citoyen.pseudo;
    const bPseudo = b.citoyen.pseudo;
    
    const aValue = aPseudo.charAt(0).toUpperCase() + aPseudo.slice(1);
    const bValue = bPseudo.charAt(0).toUpperCase() + bPseudo.slice(1);
    if (aValue === bValue) {
        return 0;
    }
    
    return aValue.localeCompare(bValue);
};

export const recupMalusDistance = (km: number): number => {
    const malus_distance = {
        0 : -100,
        1 : -75,
        2 : -50,
        3 : -25,
        4 : -10,
        5 : 0,
        6 : 0,
        7 : 0,
        8 : 0,
        9 : 0,
        10: 0,
        11: 0,
        12: 5,
        13: 7,
        14: 10,
        15: 15,
        16: 20,
    };
    
    if (km > 16) {
        return 20;
    } else {
        return malus_distance[km];
    }
};

export const recupMalusCampingSuccessif = (type_ville: string, pouvoir: string, nbr: number): number => {
    const malus_camping = {
        normal: {
            noob: {
                0 : 80,
                1 : 60,
                2 : 35,
                3 : 15,
                4 : 0,
                5 : -50,
                6 : -100,
                7 : -200,
                8 : -400,
                9 : -1000,
                10: -2000,
                11: -5000,
                12: -5000,
                13: -5000,
            },
            pro : {
                0 : 80,
                1 : 70,
                2 : 60,
                3 : 40,
                4 : 30,
                5 : 20,
                6 : 0,
                7 : -50,
                8 : -100,
                9 : -200,
                10: -400,
                11: -1000,
                12: -2000,
                13: -5000,
            },
        },
        pande : {
            noob: {
                0 : 50,
                1 : 30,
                2 : 20,
                3 : 10,
                4 : 0,
                5 : -50,
                6 : -100,
                7 : -200,
                8 : -400,
                9 : -1000,
                10: -2000,
                11: -5000,
                12: -5000,
                13: -5000,
            },
            pro : {
                0 : 50,
                1 : 45,
                2 : 40,
                3 : 30,
                4 : 20,
                5 : 10,
                6 : 0,
                7 : -50,
                8 : -100,
                9 : -200,
                10: -400,
                11: -1000,
                12: -2000,
                13: -5000,
            },
        },
    };
    
    if (nbr > 13) {
        nbr = 13;
    }
    
    return malus_camping[type_ville][pouvoir][nbr];
};

export const recupMaluseNombreCampeur = (nbr_campeur: number): number => {
    const malus_campeur = {
        1: 0,
        2: 0,
        3: -10,
        4: -30,
        5: -50,
        6: -70,
    };
    
    if (nbr_campeur > 6) {
        return -70;
    } else {
        return malus_campeur[nbr_campeur];
    }
};

export const calculMalusZombie = (nbr_zombie: number, job: number, capuche: boolean): number => {
    return -((job === 4 && capuche) ? 3 : 7) * nbr_zombie;
};

export const calculChanceCamping = (infoCamping: InfoCamping, infoCase: InfoCaseCamping, campeur: Campeur, position: number): number => {
    let malusVille = 0;
    let typeVille = "normal";
    if (infoCamping.hard) {
        typeVille = "pande";
        malusVille = -30;
    }
    let pouvoir = "noob";
    if ((campeur?.citoyen?.citoyen?.der_pouv?.ordre_recup ?? 0) >= 25 && campeur?.citoyen?.job?.id !== HabitantId) {
        pouvoir = "pro";
    }
    
    let bonusBat = -25;
    
    if (infoCase.idBat !== null && (position <= infoCase.nbrCampMax || infoCase.nbrCampMax === 0)) {
        bonusBat = infoCamping.listBat.find(
            (bat: BatPrototypeDTO) => bat.id === infoCase.idBat,
        ).bonus_camping;
    }
    
    const maxChance = campeur.citoyen.job.id === 6 ? 100 : 90;
    const malusDistance = recupMalusDistance(
        useCalculKm(
            infoCamping.x,
            infoCamping.y,
            infoCamping.posX,
            infoCamping.posY,
        ),
    );
    const malusCamping = recupMalusCampingSuccessif(
        typeVille,
        pouvoir,
        campeur.nb_camping,
    );
    const malusCampeur = recupMaluseNombreCampeur(position);
    const malusZombie = calculMalusZombie(infoCase.zombie, campeur.citoyen.job.id, campeur.capuche);
    const bonusNuit = infoCase.nuit ? 10 : 0;
    const bonusPhare = infoCase.phare ? 25 : 0;
    const bonusTombe = campeur.tombe ? 8 : 0;
    const bonusOdc = campeur.objet_camping * 5;
    const malusDevast = infoCase.devast ? -50 : 0;
    const amelioration = (infoCase.nbrAmelioOD === "" ? 0 : infoCase.nbrAmelioOD) * 5;
    
    const sommeBonusMalus =
              malusDistance +
              malusCamping +
              malusCampeur +
              malusZombie +
              bonusNuit +
              bonusPhare +
              bonusTombe +
              bonusOdc +
              malusDevast +
              amelioration +
              malusVille +
              bonusBat;
    
    return Math.min(maxChance, sommeBonusMalus);
};

export const calculChanceCampingAnonyme = (infoCase: InfoCaseCampingAnonyme, campeur: CampeurAnonyme, position: number): number => {
    let malusVille = 0;
    let typeVille = "normal";
    if (infoCase.hard) {
        typeVille = "pande";
        malusVille = -30;
    }
    const pouvoir = (campeur.campeur_pro && campeur.job?.id !== HabitantId) ? "pro" : "noob";
    
    let bonusBat = -25;
    
    if (position <= infoCase.nbrCampMax || infoCase.nbrCampMax === 0) {
        bonusBat = infoCase.bonus_camping;
    }
    
    const maxChance = campeur.job.id === 6 ? 100 : 90;
    const malusDistance = recupMalusDistance(infoCase.km);
    const malusCamping = recupMalusCampingSuccessif(typeVille, pouvoir, campeur.nb_camping);
    const malusCampeur = recupMaluseNombreCampeur(position);
    const malusZombie = calculMalusZombie(infoCase.zombie, campeur.job.id, campeur.capuche);
    const bonusNuit = infoCase.nuit ? 10 : 0;
    const bonusPhare = infoCase.phare ? 25 : 0;
    const bonusTombe = campeur.tombe ? 8 : 0;
    const bonusOdc = campeur.objet_camping * 5;
    const malusDevast = infoCase.devast ? -50 : 0;
    const amelioration =
              (infoCase.nbrAmelioOD === "" ? 0 : infoCase.nbrAmelioOD) * 5;
    
    const sommeBonusMalus =
              malusDistance +
              malusCamping +
              malusCampeur +
              malusZombie +
              bonusNuit +
              bonusPhare +
              bonusTombe +
              bonusOdc +
              malusDevast +
              amelioration +
              malusVille +
              bonusBat;
    
    return Math.min(maxChance, sommeBonusMalus);
};
