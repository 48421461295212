import * as React                                                                                                                                                                              from "react";
import { ArrayInput, Create, Datagrid, Edit, EditButton, List, NumberField, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextField, TextInput, useRecordContext } from "react-admin";
import { Status_error, Status_success, usePopUp }                                                                                                                                              from "../../types/Context/PopUpContext";
import { AdminApi }                                                                                                                                                                            from "../../services/api/AdminApi";


const MajJson = () => {
    const adminApi = new AdminApi();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const handleUpdate = () => {
        adminApi.maj_heros_json().then((response) => {
            setStatus(Status_success);
            setMessagePopUp(response.data.libRetour);
            setShowPop(true);
            setTimeout(() => {
                setShowPop(false);
                setMessagePopUp("");
            }, 1000);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error?.message ?? "Erreur inconnue");
            setShowPop(true);
        });
    };
    return (
        <button className={"btn btn-primary"} onClick={handleUpdate}>
            Mettre à jour le fichier JSON
        </button>
    );
};

export const HerosSkillList = (props) => {
    const postFilters = [
        <TextInput source="q" label="Search" alwaysOn key={"search_heros"} />,
        <ReferenceInput source="id" label="HerosSkill" reference="herosskill" key={"search_herosskill_id"} />,
    ];
    
    return <>
        <MajJson />
        <List {...props} filters={postFilters}>
            <Datagrid>
                <NumberField source="id" />
                <TextField source="name" />
                <EditButton />
            </Datagrid>
        </List>
    
    </>;
};

export const HerosSkillEdit = (props) => {
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition de {record ? `"${record.name}"` : ""}</span>;
    };
    
    return (<Edit {...props} title={<PostTitle />}>
        <SimpleForm>
            <div id={"chantier_form_prototype"}>
                <NumberInput source={"id"} label={"Id"} disabled={true} />
                <TextInput source={"name"} label={"Name"} />
            </div>
            <div id={"chantier_form_prototype_ressources"}>
                <ArrayInput name={"level"} source={"level"}>
                    <SimpleFormIterator>
                        <div className={"d-flex gap-1"}>
                            <NumberInput source={"id"} label={"Id"} disabled={true} />
                            <TextInput source={"name"} label={"Name"} />
                            <NumberInput source={"xp"} label={"Xp nécessaire"} />
                        </div>
                        <ArrayInput name={"pouvoir"} source={"pouvoir"}>
                            <SimpleFormIterator inline>
                                <NumberInput source={"id"} label={"Id"} disabled={true} />
                                <TextInput source={"text"} label={"Texte"} multiline />
                                <NumberInput source={"value"} label={"Valeur 1"} />
                                <NumberInput source={"value2"} label={"Valeur 2"} />
                                <ReferenceInput source={"heros"} reference={"heros"} label={"Pouvoir héros associé"}>
                                    <SelectInput source={"heros"}
                                                 optionText={"nom"}
                                                 optionValue={"id"}
                                                 parse={(value) => ({ id: value })}
                                                 format={(value) => value?.id}
                                    />
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </div>
        </SimpleForm>
    </Edit>);
};

export const HerosSkillCreate = (props) => {
    
    return (<Create {...props} title="Créer un nouveau skill" resource={"herosskill"} redirect={"admin/herosskill"}>
        <SimpleForm>
            <div id={"chantier_form_prototype"}>
                <NumberInput source={"id"} label={"Id"} disabled={true} />
                <TextInput source={"name"} label={"Name"} />
            </div>
            <div id={"chantier_form_prototype_ressources"}>
                <ArrayInput source={"level"}>
                    <SimpleFormIterator>
                        <div className={"d-flex gap-1"}>
                            <NumberInput source={"id"} label={"Id"} disabled={true} />
                            <TextInput source={"name"} label={"Name"} />
                            <NumberInput source={"xp"} label={"Xp nécessaire"} />
                        </div>
                        <ArrayInput name={"pouvoir"} source={"pouvoir"}>
                            <SimpleFormIterator inline>
                                <NumberInput source={"id"} label={"Id"} disabled={true} />
                                <TextInput source={"text"} label={"Texte"} multiline />
                                <NumberInput source={"value"} label={"Valeur 1"} />
                                <NumberInput source={"value2"} label={"Valeur 2"} />
                                <ReferenceInput source={"heros"} reference={"heros"} label={"Pouvoir héros associé"}>
                                    <SelectInput source={"heros"}
                                                 optionText={"nom"}
                                                 optionValue={"id"}
                                                 parse={(value) => ({ id: value })}
                                                 format={(value) => value?.id}
                                    />
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </div>
        </SimpleForm>
    </Create>);
};