import React, { useEffect, useState } from "react";
import BanniereJump                   from "./BanniereJump";
import Form                           from "react-bootstrap/Form";
import SvgIcone                       from "../../components/generality/SvgIcone";
import { v4 as uuid }                 from "uuid";
import { FormEventType }              from "../../types/components/Jump/JumpEventFormType";
import SvgDrapeau                     from "../../components/generality/SvgDrapeau";
import { EventDTO }                   from "../../types/models/event.dto";
import { JumpDTO }                    from "../../types/models/jump.dto";
import { useTranslation }             from "react-i18next";
import EditorComponent                from "../../components/editor/editor";
import Select, { components }         from "react-select";
import { JobPrototypeDTO }            from "../../types/models/jobPrototype.dto";
import HTMLParser                     from "html-react-parser";

const { SingleValue, Option } = components;

const IconSingleValue = (props: any) => {
    const { t } = useTranslation();
    return <SingleValue {...props}>
        <SvgIcone icone={"h_" + props.data.icon} />
        <span>{t(props.data.alternatif, { ns: "jump" })}</span>
    </SingleValue>;
};

const IconOption = (props: any) => {
    const { t } = useTranslation();
    return <Option {...props}>
        <SvgIcone icone={"h_" + props.data.icon} />
        <span>{t(props.data.alternatif, { ns: "jump" })}</span>
    </Option>;
};

const customStyles = {
    option           : (provided: any) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    singleValue      : (provided: any) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    control          : (provided: any) => ({
        ...provided,
        minHeight: "30px",
        height   : "30px",
        minWidth : "170px",
        maxwidth : "200px",
    }),
    input            : (provided: any) => ({
        ...provided,
        gridTemplateColumns: "none",
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: "0",
    }),
    valueContainer   : (provided: any) => ({
        ...provided,
        padding: "0px 8px",
    }),
    menu             : (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
    menuList         : (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
    menuPortal       : (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
};

export default function FormEvent({ formEventProps, onRetour, onChange, isArchMode = false }: {
    formEventProps: FormEventType,
    onRetour: (isError: boolean, showMod: boolean, messagePop: string) => void,
    onChange: (eventMod: EventDTO) => void,
    isArchMode?: boolean
}) {
    const { t } = useTranslation();
    const options = formEventProps.options;
    const user = formEventProps.user;
    
    const [eventJump, setEventJump] = useState(formEventProps.event);
    
    useEffect(() => {
        setEventJump(formEventProps.event);
    }, [formEventProps.event]);
    
    function formatDateTimeLocal(inputDate) {
        // Récupère uniquement la date et l'heure, sans les secondes ni le fuseau horaire
        return inputDate.split(".")[0].slice(0, 16);
    }
    
    const handleEventChange = (updatedProperties: Partial<EventDTO>) => {
        const eventMod = { ...eventJump, ...updatedProperties };
        onChange(eventMod);
        setEventJump(eventMod);
        
    };
    
    const handleBanniereJump = (urlBanniere: string, isErrorBan: boolean, showModBan: boolean, messagePopBan: string) => {
        if (isErrorBan) {
            onRetour(true, showModBan, messagePopBan);
        } else {
            handleEventChange({ banniere: urlBanniere });
            onRetour(false, showModBan, messagePopBan);
            
            setTimeout(() => {
                onRetour(false, false, "");
            }, 1500);
        }
    };
    
    const handleTypeVilleChange = (selectedTypeVilleId: number) => {
        // Trouvez le typeVille correspondant dans la liste
        const selectedTypeVille = options.listTypeVille.find(typeVille => typeVille.id === selectedTypeVilleId);
        
        setEventJump(prevEvent => ({
            ...prevEvent,
            type_ville: selectedTypeVille,
        }));
    };
    
    const handleCreationJump = () => {
        const listJump = eventJump.list_jump;
        const newJump: JumpDTO = {
            id          : uuid(),
            nom         : "",
            job_specific: false,
            job         : [],
        };
        
        handleEventChange({ list_jump: [...listJump, newJump] });
        
    };
    
    const onChangeJumpInEvent = (index: number, updatedProperties: Partial<JumpDTO>) => {
        const listJump = [...eventJump.list_jump];
        const jumpMod = { ...listJump[index], ...updatedProperties };
        listJump[index] = jumpMod;
        
        handleEventChange({ list_jump: listJump });
        
    };
    
    const onSuppJumpInEvent = (id: string) => {
        
        const listJump = eventJump.list_jump.filter(jump => jump.id !== id);
        
        handleEventChange({ list_jump: listJump });
        
    };
    
    const handleCommuVilleChange = (selectedCommu: string) => {
        // Trouvez le typeVille correspondant dans la liste
        handleEventChange({ community: selectedCommu });
    };
    
    return <div id="zone_event">
        <div id="formEvent" className="fondWhite02">
            <div className="simpleForm">
                <div id="nomEventCreation">
                    <label htmlFor={"event_nom"}>{t("Nom de l'event :", { ns: "jumpEvent" })}</label>
                    <input id={"event_nom"} name={"event_nom"} type={"text"} value={eventJump.nom} disabled={isArchMode}
                           onChange={(e) => handleEventChange({ nom: e.target.value })} />
                </div>
            </div>
            <p className="label_banniere_creation">{t("Bannière :", { ns: "jumpEvent" })}</p>
            <div className="simpleForm">
                <div id="banniereEventCreation">
                    <BanniereJump typeCreation={"event"} urlImage={eventJump.banniere} onRetour={handleBanniereJump} disabled={isArchMode} />
                </div>
            </div>
            <div className="simpleForm">
                <div id="textAreaEventCreation">
                    <span>{t("Descriptions :", { ns: "jumpEvent" })}</span>
                    <div className={"inscription_bloc_text"}>
                        {isArchMode ? HTMLParser(eventJump.description ?? "") : <EditorComponent width={941} data={eventJump.description ?? ""} lang={user.lang} onTextChange={(text) => {
                            handleEventChange({ description: text });
                        }} />}
                    </div>
                </div>
            </div>
            <div className="doubleForm">
                <div className="dateEventCreation">
                    <label htmlFor={"event_debInscriptionDateAt"}>{t("Date de début d'inscription :", { ns: "jumpEvent" })}</label>
                    <input id={"event_debInscriptionDateAt"} name={"event_debInscriptionDateAt"} type={"datetime-local"}
                           value={formatDateTimeLocal(eventJump.deb_inscription_date_at)}
                           disabled={isArchMode}
                           onChange={(e) => handleEventChange({ deb_inscription_date_at: e.target.value })} />
                </div>
                <div className="dateEventCreation">
                    <label htmlFor={"event_dateFinInscription"}>{t("Date de fin d'inscription :", { ns: "jumpEvent" })}</label>
                    <input id={"event_dateFinInscription"} name={"event_dateFinInscription"} type={"datetime-local"}
                           value={formatDateTimeLocal(eventJump.fin_inscription_date_at)}
                           disabled={isArchMode}
                           onChange={(e) => handleEventChange({ fin_inscription_date_at: e.target.value })} />
                </div>
            </div>
            <div className={"doubleForm"}>
                <div className="dateEventCreation">
                    <label htmlFor={"event_eventBeginAt"}>{t("Date de début de l'event :", { ns: "jumpEvent" })}</label>
                    <input id={"event_eventBeginAt"} name={"event_dateApproxJump"} type={"datetime-local"} value={formatDateTimeLocal(eventJump.event_begin_at)}
                           disabled={isArchMode}
                           onChange={(e) => handleEventChange({ event_begin_at: e.target.value })} />
                </div>
            </div>
            <div className="simpleForm">
                <div id={"event_one_metier"}>
                    <label htmlFor={"event_one_metier_input"}>{t("Inscription avec un seul métier :", { ns: "jumpEvent" })}</label>
                    <Form.Check
                        name={"event_one_metier_input"}
                        id={"event_one_metier_input"}
                        type="switch"
                        checked={eventJump.one_metier}
                        disabled={isArchMode}
                        onChange={(event) => handleEventChange({ one_metier: event.target.checked })}
                    />
                </div>
            </div>
            <div className="doubleForm">
                <div id="typeEventCreation">
                    <label className="required">{t("Type de ville :", { ns: "jumpEvent" })}</label>
                    <div id="event_typeVille">
                        {options.listTypeVille.map((typeVille) => {
                            return <React.Fragment key={"type_ville_" + typeVille.id}>
                                <input type={"radio"}
                                       name={"type_ville_" + typeVille.id}
                                       value={typeVille.id}
                                       disabled={isArchMode}
                                       id={"type_ville_" + typeVille.id}
                                       checked={eventJump.type_ville?.id === typeVille.id}
                                       onChange={() => handleTypeVilleChange(typeVille.id)}
                                />
                                <label htmlFor={"type_ville_" + typeVille.id} className={"d-flex gap-1 align-items-center"}><SvgIcone icone={typeVille.icon} /><span>{t(typeVille.abrev, { ns: "game" })}</span></label>
                            </React.Fragment>;
                        })}
                    </div>
                </div>
                <div id="typeVilleEventCreation">
                    <label htmlFor="event_eventPrived">{t("Event en ville privée :", { ns: "jumpEvent" })}</label>
                    <Form.Check
                        name={"event_eventPrived"}
                        id={"event_eventPrived"}
                        type="switch"
                        disabled={isArchMode}
                        checked={eventJump.event_prived}
                        onChange={(event) => handleEventChange({ event_prived: event.target.checked })}
                    />
                </div>
            </div>
            <div className="doubleForm">
                <div id="communityJumpCreation">
                    <label className="required">{t("Communauté :", { ns: "jumpEvent" })}</label>
                    <div id="creation_communityVille_event">
                        {options.listCommu.map((commu) => {
                            return <div key={"type_jump_ville_" + commu}>
                                <input type={"radio"}
                                       name={"type_jump_ville_" + commu}
                                       value={commu}
                                       id={"type_jump_ville_" + commu}
                                       checked={eventJump.community === commu}
                                       disabled={isArchMode}
                                       onChange={() => handleCommuVilleChange(commu)}
                                />
                                <label htmlFor={"type_ville_" + commu}><SvgDrapeau drapeau={commu} /></label>
                            </div>;
                        })}
                    </div>
                </div>
            </div>
            <fieldset id={"fieldset_event_list_jump"}>
                <legend>{t("Listes des villes associés à l'event", { ns: "jumpEvent" })}</legend>
                <div id="form_jumps">
                    <table>
                        <thead>
                        <tr>
                            <th>{t("Nom de la ville (jump)", { ns: "jumpEvent" })}</th>
                            <th>{t("Job spécifique", { ns: "jumpEvent" })}</th>
                            <th>{t("Métier spécifique", { ns: "jumpEvent" })}</th>
                            <th>{t("Action", { ns: "jumpEvent" })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {eventJump.list_jump.map((ville, index) => {
                            return <tr key={"event_ville_" + ville.id}>
                                <td>
                                    <input type={"text"} value={ville.nom} onChange={(e) => onChangeJumpInEvent(index, { nom: e.target.value })} disabled={isArchMode} />
                                </td>
                                <td>
                                    <Form.Check
                                        name={"jump_job_specific_" + ville.id}
                                        id={"jump_job_specific_" + ville.id}
                                        type="switch"
                                        disabled={isArchMode}
                                        checked={ville.job_specific}
                                        onChange={(e) => onChangeJumpInEvent(index, { job_specific: e.target.checked })}
                                    />
                                </td>
                                <td>
                                    {ville.job_specific && <Select value={ville.job[0] ?? null}
                                                                   id={"jump_job"}
                                                                   name={"jump_job"}
                                                                   options={options.listJob}
                                                                   components={{ SingleValue: IconSingleValue, Option: IconOption }}
                                                                   getOptionLabel={(job) => job.alternatif}
                                                                   isDisabled={isArchMode}
                                                                   onChange={(optionSelected: JobPrototypeDTO) => onChangeJumpInEvent(index, { job: [optionSelected] })}
                                                                   styles={customStyles} />}
                                </td>
                                <td>
                                    {!isArchMode && <button type={"button"} className={"color-red"} onClick={() => onSuppJumpInEvent(ville.id)}><i className="fa-solid fa-trash"></i></button>}
                                </td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </div>
                {!isArchMode && <div id={"form_event_button_add_jump"}>
                    <button type="button" className="add_jump_link btn btn-warning btn-sm" onClick={handleCreationJump}>{t("Ajouter une ville", { ns: "jumpEvent" })}</button>
                </div>}
            </fieldset>
        </div>
    </div>;
    
    
}