import React, {useEffect, useRef, useState}     from "react";
import {BaseThemeList, ColorThemeConfigExport}  from "../../types/components/OptionsPerso/OptionsPersoType";
import ColorPicker                              from "../../components/generality/ColorPickerGH";
import {Chart, registerables}                   from "chart.js";
import {OptionPersoApi}                         from "../../services/api/OptionPersoApi";
import {useGeneralContext}                      from "../../types/Context/GeneralContext";
import {Status_error, Status_success, usePopUp} from "../../types/Context/PopUpContext";
import {UserDTO}                                from "../../types/models/user.dto";
import {useTranslation}                         from "react-i18next";
import {ThemeUserDTO}                           from "../../types/models/themeUser.dto";
import {useOptionPerso}                         from "../../services/hook/useOptionPerso";
import {useGHContext}                           from "../../types/Context/GHContext";
import TooltipGH                                from "../../components/utils/TooltipGH";


export default function GestionTheme(props: {
    user: UserDTO, baseThemeList: BaseThemeList, themeName: string[], onMajUser: (user: UserDTO) => void
}) {
    const chartExemple = useRef<HTMLCanvasElement>(null);
    const baseThemeList = props.baseThemeList;
    const themeName = props.themeName;
    const [user, setUser] = useState<UserDTO>(props.user);
    const [themeUser, setThemeUser] = useState<ThemeUserDTO>(null);
    const {setStatus, setMessagePopUp, setShowPop, handleClose} = usePopUp();
    const {setGeneral} = useGeneralContext();
    const {triggerRefresh} = useGHContext();
    const {t} = useTranslation();
    const [isCopy, setIsCopy] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isErrorCopy, setIsErrorCopy] = useState(false);
    const [isErrorImport, setIsErrorImport] = useState(false);
    const [exportColor, setExportColor] = useState("");
    const {colorWithAlpha, validateThemeConfig} = useOptionPerso();
    
    
    useEffect(() => {
        setUser(props.user);
    }, [props.user]);
    
    const modificationThemeUser = (idTheme: number) => {
        const theme = user.themes_user.find(theme => theme.id === idTheme);
        if (theme !== undefined) {
            setThemeUser(theme);
        }
    };
    
    const annulerThemeUser = () => {
        setThemeUser(null);
    };
    
    const handleThemeUserChange = (updatedProperties: Partial<ThemeUserDTO>) => {
        const themeUserMod = {...themeUser, ...updatedProperties};
        setThemeUser(themeUserMod);
    };
    
    const handleCreateThemeUser = () => {
        const newThemeUser: ThemeUserDTO = {
            id                  : null,
            nom                 : "",
            base_theme          : "perso",
            background_color    : baseThemeList.perso.backgroundColor,
            primary_border_color: baseThemeList.perso.primaryBorderColor,
            primary_color       : baseThemeList.perso.primaryColor,
            secondary_color     : baseThemeList.perso.secondaryColor,
            tertiary_color      : baseThemeList.perso.tertiaryColor,
            primary_row_color   : baseThemeList.perso.primaryRowColor,
            secondary_row_color : baseThemeList.perso.secondaryRowColor,
            primary_font_color  : baseThemeList.perso.primaryFontColor,
            tertiary_font_color : baseThemeList.perso.tertiaryFontColor,
            my_line_color       : baseThemeList.perso.myLineColor,
            stats_bg_color      : baseThemeList.perso.statsBgColor,
            stats_font_color    : baseThemeList.perso.statsFontColor,
            stats_border_color  : baseThemeList.perso.statsBorderColor,
            hover_font_color    : baseThemeList.perso.hoverFontColor,
            bg_hover_color      : baseThemeList.perso.bgHoverColor,
            succes_color        : baseThemeList.perso.successColor,
            error_color         : baseThemeList.perso.errorColor,
            specifique_color    : baseThemeList.perso.specifiqueColor,
            user_perso_couleur  : baseThemeList.perso.perso,
        };
        
        setThemeUser(newThemeUser);
    };
    
    const handlePreAlimThemeUser = (themeBase: string, forTheme: string) => {
        const newThemeUser: ThemeUserDTO = {
            id                  : null,
            nom                 : baseThemeList[themeBase].nom,
            base_theme          : forTheme,
            background_color    : baseThemeList[themeBase].backgroundColor,
            primary_border_color: baseThemeList[themeBase].primaryBorderColor,
            primary_color       : baseThemeList[themeBase].primaryColor,
            secondary_color     : baseThemeList[themeBase].secondaryColor,
            tertiary_color      : baseThemeList[themeBase].tertiaryColor,
            primary_row_color   : baseThemeList[themeBase].primaryRowColor,
            secondary_row_color : baseThemeList[themeBase].secondaryRowColor,
            primary_font_color  : baseThemeList[themeBase].primaryFontColor,
            tertiary_font_color : baseThemeList[themeBase].tertiaryFontColor,
            my_line_color       : baseThemeList[themeBase].myLineColor,
            stats_bg_color      : baseThemeList[themeBase].statsBgColor,
            stats_font_color    : baseThemeList[themeBase].statsFontColor,
            stats_border_color  : baseThemeList[themeBase].statsBorderColor,
            hover_font_color    : baseThemeList[themeBase].hoverFontColor,
            bg_hover_color      : baseThemeList[themeBase].bgHoverColor,
            succes_color        : baseThemeList[themeBase].successColor,
            error_color         : baseThemeList[themeBase].errorColor,
            specifique_color    : baseThemeList[themeBase].specifiqueColor,
        };
        
        setThemeUser(newThemeUser);
    };
    
    const saveThemeUser = (withSelect: boolean) => {
        const optionPersoApi = new OptionPersoApi();
        
        optionPersoApi.majThemeUser({userId: user.id, themeUser: themeUser, themeToSelect: withSelect}).then(response => {
            props.onMajUser(response.data.user);
            setStatus(Status_success);
            setShowPop(true);
            setMessagePopUp(response.data.libRetour);
            setGeneral(response.data.general);
            setTimeout(() => {
                handleClose();
                setThemeUser(null);
                triggerRefresh();
            }, 1000);
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.data.error);
        });
    };
    
    const suppThemeUser = (themeId: number) => {
        const optionPersoApi = new OptionPersoApi();
        
        optionPersoApi.suppThemeUser({userId: user.id, themeUserId: themeId}).then(response => {
            props.onMajUser(response.data.user);
            setStatus(Status_success);
            setShowPop(true);
            setMessagePopUp(response.data.libRetour);
            setGeneral(response.data.general);
            setTimeout(() => {
                handleClose();
            }, 1000);
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.data.error);
        });
    };
    
    
    const handleExportCouleur = async () => {
        try {
            const couleurExport: ColorThemeConfigExport = {
                nom                    : themeUser.nom,
                couleurStats           : themeUser.stats_bg_color,
                couleurBordure         : themeUser.primary_border_color,
                couleurFond            : themeUser.background_color,
                couleurLigne           : themeUser.my_line_color,
                couleurPrincipale      : themeUser.primary_color,
                couleurSecondaire      : themeUser.secondary_color,
                couleurTertiaire       : themeUser.tertiary_color,
                couleurHover           : themeUser.hover_font_color,
                couleurPolicePrincipale: themeUser.primary_font_color,
                couleurPoliceSecondaire: themeUser.tertiary_font_color,
                couleurLignePrincipale : themeUser.primary_row_color,
                couleurLigneSecondaire : themeUser.secondary_row_color,
                baseTheme              : themeUser.base_theme,
                couleurPoliceStats     : themeUser.stats_font_color,
                couleurBordureStats    : themeUser.stats_border_color,
                couleurFondHover       : themeUser.bg_hover_color,
                couleurSpecifique      : themeUser.specifique_color,
                couleurSucces          : themeUser.succes_color,
                couleurErreur          : themeUser.error_color,
                couleurPerso           : {
                    couleurScrut            : themeUser.user_perso_couleur.couleur_scrut,
                    couleurKm               : themeUser.user_perso_couleur.couleur_km,
                    couleurPos              : themeUser.user_perso_couleur.couleur_pos,
                    couleurBat              : themeUser.user_perso_couleur.couleur_bat,
                    couleurSelectObj        : themeUser.user_perso_couleur.couleur_select_obj,
                    couleurSelectBat        : themeUser.user_perso_couleur.couleur_select_bat,
                    couleurSelectCit        : themeUser.user_perso_couleur.couleur_select_cit,
                    couleurVueAuj           : themeUser.user_perso_couleur.couleur_vue_auj,
                    couleurVue24            : themeUser.user_perso_couleur.couleur_vue24,
                    couleurVue48            : themeUser.user_perso_couleur.couleur_vue48,
                    couleurPA               : themeUser.user_perso_couleur.couleur_p_a,
                    couleurZone             : themeUser.user_perso_couleur.couleur_zone,
                    colorSelExp             : themeUser.user_perso_couleur.color_sel_exp,
                    couleurSelCaseMaj       : themeUser.user_perso_couleur.couleur_sel_case_maj,
                    couleurDanger0          : themeUser.user_perso_couleur.couleur_danger0,
                    couleurDanger1          : themeUser.user_perso_couleur.couleur_danger1,
                    couleurDanger2          : themeUser.user_perso_couleur.couleur_danger2,
                    couleurDanger3          : themeUser.user_perso_couleur.couleur_danger3,
                    couleurControleOk       : themeUser.user_perso_couleur.couleur_controle_ok,
                    couleurContoleNok       : themeUser.user_perso_couleur.couleur_contole_nok,
                    couleurEpuise           : themeUser.user_perso_couleur.couleur_epuise,
                    couleurCarte            : themeUser.user_perso_couleur.couleur_carte,
                    couleurZombie           : themeUser.user_perso_couleur.color_zombie,
                    couleurEstimZombie      : themeUser.user_perso_couleur.color_estimation_zombie,
                    couleurFlag             : themeUser.user_perso_couleur.color_flag,
                    couleurFlagFinish       : themeUser.user_perso_couleur.color_flag_finish,
                    couleurExpeInscrit      : themeUser.user_perso_couleur.color_my_exp,
                    couleurTown             : themeUser.user_perso_couleur.color_town,
                    couleurCity             : themeUser.user_perso_couleur.color_city,
                    couleurNonVu            : themeUser.user_perso_couleur.color_non_vu,
                    colorBatEpuise          : themeUser.user_perso_couleur.color_bat_epuise,
                    couleurNbrCitoyen       : themeUser.user_perso_couleur.couleur_nbr_citoyen,
                    couleurNbrItemsSol      : themeUser.user_perso_couleur.couleur_nbr_items_sol,
                    carteTextured           : themeUser.user_perso_couleur.carte_textured.toString(),
                    colorMap                : themeUser.user_perso_couleur.colormap,
                    alphaColorMap           : themeUser.user_perso_couleur.alpha_colormap.toString(),
                    colorStairUp            : themeUser.user_perso_couleur.color_stair_up,
                    colorStairDown          : themeUser.user_perso_couleur.color_stair_down,
                    colorSelectObjet        : themeUser.user_perso_couleur.color_select_objet_ruine,
                    colorDispo1             : themeUser.user_perso_couleur.color_dispo1,
                    colorDispo2             : themeUser.user_perso_couleur.color_dispo2,
                    colorDispo3             : themeUser.user_perso_couleur.color_dispo3,
                    colorVilleCommune       : themeUser.user_perso_couleur.color_ville_commune,
                    couleurChantierConstruit: themeUser.user_perso_couleur.couleur_chantier_construit,
                    couleurChantierRepa     : themeUser.user_perso_couleur.couleur_chantier_a_repa,
                    couleurChantierEnConst  : themeUser.user_perso_couleur.couleur_chantier_en_construction,
                    couleurChantierDispo    : themeUser.user_perso_couleur.couleur_chantier_dispo,
                    couleurPlanManquant     : themeUser.user_perso_couleur.couleur_plan_manquant,
                    couleurMyExpe           : themeUser.user_perso_couleur.color_my_exp,
                    colorMyExpe             : themeUser.user_perso_couleur.my_color_expe,
                },
            };
            const textExport = JSON.stringify(couleurExport);
            setExportColor(textExport);
            
            await navigator.clipboard.writeText(textExport);
            setIsCopy(true);
            setTimeout(() => setIsCopy(false), 1500);
        } catch (error) {
            setIsErrorCopy(true);
        }
    };
    
    const handleImportCouleur = async () => {
        const couleur: ColorThemeConfigExport = JSON.parse(exportColor);
        if (validateThemeConfig(couleur)) {
            // On modifie le thème en cours
            const themeUserMod = {...themeUser};
            themeUserMod.nom = couleur.nom;
            themeUserMod.stats_bg_color = colorWithAlpha(couleur.couleurStats);
            themeUserMod.primary_border_color = colorWithAlpha(couleur.couleurBordure);
            themeUserMod.background_color = colorWithAlpha(couleur.couleurFond);
            themeUserMod.my_line_color = colorWithAlpha(couleur.couleurLigne);
            themeUserMod.primary_color = colorWithAlpha(couleur.couleurPrincipale);
            themeUserMod.secondary_color = colorWithAlpha(couleur.couleurSecondaire);
            themeUserMod.tertiary_color = colorWithAlpha(couleur.couleurTertiaire);
            themeUserMod.hover_font_color = colorWithAlpha(couleur.couleurHover);
            themeUserMod.primary_font_color = colorWithAlpha(couleur.couleurPolicePrincipale);
            themeUserMod.tertiary_font_color = colorWithAlpha(couleur.couleurPoliceSecondaire);
            themeUserMod.primary_row_color = colorWithAlpha(couleur.couleurLignePrincipale);
            themeUserMod.secondary_row_color = colorWithAlpha(couleur.couleurLigneSecondaire);
            themeUserMod.base_theme = couleur.baseTheme;
            themeUserMod.stats_font_color = colorWithAlpha(couleur.couleurPoliceStats);
            themeUserMod.stats_border_color = colorWithAlpha(couleur.couleurBordureStats);
            themeUserMod.bg_hover_color = colorWithAlpha(couleur.couleurFondHover);
            themeUserMod.specifique_color = colorWithAlpha(couleur.couleurSpecifique);
            themeUserMod.succes_color = colorWithAlpha(couleur.couleurSucces);
            themeUserMod.error_color = colorWithAlpha(couleur.couleurErreur);
            
            // On traite maintenant les couleurs perso
            const userPersoCouleur = themeUserMod.user_perso_couleur;
            userPersoCouleur.couleur_scrut = colorWithAlpha(couleur.couleurPerso.couleurScrut);
            userPersoCouleur.couleur_km = colorWithAlpha(couleur.couleurPerso.couleurKm);
            userPersoCouleur.couleur_pos = colorWithAlpha(couleur.couleurPerso.couleurPos);
            userPersoCouleur.couleur_bat = colorWithAlpha(couleur.couleurPerso.couleurBat);
            userPersoCouleur.couleur_select_obj = colorWithAlpha(couleur.couleurPerso.couleurSelectObj);
            userPersoCouleur.couleur_select_bat = colorWithAlpha(couleur.couleurPerso.couleurSelectBat);
            userPersoCouleur.couleur_select_cit = colorWithAlpha(couleur.couleurPerso.couleurSelectCit);
            userPersoCouleur.couleur_vue_auj = colorWithAlpha(couleur.couleurPerso.couleurVueAuj);
            userPersoCouleur.couleur_vue24 = colorWithAlpha(couleur.couleurPerso.couleurVue24);
            userPersoCouleur.couleur_vue48 = colorWithAlpha(couleur.couleurPerso.couleurVue48);
            userPersoCouleur.couleur_p_a = colorWithAlpha(couleur.couleurPerso.couleurPA);
            userPersoCouleur.couleur_zone = colorWithAlpha(couleur.couleurPerso.couleurZone);
            userPersoCouleur.color_sel_exp = colorWithAlpha(couleur.couleurPerso.colorSelExp);
            userPersoCouleur.couleur_sel_case_maj = colorWithAlpha(couleur.couleurPerso.couleurSelCaseMaj);
            userPersoCouleur.couleur_danger0 = colorWithAlpha(couleur.couleurPerso.couleurDanger0);
            userPersoCouleur.couleur_danger1 = colorWithAlpha(couleur.couleurPerso.couleurDanger1);
            userPersoCouleur.couleur_danger2 = colorWithAlpha(couleur.couleurPerso.couleurDanger2);
            userPersoCouleur.couleur_danger3 = colorWithAlpha(couleur.couleurPerso.couleurDanger3);
            userPersoCouleur.couleur_controle_ok = colorWithAlpha(couleur.couleurPerso.couleurControleOk);
            userPersoCouleur.couleur_contole_nok = colorWithAlpha(couleur.couleurPerso.couleurContoleNok);
            userPersoCouleur.couleur_epuise = colorWithAlpha(couleur.couleurPerso.couleurEpuise);
            userPersoCouleur.couleur_carte = colorWithAlpha(couleur.couleurPerso.couleurCarte);
            userPersoCouleur.color_zombie = colorWithAlpha(couleur.couleurPerso.couleurZombie);
            userPersoCouleur.color_estimation_zombie = colorWithAlpha(couleur.couleurPerso.couleurEstimZombie);
            userPersoCouleur.color_flag = colorWithAlpha(couleur.couleurPerso.couleurFlag);
            userPersoCouleur.color_flag_finish = colorWithAlpha(couleur.couleurPerso.couleurFlagFinish);
            userPersoCouleur.color_town = colorWithAlpha(couleur.couleurPerso.couleurTown);
            userPersoCouleur.color_city = colorWithAlpha(couleur.couleurPerso.couleurCity);
            userPersoCouleur.color_non_vu = colorWithAlpha(couleur.couleurPerso.couleurNonVu);
            userPersoCouleur.color_bat_epuise = colorWithAlpha(couleur.couleurPerso.colorBatEpuise);
            userPersoCouleur.couleur_nbr_citoyen = colorWithAlpha(couleur.couleurPerso.couleurNbrCitoyen);
            userPersoCouleur.couleur_nbr_items_sol = colorWithAlpha(couleur.couleurPerso.couleurNbrItemsSol);
            userPersoCouleur.carte_textured = couleur.couleurPerso.carteTextured === "true";
            userPersoCouleur.colormap = couleur.couleurPerso.colorMap;
            userPersoCouleur.alpha_colormap = parseInt(couleur.couleurPerso.alphaColorMap, 10);
            userPersoCouleur.color_stair_up = colorWithAlpha(couleur.couleurPerso.colorStairUp);
            userPersoCouleur.color_stair_down = colorWithAlpha(couleur.couleurPerso.colorStairDown);
            userPersoCouleur.color_select_objet_ruine = colorWithAlpha(couleur.couleurPerso.colorSelectObjet);
            userPersoCouleur.color_dispo1 = colorWithAlpha(couleur.couleurPerso.colorDispo1);
            userPersoCouleur.color_dispo2 = colorWithAlpha(couleur.couleurPerso.colorDispo2);
            userPersoCouleur.color_dispo3 = colorWithAlpha(couleur.couleurPerso.colorDispo3);
            userPersoCouleur.color_ville_commune = colorWithAlpha(couleur.couleurPerso.colorVilleCommune);
            userPersoCouleur.couleur_chantier_construit = colorWithAlpha(couleur.couleurPerso.couleurChantierConstruit);
            userPersoCouleur.couleur_chantier_a_repa = colorWithAlpha(couleur.couleurPerso.couleurChantierRepa);
            userPersoCouleur.couleur_chantier_en_construction = colorWithAlpha(couleur.couleurPerso.couleurChantierEnConst);
            userPersoCouleur.couleur_chantier_dispo = colorWithAlpha(couleur.couleurPerso.couleurChantierDispo);
            userPersoCouleur.couleur_plan_manquant = colorWithAlpha(couleur.couleurPerso.couleurPlanManquant);
            userPersoCouleur.color_my_exp = colorWithAlpha(couleur.couleurPerso.couleurMyExpe);
            userPersoCouleur.my_color_expe = colorWithAlpha(couleur.couleurPerso.colorMyExpe);
            
            setThemeUser(themeUserMod);
            
            setIsImport(true);
            window.setTimeout(function () {
                setIsImport(false);
            }, 1500);
            
        } else {
            setIsErrorImport(true);
            window.setTimeout(function () {
                setIsErrorImport(false);
            }, 5000);
        }
    };
    
    const buildChartExemple = () => {
        const data = {
            "day"   : [
                1,
                2,
                3,
            ],
            "data_1": [
                25,
                65,
                155,
            ],
            "data_2": [
                10,
                30,
                60,
            ],
            "data_3": [
                5,
                15,
                45,
            ],
            "data_4": [
                10,
                20,
                50,
            ],
        };
        
        Chart.register(...registerables);
        
        
        const myChartRef = chartExemple.current.getContext("2d");
        
        const myChart = Chart.getChart("statsExemple");
        if (myChart) {
            myChart.destroy();
        }
        
        new Chart(myChartRef, {
            type   : "bar",
            data   : {
                labels  : Object.values(data.day),
                datasets: [{
                    type            : "line",
                    fill            : false,
                    borderColor     : "rgba(255,0,0,1)",
                    label           : "D1",
                    data            : Object.values(data.data_1),
                    pointRadius     : 4, // rayon des points
                    pointHoverRadius: 8, // rayon des points au survol de la souris
                    borderWidth     : 2,
                }, {
                    type           : "bar",
                    borderColor    : "rgba(255,0,0,0)",
                    backgroundColor: "rgba(100,143,255,1)",
                    label          : "D2",
                    data           : Object.values(data.data_2),
                }, {
                    type           : "bar",
                    borderColor    : "rgba(255,0,0,0)",
                    backgroundColor: "rgba(120,94,240,1)",
                    label          : "D3",
                    data           : Object.values(data.data_3),
                }, {
                    type           : "bar",
                    borderColor    : "rgba(255,0,0,0)",
                    backgroundColor: "rgba(220,38,127,1)",
                    label          : "D4",
                    data           : Object.values(data.data_4),
                },
                ],
            },
            options: {
                maintainAspectRatio: false,
                scales             : {
                    y: {
                        min    : 0,
                        stacked: true,
                        ticks  : {
                            color: themeUser.stats_font_color,
                        },
                        grid   : {
                            color: themeUser.stats_border_color,
                        },
                    },
                    x: {
                        min    : 0,
                        max    : 3,
                        stacked: true,
                        ticks  : {
                            color: themeUser.stats_font_color,
                        },
                        grid   : {
                            color: themeUser.stats_border_color,
                        },
                    },
                },
                plugins            : {
                    title : {
                        display: true,
                        text   : "Lorem Ipsum",
                        color  : themeUser.stats_font_color,
                    },
                    legend: {
                        labels: {
                            color: themeUser.stats_font_color,
                        },
                    },
                },
            },
        });
        
    };
    
    useEffect(() => {
        if (themeUser !== null) {
            buildChartExemple();
        }
    }, [themeUser?.stats_bg_color, themeUser?.stats_font_color, themeUser?.stats_border_color]);
    
    
    return (
        <div id="gestion_theme_user_option_perso">
            <div id={"gestion_theme_user_list"}>
                <div id={"listing_theme_user"}>
                    <h3>{t("Liste des thèmes", {ns: "perso"})}</h3>
                    {user.themes_user.sort((a: ThemeUserDTO, b: ThemeUserDTO) => a.nom.localeCompare(b.nom)).map((theme, index) => {
                        return (
                            <div className={"theme_user_ligne"} key={index}>
                                <div className={"theme_user_ligne_name"}>{theme.nom}</div>
                                <div className={"theme_user_ligne_bouton_action"}>
                                    <button onClick={() => modificationThemeUser(theme.id)} className={"btn btn-success btn-xs"}>
                                        <TooltipGH>
                                            <span className={"infoBulle"}>
                                                <i className="fas fa-edit"/>
                                            </span>
                                            <span className={"info"}>{t("Modifier un thème", {ns: "perso"})}</span>
                                        </TooltipGH>
                                    </button>
                                    {theme.base_theme === "perso" &&
                                        <button onClick={() => suppThemeUser(theme.id)} className={"btn btn-danger btn-xs"}>
                                            <TooltipGH>
                                                <span className={"infoBulle"}>
                                                    <i className="fas fa-trash"/>
                                                </span>
                                                <span className={"info"}>{t("Supprimer le thème", {ns: "perso"})}</span>
                                            </TooltipGH>
                                        </button>}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div id={"zone_action_theme_user_listing_option_perso"}>
                    <button className={"btn btn-warning btn-sm"} onClick={() => handleCreateThemeUser()}>{t("Créer un thème", {ns: "perso"})}</button>
                </div>
            </div>
            {themeUser !== null && <div id={"panel_gestion_couleur_theme_user"}>
                <div id={"group_gestion_couleur_appercu"}>
                    <div id={"zone_gestion_couleur_pour_appercu"}>
                        <div>
                            {(themeUser.base_theme === "perso") ? (<React.Fragment>
                                <div>{t("Pré-alimenter avec un des thèmes :", {ns: "perso"})}</div>
                                <div id={"group-btn-theme"}>
                                    {Object.entries(themeName).map(([index, theme]: [string, string]) => {
                                        return <button onClick={() => handlePreAlimThemeUser(index, "perso")} key={"theme_" + index} className={"btn btn-primary btn-xs"}>{t(theme, {ns: "perso"})}</button>;
                                    })}
                                </div>
                            </React.Fragment>) : (<button onClick={() => handlePreAlimThemeUser(themeUser.base_theme, themeUser.base_theme)} className={"btn btn-primary btn-xs"}>{t("Réinitialiser le thème", {ns: "perso"})}</button>)}
                        </div>
                        <div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Nom du thème :", {ns: "perso"})}</span>
                                <input value={themeUser.nom} onChange={(event) => handleThemeUserChange({nom: event.target.value})}/>
                            </div>
                            <h3>{t("Général :", {ns: "perso"})}</h3>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur de fond :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.background_color} onChangeColor={(color) => handleThemeUserChange({background_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur des bordures :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.primary_border_color}
                                             onChangeColor={(color) => handleThemeUserChange({primary_border_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur principale :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.primary_color} onChangeColor={(color) => handleThemeUserChange({primary_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur secondaire :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.secondary_color} onChangeColor={(color) => handleThemeUserChange({secondary_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur spécifique :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.specifique_color} onChangeColor={(color) => handleThemeUserChange({specifique_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur complémentaire :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.tertiary_color} onChangeColor={(color) => handleThemeUserChange({tertiary_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur 1 ligne tableau :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.primary_row_color} onChangeColor={(color) => handleThemeUserChange({primary_row_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur 2 ligne tableau :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.secondary_row_color} onChangeColor={(color) => handleThemeUserChange({secondary_row_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur police principale :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.primary_font_color} onChangeColor={(color) => handleThemeUserChange({primary_font_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur police secondaire :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.tertiary_font_color} onChangeColor={(color) => handleThemeUserChange({tertiary_font_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur de ma ligne :", {ns: "perso"})}
                                    <TooltipGH>
                                        <span className={"infoBulle"}>
                                            <i className="fa-solid fa-circle-info"></i>
                                        </span>
                                        <span className={"info"}>{t("page Citoyen", {ns: "perso"})}</span>
                                    </TooltipGH>
                                </span>
                                <ColorPicker color={themeUser.my_line_color} onChangeColor={(color) => handleThemeUserChange({my_line_color: color})}/>
                            </div>
                            <h3>{t("Graphique :", {ns: "perso"})}</h3>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur de fond :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.stats_bg_color} onChangeColor={(color) => handleThemeUserChange({stats_bg_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur police :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.stats_font_color} onChangeColor={(color) => handleThemeUserChange({stats_font_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur grille :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.stats_border_color} onChangeColor={(color) => handleThemeUserChange({stats_border_color: color})}/>
                            </div>
                            <h3>{t("Survol des onglets :", {ns: "perso"})}</h3>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur de la police :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.hover_font_color} onChangeColor={(color) => handleThemeUserChange({hover_font_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Couleur de fond :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.bg_hover_color} onChangeColor={(color) => handleThemeUserChange({bg_hover_color: color})}/>
                            </div>
                            <h3>{t("Couleur retour de mise à jour, icone de couleur :", {ns: "perso"})}</h3>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Succès, ok, vrai :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.succes_color} onChangeColor={(color) => handleThemeUserChange({succes_color: color})}/>
                            </div>
                            <div className={"group_label_couleur_gestion_theme_user"}>
                                <span>{t("Erreur, ko, faux :", {ns: "perso"})}</span>
                                <ColorPicker color={themeUser.error_color} onChangeColor={(color) => handleThemeUserChange({error_color: color})}/>
                            </div>
                        </div>
                    </div>
                    <div id={"zone_appercu_site"}>
                        <div id={"appercu_site_modification"} className={"appercu_site_bg_img_" + themeUser.base_theme}
                             style={{color: themeUser.primary_font_color}}>
                            <div id={"appercu_site_background"}
                                 style={{backgroundColor: themeUser.background_color, border: `1px solid ${themeUser.primary_border_color}`}}>
                                <div id={"appercu_site_primary"}
                                     style={{backgroundColor: themeUser.primary_color, border: `1px solid ${themeUser.primary_border_color}`}}>
                                    <div id={"appercu_site_secondary"}
                                         style={{backgroundColor: themeUser.secondary_color, border: `1px solid ${themeUser.primary_border_color}`}}>
                                        <p style={{color: themeUser.tertiary_font_color}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <table style={{border: `1px solid ${themeUser.primary_border_color}`}}>
                                            <thead style={{backgroundColor: themeUser.tertiary_color}}>
                                            <tr style={{border: `1px solid ${themeUser.primary_border_color}`}}>
                                                <th style={{border: `1px solid ${themeUser.primary_border_color}`}}>Lorem</th>
                                                <th style={{border: `1px solid ${themeUser.primary_border_color}`}}>Ipsum</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr style={{backgroundColor: themeUser.primary_row_color, border: `1px solid ${themeUser.primary_border_color}`}}>
                                                <td style={{border: `1px solid ${themeUser.primary_border_color}`}}>Lorem</td>
                                                <td style={{border: `1px solid ${themeUser.primary_border_color}`}}>Ipsum</td>
                                            </tr>
                                            <tr style={{backgroundColor: themeUser.secondary_row_color, border: `1px solid ${themeUser.primary_border_color}`}}>
                                                <td style={{border: `1px solid ${themeUser.primary_border_color}`}}>Suspendisse</td>
                                                <td style={{border: `1px solid ${themeUser.primary_border_color}`}}>Curabitur</td>
                                            </tr>
                                            <tr style={{backgroundColor: themeUser.primary_row_color, border: `1px solid ${themeUser.primary_border_color}`}}>
                                                <td style={{border: `1px solid ${themeUser.primary_border_color}`}}>Phasellus</td>
                                                <td style={{border: `1px solid ${themeUser.primary_border_color}`}}>Aliquam</td>
                                            </tr>
                                            <tr style={{backgroundColor: themeUser.secondary_row_color, border: `1px solid ${themeUser.primary_border_color}`}}>
                                                <td style={{border: `1px solid ${themeUser.primary_border_color}`}}>Integer</td>
                                                <td style={{border: `1px solid ${themeUser.primary_border_color}`}}>Quisque</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div id={"appercu_site_myline"} style={{backgroundColor: themeUser.primary_color, border: `1px solid ${themeUser.primary_border_color}`}}>
                                            <div style={{backgroundColor: themeUser.my_line_color}}>Lorem ipsum dolor sit amet. <div id={"zone_bordure_in_my_line"}
                                                                                                                                     style={{border: `1px solid ${themeUser.primary_border_color}`}}></div>
                                            </div>
                                        </div>
                                        <div id={"appercu_site_stats"}
                                             style={{backgroundColor: themeUser.stats_bg_color, border: `1px solid ${themeUser.primary_border_color}`}}>
                                            <div>
                                                <canvas ref={chartExemple} id="statsExemple" width="420" height="240"></canvas>
                                            </div>
                                        </div>
                                        <div id={"appercu_site_hover"}>
                                            <div style={{border: `1px solid ${themeUser.primary_border_color}`}}>Neque porro</div>
                                            <div style={{
                                                backgroundColor: themeUser.bg_hover_color,
                                                border         : `1px solid ${themeUser.primary_border_color}`,
                                                color          : themeUser.hover_font_color,
                                            }}>Quisquam est
                                            </div>
                                            <div style={{
                                                backgroundColor: themeUser.specifique_color,
                                                border         : `1px solid ${themeUser.primary_border_color}`,
                                                color          : themeUser.hover_font_color,
                                            }}>Dolorem ipsum
                                            </div>
                                        </div>
                                        <div id={"appercu_site_feedback"}>
                                            <div style={{color: `${themeUser.succes_color}`}}><i className={"fa fa-check"}></i> Suspendisse suscipit</div>
                                            <div style={{color: `${themeUser.error_color}`}}><i className={"fa fa-xmark"}></i> dapibus accumsan</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={"zone_group_action_theme_user_gestion"}>
                    <button className={"btn btn-primary btn-xs"} disabled={themeUser.nom === null || themeUser.nom === ""}
                            onClick={() => saveThemeUser(true)}>{t("Sauvegarder et appliquer le thème", {ns: "perso"})}</button>
                    <button className={"btn btn-success btn-xs"} disabled={themeUser.nom === null || themeUser.nom === ""}
                            onClick={() => saveThemeUser(false)}>{t("Sauvegarder le thème", {ns: "perso"})}</button>
                    <button className={"btn btn-danger btn-xs"} onClick={() => annulerThemeUser()}>{t("Annuler le thème", {ns: "perso"})}</button>
                </div>
                <div id="import_export_couleur" className={"d-flex flex-column justify-content-center gap-1 align-self-center"}>
                    <div id="zone_bouton_export">
                        <button type="button" id="export_couleur_perso" className="button btn-xs btn btn-success"
                                onClick={handleExportCouleur}>{(isErrorCopy) ? t("Appuyer sur \"Ctrl + C\" pour copier", {ns: "outils"}) : ((isCopy) ? t("Texte copié", {ns: "outils"}) : t("Exporter les couleurs et copier dans le presse-papier", {ns: "perso"}))}</button>
                        <button type="button" id="import_couleur_perso" className="button btn-xs btn btn-warning"
                                onClick={handleImportCouleur}>{(isErrorImport) ? t("Le texte fournit n'est pas dans le bon format attendu.", {ns: "outils"}) : ((isImport) ? t("Couleurs correctement importées, vous pouvez sauvegarder", {ns: "perso"}) : t("Importer les couleurs", {ns: "perso"}))}</button>
                    </div>
                    <div id="zone_text_import_export" className={"d-flex justify-content-center"}>
                        <textarea name="export_import_couleur_option" id="export_import_couleur_option" cols={60} rows={5} value={exportColor} onChange={(event) => setExportColor(event.target.value)}></textarea>
                    </div>
                </div>
            </div>}
        </div>
    );
}