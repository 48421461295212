import React, { useEffect, useState }     from "react";
import chargement                         from "../../../img/chargement.svg";
import { AffichageAme, ClassementAmeApi } from "../../services/api/ClassementAmeApi";
import Ame                                from "../../containers/Ame/Ame";
import { useNavigate, useParams }         from "react-router-dom";
import { useGHContext }                   from "../../types/Context/GHContext";
import { Status_error, usePopUp }         from "../../types/Context/PopUpContext";
import { useGeneralContext }              from "../../types/Context/GeneralContext";
import { useTranslation }                 from "react-i18next";

export function AmeGeneral() {
    const { general, setGeneral } = useGeneralContext();
    const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const navigate = useNavigate();
    const [ameData, setAmeData] = useState(null);
    const params = useParams();
    const { t } = useTranslation();
    
    if (!checkHabilitation("ame", "moname")) {
        navigate("/news");
    }
    
    // Fonction pour recharger les données
    const reloadData = async () => {
        const ameApi = new ClassementAmeApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
        ameApi.main(params.idUser).then((response: AffichageAme) => {
            setIsOnRefresh(false);
            setIsOnUpdate(false);
            if (response.data.general !== undefined) {
                setGeneral(response.data.general);
                sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
            }
            if (response.data.ame === undefined) {
                console.error("Erreur de chargement des ames", response.data);
            } else {
                setAmeData(response.data.ame);
            }
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error?.data?.error);
            navigate("/news");
        });
    };
    
    useEffect(() => {
        if (checkHabilitation("ame", "moname")) {
            setAmeData(null);
            reloadData().then(r => r);
        } else {
            navigate("/news");
        }
    }, [refreshKey, params.idUser]);
    
    return (
        <>
            {ameData === null ? (
                <div className={"chargement_page"}>
                    <img src={chargement} alt="Drapeau" />
                    <span>{t("Chargement...", { ns: "app" })}</span>
                </div>
            ) : (
                <Ame ame={ameData} />
            )}
        
        </>
    );
}