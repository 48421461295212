import React                 from "react";
import { useTranslation }    from "react-i18next";
import { HerosSkillTypeDTO } from "../../../types/models/herosSkillType.dto";


export default function TabCompetences({ listSkill }: { listSkill: HerosSkillTypeDTO[] }) {
    const { t } = useTranslation();
    
    // On va venir récupérer le premier élément de la liste des compétences qui correspond à la compétence de base
    const skillBase = listSkill[0];
    
    // On retire le premier élément de la liste des compétences, pour ne garder que les compétences spécifiques aux héros
    const listSkillHeros = listSkill.slice(1);
    
    // On récupère les niveaux (pour le moment, ils sont identiques en termes d'xp et de nom
    const levels = listSkillHeros[0].level;
    
    return <div id="zone_skillHeros">
        <div id="zoneSkillHeros">
            <div className={"d-flex align-items-center justify-content-center"}>
                <table className="ency_skill_basique">
                    <thead>
                    <tr className="lign_exp_heros_ency">
                        <th>{t(skillBase.name, { ns: "game" })}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {skillBase.level[0].pouvoir.map((skill, index) => {
                        return <tr className="lign_exp_heros_ency" key={"skill_" + index}>
                            <td>{t(skill.text, { ns: "game", value: skill.value, value2: skill.value2 })}</td>
                        </tr>;
                    })}
                    </tbody>
                </table>
            </div>
            <div className={"d-flex align-items-start justify-content-center"}>
                <table className={"w-100 ency_skill_heros"}>
                    <thead>
                    <tr>
                        <th rowSpan={2}>{t("Compétences", { ns: "ency" })}</th>
                        <th colSpan={4}>{t("Niveau", { ns: "ency" })}</th>
                    </tr>
                    <tr>
                        {levels.map((level, index) => {
                            return <th key={"level_" + index}>
                                <span className={"d-flex gap-1 justify-content-center"}>
                                    <span>{t(level.name, { ns: "game" })}</span>
                                    {level.xp && <span>({level.xp} {t("XP", { ns: "ency" })})</span>}
                                </span>
                            </th>;
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {listSkillHeros.map((skill, index) => (
                        <React.Fragment key={`skill_${index}`}>
                            {[0, 1, 2, 3].map((i) => (
                                <tr key={`skill_${index}_level_${i}`}
                                    className={
                                        i === 0 ? "accentuated-top-row" : i === 3 ? "accentuated-bottom-row" : ""
                                    }>
                                    {i === 0 && <td rowSpan={4} className={"taille_ency_skill_heros_comp"}>{t(skill.name, { ns: "game" })}</td>}
                                    {skill.level.map((level, levelIndex) => (
                                        <td key={`level_${levelIndex}`} className={"taille_ency_skill_heros"}>
                                            {level.pouvoir[i] && <div>
                                                {t(level.pouvoir[i].text, { ns: "game", value: level.pouvoir[i].value, value2: level.pouvoir[i].value2 })}
                                            </div>}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
    
}
