import * as React                                                                                                                                                                                                  from "react";
import { ArrayInput, BooleanField, BooleanInput, Datagrid, Edit, EditButton, List, NumberField, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextField, TextInput, useRecordContext } from "react-admin";
import { Status_error, Status_success, usePopUp }                                                                                                                                                                  from "../../types/Context/PopUpContext";
import { AdminApi }                                                                                                                                                                                                from "../../services/api/AdminApi";

const MajJson = () => {
    const adminApi = new AdminApi();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const handleUpdate = () => {
        adminApi.maj_bats_json().then((response) => {
            if (response.codeRetour === 0) {
                setStatus(Status_success);
                setMessagePopUp(response.libRetour);
                setShowPop(true);
                setTimeout(() => {
                    setShowPop(false);
                    setMessagePopUp("");
                }, 1000);
            } else {
                setStatus(Status_error);
                setMessagePopUp(response.libRetour);
                setShowPop(true);
            }
        });
    };
    return (
        <button className={"btn btn-primary"} onClick={handleUpdate}>
            Mettre à jour le fichier JSON
        </button>
    );
};

export const BatimentsList = (props: any) => {
    const postFilters = [
        <TextInput source="q" label="Search" alwaysOn key={"search_bat"} />,
        <ReferenceInput
            source="id"
            label="Batiment"
            reference="batiment"
            key={"search_id"}
        />,
    ];
    
    return (
        <>
            <div>
                <MajJson />
            </div>
            <List {...props} filters={postFilters}>
                <Datagrid>
                    <NumberField source="id" />
                    <TextField source="nom" />
                    <BooleanField source={"actif"} />
                    <EditButton />
                </Datagrid>
            </List>
        </>
    );
};

export const BatimentsEdit = (props: any) => {
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition de {record ? `"${record.nom}"` : ""}</span>;
    };
    
    return (
        <Edit {...props} title={<PostTitle />}>
            <SimpleForm>
                <div id={"chantier_form_prototype"}>
                    <NumberInput source={"id"} label={"Id"} name={"id"} />
                    <TextInput source={"nom"} label={"Nom Batiment"} name={"nom"} />
                    <TextInput source={"icon"} label={"Icon"} name={"icon"} />
                    <TextInput
                        source={"description"}
                        label={"Description"}
                        multiline
                        fullWidth
                        name={"description"}
                    />
                    <BooleanInput
                        name={"explorable"}
                        source={"explorable"}
                        label={"Batiment explorable"}
                    />
                    <NumberInput name={"km_min"} source={"km_min"} label={"Km min"} />
                    <NumberInput name={"km_max"} source={"km_max"} label={"Km max"} />
                    <NumberInput
                        name={"bonus_camping"}
                        source={"bonus_camping"}
                        label={"Bonus camping"}
                    />
                    <NumberInput
                        name={"max_campeur"}
                        source={"max_campeur"}
                        label={"Max campeurs"}
                    />
                    <NumberInput name={"id_mh"} source={"ih_mh"} label={"Id MH"} />
                    <BooleanInput
                        name={"actif"}
                        source={"actif"}
                        label={"Batiment Actif"}
                    />
                </div>
                <div>
                    <ArrayInput name={"items"} source={"items"}>
                        <SimpleFormIterator inline={true}>
                            <ReferenceInput
                                source={"item"}
                                reference={"items"}
                                label={"Item"}
                                sort={{ field: "nom", order: "ASC" }}
                            >
                                <SelectInput
                                    source={"item"}
                                    optionText={"nom"}
                                    optionValue={"id"}
                                    parse={(value) => ({ id: value })}
                                    format={(value) => value?.id}
                                />
                            </ReferenceInput>
                            <NumberInput source={"probabily"} label={"Proba"} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </div>
            </SimpleForm>
        </Edit>
    );
};
