import React, { useEffect, useState } from "react";
import TriangleSort                   from "../../../components/generality/ComposantGeneral/TriangleSort";
import { ItemPrototypeDTO }           from "../../../types/models/itemPrototype.dto";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { EncyclopedieObjetType }      from "../../../types/components/Encyclopedie/EncyclopedieObjetType";
import { useTranslation }             from "react-i18next";
import { useNormalizedSearch }        from "../../../services/hook/useNormalizedSearch";
import TranslationsDisplay            from "../../../components/utils/TranslationsDisplay";

export default function ListPoubelles({ objets, search_objet, listObjetsPrototype, isTrad, lng }: { objets: EncyclopedieObjetType, search_objet: string, listObjetsPrototype: ItemPrototypeDTO[], isTrad: boolean , lng: string}) {
    const { t } = useTranslation();
    const [listPoubelles, setListPoubelles] = useState(objets.listPoubelles);
    const [sortedColumnP, setSortedColumnP] = useState("name");
    const [sortOrderP, setSortOrderP] = useState("asc");
    const { normalizeSearch } = useNormalizedSearch();
    
    
    useEffect(() => {
        setListPoubelles(objets.listPoubelles);
    }, [objets]);
    
    
    const filterLists = (listObjetsPrototype: ItemPrototypeDTO[]) => {
        const filteredPoubelles = Object.values(objets.listPoubelles).filter((poubelle) => normalizeSearch(search_objet.trim(), t(listObjetsPrototype[poubelle.id].nom, { ns: "items" })));
        setListPoubelles(filteredPoubelles);
    };
    
    
    useEffect(() => {
        filterLists(listObjetsPrototype);
    }, [search_objet]);
    
    const getSortIndicatorClassPoubelles = (column: string) => {
        if (column === sortedColumnP) {
            return sortOrderP === "asc" ? "asc" : "desc";
        } else {
            return "default"; // Retourne une chaîne vide si la colonne n'est pas triée
        }
    };
    const handleSortDataPoubelles = (column: string) => {
        // Si la colonne cliquée est la même que la colonne triée précédemment,
        // on inverse l'ordre de tri.
        const order = column === sortedColumnP && sortOrderP === "asc" ? "desc" : (sortOrderP === "desc" ? "default" : "asc");
        
        if (order === "default") {
            column = "";
        }
        
        // On met à jour l'état avec la colonne actuellement triée et l'ordre de tri.
        setSortedColumnP(column);
        setSortOrderP(order);
    };
    const sortedListsPoubelle = (listObjetsPrototype: ItemPrototypeDTO[]) => {
        if (sortedColumnP === "pct") {
            return Object.values(listPoubelles).sort((a, b) => {
                if (a.proba_poubelle === b.proba_poubelle) {
                    return t(listObjetsPrototype[a.id].nom, { ns: "items" }).localeCompare(t(listObjetsPrototype[b.id].nom, { ns: "items" }));
                } else if (sortOrderP === "asc") {
                    return a.proba_poubelle > b.proba_poubelle ? 1 : -1;
                } else {
                    return a.proba_poubelle < b.proba_poubelle ? 1 : -1;
                }
                
            });
        } else {
            return Object.values(listPoubelles).sort((a, b) => t(listObjetsPrototype[a.id].nom, { ns: "items" }).localeCompare(t(listObjetsPrototype[b.id].nom, { ns: "items" })));
        }
    };
    const poubellesTri: ItemPrototypeDTO[] = sortedListsPoubelle(objets.listObjets);
    
    
    return (
        <div id="ency_poubelles">
            <table>
                <thead>
                <tr>
                    <th className="tab_ency_items_icone">{t("Image", { ns: "ency" })}</th>
                    <th className="tab_ency_poubelles_nom">{t("Nom", { ns: "ency" })}</th>
                    <th className="tab_ency_poubelles_proba">
                        <div className={"entete_tri"} onClick={() => handleSortDataPoubelles("pct")}>
                            <div id={"entete_ency_poubelles_proba"}>{t("Probabilité (en %)", { ns: "ency" })}</div>
                            <TriangleSort direction={getSortIndicatorClassPoubelles("pct")} />
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {poubellesTri.map((item) => {
                    
                    return <tr className={"ligne_ency_poubelles"} key={"poubelles_" + item.id}>
                        <td className={"tab_ency_items_icone"}>
                            <div><SvgIcone icone={listObjetsPrototype[item.id].icon} /></div>
                        </td>
                        <td className="tab_ency_poubelles_nom">{isTrad?<TranslationsDisplay translationKey={listObjetsPrototype[item.id].nom??""} namespace={"items"} userLang={lng}/>:t(listObjetsPrototype[item.id].nom, { ns: "items" })}</td>
                        <td className="tab_ency_poubelles_proba">{item.proba_poubelle / 1000}</td>
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
    );
}




