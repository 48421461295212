import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SvgDrapeau from "../generality/SvgDrapeau";

const TranslationsDisplay = ({
    translationKey,
    namespace,
    userLang = "fr"
}:{translationKey:string, namespace:string, userLang?:string}) => {
    const [orderedLanguages, setOrderedLanguages] = useState(["fr", "en", "de", "es"]);
    const [isLoading, setIsLoading] = useState(true);
    const { t,i18n } = useTranslation(namespace);
    
    useEffect(() => {
        const loadLanguages = async () => {
            try {
                // Forcer le chargement de toutes les langues pour ce namespace
                await i18n.loadNamespaces(namespace);
                const promises = Object.values(["fr", "en", "de", "es"]).map(langCode =>
                    i18n.loadLanguages(langCode)
                );
                await Promise.all(promises);
                setIsLoading(false);
            } catch (error) {
                console.error("Error loading languages:", error);
                setIsLoading(false);
            }
        };
        
        loadLanguages();
    }, [namespace, i18n]);
    
    
    useEffect(() => {
        const otherLangs = ["fr", "en", "de", "es"].filter(lang => lang !== userLang);
        setOrderedLanguages([userLang, ...otherLangs]);
    }, [userLang]);
    
    const getTranslation = (lang) => {
        return t(translationKey, { lng: lang });
    };
    
    
    return (
        <div>
            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                {orderedLanguages.map((lang) => (
                    <div key={lang} className={"d-flex gap-1 justify-content-center align-items-center"}>
                        <div>
                            <SvgDrapeau drapeau={lang}/>
                        </div>
                        <div>
                            {getTranslation(lang)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TranslationsDisplay;