import React                   from "react";
import { UserDTO }             from "../../types/models/user.dto";
import { useTranslation }      from "react-i18next";
import { HerosSkillTypeDTO }   from "../../types/models/herosSkillType.dto";
import { HerosSkillLevelDTO }  from "../../types/models/herosSkillLevel.dto";
import { FontAwesomeIcon }     from "@fortawesome/react-fontawesome";
import { faCircleInfo }        from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import TooltipGH               from "../../components/utils/TooltipGH";
import ColorPicker             from "../../components/generality/ColorPickerGH";
import { UserPersoCouleurDTO } from "../../types/models/userPersoCouleur.dto";
import { ThemeUserDTO }        from "../../types/models/themeUser.dto";

export default function GestionSkill({ user, listSkillType, listSkillLevel, handleUserchange, handleUserPersoCouleurChange, themeUser }: { user: UserDTO, listSkillType: HerosSkillTypeDTO[], listSkillLevel: HerosSkillLevelDTO[], handleUserchange: (updatedProperties: Partial<UserDTO>) => void, handleUserPersoCouleurChange: (updatedColorProperties: Partial<UserPersoCouleurDTO>) => void, themeUser: ThemeUserDTO }) {
    const { t } = useTranslation();
    
    const handleSkillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const skillLevel = parseInt(event.target.id.split("_")[1]);
        const updatedSkills = event.target.checked
            ? Object.values(user.skill).concat(listSkillLevel.find(skill => skill.id === skillLevel))
            : Object.values(user.skill).filter(skill => skill.id !== skillLevel);
        handleUserchange({ skill: updatedSkills });
    };
    
    const handleSkillTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const [skillType, skillLevel] = event.target.name.split("_").map(id => parseInt(id));
        const updatedSkillTypes = Object.values(user.skill_type)
            .filter(skill => skill.heros_skill_type.id !== skillType)
            .concat(listSkillLevel.find(skill => skill.id === skillLevel));
        handleUserchange({ skill_type: updatedSkillTypes });
    };
    
    const renderSkillCheckbox = (skillType: HerosSkillTypeDTO, skillLevel: HerosSkillLevelDTO) => (
        <div key={skillLevel.id}>
            <input type="checkbox" id={`${skillType.id}_${skillLevel.id}`} name={`${skillType.id}_${skillLevel.id}`} checked={!!Object.values(user.skill).find(skill => skill.id === skillLevel.id)} onChange={handleSkillChange} />
            <label htmlFor={`${skillType.id}_${skillLevel.id}`}>{t(skillLevel.name, { ns: "game" })}</label>
        </div>
    );
    
    const renderSkillRadio = (skillType: HerosSkillTypeDTO, skillLevel: HerosSkillLevelDTO) => (
        <div key={skillLevel.id}>
            <input type="radio" id={`${skillType.id}_${skillLevel.id}`} name={`${skillType.id}_${skillLevel.id}`} checked={!!Object.values(user.skill_type).find(skill => skill.id === skillLevel.id)} onChange={handleSkillTypeChange} />
            <label htmlFor={`${skillType.id}_${skillLevel.id}`}>{t(skillLevel.name, { ns: "game" })}</label>
        </div>
    );
    
    return (
        <div className="espace_skill mb-2">
            <fieldset className={"mb-1"}>
                <legend>{t("Compétences débloquées", { ns: "perso" })}</legend>
                <div className="d-flex flex-fill justify-content-evenly w-100">
                    {listSkillType.map(skillType => (
                        <fieldset key={skillType.id} style={{ width: "175px" }}>
                            <legend>{t(skillType.name, { ns: "game" })}</legend>
                            <div>
                                {listSkillLevel.filter(skill => skill.heros_skill_type.id === skillType.id && skill.lvl !== 1).map(skillLevel => renderSkillCheckbox(skillType, skillLevel))}
                            </div>
                        </fieldset>
                    ))}
                </div>
            </fieldset>
            <fieldset>
                <legend className={"d-flex gap-1"}>
                    <span>{t("Compétences préférées", { ns: "perso" })}</span>
                    <TooltipGH>
                        <span className="infoBulle">
                            <FontAwesomeIcon icon={faCircleInfo} />
                        </span>
                        <span className="infoHelpPerso">
                                <span>{t("Permettra de préremplir vos pouvoirs dans la page Citoyens entre autre", { ns: "perso" })}</span>
                        </span>
                    </TooltipGH>
                </legend>
                <div className="d-flex flex-fill justify-content-evenly w-100">
                    {listSkillType.map(skillType => (
                        <fieldset key={skillType.id} style={{ width: "175px" }}>
                            <legend>{t(skillType.name, { ns: "game" })}</legend>
                            <div>
                                {listSkillLevel.filter(skill => skill.heros_skill_type.id === skillType.id).map(skillLevel => renderSkillRadio(skillType, skillLevel))}
                            </div>
                        </fieldset>
                    ))}
                </div>
            </fieldset>
            <fieldset>
                <legend>{t("Couleur des niveaux de compétence", { ns: "perso" })}</legend>
                <div className={"d-flex gap-2 justify-content-start align-items-center flex-wrap"}>
                    <div className={"d-flex gap-1 justify-content-start align-items-center"}>
                        <span>{t("Couleur de niveau débutant :", { ns: "perso" })}</span>
                        <ColorPicker color={themeUser.user_perso_couleur.couleur_debutant}
                                     onChangeColor={(color) => handleUserPersoCouleurChange({ couleur_debutant: color })} />
                    </div>
                    <div className={"d-flex gap-1 justify-content-start align-items-center"}>
                        <span>{t("Couleur de niveau apprenti :", { ns: "perso" })}</span>
                        <ColorPicker color={themeUser.user_perso_couleur.couleur_apprenti}
                                     onChangeColor={(color) => handleUserPersoCouleurChange({ couleur_apprenti: color })} />
                    </div>
                    <div className={"d-flex gap-1 justify-content-start align-items-center"}>
                        <span>{t("Couleur de niveau expert :", { ns: "perso" })}</span>
                        <ColorPicker color={themeUser.user_perso_couleur.couleur_expert}
                                     onChangeColor={(color) => handleUserPersoCouleurChange({ couleur_expert: color })} />
                    </div>
                    <div className={"d-flex gap-1 justify-content-start align-items-center"}>
                        <span>{t("Couleur de niveau maître :", { ns: "perso" })}</span>
                        <ColorPicker color={themeUser.user_perso_couleur.couleur_elite}
                                     onChangeColor={(color) => handleUserPersoCouleurChange({ couleur_elite: color })} />
                    </div>
                </div>
            </fieldset>
        </div>
    );
}