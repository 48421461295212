import { Fetch }                          from "../../components/generality/Fetch";
import { CoalitionPropsType }             from "../../types/components/Jump/CoalitionPropsType";
import { ListeCoalitionType }             from "../../types/components/Jump/ListCoalition";
import { GeneralType }                    from "../../types/components/Generality/GeneralType";
import { FetchGH }                        from "../../components/generality/FetchGH";
import { JumpDTO }                        from "../../types/models/jump.dto";
import { RetourMajCompetenceInscription } from "./InscriptionJumpApi";
import { HerosSkillLevelDTO }             from "../../types/models/herosSkillLevel.dto";

export class CoalitionsJumpApi {
    
    private fetch: Fetch;
    private fetchGH: FetchGH;
    
    constructor(mapId?: number) {
        this.fetch = new Fetch("coalition", mapId ?? 0);
        this.fetchGH = new FetchGH("coalition", mapId ?? 0);
    }
    
    public inscriptionCoalition(data: {
        idJump: string, userId: number, idUserFournis: number, idUserEchange?: number, numCoa: number, posCoa: number
    }): Promise<RetourPlacementCoa> {
        return this.fetch.from("/inscription").request().post(data);
    }
    
    public majDispoCoalition(data: { idJump: string, userId: number, idDispo: number, idCreneau: number }): Promise<RetourMajCoa> {
        return this.fetch.from("/majDispo").request().post(data);
    }
    
    public majStatutCoalition(data: { idJump: string, userId: number, idStatut: number }): Promise<RetourMajCoa> {
        return this.fetch.from("/majStatut").request().post(data);
    }
    
    public majCreateurCoalition(data: {
        idJump: string, userId: number, createur: boolean, numCoa: number, posCoa: number
    }): Promise<RetourPlacementCoa> {
        return this.fetch.from("/majCreateur").request().post(data);
    }
    
    public gestionCoalition(idJump: string): Promise<AffichageGestionCoa> {
        return this.fetchGH.get("/" + idJump);
    }
    
    public majCompetenceInscription(data: { userId: number, idJump: string, userIdMaj: number, idSkill: number, lvlSkill: number }): Promise<RetourMajCompetenceInscription> {
        return this.fetchGH.post({ path: "/majCompetence", data: data });
    }
    
    public main(): Promise<AffichageListCoa> {
        return this.fetchGH.get("/");
    }
    
}

export interface RetourMajCoa {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: object,
}

export interface RetourPlacementCoa {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        jump: JumpDTO
    },
}

export interface AffichageGestionCoa {
    data: {
        coalition?: CoalitionPropsType
        general?: GeneralType,
        error?: string,
    }
    status?: number,
    message?: string,
}

export interface AffichageListCoa {
    data: {
        list?: ListeCoalitionType
        general?: GeneralType,
        error?: string,
    }
    status?: number,
    message?: string,
}


export interface RetourMajCompetenceInscription {
    data: {
        skill: HerosSkillLevelDTO[],
        error?: string,
    },
    status?: number,
    message?: string,
    
}