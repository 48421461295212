import * as React                                                                                                                                                                                                                         from "react";
import { useEffect, useState }                                                                                                                                                                                                            from "react";
import { ArrayInput, BooleanField, BooleanInput, Create, Datagrid, Edit, EditButton, FunctionField, List, NumberField, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextField, TextInput, useRecordContext } from "react-admin";
import Button                                                                                                                                                                                                                             from "@mui/material/Button";
import { Link }                                                                                                                                                                                                                           from "react-router-dom";
import { BsDatabaseAdd }                                                                                                                                                                                                                  from "react-icons/bs";
import { Status_error, Status_success, usePopUp }                                                                                                                                                                                         from "../../types/Context/PopUpContext";
import axios                                                                                                                                                                                                                              from "axios";
import { AdminApi }                                                                                                                                                                                                                       from "../../services/api/AdminApi";
import { TypeCaracteristiqueDTO }                                                                                                                                                                                                         from "../../types/models/typeCaracteristique.dto";
import SvgIcone                                                                                                                                                                                                                           from "../../components/generality/SvgIcone";

const CategorieButton = () => {
    return (
        <Button
            component={Link}
            to={"/admin/items/categorie"}
            color="primary"
        >
            Categories
        </Button>
    );
};
const ListAssemblageButton = () => {
    return (
        <Button
            component={Link}
            to={"/admin/items_assemblage"}
            color="primary"
        >
            Assemblage
        </Button>
    );
};

const AddItemsButton = () => (
    <Button
        component={Link}
        to="/admin/items/create"
        color="primary"
        startIcon={<BsDatabaseAdd />}
    >
        Ajouter un item
    </Button>
);

const MajJson = () => {
    const adminApi = new AdminApi();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const handleUpdate = () => {
        adminApi.maj_items_json().then((response) => {
            if (response.codeRetour === 0) {
                setStatus(Status_success);
                setMessagePopUp(response.libRetour);
                setShowPop(true);
                setTimeout(() => {
                    setShowPop(false);
                    setMessagePopUp("");
                }, 1000);
            } else {
                setStatus(Status_error);
                setMessagePopUp(response.libRetour);
                setShowPop(true);
            }
        });
    };
    return (
        <button className={"btn btn-primary"} onClick={handleUpdate}>
            Mettre à jour le fichier JSON
        </button>
    );
};

export const ItemList = (props) => {
    const postFilters = [
        <TextInput source="q" label="Search" alwaysOn key={"search_items"} />,
        <ReferenceInput source="id" label="Item" reference="items" key={"search_items_id"} />,
    ];
    
    return <>
        <div className={"d-flex gap-5"}>
            <AddItemsButton />
            <CategorieButton />
            <ListAssemblageButton />
        </div>
        <MajJson />
        <List {...props} filters={postFilters}>
            <Datagrid>
                <NumberField source="id" />
                <TextField source="nom" />
                <FunctionField render={record => record && record.icon !== null ? <SvgIcone icone={record.icon} /> : null} source={"icon"} label={"Icon"} />
                <BooleanField source={"actif"} />
                <BooleanField source={"update_by_admin"} />
                <EditButton />
            </Datagrid>
        </List>
    
    </>;
};

export const ItemEdit = (props) => {
    
    const [typeCarac, setTypeCarac] = useState([]);
    const [load, setLoad] = useState(false);
    
    const fetchTypes = async () => {
        try {
            const response = await axios.get("/rest/v1/admin/items/typeCarac");
            const itemsList = response.data.sort((a: TypeCaracteristiqueDTO, b: TypeCaracteristiqueDTO) =>
                a.nom.localeCompare(b.nom),
            );
            setTypeCarac(Object.values(itemsList));
        } catch (error) {
            console.error("Error fetching heros:", error);
        }
    };
    
    useEffect(() => {
        fetchTypes().then((r) => r);
        setLoad(true);
    }, []);
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition de {record ? `"${record.nom}"` : ""}</span>;
    };
    
    const typeObjet = [{ type: 1, nom: "Se transforme" }, { type: 2, nom: "Se casse" }, { type: 3, nom: "Disparait" }];
    
    return load && (<Edit {...props} title={<PostTitle />}>
        <SimpleForm>
            <div id={"chantier_form_prototype"}>
                <NumberInput source={"id"} label={"Id Objet"} />
                <TextInput source={"nom"} label={"Nom Objet"} />
                <TextInput source={"icon"} label={"Icon"} />
                <TextInput source={"description"} label={"Description"} multiline fullWidth />
                <BooleanInput name={"objet_veille"} source={"objet_veille"} label={"Objet veille"} />
                <NumberInput source={"def_base"} label={"Def veille"} />
                <BooleanInput name={"armurerie"} source={"armurerie"} label={"Armurerie"} />
                <BooleanInput name={"magasin"} source={"magasin"} label={"Magasin"} />
                <BooleanInput name={"tourelle"} source={"tourelle"} label={"Tourelle lance eau"} />
                <BooleanInput name={"lance_bete"} source={"lance_bete"} label={"Lance bete"} />
                <BooleanInput name={"encombrant"} source={"encombrant"} label={"Objet encombrant"} />
                <BooleanInput name={"usage_unique"} source={"usage_unique"} label={"Objet à usage unique"} />
                <BooleanInput name={"reparable"} source={"reparable"} label={"Objet réparable"} />
                <BooleanInput name={"conteneur"} source={"conteneur"} label={"Conteneur"} />
                <BooleanInput name={"expedition"} source={"expedition"} label={"Expedition"} />
                <NumberInput name={"kill_min"} source={"kill_min"} label={"Kill min"} />
                <NumberInput name={"kill_max"} source={"kill_max"} label={"Kill max"} />
                <NumberInput name={"chance_kill"} source={"chance_kill"} label={"Chance de kill"} />
                <NumberInput name={"chance"} source={"chance"} label={"Chance de cassé/vide"} />
                <SelectInput
                    source="type"
                    label="Type"
                    choices={typeObjet}
                    optionText="nom"
                    optionValue="type"
                />
                <NumberInput name={"proba_poubelle"} source={"proba_poubelle"} label={"Probabilité poubelle"} />
                <NumberInput name={"id_mh"} source={"id_mh"} label={"Identifiant MyHordes"} />
                <TextInput name={"uid"} source={"uid"} label={"Uid MyHordes"} />
                <BooleanInput name={"actif"} source={"actif"} label={"Actif"} />
                <ReferenceInput label="Catégorie" source="category_objet.id" reference="items_categorie" allowEmpty>
                    <SelectInput optionText={"nom"} />
                </ReferenceInput>
                <ReferenceInput label="Type Objet" source="type_objet.id" reference="items_type_objet" allowEmpty>
                    <SelectInput optionText={"nom"} />
                </ReferenceInput>
                <ReferenceInput label="Decharge" source="type_decharge.id" reference="chantier_decharge" allowEmpty>
                    <SelectInput optionText={"chantier.nom"} />
                </ReferenceInput>
            </div>
            <div id={"chantier_form_prototype_ressources"}>
                {typeCarac.length > 0 && (
                    <ArrayInput name={"caracteristiques"} source={"caracteristiques"}>
                        <SimpleFormIterator inline>
                            <NumberInput source="id" label="Id" />
                            <SelectInput
                                source="type_carac.id"
                                label="Type caractéristique"
                                choices={typeCarac}
                                optionText="nom"
                                optionValue="id"
                            />
                            <NumberInput source="value" label="Valeur" />
                            <NumberInput source="probabilite" label="Probabilité" />
                        </SimpleFormIterator>
                    </ArrayInput>
                )}
            </div>
        </SimpleForm>
    </Edit>);
};

export const ItemCreate = (props) => {
    
    const typeObjet = [{ type: 1, nom: "Se transforme" }, { type: 2, nom: "Se casse" }, { type: 3, nom: "Disparait" }];
    
    return (<Create {...props} title={"Création d'un item"} resource={"items"}>
        <SimpleForm>
            <div id={"chantier_form_prototype"}>
                <TextInput source={"nom"} label={"Nom Objet"} />
                <TextInput source={"icon"} label={"Icon"} />
                <TextInput source={"description"} label={"Description"} multiline fullWidth />
                <BooleanInput name={"objet_veille"} source={"objet_veille"} label={"Objet veille"} />
                <NumberInput source={"def_base"} label={"Def veille"} />
                <BooleanInput name={"armurerie"} source={"armurerie"} label={"Armurerie"} />
                <BooleanInput name={"magasin"} source={"magasin"} label={"Magasin"} />
                <BooleanInput name={"tourelle"} source={"tourelle"} label={"Tourelle lance eau"} />
                <BooleanInput name={"lance_bete"} source={"lance_bete"} label={"Lance bete"} />
                <BooleanInput name={"encombrant"} source={"encombrant"} label={"Objet encombrant"} />
                <BooleanInput name={"usage_unique"} source={"usage_unique"} label={"Objet à usage unique"} />
                <BooleanInput name={"reparable"} source={"reparable"} label={"Objet réparable"} />
                <BooleanInput name={"conteneur"} source={"conteneur"} label={"Conteneur"} />
                <NumberInput name={"kill_min"} source={"kill_min"} label={"Kill min"} />
                <NumberInput name={"kill_max"} source={"kill_max"} label={"Kill max"} />
                <NumberInput name={"chance_kill"} source={"chance_kill"} label={"Chance de kill"} />
                <NumberInput name={"chance"} source={"chance"} label={"Chance de cassé/vide"} />
                <SelectInput
                    source="type"
                    label="Type"
                    choices={typeObjet}
                    optionText="nom"
                    optionValue="type"
                />
                <NumberInput name={"proba_poubelle"} source={"proba_poubelle"} label={"Probabilité poubelle"} />
                <NumberInput name={"id_mh"} source={"id_mh"} label={"Identifiant MyHordes"} />
                <TextInput name={"uid"} source={"uid"} label={"Uid MyHordes"} />
                <BooleanInput name={"actif"} source={"actif"} label={"Actif"} />
                <ReferenceInput label="Catégorie" source="category_objet.id" reference="items_categorie">
                    <SelectInput optionText={"nom"} />
                </ReferenceInput>
                <ReferenceInput label="Type Objet" source="type_objet.id" reference="items_type_objet">
                    <SelectInput optionText={"nom"} />
                </ReferenceInput>
                <ReferenceInput label="Decharge" source="type_decharge.id" reference="chantier_decharge">
                    <SelectInput optionText={"chantier.nom"} />
                </ReferenceInput>
            </div>
        </SimpleForm>
    </Create>);
};