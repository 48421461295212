import { createContext, useContext } from "react";
import { CarteContextType }          from "./CarteContextType";

export const CarteContext = createContext<CarteContextType>({
    carte           : null,
    setCarte        : () => {
    },
    idClick         : null,
    setIdClick      : () => {
    },
    isSelCase       : null,
    setIsSelCase    : () => {
    },
    jourActuel      : null,
    setJourActuel   : () => {
    },
    consigneExpes   : null,
    setConsigneExpes: () => {
    },
});

export function useCarteContext() {
    return useContext(CarteContext);
}