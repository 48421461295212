import React, { useEffect }                                                                                                                                                                                            from "react";
import { InscriptionConsigneSauvegardeType, InscriptionExpeditionSauvegardeType }                                                                                                                                      from "../../../types/components/Hotel/InscriptionExpedition";
import SvgIcone                                                                                                                                                                                                        from "../../../components/generality/SvgIcone";
import { SoifType }                                                                                                                                                                                                    from "../../../types/components/Outils/OutilsExpeditionType";
import Select                                                                                                                                                                                                          from "react-select";
import { useGeneralContext }                                                                                                                                                                                           from "../../../types/Context/GeneralContext";
import { camaraderie, corps_sain, jolie_trouvaille, pef, rdh, sauvetage, ss, trouvaille, uppercut, vlm }                                                                                                               from "../../../types/components/Generality/Heros";
import { AffNbrIcon }                                                                                                                                                                                                  from "../../../components/generality/ComposantGeneral";
import { InscriptionExpeditionApi }                                                                                                                                                                                    from "../../../services/api/InscriptionExpeditionApi";
import { Status_error, Status_warning, usePopUp }                                                                                                                                                                      from "../../../types/Context/PopUpContext";
import ConfirmationDialog                                                                                                                                                                                              from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import { useGHContext }                                                                                                                                                                                                from "../../../types/Context/GHContext";
import { calculColorDispo, customStyle, customStyleDispo, DispoOption, DispoSingleValue, InscriptionExpeditionProps, PseudoOption, PseudoSingleValue, SoifOption, SoifSingleValue, styleDispo, styleJob, stylePseudo } from "./InscriptionExpedition";
import HTMLParser                                                                                                                                                                                                      from "html-react-parser";
import CustomSwitchToggle                                                                                                                                                                                              from "../../../components/generality/CustomSwitchToggle";
import { useIEContext }                                                                                                                                                                                                from "../../../types/Context/Hotel/Expedition/InscriptionExpeditionContext";
import { calculPdc, calculPdcExpe, ID_RAMASSAGE, recuperationCitoyensInscritExpedition, recuperationCitoyensPreinscritExpedition }                                                                                     from "../../Outils/Expedition/ExpeditionUtils";
import Modal                                                                                                                                                                                                           from "react-bootstrap/Modal";
import AppercuCarte                                                                                                                                                                                                    from "./AppercuCarte";
import { Helmet }                                                                                                                                                                                                      from "react-helmet-async";
import { ExpeditionDTO }                                                                                                                                                                                               from "../../../types/models/expedition.dto";
import { ExpeditionnaireDTO }                                                                                                                                                                                          from "../../../types/models/expeditionnaire.dto";
import { ExpeditionPartDTO }                                                                                                                                                                                           from "../../../types/models/expeditionPart.dto";
import { ConsigneExpeditionDTO }                                                                                                                                                                                       from "../../../types/models/consigneExpedition.dto";
import { CitoyensDTO }                                                                                                                                                                                                 from "../../../types/models/citoyens.dto";
import { JobPrototypeDTO }                                                                                                                                                                                             from "../../../types/models/jobPrototype.dto";
import { HerosPrototypeDTO }                                                                                                                                                                                           from "../../../types/models/herosPrototype.dto";
import { UserDTO }                                                                                                                                                                                                     from "../../../types/models/user.dto";
import { TypeDispoDTO }                                                                                                                                                                                                from "../../../types/models/typeDispo.dto";
import { VilleDTO }                                                                                                                                                                                                    from "../../../types/models/ville.dto";
import { ZoneMapDTO }                                                                                                                                                                                                  from "../../../types/models/zoneMap.dto";
import { DispoExpeditionDTO }                                                                                                                                                                                          from "../../../types/models/dispoExpedition.dto";
import { useTranslation }                                                                                                                                                                                              from "react-i18next";
import { faComment, faPenToSquare }                                                                                                                                                                                    from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }                                                                                                                                                                                             from "@fortawesome/react-fontawesome";
import Tooltips                                                                                                                                                                                                        from "../../../components/utils/Tooltips";
import Button                                                                                                                                                                                                          from "../../../components/utils/Button";
import PopUpCommentaire                                                                                                                                                                                                from "./PopUpCommentaire";
import { UserPersoCouleurDTO }                                                                                                                                                                                         from "../../../types/models/userPersoCouleur.dto";

const calculExpeComplete = (expedition: ExpeditionDTO) => {
    return expedition.expedition_parts[0].expeditionnaires.length === expedition.expedition_parts[0].expeditionnaires.filter((exped) => exped.citoyen !== null).length;
};

const calculMyExpe = (expedition: ExpeditionDTO, user: UserDTO) => {
    return expedition.expedition_parts[0].expeditionnaires.find((exped) => exped.citoyen !== null && exped.citoyen.citoyen.id === user.id) !== undefined;
};


// retourne si on est inscrit dans cette expedition ou non
const myExpedition = (expedition: ExpeditionDTO, me: UserDTO) => {
    const citoyens = recuperationCitoyensInscritExpedition(expedition);
    return citoyens.find((citoyen) => citoyen.citoyen.id === me.id) !== undefined;
};

const generateCSSUser = (userColor: UserPersoCouleurDTO) => {
    return `
		.my_line_expedition {background-color:${userColor.my_color_expe} !important;}
		`;
};

export default function ListeExpedition({ inscription }: InscriptionExpeditionProps) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const { triggerRefresh } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const { expeditions, setExpeditions, optionsSoif, recuperationCitoyensPreinscrit, recuperationCitoyensPreinscritOuvrier, ouvriers, jourActuel } = useIEContext();
    const inscriptionApi = new InscriptionExpeditionApi(general.ville.map_id ?? 0);
    const [citoyenExpe, setCitoyenExpe] = React.useState<CitoyensDTO | null>(null);
    const [selectionPseudo, setSelectionPseudo] = React.useState<{ expeditionnaire: ExpeditionnaireDTO, indexExpeditionnaire: number, index_expedition: number, optionSelected: CitoyensDTO, placeVide: boolean }>(null);
    const [confirmationPdcShow, setConfirmationPdcShow] = React.useState(false);
    const [confirmationPseudoShow, setConfirmationPseudoShow] = React.useState(false);
    const [confirmationPseudo2Show, setConfirmationPseudo2Show] = React.useState(false);
    const [soifExpeditionnaire, setSoifExpeditionnaire] = React.useState<{ expe_id: number, expeditionnaire_id: number } | null>(null);
    const [dispoExpeditionnaire, setDispoExpeditionnaire] = React.useState<{ expe_id: number, expeditionnaire_id: number, creneauId: number } | null>(null);
    const [dispoRapideExpeditionnaire, setDispoRapideExpeditionnaire] = React.useState<{ expe_id: string } | null>(null);
    const [consigneSave, setConsigneSave] = React.useState<ConsigneExpeditionDTO | null>(null);
    const [maskComplete, setMaskComplete] = React.useState<boolean>(false);
    const [saveExpeditionnaire, setSaveExpeditionnaire] = React.useState<ExpeditionnaireDTO | null>(null);
    const [afficherAppercu, setAfficherAppercu] = React.useState<boolean>(false);
    const [zoneAppercu, setZoneAppercu] = React.useState<ZoneMapDTO[] | null>(null);
    const [traceAppercu, setTraceAppercu] = React.useState<number[]>([]);
    const [borne, setBorne] = React.useState<{ x_min: number, x_max: number, y_min: number, y_max: number } | null>(null);
    const [ville, setVille] = React.useState<VilleDTO | null>(null);
    const [userOption, setUserOption] = React.useState<UserDTO | null>(null);
    const [idExpeAppercu, setIdExpeAppercu] = React.useState<string>("");
    const [listCitoyen, setListCitoyen] = React.useState<CitoyensDTO[]>(inscription.citoyens.sort((citoyen_a, citoyen_b) => {
        // Tri pour mettre l'utilisateur en premier
        if (citoyen_a.citoyen.id === general.user.id) {
            return -1;
        }
        if (citoyen_b.citoyen.id === general.user.id) {
            return 1;
        }
        // Tri pour mettre par ordre alpha les pseudos
        return citoyen_a.citoyen.pseudo.localeCompare(citoyen_b.citoyen.pseudo);
    }));
    const [openCommentaire, setOpenCommentaire] = React.useState<boolean>(false);
    const [editCommentaire, setEditCommentaire] = React.useState<boolean>(false);
    const [commentaireExpeditionnaire, setCommentaireExpeditionnaire] = React.useState<{ expeditionIndex: number, expePartIndex: number, expeditionnaireIndex: number }>(null);
    
    useEffect(() => {
        
        const citoyenTrier = listCitoyen.sort((citoyen_a, citoyen_b) => {
            // Tri pour mettre l'utilisateur en premier
            if (citoyen_a.citoyen.id === general.user.id) {
                return -1;
            }
            if (citoyen_b.citoyen.id === general.user.id) {
                return 1;
            }
            // Tri pour mettre par ordre alpha les pseudos
            return citoyen_a.citoyen.pseudo.localeCompare(citoyen_b.citoyen.pseudo);
        });
        
        setListCitoyen(citoyenTrier);
    }, [inscription.citoyens]);
    
    // Gestion des changements de d'information sur les expéditions et les ouvriers
    const handleChangeExpeditionnaire = (indexExped: number, index_expe: number, updatedProperties: Partial<ExpeditionnaireDTO>) => {
        const newExpeditions = [...expeditions];
        // On balaye toutes les parties de l'expedition pour metre à jour l'expediteur
        newExpeditions[index_expe].expedition_parts.map((expedition_part) => {
            expedition_part.expeditionnaires[indexExped] = {
                ...expedition_part.expeditionnaires[indexExped],
                ...updatedProperties,
            };
        });
        setExpeditions(newExpeditions);
    };
    
    // Gestion des changements de disponibilité des expéditionnaires
    const handleChangeExpeditionnaireDispo = (indexExped: number, index_expe: number, indexCreneau: number, updatedProperties: TypeDispoDTO) => {
        const newExpeditions = [...expeditions];
        // On balaye toutes les parties de l'expedition pour metre à jour l'expediteur
        newExpeditions[index_expe].expedition_parts.map((expedition_part) => {
            const dispos = expedition_part.expeditionnaires[indexExped].dispo;
            dispos[indexCreneau] = {
                ...dispos[indexCreneau],
                dispo: updatedProperties,
            };
        });
        setExpeditions(newExpeditions);
    };
    
    // Gestion du changement de citoyen dans une expédition - controle sur les préinscriptions, et s'il est déjà inscrit ailleurs
    const handleChangeExpeditionnaireInscription = (expeditionnaire: ExpeditionnaireDTO, indexExpeditionnaire: number, index_expedition: number, optionSelected: CitoyensDTO) => {
        
        const placeVide = expeditions[index_expedition].expedition_parts[0].expeditionnaires[indexExpeditionnaire].citoyen === null;
        // Copie de l'état actuel en cas d'annulation
        setSaveExpeditionnaire(JSON.parse(JSON.stringify(expeditionnaire)));
        setSelectionPseudo({ expeditionnaire, indexExpeditionnaire, index_expedition, optionSelected, placeVide });
        const nbr_inscrit = controleNbrInscription(expeditions[index_expedition]);
        if (nbr_inscrit === expeditions[index_expedition].expedition_parts[0].expeditionnaires.length - 1 && optionSelected !== null) {
            const pdc = calculPdcExpe(expeditions[index_expedition], general);
            const pdcInscrit = calculPdc(optionSelected, expeditions[index_expedition].expedition_parts[0], general);
            
            if ((pdc + pdcInscrit) < expeditions[index_expedition].min_pdc) {
                setConfirmationPdcShow(true);
                return;
            }
        }
        
        // Contrôle si le citoyen ne fait pas l'objet d'une préinscription si et seulement la sélection ne vaut pas null
        if (optionSelected === null) {
            gestionInscriptionPseudo(expeditionnaire, indexExpeditionnaire, index_expedition, optionSelected, false);
        } else {
            let preinscrit = false;
            recuperationCitoyensPreinscrit().forEach((citoyen) => {
                if (citoyen.citoyen.id === optionSelected.citoyen.id) {
                    preinscrit = true;
                }
            });
            recuperationCitoyensPreinscritOuvrier().forEach((citoyen) => {
                if (citoyen.citoyen.id === optionSelected.citoyen.id) {
                    preinscrit = true;
                }
            });
            
            if (preinscrit) {
                setStatus(Status_error);
                setMessagePopUp(t("Attention, vous êtes préinscrit ailleurs !", { ns: "hotel" }));
                setShowPop(true);
                return;
            } else {
                // vérification si le joueur n'est pas déjà inscrit ailleurs : on parcourt toutes les expéditions
                let dejaInscrit = false;
                expeditions.forEach((expedition) => {
                    expedition.expedition_parts[0].expeditionnaires.forEach((exped) => {
                        if (exped.citoyen !== null && exped.citoyen.citoyen.id === optionSelected.citoyen.id) {
                            dejaInscrit = true;
                        }
                    });
                });
                // on vérifie également s'il n'est pas inscrit dans les ouvriers
                ouvriers.forEach((ouvrier) => {
                    if (ouvrier.citoyen !== null && ouvrier.citoyen.citoyen.id === optionSelected.citoyen.id) {
                        dejaInscrit = true;
                    }
                });
                if (dejaInscrit) {
                    setConfirmationPseudoShow(true);
                } else {
                    // Si la place n'est pas vide
                    if (placeVide) {
                        gestionInscriptionPseudo(expeditionnaire, indexExpeditionnaire, index_expedition, optionSelected, placeVide);
                    } else {
                        setConfirmationPseudo2Show(true);
                    }
                }
                
            }
            
        }
        
    };
    
    const gestionInscriptionPseudo = (expeditionnaire: ExpeditionnaireDTO, indexExpeditionnaire: number, index_expedition: number, optionSelected: CitoyensDTO, placeVide: boolean) => {
        if (expeditionnaire.job_fige) {
            handleChangeExpeditionnaire(indexExpeditionnaire, index_expedition, { citoyen: optionSelected });
        } else {
            handleChangeExpeditionnaire(indexExpeditionnaire, index_expedition, { citoyen: optionSelected, job: optionSelected?.job });
        }
        handleMajPseudoExpeditionnaire(index_expedition, indexExpeditionnaire, optionSelected, placeVide);
    };
    
    const handleConfirmationExpedition = () => {
        if (selectionPseudo !== null) {
            gestionInscriptionPseudo(selectionPseudo.expeditionnaire, selectionPseudo.indexExpeditionnaire, selectionPseudo.index_expedition, selectionPseudo.optionSelected, selectionPseudo.placeVide);
            setSelectionPseudo(null);
        }
        setConfirmationPdcShow(false);
        setConfirmationPseudoShow(false);
        setConfirmationPseudo2Show(false);
    };
    
    const handleAnnulationExpedition = () => {
        // On remet à null la sélection, y compris dans le select
        const expeditionsMod = [...expeditions];
        // on balaye toutes les parties de l'expedition pour metre à jour l'expediteur
        expeditionsMod[selectionPseudo.index_expedition].expedition_parts.map((expedition_part) => {
            expedition_part.expeditionnaires[selectionPseudo.indexExpeditionnaire].citoyen = saveExpeditionnaire?.citoyen ?? null;
        });
        setSaveExpeditionnaire(null);
        setExpeditions(expeditionsMod);
        setSelectionPseudo(null);
        setConfirmationPdcShow(false);
        setConfirmationPseudoShow(false);
        setConfirmationPseudo2Show(false);
    };
    
    const filtreCitoyen = (citoyen: CitoyensDTO, action_heroic: HerosPrototypeDTO, job: JobPrototypeDTO | null) => {
        // On stocke si l'action héroïque a été utilisé ou non pour le citoyen
        let action_heroic_utilise = true;
        if (action_heroic !== null) {
            switch (action_heroic.id) {
                case sauvetage.id:
                    action_heroic_utilise = citoyen.sauvetage;
                    break;
                case rdh.id:
                    action_heroic_utilise = citoyen.rdh;
                    break;
                case uppercut.id:
                    action_heroic_utilise = citoyen.uppercut;
                    break;
                case trouvaille.id:
                    action_heroic_utilise = citoyen.trouvaille && citoyen.citoyen.der_pouv.ordre_recup <= trouvaille.recup;
                    break;
                case pef.id:
                    action_heroic_utilise = citoyen.pef && citoyen.citoyen.tem_arma;
                    break;
                case camaraderie.id:
                    action_heroic_utilise = citoyen.don_jh && citoyen.citoyen.nb_charge_camaraderie > 0;
                    break;
                case corps_sain.id:
                    action_heroic_utilise = citoyen.corps_sain && citoyen.citoyen.der_pouv.ordre_recup >= corps_sain.recup;
                    break;
                case jolie_trouvaille.id:
                    action_heroic_utilise = citoyen.trouvaille && citoyen.citoyen.der_pouv.ordre_recup >= jolie_trouvaille.recup;
                    break;
                case ss.id:
                    action_heroic_utilise = citoyen.second_souffle && citoyen.citoyen.der_pouv.ordre_recup >= ss.recup;
                    break;
                case vlm.id:
                    action_heroic_utilise = citoyen.vlm && citoyen.citoyen.der_pouv.ordre_recup >= vlm.recup;
                    break;
                default:
                    action_heroic_utilise = true;
                    break;
            }
        }
        
        // Vérifier si le métier du citoyen correspond à celui spécifié dans le filtre
        const conditionJobFige = job === null || citoyen.job.id === job.id;
        // Retourner true si le citoyen passe tous les critères de filtrage
        return action_heroic_utilise && conditionJobFige;
    };
    
    const controleNbrInscription = (expedition: ExpeditionDTO) => {
        let nbrInscription = 0;
        expedition.expedition_parts[0].expeditionnaires.map((exped) => {
            if (exped.citoyen !== null) {
                nbrInscription++;
            }
        });
        return nbrInscription;
    };
    
    const handleMajPseudoExpeditionnaire = (indexExpedition: number, indexExpeditionnaire: number, citoyen: CitoyensDTO, placeVide: boolean) => {
        const expeditionId = expeditions[indexExpedition].id;
        const expeditionnaireId = expeditions[indexExpedition].expedition_parts[0].expeditionnaires[indexExpeditionnaire].id;
        const expedition: InscriptionExpeditionSauvegardeType = {
            expedition  : {
                expedition_id     : expeditionId,
                expeditionnaire_id: expeditionnaireId,
            },
            modification: {
                citoyen   : citoyen,
                place_vide: placeVide,
            },
        };
        inscriptionApi.sauvegarderInscriptionExpedition(expedition).then(() => {
            setCitoyenExpe(citoyen);
            if (citoyen === null || (saveExpeditionnaire !== null && saveExpeditionnaire.citoyen?.citoyen?.id !== citoyen?.citoyen?.id)) {
                // on vient remettre à blanc les dispos
                const newExpeditions = [...expeditions];
                // On balaye toutes les parties de l'expedition pour mettre à jour l'expediteur
                newExpeditions[indexExpedition].expedition_parts.map((expedition_part) => {
                    const dispos = [...expedition_part.expeditionnaires[indexExpeditionnaire].dispo];
                    dispos.map((dispo) => {
                        dispo.dispo = null;
                    });
                    expedition_part.expeditionnaires[indexExpeditionnaire].dispo_rapide = null;
                });
                setExpeditions(newExpeditions);
            }
            setTimeout(() => {
                setCitoyenExpe(null);
            }, 2000);
        }).catch((error) => {
            if (error.status === 409) {
                setStatus(Status_warning);
                setMessagePopUp(error?.data?.error ?? error.message);
                setShowPop(true);
                setTimeout(() => {
                    setShowPop(false);
                    setMessagePopUp("");
                    triggerRefresh();
                }, 2000);
            } else {
                setStatus(Status_error);
                setMessagePopUp(error?.data?.error ?? error.message);
                setShowPop(true);
            }
        });
    };
    
    const handleMajSoifExpeditionnaire = (indexExpedition: number, indexExpeditionnaire: number, soif: boolean) => {
        const expeditionId = expeditions[indexExpedition].id;
        const expeditionnaireId = expeditions[indexExpedition].expedition_parts[0].expeditionnaires[indexExpeditionnaire].id;
        const expedition: InscriptionExpeditionSauvegardeType = {
            expedition  : {
                expedition_id     : expeditionId,
                expeditionnaire_id: expeditionnaireId,
            },
            modification: {
                soif: soif,
            },
        };
        inscriptionApi.sauvegarderInscriptionExpedition(expedition).then(() => {
            setSoifExpeditionnaire({ expe_id: indexExpedition, expeditionnaire_id: indexExpeditionnaire });
            setTimeout(() => {
                setSoifExpeditionnaire(null);
            }, 2000);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error.message);
            setShowPop(true);
        });
    };
    
    const handleMajDispoExpeditionnaire = (indexExpedition: number, indexExpeditionnaire: number, dispo: DispoExpeditionDTO) => {
        const expeditionId = expeditions[indexExpedition].id;
        const expeditionnaireId = expeditions[indexExpedition].expedition_parts[0].expeditionnaires[indexExpeditionnaire].id;
        const expedition: InscriptionExpeditionSauvegardeType = {
            expedition  : {
                expedition_id     : expeditionId,
                expeditionnaire_id: expeditionnaireId,
            },
            modification: {
                dispo: dispo,
            },
        };
        inscriptionApi.sauvegarderInscriptionExpedition(expedition).then(() => {
            setDispoExpeditionnaire({ expe_id: indexExpedition, expeditionnaire_id: indexExpeditionnaire, creneauId: dispo.creneau.id });
            setTimeout(() => {
                setDispoExpeditionnaire(null);
            }, 2000);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error.message);
            setShowPop(true);
        });
    };
    
    const handleMajConsigneExpedition = (expedition_part: ExpeditionPartDTO, consigne: ConsigneExpeditionDTO, fait: boolean) => {
        const consigneSauvegarde: InscriptionConsigneSauvegardeType = {
            consigne_id       : consigne.id,
            expedition_part_id: expedition_part.id,
            valide            : fait,
        };
        inscriptionApi.sauvegarderConsigneExpedition(consigneSauvegarde).then(() => {
            setConsigneSave(consigne);
            setTimeout(() => {
                setConsigneSave(null);
                triggerRefresh();
            }, 2000);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error.message);
            setShowPop(true);
        });
    };
    
    const handleAppercuTrace = (id_trace: string) => {
        inscriptionApi.appercuTrace(id_trace).then((response) => {
            setIdExpeAppercu(id_trace);
            setZoneAppercu(response.data.appercu.zones);
            setTraceAppercu(response.data.appercu.trace);
            setVille(response.data.appercu.ville);
            setUserOption(response.data.appercu.user);
            setBorne({ x_min: response.data.appercu.x_min, x_max: response.data.appercu.x_max, y_min: response.data.appercu.y_min, y_max: response.data.appercu.y_max });
            setAfficherAppercu(true);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error.message);
            setShowPop(true);
        });
    };
    
    const handleChangeDispo = (expedition: ExpeditionDTO, dispo_value: string) => {
        // On va créer une copie de l'expedition pour la mettre à jour
        const newExpeditions = [...expeditions];
        // On recherche l'expédition
        const expeditionIndex = newExpeditions.findIndex((exp) => exp.id === expedition.id);
        const expeditionMod = newExpeditions[expeditionIndex];
        
        const dispo_rapide = inscription.userOption.dispo_user_type_expeditions.find((dispo) => dispo.id === parseInt(dispo_value));
        
        const expeditionId = expedition.id;
        const expeditionnaireId = expedition.expedition_parts[0].expeditionnaires.find((exped) => exped.citoyen !== null && exped.citoyen.citoyen.id === general.user.id).id;
        const expeditionData: InscriptionExpeditionSauvegardeType = {
            expedition  : {
                expedition_id     : expeditionId,
                expeditionnaire_id: expeditionnaireId,
            },
            modification: {
                dispos: dispo_rapide,
            },
        };
        
        inscriptionApi.sauvegarderInscriptionExpedition(expeditionData).then(() => {
            // On va balayer toutes les parties de l'expedition pour mettre à jour les dispos
            expeditionMod.expedition_parts.map((expedition_part) => {
                expedition_part.expeditionnaires.map((exped) => {
                    if (exped.citoyen !== null) {
                        // On vérifie si le citoyen est l'utilisateur connecté
                        if (exped.citoyen.citoyen.id === general.user.id) {
                            // On va balayer les différents créneaux pour alimenter les dispos selon la disponibilité rapide
                            exped.dispo.map((dispo) => {
                                if (dispo_rapide === null) {
                                    dispo.dispo = null;
                                } else {
                                    dispo.dispo = dispo_rapide.creneau.find((creneau) => creneau.creneau.id === dispo.creneau.id).dispo;
                                }
                            });
                        }
                    }
                });
            });
            // On va mettre à jour l'expedition
            setExpeditions(newExpeditions);
            setDispoRapideExpeditionnaire({ expe_id: expeditionId });
            setTimeout(() => {
                setDispoRapideExpeditionnaire(null);
            }, 2000);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error.message);
            setShowPop(true);
        });
    };
    
    const lectureSeul = jourActuel < general.ville.jour;
    
    const getListSelectDispo = (expedition: ExpeditionDTO, index_exp: number) => {
        if (inscription.userOption.dispo_user_type_expeditions.length === 0) {
            return null;
        }
        
        const index_expeditionnaire = expedition.expedition_parts[0].expeditionnaires.findIndex((exped) => exped.citoyen !== null && exped.citoyen.citoyen.id === general.user.id);
        const expeditionnaire = expedition.expedition_parts[0].expeditionnaires[index_expeditionnaire];
        
        return <select value={expeditionnaire.dispo_rapide ?? 0} onChange={(event) => {
            handleChangeExpeditionnaire(index_expeditionnaire, index_exp, { dispo_rapide: parseInt(event.target.value) });
            handleChangeDispo(expedition, event.target.value);
        }}>
            <option value={0}>{t("Utiliser une dispo rapide", { ns: "hotel" })}</option>
            {inscription.userOption.dispo_user_type_expeditions.sort((dispo_a, dispo_b) => dispo_a.priorite < dispo_b.priorite ? -1 : 1).map((dispo) => {
                return <option key={dispo.id} value={dispo.id}>{dispo.nom}</option>;
            })}
        </select>;
    };
    
    const handleCommentaire = (text: string) => {
        const expeditionnaireId = expeditions[commentaireExpeditionnaire.expeditionIndex].expedition_parts[commentaireExpeditionnaire.expePartIndex].expeditionnaires[commentaireExpeditionnaire.expeditionnaireIndex].id;
        const expeditionPartId = expeditions[commentaireExpeditionnaire.expeditionIndex].expedition_parts[commentaireExpeditionnaire.expePartIndex].id;
        // Appel de l'API pour sauvegarder le commentaire
        inscriptionApi.sauvegardeCommentaireExpeditionnaire({ expeditionnaire_id: expeditionnaireId, expedition_part_id: expeditionPartId, commentaire: text }).then(() => {
            // On met à jour le commentaire
            const expeditionsMod = [...expeditions];
            expeditionsMod[commentaireExpeditionnaire.expeditionIndex].expedition_parts[commentaireExpeditionnaire.expePartIndex].expeditionnaires[commentaireExpeditionnaire.expeditionnaireIndex].commentaire = text;
            setExpeditions(expeditionsMod);
            setEditCommentaire(false);
            setOpenCommentaire(false);
            setCommentaireExpeditionnaire(null);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error.message);
            setShowPop(true);
        });
    };
    
    return <div id={"liste_expeditions_inscription"}>
        <Helmet>
            <style>{generateCSSUser(general.themeUser.user_perso_couleur)}</style>
        </Helmet>
        <h1>{t("Liste des expéditions", { ns: "app" })}</h1>
        {!lectureSeul && <div id={"option_masquave_expe_complete"}>
            <label>{t("Masquer les expéditions complètes", { ns: "hotel" })}</label>
            <CustomSwitchToggle checked={maskComplete} size={"sm"} onstyle="success" offstyle={"danger"}
                                onlabel={t("Oui", { ns: "app" })} offlabel={t("Non", { ns: "app" })}
                                onChange={() => setMaskComplete(!maskComplete)} />
        </div>}
        <div id={"zone_tab_expedition"}>
            {expeditions.sort((expedition_a, expedition_b) => {
                // On trie les expéditions par ordre croissant de priorité, sauf si l'utilisateur est inscrit et qu'il a l'option qui met en avant son expédition
                if (inscription.userOption.user_personnalisation.expe_on_the_top) {
                    // Si l'utilisateur est dans l'expédition A, alors A doit venir en premier
                    if (myExpedition(expedition_a, general.user)) {
                        return -1;
                    }
                    // Si l'utilisateur est dans l'expédition B, alors B doit venir en premier
                    if (myExpedition(expedition_b, general.user)) {
                        return 1;
                    }
                    // Si l'utilisateur n'est dans aucune des deux expéditions, alors on trie par ordre de priorité
                }
                return expedition_a.priorite < expedition_b.priorite ? -1 : 1;
            })
                .map((expedition, index_exp) => {
                    return <div key={expedition.id} className={maskComplete ? ((!calculExpeComplete(expedition) || calculMyExpe(expedition, general.user)) ? "zone_expedition_group_part" : "zone_expedition_mask_group_part") : "zone_expedition_group_part"}>
                        {expedition.expedition_parts.sort((exp_a, exp_b) => exp_a.number < exp_b.number ? -1 : 1).map((expedition_part, index_part) => {
                            const preinscrit = recuperationCitoyensPreinscritExpedition(expedition);
                            const me = myExpedition(expedition, general.user);
                            
                            return <div key={expedition_part.id} className={"zone_expedition_expe_part"}>
                                <table className={"inscription_expedition"}>
                                    <thead>
                                    <tr className={"ligne_inscription_expedition"}>
                                        <th rowSpan={2} className={"cols40_expedition"}><SvgIcone icone={expedition.type_expe.icon} classIcone={"itemExpe"} /></th>
                                        <th colSpan={17} style={{ fontSize: "18px" }}>
                                            <div className={"expedition_titre"}>
                                                <span onClick={() => handleAppercuTrace(expedition_part.trace.id)} style={{ cursor: "pointer" }}><SvgIcone icone={"r_explor"} /></span>
                                                {expedition.type_expe.id === ID_RAMASSAGE ? (<span>{expedition.nom} - {index_part + 1}</span>) : (<span>{expedition.nom}</span>)}
                                                <span className={"expedition_titre d-flex gap-1 align-items-center justify-content-center"}>
                                                    <span>-</span>
                                                    <span className={"d-flex gap-1 align-items-center justify-content-center"}>
                                                        <span>{expedition_part.pa}</span>
                                                        <SvgIcone icone={"h_pa"} />
                                                    </span>
                                                </span>
                                                {calculExpeComplete(expedition) && <span className={"color-red expedition_titre"}><span>-</span><span>{t("Complète", { ns: "hotel" })}</span></span>}
                                            </div>
                                        </th>
                                        <th rowSpan={2} colSpan={3} className={"pdc_zone_tab"}>
                                            <div className={(calculPdcExpe(expedition, general) >= (expedition?.min_pdc ?? 0)) ? "color-green" : "color-red"}>
                                                <span>{calculPdcExpe(expedition, general)}</span>
                                                <span>/</span>
                                                <span>{expedition.min_pdc}</span>
                                                <span>{t("PDC", { ns: "hotel" })}</span>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr className={"ligne_inscription_expedition"}>
                                        <th colSpan={17}>
                                            {preinscrit.length > 0 && <>
                                                <span>{t("Préinscrits sur cette expédition :", { ns: "hotel" })}</span>
                                                <span className={"preinscrit_expeditions"}>{preinscrit.map((citoyen) => {
                                                    return <span key={citoyen.citoyen.id} className={"preinscrit"}>
                                                    <SvgIcone icone={"h_" + citoyen.job?.icon} />
                                                    <span>
                                                        <span className={citoyen.ban ? "color-red" : ""}>{citoyen.citoyen.pseudo}</span>
                                                        {citoyen.ban && <SvgIcone icone={"h_ban"} />}
                                                    </span>
                                                </span>;
                                                })}</span>
                                            </>}
                                        </th>
                                    </tr>
                                    <tr className={"ligne_inscription_expedition"}>
                                        <th rowSpan={2} className={"cols40_expedition"}></th>
                                        <th rowSpan={2} className={"pseudo_expedition"}>{t("Pseudo", { ns: "hotel" })}</th>
                                        <th rowSpan={2} className={"cols40_expedition"}>
                                            <span className={"d-flex gap-1 align-items-center justify-content-center"}>
                                                <SvgIcone icone={"h_ban"} />
                                            </span>
                                        </th>
                                        <th rowSpan={2} className={"cols40_expedition"}>
                                            <span className={"d-flex gap-1 align-items-center justify-content-center"}>
                                                <SvgIcone icone={"r_dwater"} />
                                            </span>
                                        </th>
                                        <th rowSpan={2} className={"cols20_expedition"}>
                                            <span className={"d-flex gap-1 align-items-center justify-content-center"}>
                                                <Tooltips label={t("Points d'action de base", { ns: "hotel" })}>
                                                    <SvgIcone icone={"h_pa"} /></Tooltips>
                                            </span>
                                        </th>
                                        <th rowSpan={2} className={"cols20_expedition"}>
                                            <span className={"d-flex gap-1 align-items-center justify-content-center"}>
                                                <Tooltips label={t("Points d'exploration de base", { ns: "hotel" })}><SvgIcone icone={"h_pe"} /></Tooltips>
                                            </span>
                                        </th>
                                        <th rowSpan={2} className={"cols20_expedition"}>
                                            <span className={"d-flex gap-1 align-items-center justify-content-center"}>
                                                <SvgIcone icone={"h_guard"} />
                                            </span>
                                        </th>
                                        <th rowSpan={2} className={"cols20_expedition"}>
                                            <span className={"d-flex gap-1 align-items-center justify-content-center"}>
                                                <Tooltips label={t("Commentaires joueurs", { ns: "hotel" })}><FontAwesomeIcon icon={faComment} /></Tooltips>
                                            </span>
                                        </th>
                                        <th rowSpan={2} className={"sac_expedition"}>{t("Sac", { ns: "hotel" })}</th>
                                        <th rowSpan={2} className={"ah_expedition"}>{t("Action héroïque", { ns: "hotel" })}</th>
                                        <th colSpan={inscription.liste.creneau.length}>
                                            <span className={"dispo_rapide_inscription"}>
                                                <span>{t("Disponibilités", { ns: "hotel" })}</span>
                                                {me && !lectureSeul && <span>{getListSelectDispo(expedition, index_exp)}</span>}
                                                {me && dispoRapideExpeditionnaire !== null && dispoRapideExpeditionnaire.expe_id === expedition.id && <span className={"color-green"}><i className="fa-solid fa-calendar-check"></i></span>}
                                            </span>
                                        </th>
                                    </tr>
                                    <tr className={"ligne_inscription_expedition"}>
                                        {inscription.liste.creneau.map((creneau) => {
                                            return <th key={creneau.id} className={"cols40_expedition"}>{t(creneau.libelle, { ns: "jump" })}</th>;
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {expedition_part.expeditionnaires.sort((exped_a, exped_b) => exped_a.position < exped_b.position ? -1 : 1).map((exped, index) => {
                                        return <tr key={exped.id} className={"ligne_inscription_expedition ligne_citoyen_user" + ((exped.citoyen?.citoyen?.id === general.user.id) ? " my_line_expedition" : "")}>
                                            <td className={"cols40_expedition"}>
                                                <div className={"zone_td_display"}>
                                                    {exped.job && <SvgIcone icone={"h_" + exped.job?.icon} />}
                                                </div>
                                            </td>
                                            <td className={"pseudo_expedition"}>
                                                <div className={"zone_td_display"}>{(lectureSeul || exped.preinscrit) ? (
                                                    <span className={"pseudo_expedition_liste"}>
                                                    <span className={exped.citoyen?.ban ? "color-red" : ""}>{exped.citoyen?.citoyen.pseudo}</span>
                                                        {exped.citoyen?.ban && <SvgIcone icone={"h_ban"} />}
                                                </span>
                                                ) : (
                                                    <Select
                                                        value={exped.citoyen?.citoyen ? listCitoyen.find((citoyen) => citoyen.citoyen.id === exped.citoyen.citoyen.id) : null}
                                                        id={"pseudo_expeditionnaire_" + index}
                                                        name={"pseudo_expeditionnaire_" + index}
                                                        options={listCitoyen.filter((citoyen) => {
                                                            if (exped.action_heroic !== null || exped.job_fige) {
                                                                return filtreCitoyen(citoyen, exped.action_heroic, exped.job_fige ? exped.job : null);
                                                            }
                                                            return true;
                                                        })}
                                                        isClearable={true}
                                                        components={{ SingleValue: PseudoSingleValue, Option: PseudoOption }}
                                                        getOptionLabel={(citoyen: CitoyensDTO) => citoyen.citoyen.pseudo}
                                                        getOptionValue={(citoyen: CitoyensDTO) => citoyen.citoyen.id.toString()}
                                                        onChange={(optionSelected: CitoyensDTO) => {
                                                            handleChangeExpeditionnaireInscription(exped, index, index_exp, optionSelected);
                                                        }}
                                                        styles={customStyle(general.themeUser, stylePseudo, (citoyenExpe !== null && citoyenExpe?.citoyen !== null && citoyenExpe?.citoyen?.id === exped.citoyen?.citoyen.id))}
                                                    />
                                                )}</div>
                                            </td>
                                            <td className={"cols40_expedition"}>
                                                <div className={"zone_td_display"}>
                                                    {exped.for_banni ? <SvgIcone icone={"h_ban"} /> : <i className="fa-solid fa-xmark color-red"></i>}
                                                </div>
                                            </td>
                                            <td className={"cols40_expedition"}>
                                                <div className={"zone_td_display"}>
                                                    {(lectureSeul || exped.soif_fige) ? (exped.soif ? <SvgIcone icone={"r_dwater"} /> : <SvgIcone icone={"status_clean"} />) : (
                                                        <Select value={optionsSoif.find((soif) => soif.value === (exped.soif ? 1 : (exped.soif ? null : 0)))}
                                                                id={"soif_expeditionnaire_" + index}
                                                                name={"soif_expeditionnaire_" + index}
                                                                options={optionsSoif}
                                                                isClearable={false}
                                                                components={{ SingleValue: SoifSingleValue, Option: SoifOption }}
                                                                getOptionLabel={(soif: SoifType) => soif.label}
                                                                getOptionValue={(soif: SoifType) => soif.value.toString()}
                                                                onChange={(optionSelected: SoifType) => {
                                                                    handleChangeExpeditionnaire(index, index_exp, { soif: optionSelected.value === 1 });
                                                                    handleMajSoifExpeditionnaire(index_exp, index, optionSelected.value === 1);
                                                                }}
                                                                styles={customStyle(general.themeUser, styleJob, (soifExpeditionnaire !== null && soifExpeditionnaire.expe_id === index_exp && soifExpeditionnaire.expeditionnaire_id === index))} />
                                                    )}</div>
                                            </td>
                                            <td className={"cols20_expedition"}>
                                                <div className={"zone_td_display"}>{exped.pa_base ?? 6}</div>
                                            </td>
                                            <td className={"cols20_expedition"}>
                                                <div className={"zone_td_display"}>{exped.pe_base ?? 0}</div>
                                            </td>
                                            <td className={"cols20_expedition"}>
                                                <div className={"zone_td_display"}>{calculPdc(exped?.citoyen ?? null, expedition_part, general)}</div>
                                            </td>
                                            <td className={"cols40_expedition"}>
                                                <div className={"zone_td_display gap-1"}>
                                                    {exped.commentaire && <FontAwesomeIcon icon={faComment} style={{ cursor: "pointer" }} onClick={() => {
                                                        setCommentaireExpeditionnaire({ expeditionIndex: index_exp, expePartIndex: index_part, expeditionnaireIndex: index });
                                                        setOpenCommentaire(true);
                                                    }} />}
                                                    {((exped.citoyen?.citoyen?.id === general.user.id) && !lectureSeul) && <Button couleur={"success"} taille={"xs"} onClick={() => {
                                                        setCommentaireExpeditionnaire({ expeditionIndex: index_exp, expePartIndex: index_part, expeditionnaireIndex: index });
                                                        setEditCommentaire(true);
                                                    }}><FontAwesomeIcon icon={faPenToSquare} /></Button>}
                                                </div>
                                            </td>
                                            <td className={"sac_expedition"}>
                                                <div className={"zone_sac_expeditionnaire"}>
                                                    <div className={"sac_expeditionnaire"}>{exped.sac.map((sacItems) => {
                                                        return <div key={`obj_${sacItems.item.id}_${sacItems.broken ? 1 : 0}`}>
                                                            <AffNbrIcon
                                                                item={sacItems.item}
                                                                broken={sacItems.broken}
                                                                nbr={sacItems.nbr}
                                                                classSpecifique={"itemSacOutilsExpedition"}
                                                            />
                                                        </div>;
                                                    })}</div>
                                                </div>
                                            </td>
                                            <td className={"ah_expedition"}>
                                                <div className={"zone_td_display"}>
                                                    <SvgIcone icone={exped.action_heroic?.icon} />
                                                    <span>{exped.action_heroic?.nom}</span>
                                                </div>
                                            </td>
                                            {
                                                inscription.liste.creneau.map((creneau, indexCreneau) => {
                                                    
                                                    const dispoBlock = inscription.userOption.user_personnalisation.fix_inscription_expe && exped.citoyen?.citoyen?.id !== general.user.id;
                                                    
                                                    return <td key={creneau.id} className={"cols40_expedition"}>
                                                        <div className={"zone_td_display"}>
                                                            <Select
                                                                value={exped.dispo[indexCreneau].dispo ? inscription.liste.dispo.find(dispo => dispo.id === exped.dispo[indexCreneau].dispo.id) : null}
                                                                id={"dispo_expeditionnaire_" + indexCreneau + "_" + index}
                                                                name={"dispo_expeditionnaire_" + indexCreneau + "_" + index}
                                                                options={inscription.liste.dispo.sort((a, b) => a.nom.localeCompare(b.nom))}
                                                                isClearable={true}
                                                                isDisabled={lectureSeul || dispoBlock}
                                                                components={{ SingleValue: DispoSingleValue, Option: DispoOption }}
                                                                getOptionLabel={(dispo) => dispo.nom}
                                                                getOptionValue={(dispo) => dispo.id.toString()}
                                                                onChange={(optionSelected: TypeDispoDTO) => {
                                                                    handleChangeExpeditionnaireDispo(index, index_exp, indexCreneau, optionSelected);
                                                                    handleMajDispoExpeditionnaire(index_exp, index, { id: exped.dispo[indexCreneau].id, creneau: creneau, dispo: optionSelected });
                                                                }}
                                                                styles={customStyleDispo(general.themeUser,
                                                                    styleDispo,
                                                                    dispoExpeditionnaire !== null && dispoExpeditionnaire.expe_id === index_exp && dispoExpeditionnaire.expeditionnaire_id === index && dispoExpeditionnaire.creneauId === creneau.id,
                                                                    calculColorDispo(exped.dispo[indexCreneau].dispo, general.themeUser.user_perso_couleur),
                                                                )}
                                                            />
                                                        </div>
                                                    </td>;
                                                })
                                            }
                                        </tr>;
                                    })}
                                    {expedition_part.description !== null && <>
                                        <tr style={{ height: "5px" }}></tr>
                                        <tr className={"ligne_inscription_expedition"}>
                                            <th colSpan={2}>{t("Description", { ns: "hotel" })}</th>
                                            <td colSpan={6 + inscription.liste.creneau.length} className={"description_expedition_part"}>{HTMLParser(expedition_part.description)}</td>
                                        </tr>
                                    </>}
                                    {expedition_part.consignes.length > 0 && <>
                                        <tr className={"ligne_inscription_expedition"}>
                                            <th colSpan={8 + inscription.liste.creneau.length}>{t("Consignes", { ns: "hotel" })}</th>
                                        </tr>
                                        {expedition_part.consignes.sort((consigne_a: ConsigneExpeditionDTO, consigne_b: ConsigneExpeditionDTO) => {
                                            return consigne_a.ordre_consigne < consigne_b.ordre_consigne ? -1 : 1;
                                        }).map((consigne, indexConsigne) => {
                                            return <tr key={indexConsigne} className={"ligne_inscription_expedition"}>
                                                <td colSpan={1}>
                                                    <div style={(consigneSave !== null && consigneSave.id === consigne.id) ? { border: "2px solid lime" } : {}}>
                                                        {(me && !lectureSeul) ? (<input type={"checkbox"} checked={consigne?.fait ?? false} onChange={(event) => {
                                                            const newExpeditions = [...expeditions];
                                                            newExpeditions[index_exp].expedition_parts[0].consignes[indexConsigne].fait = event.target.checked;
                                                            setExpeditions(newExpeditions);
                                                            handleMajConsigneExpedition(expedition_part, consigne, event.target.checked);
                                                        }} />) : (consigne?.fait ? <i className="fa-solid fa-check color-green"></i> : <i className="fa-solid fa-xmark color-red"></i>)}
                                                    </div>
                                                </td>
                                                <td colSpan={8 + inscription.liste.creneau.length} className={"consigne_zone_checkbox"}>
                                                    <div>
                                                        <div>{consigne?.zone?.x_rel}/{consigne?.zone?.y_rel}</div>
                                                        <div>:</div>
                                                        <div className={"text_consigne_zone_consigne"}>{HTMLParser(consigne.text)}</div>
                                                    </div>
                                                </td>
                                            </tr>;
                                        })}
                                    </>}
                                    </tbody>
                                </table>
                            </div>;
                        })}
                    </div>;
                })}
        </div>
        <ConfirmationDialog
            message={t("Êtes vous sûr de vous inscrire ici ? Vous n'aurez pas les points de contrôle nécessaire !", { ns: "hotel" })}
            titre={t("Attention !", { ns: "hotel" })}
            btnAnnuler={t("Non", { ns: "hotel" })}
            btnConfirmation={t("Oui", { ns: "hotel" })}
            show={confirmationPdcShow}
            onConfirm={handleConfirmationExpedition}
            onCancel={handleAnnulationExpedition}
        />
        <ConfirmationDialog
            message={t("Joueur déjà inscrit ailleurs, êtes vous sûr ?", { ns: "hotel" })}
            titre={t("Attention !", { ns: "hotel" })}
            btnAnnuler={t("Non", { ns: "hotel" })}
            btnConfirmation={t("Oui", { ns: "hotel" })}
            show={confirmationPseudoShow}
            onConfirm={handleConfirmationExpedition}
            onCancel={handleAnnulationExpedition}
        />
        <ConfirmationDialog
            message={t("Il y a déjà un joueur inscrit à cette place, êtes vous sûr ?", { ns: "hotel" })}
            titre={t("Attention !", { ns: "hotel" })}
            btnAnnuler={t("Non", { ns: "hotel" })}
            btnConfirmation={t("Oui", { ns: "hotel" })}
            show={confirmationPseudo2Show}
            onConfirm={handleConfirmationExpedition}
            onCancel={handleAnnulationExpedition}
        />
        <Modal show={afficherAppercu} onHide={() => {
            setAfficherAppercu(false);
        }} centered key={"modal_appercu"}>
            {afficherAppercu && <div className={"modal-centralisation"}>
                <div id={"modal_appercu"} style={{ width: `${25 * (borne.x_max - borne.x_min + 1) + 50}px` }}>
                    <h2>{t("Aperçu du tracé", { ns: "hotel" })}</h2>
                    <AppercuCarte trace={traceAppercu} ville={ville} x_max={borne.x_max} y_max={borne.y_max} x_min={borne.x_min} y_min={borne.y_min} zones={zoneAppercu} user={userOption} idTrace={idExpeAppercu} />
                    <button className={"btn btn-primary btn-xs"} onClick={() => {
                        setAfficherAppercu(false);
                        setIdExpeAppercu("");
                        setZoneAppercu(null);
                        setTraceAppercu([]);
                        setBorne(null);
                        setVille(null);
                        setUserOption(null);
                    }}>{t("Fermer", { ns: "hotel" })}</button>
                </div>
            </div>}
        </Modal>
        <Modal show={editCommentaire} onHide={() => setEditCommentaire(false)} centered key={"modal_commentaire_expeditionnaire_edit"}>
            {commentaireExpeditionnaire !== null && <div id={"popUpConsigne"}>
                <PopUpCommentaire translate={{ valider: t("Valider", { ns: "ville" }), annuler: t("Annuler", { ns: "ville" }) }}
                                  texte={expeditions[commentaireExpeditionnaire.expeditionIndex].expedition_parts[commentaireExpeditionnaire.expePartIndex].expeditionnaires[commentaireExpeditionnaire.expeditionnaireIndex]?.commentaire ?? ""}
                                  onChange={(text) => {
                                      handleCommentaire(text);
                                  }}
                                  onCancel={() => {
                                      setCommentaireExpeditionnaire(null);
                                      setEditCommentaire(false);
                                  }}
                                  lang={general.user.lang}
                />
            </div>}
        </Modal>
        <Modal show={openCommentaire} onHide={() => setOpenCommentaire(false)} centered key={"modal_commentaire_expeditionnaire_view"}>
            {commentaireExpeditionnaire !== null && <div id={"popUpConsigne"}>
                <div>{HTMLParser(expeditions[commentaireExpeditionnaire.expeditionIndex].expedition_parts[commentaireExpeditionnaire.expePartIndex].expeditionnaires[commentaireExpeditionnaire.expeditionnaireIndex]?.commentaire ?? "")}</div>
                <div>
                    <Button taille={"xs"} couleur={"primary"} onClick={() => setOpenCommentaire(false)}>{t("Fermer", { ns: "app" })}</Button>
                </div>
            </div>}
        </Modal>
    </div>;
}