import axios from "axios";

export class FetchGH {
    
    private readonly rest: string = null;
    private readonly axiosInstance: any;
    
    constructor(restEndpoint?: string, mapId = 0, version = 1) {
        // @ts-ignore
        this.rest = `${window.location.protocol}//${window.location.host}/rest/v${version}/${this.remove_slashes(restEndpoint ?? "")}`;
        
        this.axiosInstance = axios.create({
            baseURL        : this.rest,
            headers        : {
                "Content-Type": "application/json",
                "Accept"      : "application/json",
                "gh-mapId"    : mapId,
            },
            withCredentials: true,
        });
    }
    
    public get(path) {
        return this.request("GET", path);
    }
    
    public post({ path, data }: { path: string, data?: any }) {
        return this.request("POST", path, data);
    }
    
    public delete(path, data = null) {
        return this.request("DELETE", path, data);
    }
    
    private remove_slashes(url: string): string {
        return url.match(/^\/?(.*?)\/?$/)[1];
    }
    
    private async request(method, path, data = null) {
        try {
            return await this.axiosInstance({
                method: method,
                url   : path,
                data  : data,
            });
        } catch (error) {
            // Ajouter le statut de la réponse à l'objet d'erreur avant de le propager
            if (error.response) {
                // Vous pouvez ajouter des informations supplémentaires ici si nécessaire
                throw { status: error.response.status, message: error.message, data: error.response.data };
            } else {
                // Gestion des erreurs non liées à une réponse HTTP
                throw error;
            }
        }
    }
}