import * as React                                                           from "react";
import {useEffect, useState}                                                from "react";
import {v4 as uuid}                                                         from "uuid";
import Select, {components, OptionProps, SingleValueProps}                  from "react-select";
import SvgIcone                                                             from "../../../components/generality/SvgIcone";
import {calculChanceCampingAnonyme, CampeurAnonyme, InfoCaseCampingAnonyme} from "../../Ville/Carte/calculCamping";
import {BatPrototypeDTO}                                                    from "../../../types/models/batPrototype.dto";
import {JobPrototypeDTO}                                                    from "../../../types/models/jobPrototype.dto";
import {useTranslation}                                                     from "react-i18next";
import TooltipGH                                                            from "../../../components/utils/TooltipGH";

const {SingleValue, Option} = components;

interface PropsCamping {
    listBat: BatPrototypeDTO[];
    listJob: JobPrototypeDTO[];
}

const IconSingleValue = (props: SingleValueProps<JobPrototypeDTO>) => {
    const {t} = useTranslation();
    return (
        <SingleValue {...props}>
            <SvgIcone icone={"h_" + props.data.icon}/>
            <span className={"joueurCp"}>{t(props.data.nom, {ns: "game"})}</span>
        </SingleValue>
    );
};

const IconOption = (props: OptionProps<JobPrototypeDTO>) => {
    const {t} = useTranslation();
    return (
        <Option {...props}>
            <SvgIcone icone={"h_" + props.data.icon}/>
            <span className={"joueurCp"}>{t(props.data.nom, {ns: "game"})}</span>
        </Option>
    );
};

const customStyles = {
    option           : (provided) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    singleValue      : (provided) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    control          : (provided) => ({
        ...provided,
        minHeight: "24px",
        height   : "24px",
    }),
    input            : (provided) => ({
        ...provided,
        gridTemplateColumns: "none",
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0",
    }),
    valueContainer   : (provided) => ({
        ...provided,
        padding: "0px 8px",
    }),
    menu             : (provided) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
    menuList         : (provided) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
    menuPortal       : (provided) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
};

export default function CampingGeneral({listBat, listJob}: PropsCamping) {
    const {t} = useTranslation();
    const [infoCase, setInfoCase] = useState<InfoCaseCampingAnonyme>({
        phare        : false,
        nuit         : true,
        devast       : false,
        nbrAmelioOD  : 0,
        zombie       : 0,
        bonus_camping: -25,
        nbrCampMax   : 99,
        km           : 1,
        hard         : false,
        nbTas        : 0,
    });
    const [idBat, setIdBat] = useState(-2);
    const [campeurs, setCampeurs] = useState<CampeurAnonyme[]>([
        {
            id            : uuid(),
            job           : {icon: "", nom: t("Aucun", {ns: "jump"}), id: 1, alternatif: t("Indécis", {ns: "jump"})},
            campeur_pro   : false,
            capuche       : true,
            tombe         : false,
            nb_camping    : 0,
            objet_camping : 0,
            chance_camping: 0,
        },
    ]);
    
    const addCampeur = () => {
        const newCampeur = {
            id            : uuid(),
            job           : {icon: "", nom: "Aucun", id: 1, alternatif: "Indécis"},
            campeur_pro   : false,
            capuche       : true,
            tombe         : false,
            nb_camping    : 0,
            objet_camping : 0,
            chance_camping: 0,
        };
        
        setCampeurs([...campeurs, newCampeur]);
        updateSuccessChanceAll([...campeurs, newCampeur]);
    };
    
    const deleteCamper = (id) => {
        const updatedCampeurs = campeurs.filter((campeur) => campeur.id !== id);
        setCampeurs(updatedCampeurs);
    };
    
    const updateSuccessChance = (index, updatedCamper: CampeurAnonyme) => {
        const updatedCampeurs = [...campeurs];
        updatedCampeurs[index] = updatedCamper;
        // Mettre à jour le tableau de campeurs
        updatedCampeurs[index] = {
            ...updatedCampeurs[index],
            chance_camping: calculChanceCampingAnonyme(
                infoCase,
                updatedCamper,
                index + 1,
            ),
        };
        
        // Mettre à jour l'état des campeurs
        setCampeurs(updatedCampeurs);
    };
    
    const updateSuccessChanceAll = (newCampeurs: CampeurAnonyme[] = campeurs) => {
        const campeursCalc = [...newCampeurs];
        
        campeursCalc.map((campeur, index) => {
            campeur.chance_camping = calculChanceCampingAnonyme(
                infoCase,
                campeur,
                index + 1,
            );
            campeursCalc[index] = campeur;
        });
        
        setCampeurs(campeursCalc);
    };
    
    const swapCampers = (index1: number, index2: number) => {
        const newCampeurs = [...campeurs];
        const campeur1 = {...newCampeurs[index1]};
        const campeur2 = {...newCampeurs[index2]};
        newCampeurs.splice(index1, 1, campeur2);
        newCampeurs.splice(index2, 1, campeur1);
        
        setCampeurs(newCampeurs); // Mettre à jour l'état avec les campeurs permutés
        updateSuccessChanceAll(newCampeurs); // Recalculer les chances de camping avec les nouveaux campeurs
    };
    
    const OptionsList = (id: number) => {
        const options = [];
        
        for (let i = 0; i <= 12; i++) {
            options.push(
                <option key={id + "_" + i} value={i}>
                    {i}
                </option>,
            );
        }
        
        return (
            <select
                id={`odc-${id}`}
                value={campeurs[id].objet_camping ?? 0}
                onChange={(event) => {
                    const updatedCamper = {
                        ...campeurs[id],
                        objet_camping: parseInt(event.target.value, 10),
                    };
                    updateSuccessChance(id, updatedCamper);
                }}
            >
                {options}
            </select>
        );
    };
    
    const NbCampingList = (id: number, nb_camping: number) => {
        const options = [];
        
        for (let i = 0; i <= 10; i++) {
            options.push(
                <option key={id + "_" + i} value={i}>
                    {i}
                </option>,
            );
        }
        
        return (
            <select
                value={nb_camping}
                id={`nb_camp-${id}`}
                onChange={(event) => {
                    const updatedCamper = {
                        ...campeurs[id],
                        nb_camping: parseInt(event.target.value, 10),
                    };
                    updateSuccessChance(id, updatedCamper);
                }}
            >
                {options}
            </select>
        );
    };
    
    useEffect(() => {
        updateSuccessChanceAll();
    }, [infoCase, idBat]);
    
    // create a distance array from 0 to 30
    const distance: number[] = [];
    for (let i = 0; i <= 30; i++) {
        distance.push(i);
    }
    
    const listBatFiltred = Object.values(listBat).filter(
        (bat) => bat.km_min <= infoCase.km && bat.km_max >= infoCase.km,
    );
    
    return (
        <div className={"outilsCalculCamping"}>
            <div id={"form_camp_batiment"}>
                <div key={"km"} id={"camping_km_select"} className={"d-flex justify-content-start align-content-center align-items-center gap-2"}>
                    <label>{t("Distance de la ville:", {ns: "ville"})}</label>
                    <select
                        value={infoCase.km}
                        onChange={(event) => {
                            setInfoCase({
                                ...infoCase,
                                ...{km: parseInt(event.target.value, 10)},
                            });
                        }}
                    >
                        {distance.map((km) => (
                            <option key={km} value={km}>
                                {km}
                            </option>
                        ))}
                    </select>
                </div>
                <div key={"batiment"} id={"camping_batiment_select"} className={"d-flex justify-content-start align-content-center align-items-center gap-2"}>
                    <label>{t("Bâtiment", {ns: "ville"})}</label>
                    <select
                        value={idBat}
                        onChange={(event) => {
                            if (event.target.value === "-2") {
                                setIdBat(-2);
                                setInfoCase({
                                    ...infoCase,
                                    ...{bonus_camping: -25, nbrCampMax: 99},
                                });
                            } else {
                                const idBat = parseInt(event.target.value, 10);
                                setIdBat(idBat);
                                const bat = Object.values(listBat).find(
                                    (bat) => bat.id === idBat,
                                );
                                let maxCamp: number;
                                if (idBat === -1) {
                                    const nbrCamp = Math.floor(infoCase.nbTas / 3);
                                    maxCamp = nbrCamp > 3 ? 3 : nbrCamp;
                                }
                                
                                const max_campeur =
                                          idBat === -1 ? maxCamp ?? 0 : bat.max_campeur;
                                setInfoCase({
                                    ...infoCase,
                                    ...{
                                        bonus_camping: bat.bonus_camping,
                                        nbrCampMax   : max_campeur,
                                    },
                                });
                            }
                        }}
                    >
                        <option value={-2}>{t("Aucun", {ns: "ville"})}</option>
                        {Object.values(listBatFiltred).map((bat) => (
                            <option key={bat.id} value={bat.id}>
                                {t(bat.nom, {ns: "bats"})} ({bat.bonus_camping}%)
                            </option>
                        ))}
                    </select>
                    {idBat === -1 && (
                        <React.Fragment>
                            <label>{t("Nombre de tas :", {ns: "ville"})}</label>
                            <input
                                type="number"
                                id="nb_tas"
                                value={infoCase.nbTas}
                                onChange={(event) => {
                                    const nbr_tas = Math.max(parseInt(event.target.value, 10), 0);
                                    const nbrCamp = Math.floor(nbr_tas / 3);
                                    const maxCamp = nbrCamp > 3 ? 3 : nbrCamp;
                                    setInfoCase({
                                        ...infoCase,
                                        ...{nbTas: nbr_tas, nbrCampMax: maxCamp},
                                    });
                                }}
                                min={0}
                            />
                        </React.Fragment>
                    )}
                </div>
                <div key={"camp_max"} id={"camp_max_input"} className={"d-flex justify-content-start align-content-center align-items-center gap-2"}>
                    <label htmlFor="{'camp_max'}">{t("Nombre de campeur maximum", {ns: "ville"})} </label>
                    <input
                        type="number"
                        id="camp_max"
                        value={infoCase.nbrCampMax}
                        onChange={(event) => {
                            setInfoCase({
                                ...infoCase,
                                ...{nbrCampMax: parseInt(event.target.value, 10)},
                            });
                        }}
                        min={0}
                    />
                </div>
                <div key={"phare"} id={"camping_phare_select"} className={"d-flex justify-content-start align-content-center align-items-center gap-2"}>
                    <label htmlFor="{'phare_construit'}">{t("Phare construit", {ns: "ville"})} </label>
                    <select
                        id={"phare_construit"}
                        defaultValue={infoCase.phare ? 1 : 0}
                        onChange={(event) => {
                            setInfoCase({
                                ...infoCase,
                                ...{phare: event.target.value === "1"},
                            });
                        }}
                    >
                        <option value={0}>{t("Non", {ns: "app"})}</option>
                        <option value={1}>{t("Oui", {ns: "app"})}</option>
                    </select>
                </div>
                <div key={"nuit"} id={"camping_nuit_select"} className={"d-flex justify-content-start align-content-center align-items-center gap-2"}>
                    <label htmlFor="{'camping_nuit'}">{t("Nuit", {ns: "ville"})} </label>
                    <select
                        id={"camping_nuit"}
                        value={infoCase.nuit ? 1 : 0}
                        onChange={(event) => {
                            setInfoCase({
                                ...infoCase,
                                ...{nuit: event.target.value === "1"},
                            });
                        }}
                    >
                        <option value={0}>{t("Non", {ns: "app"})}</option>
                        <option value={1}>{t("Oui", {ns: "app"})}</option>
                    </select>
                </div>
                <div key={"zombie"} id={"camping_zombie_select"} className={"d-flex justify-content-start align-content-center align-items-center gap-2"}>
                    <label htmlFor="{'camping_zombie'}">{t("Zombie", {ns: "ville"})} </label>
                    <input
                        type="number"
                        id="camping_zombie"
                        value={infoCase.zombie}
                        onChange={(event) => {
                            setInfoCase({
                                ...infoCase,
                                ...{zombie: parseInt(event.target.value, 10)},
                            });
                        }}
                        min={0}
                    />
                </div>
                <div key={"devast"} id={"camping_devast_select"} className={"d-flex justify-content-start align-content-center align-items-center gap-2"}>
                    <label htmlFor="{'camping_devast'}">{t("Dévasté", {ns: "ville"})} </label>
                    <select
                        id={"camping_devast"}
                        defaultValue={infoCase.devast ? 1 : 0}
                        onChange={(event) => {
                            setInfoCase({
                                ...infoCase,
                                ...{devast: event.target.value === "1"},
                            });
                        }}
                    >
                        <option value={0}>{t("Non", {ns: "app"})}</option>
                        <option value={1}>{t("Oui", {ns: "app"})}</option>
                    </select>
                </div>
                <div id={"camping_amelio_od_select"} key={"amelio"} className={"d-flex justify-content-start align-content-center align-items-center gap-2"}>
                    <label htmlFor="{'camping_amelio_od'}" className={"d-flex gap-1 justify-content-start align-content-center align-items-center"}>
                        <span>{t("Nombre d'amélioration + OD", {ns: "ville"})}{" "}</span>
                        <TooltipGH>
                            <span className="infoBulle">
                                <i className="fa-solid fa-circle-info"></i>
                            </span>
                            <span className="info">{t("Comptez 1,8 pour un OD, et 1 pour une amelioration, maximum 11,6", {ns: "ville"})}</span>
                        </TooltipGH>
                    </label>
                    <span className={"d-flex gap-1 align-items-center justify-content-start mb-1"}>
                        <input type="number"
                               id="camping_amelio_od"
                               value={infoCase.nbrAmelioOD}
                               onChange={(event) => {
                                   setInfoCase({
                                       ...infoCase,
                                       ...{
                                           nbrAmelioOD: isNaN(
                                               parseFloat(event.target.value.replace(".", ",")),
                                           )
                                               ? "" : Math.min(parseFloat(event.target.value), 11.6),
                                       },
                                   });
                               }}
                               min={0}
                               max={11.6}
                               step={0.1}
                        />
                        <TooltipGH>
                            <span className="infoBulle">
                                <SvgIcone icone={"h_warning"}/>
                            </span>
                                <span className="info">{t("Décomptez 3 améliorations par jour", {ns: "ville"})}</span>
                        </TooltipGH>
                    </span>
                </div>
            </div>
            <div key={"listCampeur"} id={"camping_campeur_ency"}>
                <table>
                    <thead>
                    <tr>
                        <th className={"tbl_camping_ordre"}>{t("Ordre", {ns: "ville"})}</th>
                        <th className={"tbl_camping_campeur"}>
                            <span className={"d-flex gap-1 justify-content-center align-content-center align-items-center"}>
                                <span>{t("Campeur", {ns: "ville"})}{" "}</span>
                                <TooltipGH>
                                    <span className="infoBulle">
                                        <i className="fa-solid fa-circle-info"></i>
                                    </span>
                                    <span className="info">{t("Les campeurs en couleur rouge sont ceux qui n'ont pas le pouvoir héros campeur pro", {ns: "ville"})}</span>
                                </TooltipGH>
                            </span>
                        </th>
                        <th className={"tbl_camping_nbr"}>{t("Nombre Camping", {ns: "ville"})}</th>
                        <th className={"tbl_camping_cp"}>{t("Campeur pro", {ns: "ville"})}</th>
                        <th className={"tbl_camping_cp"}>{t("Spécifique", {ns: "ville"})}</th>
                        <th className={"tbl_camping_tombe"}>{t("Tombe", {ns: "ville"})}</th>
                        <th className={"tbl_camping_odc"}>{t("Objet Camping", {ns: "ville"})}</th>
                        <th className={"tbl_camping_chance"}>{t("Chance de réussite", {ns: "ville"})}</th>
                        <th className={"tbl_camping_action"}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {campeurs.map((campeur, index) => (
                        <tr key={campeur.id}>
                            <td className={"tbl_camping_ordre"}>{index + 1}</td>
                            <td className={"tbl_camping_campeur"}>
                                <Select
                                    value={campeur.job}
                                    options={listJob}
                                    components={{
                                        SingleValue: IconSingleValue,
                                        Option     : IconOption,
                                    }}
                                    onChange={(optionSelected: JobPrototypeDTO) => {
                                        const updatedCamper = {
                                            ...campeurs[index],
                                            job        : optionSelected,
                                            campeur_pro: false,
                                            nb_camping : 0,
                                        };
                                        updateSuccessChance(index, updatedCamper);
                                    }}
                                    key={"citoyen_" + campeur.id}
                                    styles={customStyles}
                                    getOptionLabel={(job) => job.nom}
                                />
                            </td>
                            <td className={"tbl_camping_nbr"}>
                                {NbCampingList(index, campeur.nb_camping)}
                            </td>
                            <td className={"tbl_camping_cp"}>
                                <input
                                    type="checkbox"
                                    id={`cp-${index}`}
                                    checked={campeur.campeur_pro}
                                    onChange={(event) => {
                                        const updatedCamper = {
                                            ...campeurs[index],
                                            campeur_pro: event.target.checked,
                                        };
                                        updateSuccessChance(index, updatedCamper);
                                    }}
                                />
                            </td>
                            <td className={"tbl_camping_cp"}>
                                {campeur.job.id === 4 && (<React.Fragment>
                                        <input
                                            type="checkbox"
                                            id={`capuche-${index}`}
                                            checked={campeur.capuche}
                                            onChange={(event) => {
                                                const updatedCamper = {
                                                    ...campeurs[index],
                                                    capuche: event.target.checked,
                                                };
                                                updateSuccessChance(index, updatedCamper);
                                            }}
                                        />
                                        <TooltipGH>
                                            <span className={"infoBulle"}>
                                                <i className="fa-solid fa-circle-info"></i>
                                            </span>
                                            <span className={"info"}>{t("Capuche présente", {ns: "ville"})}</span>
                                        </TooltipGH>
                                    </React.Fragment>
                                )}
                            </td>
                            <td className={"tbl_camping_tombe"}>
                                <input
                                    type="checkbox"
                                    id={`tombe-${index}`}
                                    onChange={(event) => {
                                        const updatedCamper = {
                                            ...campeurs[index],
                                            tombe: event.target.checked,
                                        };
                                        updateSuccessChance(index, updatedCamper);
                                    }}
                                />
                            </td>
                            <td className={"tbl_camping_odc"}>{OptionsList(index)}</td>
                            <td className={"tbl_camping_chance"}>
                                {campeur.chance_camping} %
                            </td>
                            <td className={"tbl_camping_action"}>
                                <div className={"bouton_action_camping"}>
                                    {campeurs.length > 1 && (
                                        <button onClick={() => deleteCamper(campeur.id)}>
                                            <i className="fa-solid fa-trash color-red"></i>
                                        </button>
                                    )}
                                    {campeurs.length > 1 && (
                                        <button
                                            onClick={() => swapCampers(index, index - 1)}
                                            disabled={index === 0}
                                        >
                                            <TooltipGH>
                                                <span className="infoBulle">
                                                  <i className="fa-solid fa-circle-chevron-up"></i>
                                                </span>
                                                <span className="info">{t("Remonter le campeur", {ns: "ville"})}</span>
                                            </TooltipGH>
                                        </button>
                                    )}
                                    {campeurs.length > 1 && (
                                        <button
                                            onClick={() => swapCampers(index, index + 1)}
                                            disabled={index === campeurs.length - 1}
                                        >
                                            <TooltipGH>
                                                <span className="infoBulle">
                                                  <i className="fa-solid fa-circle-chevron-down"></i>
                                                </span>
                                                <span className="info">{t("Descendre le campeur", {ns: "ville"})}</span>
                                            </TooltipGH>
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <button onClick={addCampeur} className={"btn btn-sm btn-primary"}>
                    {t("Ajouter un campeur", {ns: "ville"})}
                </button>
            </div>
        </div>
    );
}
