import React, {ReactNode, useEffect, useState} from "react";
import { createPortal }                        from "react-dom";
import {useGeneralContext}                     from "../../types/Context/GeneralContext";

const TooltipGH = ({ children }: { children: ReactNode }) => {
    const {general} = useGeneralContext();
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [tooltipWidth, setTooltipWidth] = useState(0);
    
    const handleMouseEnter = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPosition({
            x: rect.left + rect.width / 2,
            y: rect.bottom + window.scrollY,
        });
        setVisible(true);
    };
    
    const handleMouseLeave = () => {
        setVisible(false);
    };
    
    // Récupérer la largeur du tooltip après le rendu
    useEffect(() => {
        if (visible) {
            const tooltipElement = document.querySelector(".tooltip-container");
            setTooltipWidth(tooltipElement?.clientWidth || 0);
        }
    }, [visible]);
    
    // Ajuster la position si le tooltip déborde de l'écran
    const adjustedLeft = Math.min(
        Math.max(position.x, tooltipWidth / 2),
        window.innerWidth - tooltipWidth / 2
    );
    
    const arrowOffset = position.x - adjustedLeft;
    
    
    return (
        <>
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children[0]}
            </div>
            {visible &&
                createPortal(
                    <div
                        className="tooltip-container"
                        style={{
                            position : "absolute",
                            top      : position.y,
                            left     : adjustedLeft,
                            transform: "translateX(-50%) translateY(14px)",
                            zIndex   : 9999,
                        }}
                    >
                        {children[1]}
                        <div
                            style={{
                                position    : "absolute",
                                top         : "-7px",
                                left        : `calc(50% + ${arrowOffset}px)`,
                                transform   : "translateX(-50%)",
                                width       : 0,
                                height      : 0,
                                borderLeft  : "7px solid transparent",
                                borderRight : "7px solid transparent",
                                borderBottom: `7px solid ${general.themeUser.primary_border_color}`, // Couleur du tooltip
                            }}
                        />
                    </div>,
                    document.body
                )}
        </>
    );
};

export default TooltipGH;
