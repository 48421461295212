import React from "react";
import { ComparatifVilles } from "../../types/components/Villes/VillesType";

interface ComparatifsVillesProps {
    villes: ComparatifVilles[];
}

export default function ComparatifsVilles({ villes }: ComparatifsVillesProps) {
    
    return (
        <div></div>
    );
}