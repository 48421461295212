import * as React                                                                                                                                                                 from "react";
import { ArrayInput, Create, Datagrid, Edit, EditButton, List, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextField, TextInput, useRecordContext } from "react-admin";
import { useParams }                                                                                                                                                              from "react-router-dom";


export const AssemblageList = (props) => {
    
    const postFilters = [
        <TextInput source="q" label="Search" alwaysOn key={"search_items"} />,
        <ReferenceInput source="id" label="Item" reference="items_assemblage" key={"search_items_id"} />,
    ];
    
    return <List {...props} filters={postFilters}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="item_principal.nom" label="Nom de l'item principal" />
            <EditButton />
        </Datagrid>
    </List>;
};


export const AssemblageEdit = (props) => {
    
    const { id } = useParams();
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition assemblage {record ? `"${record.item_principal.nom}"` : ""}</span>;
    };
    
    return (<Edit {...props} title={<PostTitle />} resource={"items_assemblage"} id={id} redirect={"/admin/items_assemblage"}>
        <SimpleForm>
            <NumberInput source={"id"} label={"ID"} disabled={true} />
            <ReferenceInput source={"item_principal"} reference={"items"} label={"Item principal"}>
                <SelectInput source={"item_principal"}
                             optionText={"nom"}
                             optionValue={"id"}
                             parse={(value) => ({ id: value })}
                             format={(value) => value?.id}
                />
            </ReferenceInput>
            <ReferenceInput source={"type_action"} reference={"type_action_assemblage"} label={"Type Assemblage"}>
                <SelectInput source={"type_action"}
                             optionText={"nom"}
                             optionValue={"id"}
                             parse={(value) => ({ id: value })}
                             format={(value) => value?.id}
                />
            </ReferenceInput>
            <ArrayInput name={"item_need"} source={"item_need"}>
                <SimpleFormIterator inline={true}>
                    <ReferenceInput source={"item"} reference={"items"} label={"Besoin Item"}>
                        <SelectInput source={"item"}
                                     optionText={"nom"}
                                     optionValue={"id"}
                                     parse={(value) => ({ id: value })}
                                     format={(value) => value?.id}
                        />
                    </ReferenceInput>
                    <NumberInput source={"number"} label={"Nombre"} />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput name={"item_obtain"} source={"item_obtain"}>
                <SimpleFormIterator inline={true}>
                    <ReferenceInput source={"item"} reference={"items"} label={"Item Obtenu"}>
                        <SelectInput source={"item"}
                                     optionText={"nom"}
                                     optionValue={"id"}
                                     parse={(value) => ({ id: value })}
                                     format={(value) => value?.id}
                        />
                    </ReferenceInput>
                    <NumberInput source={"taux"} label={"Taux"} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>);
};

export const AssemblageCreate = (props) => (
    <Create {...props} resource={"items_assemblage"} redirect={"/admin/items_assemblage"}>
        <SimpleForm>
            <ReferenceInput source={"item_principal"} reference={"items"} label={"Item principal"} sort={{ field: "nom", order: "ASC" }}>
                <SelectInput source={"item_principal"}
                             optionText={"nom"}
                             optionValue={"id"}
                             parse={(value) => ({ id: value })}
                             format={(value) => value?.id}
                />
            </ReferenceInput>
            <ReferenceInput source={"type_action"} reference={"type_action_assemblage"} label={"Type Assemblage"}>
                <SelectInput source={"type_action"}
                             optionText={"nom"}
                             optionValue={"id"}
                             parse={(value) => ({ id: value })}
                             format={(value) => value?.id}
                />
            </ReferenceInput>
            <ArrayInput name={"item_need"} source={"item_need"}>
                <SimpleFormIterator inline={true}>
                    <ReferenceInput source={"item"} reference={"items"} label={"Besoin Item"} sort={{ field: "nom", order: "ASC" }}>
                        <SelectInput source={"item"}
                                     optionText={"nom"}
                                     optionValue={"id"}
                                     parse={(value) => ({ id: value })}
                                     format={(value) => value?.id}
                        />
                    </ReferenceInput>
                    <NumberInput source={"number"} label={"Nombre"} />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput name={"item_obtain"} source={"item_obtain"}>
                <SimpleFormIterator inline={true}>
                    <ReferenceInput source={"item"} reference={"items"} label={"Item Obtenu"} sort={{ field: "nom", order: "ASC" }}>
                        <SelectInput source={"item"}
                                     optionText={"nom"}
                                     optionValue={"id"}
                                     parse={(value) => ({ id: value })}
                                     format={(value) => value?.id}
                        />
                    </ReferenceInput>
                    <NumberInput source={"taux"} label={"Taux"} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);