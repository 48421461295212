import React, { ReactNode, useState }    from "react";
import { FontAwesomeIcon }               from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const CollapsibleFieldset = ({ title, children, className }: { title: string, children: ReactNode, className?: string }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    
    return (
        <fieldset className={className}>
            <legend
                onClick={toggleCollapse}
                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            >
                <FontAwesomeIcon
                    icon={isCollapsed ? faChevronRight : faChevronDown}
                    style={{ marginRight: "8px" }}
                />
                {title}
            </legend>
            <div style={{ display: isCollapsed ? "none" : "block" }}>
                {children}
            </div>
        </fieldset>
    );
};

export default CollapsibleFieldset;
