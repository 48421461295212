import { Chantier }             from "../../Encyclopedie/Chantiers/Chantier";
import React, { useState }      from "react";
import SvgIcone                 from "../../../components/generality/SvgIcone";
import { ChantierPrototypeDTO } from "../../../types/models/chantierPrototype.dto";
import Collapse                 from "react-bootstrap/Collapse";

interface LigneChantierProps {
    chantier: ChantierPrototypeDTO,
    niveau: number,
    index: number,
    onChantiers: (chantierMod) => void
}

export default function LigneOrderChantier({ chantier, niveau, onChantiers }: LigneChantierProps) {
    
    const [open, setOpen] = useState(false);
    
    const chantierClass = new Chantier({
        chantier: chantier,
    });
    const plan_string: string = chantierClass.getPlansString();
    
    if (niveau > 7) {
        return null;
    }
    
    const [children, setChildren] = useState(chantier.children);
    
    const swapChantier = (index1: number, index2: number) => {
        const chantiers = [...children];
        const chantier1 = { ...children[index1] };
        const chantier2 = { ...children[index2] };
        
        // Échanger les valeurs de `order_by_listing`
        const tempOrderBy = chantier1.order_by_listing;
        chantier1.order_by_listing = chantier2.order_by_listing;
        chantier2.order_by_listing = tempOrderBy;
        
        // Échanger les chantiers dans le tableau
        chantiers[index1] = chantier1;
        chantiers[index2] = chantier2;
        
        setChildren(chantiers);
        
        onChantiers({ ...chantier, ...{ children: chantiers } });
    };
    
    const updateChildren = (chantierMod: ChantierPrototypeDTO, index: number) => {
        const chantiers = [...children];
        chantiers[index] = chantierMod;
        
        setChildren(chantiers);
        onChantiers({ ...chantier, ...{ children: chantiers } });
    };
    
    return <React.Fragment key={"regroupement_chantier_" + chantierClass.id}>
        {<div className={"arbre_chantier"} id={"listChantier_" + chantierClass.id} key={"ligne_chantier_" + chantierClass.id}>
            <div className={"header_chantier"}>
                <div className={"chantier_nom " + ((niveau > 0) ? ("lv-" + Math.min(niveau, 6)) : "root")}>
                    <span></span>
                    <SvgIcone icone={chantierClass.icon} />{chantierClass.nom}
                    <SvgIcone icone={plan_string} />
                </div>
                <div>
                    {children.length !== 0 && <button type={"button"} className={"btn btn-sm btn-primary"} onClick={() => setOpen(!open)}><i className={`fa-solid fa-chevron-${(open ? "up" : "down")}`}></i></button>}
                </div>
            </div>
            <Collapse in={open}>
                <div className="children">{children.length !== 0 && children.sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => a.order_by_listing < b.order_by_listing ? -1 : 1).map((chantierFille: ChantierPrototypeDTO, index) => {
                    return <div className={"childrenline"} key={"ligne_" + chantierFille.id}>
                        <LigneOrderChantier chantier={chantierFille} niveau={niveau + 1} onChantiers={(chantierMod) => updateChildren(chantierMod, index)} index={index} />
                        {children.length > 1 && <div className={"children-group-btn"}>
                            <div>
                                <button onClick={() => swapChantier(index, index - 1)} disabled={index === 0}><i className="fa-solid fa-circle-chevron-up"></i></button>
                                <button onClick={() => swapChantier(index, index + 1)} disabled={index === children.length - 1}><i className="fa-solid fa-circle-chevron-down"></i></button>
                            </div>
                        </div>}
                    </div>;
                })
                }</div>
            </Collapse>
        </div>}
    </React.Fragment>;
}