import React               from "react";
import Form                from "react-bootstrap/Form";
import { SwitchItemProps } from "../../types/components/OptionsPerso/OptionsPersoType";
import TooltipGH           from "../utils/TooltipGH";


export default function SwitchItems({ label, forHtml, checked, onChangeUser, tooltip }: SwitchItemProps) {
    
    return <div className={"gestion_option_couleur_carte"}>
        {(tooltip) ? <span className={"d-flex gap-1 align-items-center"}>
            <label htmlFor={forHtml} className={"option_specifique"}>{label}</label>
            <TooltipGH>
                <span className={"option_specifique infoBulle"}>
                    <i className="fa fa-circle-info"></i>
                </span>
                <span className="option_specifique infoHelpPerso">{tooltip}</span>
            </TooltipGH>
            
        </span> : <label htmlFor={forHtml}>{label}</label>}
        <Form.Check name={forHtml} id={forHtml} type="switch" checked={checked} onChange={(event) => onChangeUser(event.target.checked)} />
    </div>;
}
