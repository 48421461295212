import { useCallback } from "react";

const normalizeString = (str: string) => {
    return str
        .normalize("NFD") // Normalise les caractères en leurs formes décomposées
        .replace(/[\u0300-\u036f]/g, "") // Supprime les accents
        .toLowerCase(); // Convertit en minuscules
};


export const useNormalizedSearch = () => {
    
    const normalizeSearch = useCallback((searchTerm: string, data: string): boolean => {
        
        if (searchTerm === "") {
            return true;
        } else {
            const normalizedSearchTerm = normalizeString(searchTerm);
            
            return normalizeString(data).includes(normalizedSearchTerm);
            
        }
    }, []);
    
    return { normalizeSearch };
    
};

