import { ChantierPrototypeDTO } from "../../../types/models/chantierPrototype.dto";
import { ChantiersDTO }         from "../../../types/models/chantiers.dto";
import { RessourceChantierDTO } from "../../../types/models/ressourceChantier.dto";

export class Chantier {
    cat_chantier?: number | ChantierPrototypeDTO;
    parent?: ChantierPrototypeDTO;
    children?: ChantierPrototypeDTO[];
    def?: number;
    icon?: string;
    id: number;
    indes?: boolean;
    niveau?: number;
    nom?: string;
    order_by_listing?: number;
    orderby?: number;
    pa?: number;
    pv?: number;
    plan?: number;
    ressources?: RessourceChantierDTO[];
    ruine_bu?: boolean;
    ruine_ho?: boolean;
    ruine_hs?: boolean;
    temp?: boolean;
    water?: number;
    actif?: boolean;
    description?: string;
    avancement?: { pa: number };
    reparation?: ChantiersDTO;
    construction: boolean;
    planObtenu: boolean;
    detruit?: boolean;
    
    constructor({ chantier, avancement, reparation, construction, planObtenu }: { chantier: ChantierPrototypeDTO, avancement?: { pa: number }, reparation?: ChantiersDTO, construction?: boolean, planObtenu?: boolean }) {
        this.cat_chantier = chantier.cat_chantier;
        this.parent = chantier.parent;
        this.children = chantier.children;
        this.def = chantier.def;
        this.icon = chantier.icon;
        this.id = chantier.id;
        this.indes = chantier.indes;
        this.niveau = chantier.niveau;
        this.nom = chantier.nom;
        this.order_by_listing = chantier.order_by_listing;
        this.orderby = chantier.orderby;
        this.pa = chantier.pa;
        this.pv = chantier.pv;
        this.plan = chantier.plan;
        this.ressources = chantier.ressources;
        this.ruine_bu = chantier.ruine_bu;
        this.ruine_ho = chantier.ruine_ho;
        this.ruine_hs = chantier.ruine_hs;
        this.temp = chantier.temp;
        this.water = chantier.water;
        this.actif = chantier.actif;
        this.description = chantier.description;
        this.construction = (construction === undefined) ? false : construction;
        this.avancement = (avancement === undefined) ? { pa: ((this.construction) ? 9999 : this.pa) } : avancement;
        this.planObtenu = (planObtenu === undefined) ? false : planObtenu;
        this.reparation = (reparation === undefined) ? ((this.construction) ? { pv: this.pv, def: this.def } : { pv: 9999, def: 9999 }) : reparation;
        this.detruit = (reparation === undefined) ? false : reparation.detruit;
    }
    
    
    getPlansString(): string {
        switch (this.plan) {
            case 0:
                return "";
            case 1:
                return "item_bplan_c";
            case 2:
                return "item_bplan_u";
            case 3:
                return "item_bplan_r";
            case 4:
                return "item_bplan_e";
        }
    }
    
    getPlansRuineString(idBat: number): string {
        if (!this.ruine_bu && !this.ruine_hs && !this.ruine_ho) {
            return "";
        }
        const prefix = (this.ruine_bu && idBat === 61) ? "b" : ((this.ruine_ho && idBat === 62) ? "m" : "h");
        
        switch (this.plan) {
            case 2:
                return "item_" + prefix + "bplan_u";
            case 3:
                return "item_" + prefix + "bplan_r";
            case 4:
                return "item_" + prefix + "bplan_e";
        }
    }
    
    getClassChantier(): string {
        if (this.avancement === undefined) {
            return "";
        }
        
        if (this.pv > 0 && !this.indes && this.reparation.pv < this.pv && !this.detruit) {
            return "chEndom";
        }
        
        if (this.avancement !== undefined) {
            return (this.construction) ? "chCons" : ((this.avancement.pa < this.pa) ? "chEnCons" : ((this.plan !== 0 && !this.planObtenu) ? "chBlock" : "plObte"));
        }
        
        
    }
}