import { ListPlansObtenu }      from "../../../types/components/Hotel/PlansChantierType";
import SvgIcone                 from "../../../components/generality/SvgIcone";
import React                    from "react";
import { ChantierPrototypeDTO } from "../../../types/models/chantierPrototype.dto";
import { useTranslation }       from "react-i18next";

export interface LignePlanChantierProps {
    chantier: ChantierPrototypeDTO,
    chantierObtenu: ListPlansObtenu[],
    onClickChantier?: (chantier: ChantierPrototypeDTO) => void,
    type: string,
    sous_type?: string
}

export default function LignePlanChantier({ chantier, chantierObtenu, onClickChantier, type, sous_type }: LignePlanChantierProps) {
    const { t } = useTranslation();
    let prefix = "";
    let disabled_form = false;
    if (sous_type !== undefined) {
        prefix = sous_type;
        disabled_form = true;
    }
    
    /*if (!disabled_form) {
     disabled_form = !props.myVille;
     }*/
    
    
    const icon: string = "item_" + prefix + "bplan_" + type;
    
    // On vérifie si le chantier est obtenu
    const obtenu: boolean = chantierObtenu.find(plan => plan.chantier.id === chantier.id) !== undefined;
    
    const parent = chantier.parent;
    
    let parentObtenu: boolean;
    let parentPlan: boolean = false;
    if (parent.plan > 0) {
        parentPlan = true;
        parentObtenu = chantierObtenu.find(plan => plan.chantier.id === parent.id) !== undefined;
    }
    
    const classForm = "d-flex justify-content-start align-items-start" + ((parentPlan && !parentObtenu) ? " blocPlan" : "");
    
    return <span className={classForm}>
        <input type={"checkbox"}
               id={"plan_chantier_" + chantier.id}
               name={"plan_chantier_" + chantier.id}
               disabled={disabled_form || (parentPlan && !parentObtenu)}
               checked={obtenu}
               onChange={() => onClickChantier ? onClickChantier(chantier) : null}
        />
        <label htmlFor={"plan_chantier_" + chantier.id} className={"d-flex justify-content-start align-items-center align-self-center gap-1"}><SvgIcone icone={icon} />{t(chantier.nom, { ns: "chantiers" })}</label>
    </span>;
}