import React, { useEffect, useState }             from "react";
import { GestionEventType }                       from "../../../types/components/Jump/GestionEventType";
import FormEvent                                  from "../FormEvent";
import HTMLParser                                 from "html-react-parser";
import Modal                                      from "react-bootstrap/Modal";
import Button                                     from "react-bootstrap/Button";
import ConfirmationDialog                         from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import { GestionJumpApi }                         from "../../../services/api/GestionJumpApi";
import { formatInTimeZone }                       from "date-fns-tz";
import { Link, useNavigate }                      from "react-router-dom";
import { useGeneralContext }                      from "../../../types/Context/GeneralContext";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { UserDTO }                                from "../../../types/models/user.dto";
import { JumpDTO }                                from "../../../types/models/jump.dto";
import { LogEventInscriptionDTO }                 from "../../../types/models/logEventInscription.dto";
import { InscriptionJumpDTO }                     from "../../../types/models/inscriptionJump.dto";
import { useTranslation }                         from "react-i18next";

export default function GestionEvent({ gestionEvent, isArchMode = false }: { gestionEvent: GestionEventType, isArchMode?: boolean }) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const navigate = useNavigate();
    const options = gestionEvent.options;
    const gestionApi = new GestionJumpApi();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    
    const [eventJump, setEventJump] = useState(gestionEvent.event);
    const [listOrga, setListOrga] = useState(Object.values(eventJump.organisateurs));
    const [messagePopUpListErreur, setMessagePopUpListErreur] = useState(null);
    const [showModListErreur, setShowModListErreur] = useState(false);
    const [confirmShow, setConfirmShow] = useState(false);
    
    const extractionInscription = (): UserDTO[] => {
        const inscriptionUnique: UserDTO[] = [];
        Object.values(eventJump.list_jump).map((jump: JumpDTO) => {
            if (jump.inscription_jumps !== undefined) {
                Object.values(jump.inscription_jumps).map((inscription: InscriptionJumpDTO) => {
                    if (inscription.statut.id === options.statutAcc) {
                        inscriptionUnique[inscription.user.id] = inscription.user;
                    }
                });
            }
        });
        
        return inscriptionUnique.filter((user) => user !== undefined);
        
    };
    
    const verification_inscription = (orga: UserDTO): boolean => {
        const listInscription = extractionInscription();
        
        return listInscription.map((user) => user.id).includes(orga.id);
    };
    
    const organisateur_inscrit = verification_inscription(gestionEvent.user);
    
    const listInscrit = extractionInscription();
    
    const onRetour = (isErrorCreation: boolean, showModCreation: boolean, messagePopCreation: string) => {
        setStatus(isErrorCreation ? Status_error : Status_success);
        setShowPop(showModCreation);
        setMessagePopUp(messagePopCreation);
        if (!isErrorCreation) {
            setTimeout(() => {
                setShowPop(false);
                setMessagePopUp("");
            }, 1500);
        }
    };
    
    const handleClose = () => {
        setMessagePopUpListErreur(null);
        setShowModListErreur(false);
    };
    
    const onChangeOrgaEvent = (e, index: number) => {
        const listInscription = extractionInscription();
        const organisateurs = [...listOrga];
        
        organisateurs[index] = listInscription.find((inscription) => inscription.id === parseInt(e.target.value, 10));
        setListOrga(organisateurs);
    };
    
    const onSuppOrgaEvent = (id: number) => {
        const orgas = Object.values(listOrga).filter(orga => orga.id !== id);
        setListOrga(orgas);
    };
    
    const handleCreationOrga = () => {
        const newOrga: UserDTO = {
            id    : 0,
            pseudo: "",
        };
        
        setListOrga(prevEvent => (
            [...prevEvent, newOrga]
        ));
        
    };
    
    const handleSaveEvent = () => {
        gestionApi.modification_event({ event: eventJump }).then((response) => {
            if (response.codeRetour === 0) {
                onRetour(false, true, response.libRetour);
                setTimeout(() => {
                    onRetour(false, false, "");
                }, 2000);
                setEventJump(response.zoneRetour.event);
            } else if (response.codeRetour === 2) {
                const retourErreur = <>
                    <span>{response.libRetour}</span>
                    <ul>{Object.values(response.zoneRetour.libErreur).map((erreur, index) => {
                        return <li key={"erreur_" + index}>{erreur}</li>;
                    })}</ul>
                </>;
                setShowModListErreur(true);
                setMessagePopUpListErreur(retourErreur);
            } else {
                onRetour(true, true, response.libRetour);
            }
        });
    };
    
    const handleSuppEvent = () => {
        gestionApi.suppression_event(eventJump.id).then((response) => {
            if (response.codeRetour === 0) {
                onRetour(false, true, response.libRetour);
                setTimeout(() => {
                    onRetour(false, false, "");
                    navigate("/jump/gestion");
                }, 2000);
            } else {
                onRetour(true, true, response.libRetour);
            }
        });
    };
    
    useEffect(() => {
        setEventJump(gestionEvent.event);
    }, [gestionEvent]);
    
    useEffect(() => {
        setEventJump(eventJump => ({ ...eventJump, organisateurs: listOrga }));
    }, [listOrga]);
    
    
    return <div className="jumpCorps">
        <div id="formGestion" className="fondWhite02">
            <h3>{t("Gestion de l'event", { ns: "jumpEvent" })}</h3>
            <fieldset>
                <legend>{t("Info principal", { ns: "jumpEvent" })}</legend>
                <FormEvent formEventProps={{
                    event  : eventJump,
                    options: gestionEvent.options,
                    user   : gestionEvent.user,
                }}
                           isArchMode={isArchMode}
                           onRetour={onRetour}
                           onChange={setEventJump} />
            </fieldset>
            <fieldset>
                <legend>{listOrga.length > 1 ? t("Organisateurs de l'event", { ns: "jumpEvent" }) : t("Organisateur de l'event", { ns: "jumpEvent" })}</legend>
                <div id="form_orgas">
                    {!isArchMode && listOrga.length === 1 && Object.values(listOrga).map((orga) => (
                        <div key={"event_orga_" + orga.id} className={"ligneGestionJump"}>
                            <span>{t("Joueur :", { ns: "jumpEvent" })}</span>
                            <span>{orga.pseudo}</span>
                        </div>
                    ))}
                    {!isArchMode && listOrga.length > 1 && listInscrit.length > 1 && Object.values(listOrga).map((orga, index) => (
                        <div key={"event_orga_" + orga.id} className={"ligneGestionJump"}>
                            <label>{t("Joueur :", { ns: "jumpEvent" })}</label>
                            <select value={orga.id} onChange={(event) => onChangeOrgaEvent(event, index)}>
                                <option value={0}>{t("Choix joueur.", { ns: "jumpEvent" })}</option>
                                {listInscrit.map((user) => {
                                    return <option value={user.id} key={"orga_" + user.id}>{user.pseudo}</option>;
                                })}
                            </select>
                            <button type={"button"} className={"color-red"} onClick={() => onSuppOrgaEvent(orga.id)}><i className="fa-solid fa-trash"></i></button>
                        </div>
                    ))}
                    {isArchMode && Object.values(listOrga).map((orga, index) => (
                        <div key={"event_orga_" + orga.id} className={"ligneGestionJump"}>
                            <label>{t("Joueur :", { ns: "jumpEvent" })}</label>
                            <span>{orga.pseudo}</span>
                        </div>
                    ))}
                </div>
                {!isArchMode && <div>
                    {organisateur_inscrit ? (<button type="button" className="add_jump_link btn btn-warning btn-sm" onClick={handleCreationOrga}
                                                     disabled={!organisateur_inscrit || listInscrit.length === 1}>{t("Ajouter un organisateur", { ns: "jumpEvent" })}</button>) :
                        (<span  className={"color-red"}>{t("Impossible d'ajouter d'autres organisateurs tant que vous ne serez pas inscrit à votre jump.", { ns: "jumpEvent" })}</span>)}
                </div>}
            </fieldset>
            {!isArchMode && <div className="doubleFormButton">
                <button type={"button"} className={"btn btn-primary"} onClick={handleSaveEvent}>{t("Sauvegarder event", { ns: "jumpEvent" })}</button>
                <button type={"button"} className={"btn btn-danger"} onClick={() => setConfirmShow(true)}>{t("Supprimer event", { ns: "jumpEvent" })}</button>
            </div>}
        </div>
        <div id="listJump" className="fondWhite02">
            <h3>{t("Gestion des jumps de l'event", { ns: "jumpEvent" })}</h3>
            <table>
                <thead>
                <tr>
                    <th className="th_nomJump">{t("Nom du jump", { ns: "jumpEvent" })}</th>
                    <th className="td_dateJump">{HTMLParser(t("Date de début <br/> inscription", { ns: "jumpEvent" }))}</th>
                    <th className="td_dateJump">{HTMLParser(t("Date de fin <br/> inscription", { ns: "jumpEvent" }))}</th>
                    <th className="td_lienInscipJump">{HTMLParser(t("Lien vers <br/> la gestion", { ns: "jumpEvent" }))}</th>
                </tr>
                </thead>
                <tbody>
                {Object.values(eventJump.list_jump).map((jump: JumpDTO) => {
                    if (jump.date_deb_inscription === undefined) {
                        return null;
                    } else {
                        return <tr key={"jump_id_" + jump.id}>
                            <td className="td_nomJump">{jump.nom}</td>
                            <td className="td_dateJump">{formatInTimeZone(new Date(jump.date_deb_inscription), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
                            <td className="td_dateJump">{formatInTimeZone(new Date(jump.date_fin_inscription), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
                            <td className="td_lienInscipJump">
                                {!isArchMode && <Link to={"/jump/gestion/" + jump.id} style={{ textDecoration: "none", color: "inherit" }}>
                                    <button className="voirCandiGestion" type={"button"}>{t("Gérer le jump", { ns: "jumpEvent" })}</button>
                                </Link>}
                                {isArchMode && <Link to={"/jump/gestion/archive/" + jump.id} style={{ textDecoration: "none", color: "inherit" }}>
                                    <button className="voirCandiGestion" type={"button"}>{t("Voir l'archive du jump", { ns: "jumpEvent" })}</button>
                                </Link>}
                            </td>
                        </tr>;
                    }
                    
                })}
                </tbody>
            </table>
        </div>
        <div id="listLog" className="fondWhite02">
            <h3>{t("Historique des modifications", { ns: "jumpEvent" })}</h3>
            <table>
                <thead>
                <tr>
                    <th className="td_log_date">{t("Date", { ns: "jumpEvent" })}</th>
                    <th className="td_log_user">{t("Qui ?", { ns: "jumpEvent" })}</th>
                    <th className="td_log_libelle">{t("Evénement", { ns: "jumpEvent" })}</th>
                    <th className="td_log_valeur_av">{t("Valeur avant", { ns: "jumpEvent" })}</th>
                    <th className="td_log_valeur_ap">{t("Valeur après", { ns: "jumpEvent" })}</th>
                </tr>
                </thead>
                <tbody>
                {Object.values(eventJump.log_event).sort((a: LogEventInscriptionDTO, b: LogEventInscriptionDTO) => a.id > b.id ? -1 : 1).map((log: LogEventInscriptionDTO) => {
                    return <tr key={"jump_id_" + log.id}>
                        <td className="td_log_date">{formatInTimeZone(new Date(log.event_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
                        <td className="td_log_user">{log.declencheur.pseudo}</td>
                        <td className="td_log_libelle">{t(log.libelle, { ns: "jumpEvent" })}</td>
                        <td className="td_log_valeur_av">{log.valeur_avant}</td>
                        <td className="td_log_valeur_ap">{log.valeur_apres}</td>
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
        <Modal show={showModListErreur} onHide={handleClose} centered={true}>
            <Modal.Header className={"modal-header-error"}>
                <Modal.Title>{t("Erreur !", { ns: "app" })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{messagePopUpListErreur}</Modal.Body>
            {<Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>{t("Fermer", { ns: "app" })}</Button>
            </Modal.Footer>}
        </Modal>
        <ConfirmationDialog
            message={t("Voulez-vous réellement supprimer le jump ? Attention, toute suppression est définitive, cela supprimera également toutes les candidatures.", { ns: "jumpEvent" })}
            titre={t("Confimation", { ns: "jumpEvent" })}
            btnAnnuler={t("Annuler", { ns: "jumpEvent" })}
            btnConfirmation={t("Oui", { ns: "jumpEvent" })}
            show={confirmShow}
            onConfirm={() => {
                handleSuppEvent();
                setConfirmShow(false);
            }}
            onCancel={() => setConfirmShow(false)}
        />
    </div>;
}