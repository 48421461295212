import { useCallback } from "react";
import { UserDTO }     from "../../types/models/user.dto";

// Hook personnalisé pour mettre la première lettre en majuscule
export const useMHFormat = () => {
    const formatPseudoMH = useCallback((user: UserDTO): string => {
        return `@${user.pseudo.replace(/\s/g, "")}:${user.id_my_hordes}`;
    }, []);
    
    return { formatPseudoMH };
};