import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GestionVillesState {
    colonneMasquer: string[];
}

const initialState: GestionVillesState = {
    colonneMasquer    : [],
};

const gestionVillesSlice = createSlice({
    name    : "gestionVilles",
    initialState,
    reducers: {
        addColonneMasquer(state, action: PayloadAction<string>) {
            state.colonneMasquer.push(action.payload);
            // Et on stocke cela dans le local storage
            localStorage.setItem("colonneMasquer", JSON.stringify(state.colonneMasquer));
        },
        removeColonneMasquer(state, action: PayloadAction<string>) {
            state.colonneMasquer = state.colonneMasquer.filter((colonne) => colonne !== action.payload);
            // Et on stocke cela dans le local storage
            localStorage.setItem("colonneMasquer", JSON.stringify(state.colonneMasquer));
        },
        getColonneMasquer(state) {
            const colonneMasquer = localStorage.getItem("colonneMasquer");
            if (colonneMasquer) {
                state.colonneMasquer = JSON.parse(colonneMasquer);
            }
        }
    },
});

export const { addColonneMasquer, removeColonneMasquer, getColonneMasquer } = gestionVillesSlice.actions;
export default gestionVillesSlice.reducer;
