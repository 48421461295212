import React, { useEffect, useState } from "react";
import { EncyclopedieBatimentsType }  from "../../types/components/Encyclopedie/EncyclopedieBatimentsType";
import Card                           from "react-bootstrap/Card";
import SvgBatiment                    from "../../components/generality/SvgBatiment";
import SvgIcone                       from "../../components/generality/SvgIcone";
import Tabs                           from "react-bootstrap/Tabs";
import Tab                            from "react-bootstrap/Tab";
import Cookies                        from "js-cookie";
import CampingGeneral                 from "./Batiments/CampingGeneral";
import HTMLParser                     from "html-react-parser";
import { BatPrototypeDTO }            from "../../types/models/batPrototype.dto";
import { ItemBatimentDTO }            from "../../types/models/itemBatiment.dto";
import { useTranslation }             from "react-i18next";
import { useNormalizedSearch }        from "../../services/hook/useNormalizedSearch";
import TooltipGH                      from "../../components/utils/TooltipGH";
import { Form }                       from "react-bootstrap";
import TranslationsDisplay            from "../../components/utils/TranslationsDisplay";
import { useGeneralContext }          from "../../types/Context/GeneralContext";

interface EncyclopedieBatimentProps {
    batiments: EncyclopedieBatimentsType;
}

interface FiltreBatiment {
    searchFiltre?: SearchBatiment,
    filterBatList?: boolean
}

interface SearchBatiment {
    bat: string,
    item: string,
    km: any,
}

export default function EncyBatiments({ batiments }: EncyclopedieBatimentProps) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const [listBat, setListBat] = useState(batiments.listBat);
    const [search, setSearch] = useState({ bat: "", item: "", km: "" });
    const [filterBat, setFilterBat] = useState(false);
    const [activeKey, setActiveKey] = useState("batiments");
    const [activeTrad, setActiveTrad] = useState(localStorage.getItem("activeTrad") === "true");
    const { normalizeSearch } = useNormalizedSearch();
    
    useEffect(() => {
        setListBat(batiments.listBat);
    }, [batiments]);
    
    const filtredListsBatiments = ({ searchFiltre = search, filterBatList = filterBat }: FiltreBatiment) => {
        if (searchFiltre.bat !== search.bat || searchFiltre.km !== search.km || searchFiltre.item !== search.item) {
            setSearch(searchFiltre);
        }
        if (filterBatList !== filterBat) {
            setFilterBat(filterBatList);
        }
        const tabIdBatiment = Object.values(batiments.listBatMaVille);
        // On filtre d'abord sur les batiments de la ville, puis sur les km, ensuite sur le nom des bâts ensuite sur les items -- si et seulement, ils ont les
        // valeurs remplies
        
        const filteredBats: BatPrototypeDTO[] = Object.values(batiments.listBat)
            .filter((batiment) => {
                return !filterBatList || tabIdBatiment.includes(batiment.id);
            })
            .filter((batiment) => {
                const search_km_num = (searchFiltre.km === "") ? 0 : parseInt(searchFiltre.km, 10);
                return search_km_num === 0 || (batiment.km_min <= search_km_num && batiment.km_max >= search_km_num);
            })
            .filter((batiment) => normalizeSearch(searchFiltre.bat.trim(), t(batiment.nom, { ns: "bats" })))
            .filter((batiment) => {
                
                if (searchFiltre.item.trim() === "") {
                    return true;
                }
                
                let countItemFind = 0;
                
                Object.values(batiment.items).map((item) => {
                    if (normalizeSearch(searchFiltre.item.trim(), t(item.item.nom, { ns: "items" }))) {
                        countItemFind++;
                    }
                });
                
                return countItemFind > 0;
                
            });
        
        setListBat(filteredBats);
    };
    
    const batimentsTri: BatPrototypeDTO[] = Object.values(listBat).sort((a: BatPrototypeDTO, b: BatPrototypeDTO) => {
        const aBat = t(a.nom, { ns: "batiments" });
        const bBat = t(b.nom, { ns: "batiments" });
        return aBat.localeCompare(bBat);
    });
    
    // Fonction pour changer d'onglet et enregistrer le cookie
    const handleTabChange = (eventKey) => {
        setActiveKey(eventKey);
        Cookies.set("activeTab_ency_batimens", eventKey, { expires: 365 }); // Le cookie expirera dans 365 jours
    };
    
    return <div id="ency_bat">
        <Tabs activeKey={activeKey} onSelect={handleTabChange}>
            <Tab eventKey={"batiments"} title={t("Liste des bâtiments", { ns: "ency" })}>
                <div className={"container-tabs"}>
                    <div id="form_search_bat">
                        <Card>
                            <Card.Body>
                                <h3>{t("Recherche", { ns: "ency" })}</h3>
                                <div id="form">
                                    <div>
                                        <label htmlFor="form_nom" className="required">{t("Nom bâtiment :", { ns: "ency" })} </label>
                                        <input type={"search"}
                                               id="form_nom"
                                               value={search.bat}
                                               onClick={() => filtredListsBatiments({ searchFiltre: { ...search, bat: "" } })}
                                               onChange={(event) => filtredListsBatiments({ searchFiltre: { ...search, bat: event.target.value } })}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="form_km" className="required">{t("Km du bâtiment :", { ns: "ency" })} </label>
                                        <input type={"search"}
                                               id="form_km"
                                               value={search.km}
                                               onClick={() => filtredListsBatiments({ searchFiltre: { ...search, km: "" } })}
                                               onChange={(event) => filtredListsBatiments(
                                                   { searchFiltre: { ...search, km: (isNaN(parseInt(event.target.value))) ? "" : parseInt(event.target.value) } })}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="form_nomObjet" className="required">{t("Objet cherché :", { ns: "ency" })} </label>
                                        <input type={"search"}
                                               id="form_nomObjet"
                                               value={search.item}
                                               onClick={() => filtredListsBatiments({ searchFiltre: { ...search, item: "" } })}
                                               onChange={(event) => filtredListsBatiments({ searchFiltre: { ...search, item: event.target.value } })}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="form_filtreBat" className="required">{t("Filtrer sur les bâtiments de ma ville :", { ns: "ency" })} </label>
                                        <input type={"checkbox"}
                                               id="form_filtreBat"
                                               checked={filterBat}
                                               onChange={(event) => filtredListsBatiments({ filterBatList: event.target.checked })}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="form_AfficheTrad" className="required">{t("Afficher le nom des bâtiments dans les 4 langues :", { ns: "ency" })} </label>
                                        <Form.Check
                                            type="switch"
                                            id="form_AfficheTrad"
                                            checked={activeTrad}
                                            onChange={(event) => {
                                                setActiveTrad(event.target.checked);
                                                // On stock le choix de l'utilisateur dans le storage
                                                localStorage.setItem("activeTrad", event.target.checked.toString());
                                            }}
                                            style={{ marginLeft: "10px" }}
                                        />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <table className="fondWhite02">
                        <thead>
                        <tr>
                            <th className="tab_ency_icone">{t("Image", { ns: "ency" })}</th>
                            <th className="tab_ency_nom">{t("Nom", { ns: "ency" })}</th>
                            <th className="tab_ency_desc">{t("Description", { ns: "ency" })}</th>
                            <th className="tab_ency_objet">{t("Objets", { ns: "ency" })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.values(batimentsTri).map((bat) => {
                            return <tr className="ligne_ency_batiment" key={"bat_" + bat.id}>
                                <td className="tab_ency_icone">
                                    <div>
                                        <div className="iconRuine"><SvgBatiment icone={bat.icon} /></div>
                                        <div>Km<sub>min</sub> : {bat.km_min}</div>
                                        <div>Km<sub>max</sub> : {bat.km_max}</div>
                                        <div>{HTMLParser(t("Bonus<sub>camping</sub>", { ns: "ency" }))} : {bat.bonus_camping}%</div>
                                        <div>{HTMLParser(t("Campeur<sub>max</sub>", { ns: "ency" }))} : {bat.max_campeur}</div>
                                    </div>
                                </td>
                                <td className="tab_ency_nom">{activeTrad ? <TranslationsDisplay translationKey={bat.nom} namespace={"bats"} userLang={general.user.lang} /> : t(bat.nom, { ns: "bats" })}</td>
                                <td className="tab_ency_desc">{HTMLParser(t(bat.description, { ns: "bats" }))}</td>
                                <td className="tab_ency_objet">
                                    <div>
                                        {Object.values(bat.items).sort((a: ItemBatimentDTO, b: ItemBatimentDTO) => {
                                            return a.probabily > b.probabily ? -1 : 1;
                                        }).map((item: ItemBatimentDTO) => {
                                            return <span key={"bat_" + bat.id + "_item_" + item.item.id}>
                                                <TooltipGH>
                                                    <span className="infoBulle">
                                                        <SvgIcone icone={item.item.icon} />
                                                    </span>
                                                        <span className="info">{t(item.item.nom, { ns: "items" })}</span>
                                                </TooltipGH>
                                                <span className={"ency_space"}><em>({Math.round((item.probabily * 100) / (bat.proba_item_globale * 100) * 10000) / 100} %)</em></span>
                                            </span>;
                                        })}
                                    </div>
                                </td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </div>
            </Tab>
            <Tab eventKey={"camping"} title={t("Calculateur de camping", { ns: "ency" })}>
                <div className={"container-tabs"}>
                    <CampingGeneral listBat={listBat} listJob={batiments.listJob} />
                </div>
            </Tab>
        </Tabs>
    
    </div>;
    
}


