import React, { useEffect }                                                                                                                                                       from "react";
import { addItemToGroup, addItemToMenu, closeModal, initMenu, moveMenuElement, openModal, removeItemFromGroup, removeItemFromMenu, resetMenu, setCurrentMenuItems, setGroupName } from "../../store/options/menuOptionsSlice";
import { useDispatch, useSelector }                                                                                                                                               from "react-redux";
import { AppDispatch, RootState }                                                                                                                                                 from "../../store/store";
import { DragDropContext, Draggable, Droppable }                                                                                                                                  from "@hello-pangea/dnd";
import { Card, Modal }                                                                                                                                                            from "react-bootstrap";
import Form                                                                                                                                                                       from "react-bootstrap/Form";
import { FaChevronDown, FaChevronUp, FaTrash }                                                                                                                                    from "react-icons/fa";
import { MenuElementDTO }                                                                                                                                                         from "../../types/models/menuElement.dto";
import { useGeneralContext }                                                                                                                                                      from "../../types/Context/GeneralContext";
import { Status_success, usePopUp }                                                                                                                                               from "../../types/Context/PopUpContext";
import { OptionPersoApi }                                                                                                                                                         from "../../services/api/OptionPersoApi";
import { MenuPrototypeDTO }                                                                                                                                                       from "../../types/models/menuPrototype.dto";
import { MenuDTO }                                                                                                                                                                from "../../types/models/menu.dto";
import { useTranslation }                                                                                                                                                         from "react-i18next";
import Button                                                                                                                                                                     from "../../components/utils/Button";
import { useGHContext }                                                                                                                                                           from "../../types/Context/GHContext";


const MenuDesignerOptionsPerso = ({ menuProto, menuUser }: { menuProto: MenuPrototypeDTO[], menuUser: MenuDTO }) => {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const dispatch = useDispatch<AppDispatch>();
    const availableMenus = useSelector((state: RootState) => state.menuOptions.availableMenuPrototypes);
    const remainingAvailableMenus = useSelector((state: RootState) => state.menuOptions.remainingAvailableMenus);
    const currentMenu = useSelector((state: RootState) => state.menuOptions.currentMenu);
    const showModal = useSelector((state: RootState) => state.menuOptions.showModal);
    const groupName = useSelector((state: RootState) => state.menuOptions.groupName);
    const { setMessagePopUp, setShowPop, setStatus } = usePopUp();
    const { triggerRefresh } = useGHContext();
    
    useEffect(() => {
        dispatch(initMenu({ menuProto: menuProto, currentMenu: menuUser }));
    }, [dispatch]);
    
    const onDragEnd = (result) => {
        const { source, destination } = result;
        
        if (!destination) {
            return;
        }
        // Si l'élément est déplacé de la liste des menus disponibles vers le menu actuel
        if (source.droppableId === "availableMenus" && destination.droppableId === "currentMenu") {
            const draggedMenuPrototype = remainingAvailableMenus[source.index];
            
            // Créer un nouvel objet MenuElementDTO
            const draggedMenu = {
                id       : Date.now(), // Assurer que l'ID est correct
                type_menu: "menu", // Spécifie que c'est un menu
                name     : "", // Assurez-vous que c'est le bon champ
                menu     : draggedMenuPrototype,
                items    : [], // Les sous-éléments, initialement vides
            };
            
            // Ajout de l'élément au menu sans groupe
            dispatch(addItemToMenu({ item: draggedMenu, order: (currentMenu.items ?? []).length }));
        }
        
        // Si l'élément est déplacé vers un groupe
        if (source.droppableId === "availableMenus" && destination.droppableId.startsWith("group-")) {
            const draggedItem = remainingAvailableMenus[source.index];
            
            // Créer un nouvel objet MenuElementDTO
            const draggedMenu = {
                id       : Date.now(), // Assurer que l'ID est correct
                type_menu: "menu", // Spécifie que c'est un menu
                name     : "", // Assurez-vous que c'est le bon champ
                menu     : draggedItem,
                items    : [], // Les sous-éléments, initialement vides
            };
            
            // Trouver le groupe où l'élément est déposé
            const targetGroupId = destination.droppableId.split("-")[1];
            
            // Récupérer l'élément correspondant au groupe
            const targetGroup = currentMenu.items.find((item) => item.id === parseInt(targetGroupId));
            dispatch(addItemToGroup({ item: draggedMenu, groupCibleId: targetGroup.id, order: (targetGroup.items ?? []).length }));
        }
    };
    
    const handleAddGroup = () => {
        dispatch(openModal());
    };
    
    const handleCloseModal = () => {
        dispatch(closeModal());
    };
    
    const handleSaveGroup = () => {
        const newGroup = {
            id       : Date.now(),
            type_menu: "group",
            name     : groupName,
            items    : [],
        };
        dispatch(addItemToMenu({ item: newGroup, order: currentMenu.items.length }));
        handleCloseModal();
    };
    
    const handleRemoveGroup = (groupId) => {
        dispatch(removeItemFromMenu(groupId));
    };
    
    const handleRemoveMenuOnGroup = ({ groupId, menuId }: { groupId: number, menuId: number }) => {
        dispatch(removeItemFromGroup({ groupId: groupId, menuId: menuId }));
    };
    
    const moveItemToGroup = ({ index, direction, groupId }: { index: number, direction: "up" | "down", groupId?: number }) => {
        dispatch(moveMenuElement({ index: index, direction: direction, groupId: groupId }));
    };
    const moveItemToMenu = ({ index, direction }: { index: number, direction: "up" | "down" }) => {
        dispatch(moveMenuElement({ index: index, direction: direction }));
    };
    
    const handleReset = () => {
        dispatch(resetMenu());
        dispatch(initMenu({ menuProto: menuProto, currentMenu: menuUser }));
    };
    
    const DraggableItem = ({ item, index, nbr, inGroup = false, groupId = 0 }: { item: MenuElementDTO, index: number, nbr: number, inGroup?: boolean, groupId?: number }) => {
        return <Card className={"w-100"}>
            <Card.Body className={"d-flex align-items-center justify-content-between gap-1"}>
                <div><strong>{item.menu.label}</strong></div>
                <div className={"d-flex gap-2"}>
                    {index !== nbr - 1 && <Button taille={"xs"} couleur={inGroup ? "success" : "primary"} onClick={() => inGroup ? moveItemToGroup({ index: index, direction: "down", groupId: groupId }) : moveItemToMenu({ index: index, direction: "down" })}><FaChevronDown /></Button>}
                    {index !== 0 && <Button taille={"xs"} couleur={inGroup ? "success" : "primary"} onClick={() => inGroup ? moveItemToGroup({ index: index, direction: "up", groupId: groupId }) : moveItemToMenu({ index: index, direction: "up" })}><FaChevronUp /></Button>}
                    <Button taille={"xs"} couleur="danger" onClick={() => inGroup ? handleRemoveMenuOnGroup({ groupId: groupId, menuId: item.id }) : handleRemoveGroup(item.id)}><FaTrash /></Button>
                </div>
            </Card.Body>
        </Card>;
    };
    
    const DroppableGroup = ({ group, groupIndex, nbrGroup }: { group: MenuElementDTO, groupIndex: number, nbrGroup: number }) => {
        return <Card className={"w-100"}>
            <Card.Header className={"d-flex align-items-center justify-content-between"}>
                <div className={"text-primary-gh"}><strong>{group.name + "(Group)"}</strong></div>
                <div className={"d-flex gap-2"}>
                    {groupIndex !== nbrGroup - 1 && <Button taille={"xs"} couleur="primary" onClick={() => moveItemToMenu({ index: groupIndex, direction: "down" })}><FaChevronDown /></Button>}
                    {groupIndex !== 0 && <Button couleur="primary" taille={"xs"} onClick={() => moveItemToMenu({ index: groupIndex, direction: "up" })}><FaChevronUp /></Button>}
                    <Button couleur="danger" taille={"xs"} onClick={() => handleRemoveGroup(group.id)}><FaTrash /></Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Droppable droppableId={`group-${group.id}`}>
                    {(providedGroup, snapshotGroup) => (<>
                            <div
                                ref={providedGroup.innerRef}
                                {...providedGroup.droppableProps}
                                className={"m-auto p-1 rounded-1 border-dash-1 d-flex align-items-center justify-content-center gap-1 flex-column"}
                                style={{
                                    width    : "97%",
                                    minHeight: "50px", // Assure une taille minimale pour afficher un message
                                    border   : snapshotGroup.isDraggingOver ? `2px dashed ${general.themeUser.secondary_color}` : `2px dashed ${general.themeUser.primary_border_color}`, // Changement de style de bordure
                                }}
                            >
                                {group.items?.map((groupItem, groupIndex) => (
                                    <DraggableItem key={groupItem.id} item={groupItem} index={groupIndex} nbr={group.items.length} inGroup={true} groupId={group.id} />
                                ))}
                                {group.items?.length >= 0 && !snapshotGroup.isDraggingOver && (
                                    <p style={{ textAlign: "center" }} className={"m-0 p-0 text-primary-gh"}>Lâcher ici pour ajouter au groupe</p>
                                )}
                                {providedGroup.placeholder}
                            </div>
                        </>
                    )}
                </Droppable>
            </Card.Body>
        </Card>;
    };
    
    const handleSaveMenu = () => {
        // Enregistrer le menu
        const optionApi = new OptionPersoApi();
        optionApi.maj_menu({ data: currentMenu }).then((response) => {
            setMessagePopUp("Mise à jour du menu réussie");
            setCurrentMenuItems(response.data.menu);
            setStatus(Status_success);
            setShowPop(true);
            triggerRefresh();
            setTimeout(() => {
                setShowPop(false);
            }, 2000);
        }).catch((error) => {
            setMessagePopUp(error?.data?.error ?? error?.message ?? "Erreur lors de la mise à jour du menu");
            setStatus("error");
            setShowPop(true);
        });
    };
    
    const handleBaseMenu = () => {
        dispatch(resetMenu());
        const optionApi = new OptionPersoApi();
        optionApi.maj_menu({ data: { items: [] } }).then((response) => {
            setMessagePopUp("Mise à jour du menu réussie");
            setCurrentMenuItems(response.data.menu);
            setStatus(Status_success);
            setShowPop(true);
            triggerRefresh();
            setTimeout(() => {
                setShowPop(false);
            }, 2000);
        }).catch((error) => {
            setMessagePopUp(error?.data?.error ?? error?.message ?? "Erreur lors de la mise à jour du menu");
            setStatus("error");
            setShowPop(true);
        });
    };
    return (
        <div id={"zoneMenu"}>
            <div className={"d-flex justify-content-center"}>
                <DragDropContext onDragEnd={onDragEnd}>
                    {/* Menus disponibles sur la gauche */}
                    <Droppable droppableId="availableMenus">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={"m-1 p-1 rounded-1 border-solid-1"}
                                style={{ width: "400px" }}
                            >
                                <h2 className={"d-flex justify-content-center gap-5"}>{t("Menus disponibles", { ns: "perso" })}</h2>
                                {remainingAvailableMenus.map((item, index) => (
                                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                        {(provided) => (
                                            <Card
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <span className={"d-flex justify-content-center align-self-center p-1 text-primary-gh"}>{t(item.label, { ns: "app" })}</span>
                                            </Card>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <div
                        className={"m-1 p-1 rounded-1 border-solid-1"}
                        style={{ width: "500px" }}
                    >
                        <h2 className={"d-flex justify-content-center gap-5"}>{t("Menu personnel", { ns: "perso" })}</h2>
                        
                        {/* Bouton pour ajouter un nouveau groupe */}
                        <div className={"d-flex justify-content-center gap-5 mb-2"}>
                            <Button onClick={handleAddGroup} couleur="primary" taille={"xs"}>{t("Ajouter un groupe", { ns: "perso" })}</Button>
                            <Button onClick={handleReset} couleur="danger" taille={"xs"}>{t("Annuler les modifications", { ns: "perso" })}</Button>
                            <Button onClick={handleBaseMenu} couleur="danger" taille={"xs"}>{t("Remettre le menu de base", { ns: "perso" })}</Button>
                        </div>
                        <div>
                            <Droppable droppableId="currentMenu" direction="vertical">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={"m-auto p-1 rounded-1 border-dash-1 d-flex align-items-center justify-content-center"}
                                        style={{
                                            width    : "80%",
                                            height   : snapshot.isDraggingOver ? "auto" : "10px", // Ajuste la hauteur lorsque vous survolez
                                            minHeight: "50px", // Assure une taille minimale pour afficher un message
                                            border   : snapshot.isDraggingOver ? `2px dashed ${general.themeUser.secondary_color}` : `2px dashed ${general.themeUser.primary_border_color}`, // Changement de style de bordure
                                        }}
                                    >
                                        {!snapshot.isDraggingOver && (
                                            <p style={{ textAlign: "center" }} className={"m-0 p-0 text-primary-gh"}>{t("Lâcher ici pour ajouter un menu", { ns: "perso" })}</p>
                                        )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <div className={"mt-3 p-1 d-flex flex-column gap-1 justify-content-center align-items-center"}>
                                {currentMenu.items?.map((item, index) => {
                                    if (item.type_menu === "group") {
                                        return <DroppableGroup key={item.id} group={item} groupIndex={index} nbrGroup={currentMenu.items.length} />;
                                    } else {
                                        return <DraggableItem key={item.id} item={item} index={index} nbr={currentMenu.items.length} />;
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </DragDropContext>
                <Modal show={showModal} onHide={handleCloseModal} centered={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Ajouter un nouveau groupe", { ns: "perso" })}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className={"d-flex"}>
                            <Form.Group controlId="formGroupName">
                                <Form.Label>{t("Nom du groupe", { ns: "perso" })}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t("Entrer un nom ici...", { ns: "perso" })}
                                    value={groupName}
                                    onChange={(e) => dispatch(setGroupName(e.target.value))}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button taille={"xs"} couleur="secondary" onClick={handleCloseModal}>{t("Fermer", { ns: "perso" })}</Button>
                        <Button taille={"xs"} couleur="primary" onClick={handleSaveGroup}>{t("Sauvegarder", { ns: "perso" })}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className={"d-flex justify-content-center"}>
                <Button taille={"xs"} onClick={handleSaveMenu} couleur="primary">{t("Sauvegarder le menu", { ns: "perso" })}</Button>
            </div>
        </div>
    );
};
export default MenuDesignerOptionsPerso;