import React, {useEffect, useState}                        from "react";
import {v4 as uuid}                                        from "uuid";
import {InfoCamping}                                       from "../../../types/components/ville/InfoCamping";
import Select, {components, OptionProps, SingleValueProps} from "react-select";
import SvgIcone                                            from "../../../components/generality/SvgIcone";
import {calculChanceCamping, Campeur, comparePseudo}       from "./calculCamping";
import {HabitantId}                                        from "../../../types/components/Generality/Job";
import {CitoyensDTO}                                       from "../../../types/models/citoyens.dto";
import {useTranslation}                                    from "react-i18next";
import TooltipGH                                           from "../../../components/utils/TooltipGH";

const {SingleValue, Option} = components;

interface PropsCamping {
    infoCase: InfoCamping;
}

const IconSingleValue = (props: SingleValueProps<CitoyensDTO>) => {
    let classCouleurCP = "joueurNonCp";
    if (props.data.campeur_pro && props.data.job?.id !== HabitantId) {
        classCouleurCP = "joueurCp";
    }
    
    return (
        <SingleValue {...props}>
            <SvgIcone icone={"h_" + props.data.job.icon}/>
            <span className={classCouleurCP}>{props.data.citoyen.pseudo}</span>
        </SingleValue>
    );
};

const IconOption = (props: OptionProps<CitoyensDTO>) => {
    let classCouleurCP = "joueurNonCp";
    if (props.data.campeur_pro && props.data.job?.id !== HabitantId) {
        classCouleurCP = "joueurCp";
    }
    
    return (
        <Option {...props}>
            <SvgIcone icone={"h_" + props.data.job.icon}/>
            <span className={classCouleurCP}>{props.data.citoyen.pseudo}</span>
        </Option>
    );
};

const customStyles = {
    option           : (provided: any) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    singleValue      : (provided: any) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    control          : (provided: any) => ({
        ...provided,
        minHeight: "24px",
        height   : "24px",
    }),
    input            : (provided: any) => ({
        ...provided,
        gridTemplateColumns: "none",
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: "0",
    }),
    valueContainer   : (provided: any) => ({
        ...provided,
        padding: "0px 8px",
    }),
    menu             : (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
    menuList         : (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
    menuPortal       : (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
};

export default function Camping({infoCase}: PropsCamping): React.JSX.Element {
    const {t} = useTranslation();
    const sortedPseudo = Object.values(infoCase.citoyens).sort(comparePseudo).filter((citoyen: CitoyensDTO) => !citoyen.mort);
    const [phare, setPhare] = useState(infoCase.phare);
    const [devast, setDevast] = useState(infoCase.devast);
    const [maxCamp, setMaxCamp] = useState(99);
    const [nuit, setNuit] = useState(true);
    const [nbrAmelioOd, setNbrAmelioOd] = useState<number | string>(0);
    const [zombie, setZombie] = useState(infoCase.zombie ?? 0);
    const [idBat, setIdBat] = useState(infoCase.bat === null ? null : infoCase.bat.id);
    const [listCitoyens] = useState(sortedPseudo);
    const [campeurs, setCampeurs] = useState<Campeur[]>([
        {
            id            : uuid(),
            citoyen       : listCitoyens[0] ?? null,
            tombe         : false,
            capuche       : true,
            nb_camping    : listCitoyens[0]?.nb_camping ?? 0,
            objet_camping : 0,
            chance_camping: calculChanceCamping(
                infoCase,
                {
                    nuit       : nuit,
                    phare      : phare,
                    devast     : devast,
                    nbrAmelioOD: nbrAmelioOd,
                    zombie     : zombie,
                    idBat      : idBat,
                    nbrCampMax : maxCamp,
                },
                {
                    citoyen       : listCitoyens[0] ?? null,
                    tombe         : false,
                    capuche       : true,
                    nb_camping    : listCitoyens[0]?.nb_camping ?? 0,
                    objet_camping : 0,
                    chance_camping: 0,
                },
                1,
            ),
        },
    ]);
    
    const addCampeur = () => {
        const newCampeur = {
            id            : uuid(),
            citoyen       : listCitoyens[0] ?? null,
            capuche       : true,
            tombe         : false,
            nb_camping    : listCitoyens[0]?.nb_camping ?? 0,
            objet_camping : 0,
            chance_camping: calculChanceCamping(
                infoCase,
                {
                    nuit       : nuit,
                    phare      : phare,
                    devast     : devast,
                    nbrAmelioOD: nbrAmelioOd,
                    zombie     : zombie,
                    idBat      : idBat,
                    nbrCampMax : maxCamp,
                },
                {
                    citoyen       : listCitoyens[0] ?? null,
                    tombe         : false,
                    capuche       : true,
                    nb_camping    : listCitoyens[0]?.nb_camping ?? 0,
                    objet_camping : 0,
                    chance_camping: 0,
                },
                campeurs.length + 1,
            ),
        };
        
        setCampeurs([...campeurs, newCampeur]);
    };
    
    const deleteCamper = (id: string) => {
        const updatedCampeurs = campeurs.filter((campeur) => campeur.id !== id);
        setCampeurs(updatedCampeurs);
    };
    
    const updateSuccessChance = (index: number, updatedCamper: Campeur) => {
        const updatedCampeurs = [...campeurs];
        updatedCampeurs[index] = updatedCamper;
        // Mettre à jour le tableau de campeurs
        updatedCampeurs[index] = {
            ...updatedCampeurs[index],
            chance_camping: calculChanceCamping(
                infoCase,
                {
                    nuit       : nuit,
                    phare      : phare,
                    devast     : devast,
                    nbrAmelioOD: nbrAmelioOd,
                    zombie     : zombie,
                    idBat      : idBat,
                    nbrCampMax : maxCamp,
                },
                updatedCamper,
                index + 1,
            ),
        };
        
        // Mettre à jour l'état des campeurs
        setCampeurs(updatedCampeurs);
    };
    
    const updateSuccessChanceAll = (newCampeurs: Campeur[] = campeurs) => {
        const campeursCalc = [...newCampeurs];
        campeursCalc.map((campeur, index) => {
            campeur.chance_camping = calculChanceCamping(
                infoCase,
                {
                    nuit       : nuit,
                    phare      : phare,
                    devast     : devast,
                    nbrAmelioOD: nbrAmelioOd,
                    zombie     : zombie,
                    idBat      : idBat,
                    nbrCampMax : maxCamp,
                },
                campeur,
                index + 1,
            );
            campeursCalc[index] = campeur;
        });
        
        setCampeurs(campeursCalc);
    };
    
    const swapCampers = (index1: number, index2: number) => {
        const newCampeurs = [...campeurs];
        const campeur1 = {...newCampeurs[index1]};
        const campeur2 = {...newCampeurs[index2]};
        newCampeurs.splice(index1, 1, campeur2);
        newCampeurs.splice(index2, 1, campeur1);
        
        setCampeurs(newCampeurs); // Mettre à jour l'état avec les campeurs permutés
        updateSuccessChanceAll(newCampeurs); // Recalculer les chances de camping avec les nouveaux campeurs
    };
    
    const OptionsList = (id: number) => {
        const options = [];
        
        for (let i = 0; i <= 12; i++) {
            options.push(<option key={id + "_" + i} value={i}>{i}</option>);
        }
        
        return (
            <select
                id={`odc-${id}`}
                value={campeurs[id].objet_camping ?? 0}
                onChange={(event) => {
                    const updatedCamper = {
                        ...campeurs[id],
                        objet_camping: parseInt(event.target.value, 10),
                    };
                    updateSuccessChance(id, updatedCamper);
                }}
            >
                {options}
            </select>
        );
    };
    
    const NbCampingList = (id: number, nb_camping: number) => {
        const options = [];
        
        for (let i = 0; i <= 10; i++) {
            options.push(
                <option key={id + "_" + i} value={i}>
                    {i}
                </option>,
            );
        }
        
        return (
            <select
                value={nb_camping}
                id={`nb_camp-${id}`}
                onChange={(event) => {
                    const updatedCamper = {
                        ...campeurs[id],
                        nb_camping: parseInt(event.target.value, 10),
                    };
                    updateSuccessChance(id, updatedCamper);
                }}
            >
                {options}
            </select>
        );
    };
    
    useEffect(() => {
        setZombie(infoCase.zombie ?? 0);
        setIdBat(infoCase.bat === null ? null : infoCase.bat.id);
        if (infoCase.nbTas ?? 0 > 0) {
            const maxCamp = Math.floor(infoCase.nbTas / 3);
            setMaxCamp(maxCamp > 3 ? 3 : maxCamp);
        } else {
            setMaxCamp(infoCase.bat === null ? 99 : infoCase.bat.max_campeur);
        }
        updateSuccessChanceAll();
    }, [infoCase]);
    
    useEffect(() => {
        updateSuccessChanceAll();
    }, [zombie, nbrAmelioOd, idBat, nuit, devast, phare, maxCamp]);
    
    return (
        <div className={"outilsCalculCamping"}>
            {infoCase.bat !== null && (
                <div key={"batiment"} id={"camping_batiment_select"}>
                    <label>{t("Bâtiment", {ns: "ville"})}</label>
                    <select
                        value={idBat}
                        onChange={(event) => {
                            setIdBat(parseInt(event.target.value, 10));
                        }}
                    >
                        {Object.values(infoCase.listBat).sort((bat_a, bat_b) => {
                            const aName = t(bat_a.nom, {ns: "bats"});
                            const bName = t(bat_b.nom, {ns: "bats"});
                            return aName.localeCompare(bName);
                        }).map((bat) => (
                            <option key={bat.id} value={bat.id}>
                                {t(bat.nom, {ns: "bats"})} ({bat.bonus_camping}%)
                            </option>
                        ))}
                    </select>
                </div>
            )}
            {infoCase.bat === null && (
                <div key={"batiment"} id={"camping_batiment_select"}>
                    {t("Bâtiment : Aucun", {ns: "ville"})}
                </div>
            )}
            <div key={"camp_max"} id={"camp_max_input"}>
                <label htmlFor="{'camp_max'}">{t("Nombre de campeur maximum", {ns: "ville"})} </label>
                <input
                    type="number"
                    id="camp_max"
                    value={maxCamp}
                    onChange={(event) => {
                        setMaxCamp(parseInt(event.target.value, 10));
                    }}
                    min={0}
                />
            </div>
            <div key={"phare"} id={"camping_phare_select"}>
                <label htmlFor="{'phare_construit'}">{t("Phare construit", {ns: "ville"})} </label>
                <select
                    id={"phare_construit"}
                    defaultValue={phare ? 1 : 0}
                    onChange={(event) => {
                        setPhare(event.target.value === "1");
                    }}
                >
                    <option value={0}>{t("Non", {ns: "app"})}</option>
                    <option value={1}>{t("Oui", {ns: "app"})}</option>
                </select>
            </div>
            <div key={"nuit"} id={"camping_nuit_select"}>
                <label htmlFor="{'camping_nuit'}">{t("Nuit", {ns: "ville"})} </label>
                <select
                    id={"camping_nuit"}
                    defaultValue={1}
                    onChange={(event) => {
                        setNuit(event.target.value === "1");
                    }}
                >
                    <option value={0}>{t("Non", {ns: "app"})}</option>
                    <option value={1}>{t("Oui", {ns: "app"})}</option>
                </select>
            </div>
            <div key={"zombie"} id={"camping_zombie_select"}>
                <label htmlFor="{'camping_zombie'}">{t("Zombie", {ns: "ville"})} </label>
                <input
                    type="number"
                    id="camping_zombie"
                    value={zombie}
                    onChange={(event) => {
                        setZombie(parseInt(event.target.value, 10));
                    }}
                    min={0}
                />
            </div>
            <div key={"devast"} id={"camping_devast_select"}>
                <label htmlFor="{'camping_devast'}">{t("Dévasté", {ns: "ville"})} </label>
                <select
                    id={"camping_devast"}
                    defaultValue={devast ? 1 : 0}
                    onChange={(event) => {
                        setDevast(event.target.value === "1");
                    }}
                >
                    <option value={0}>{t("Non", {ns: "app"})}</option>
                    <option value={1}>{t("Oui", {ns: "app"})}</option>
                </select>
            </div>
            <div id={"camping_amelio_od_select"} key={"amelio"}>
                <label htmlFor="{'camping_amelio_od'}" className={"d-flex gap-1 justify-content-start align-content-center align-items-center"}>
                    <span>{t("Nombre d'amélioration + OD", {ns: "ville"})}{" "}</span>
                    <TooltipGH>
                        <span className="infoBulle">
                            <i className="fa-solid fa-circle-info"></i>
                        </span>
                        <span className="info">{t("Comptez 1,8 pour un OD, et 1 pour une amelioration, maximum 11,6", {ns: "ville"})}</span>
                    </TooltipGH>
                </label>
                <span className={"d-flex gap-1 align-items-center justify-content-start mb-1"}>
                    <input type="number" id="camping_amelio_od"
                           value={nbrAmelioOd}
                           onChange={(event) => {
                               setNbrAmelioOd(isNaN(parseFloat(event.target.value.replace(".", ","))) ? "" : Math.min(parseFloat(event.target.value), 11.6));
                           }}
                           min={0}
                           max={11.6}
                           step={0.1}
                    />
                    <TooltipGH>
                        <span className="infoBulle">
                            <SvgIcone icone={"h_warning"}/>
                        </span>
                        <span className="info">{t("Décomptez 3 améliorations par jour", {ns: "ville"})}</span>
                    </TooltipGH>
                </span>
            </div>
            
            <div key={"listCampeur"} id={"camping_campeur"}>
                <table>
                    <thead>
                    <tr>
                        <th className={"tbl_camping_ordre"}>{t("Ordre", {ns: "ville"})}</th>
                        <th className={"tbl_camping_campeur"}>
                            <span  className={"d-flex gap-1 justify-content-center align-content-center align-items-center"}>
                                <span>{t("Campeur", {ns: "ville"})}{" "}</span>
                                <TooltipGH>
                                    <span className="infoBulle">
                                        <i className="fa-solid fa-circle-info"></i>
                                    </span>
                                    <span className="info">{t("Les campeurs en couleur rouge sont ceux qui n'ont pas le pouvoir héros campeur pro", {ns: "ville"})}</span>
                                </TooltipGH>
                            </span>
                        
                        </th>
                        <th className={"tbl_camping_nbr"}>{t("Nombre Camping", {ns: "ville"})}</th>
                        <th className={"tbl_camping_tombe"}>{t("Tombe", {ns: "ville"})}</th>
                        <th className={"tbl_camping_tombe"}>{t("Spécifique", {ns: "ville"})}</th>
                        <th className={"tbl_camping_odc"}>{t("Objet Camping", {ns: "ville"})}</th>
                        <th className={"tbl_camping_chance"}>{t("Chance de réussite", {ns: "ville"})}</th>
                        <th className={"tbl_camping_action"}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {campeurs.map((campeur, index) => (
                        <tr key={campeur.id}>
                            <td className={"tbl_camping_ordre"}>{index + 1}</td>
                            <td className={"tbl_camping_campeur"}>
                                <Select
                                    value={campeur.citoyen}
                                    options={listCitoyens}
                                    components={{
                                        SingleValue: IconSingleValue,
                                        Option     : IconOption,
                                    }}
                                    onChange={(optionSelected: CitoyensDTO) => {
                                        const updatedCamper = {
                                            ...campeurs[index],
                                            citoyen   : optionSelected,
                                            nb_camping: optionSelected.nb_camping,
                                        };
                                        updateSuccessChance(index, updatedCamper);
                                    }}
                                    key={"citoyen_" + campeur.id}
                                    styles={customStyles}
                                    getOptionLabel={(citoyen) => citoyen.citoyen.pseudo}
                                />
                            </td>
                            <td className={"tbl_camping_nbr"}>
                                {NbCampingList(index, campeur.nb_camping)}
                            </td>
                            <td className={"tbl_camping_tombe"}>
                                <input
                                    type="checkbox"
                                    id={`tombe-${index}`}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const updatedCamper = {
                                            ...campeurs[index],
                                            tombe: event.target.checked,
                                        };
                                        updateSuccessChance(index, updatedCamper);
                                    }}
                                />
                            </td>
                            <td className={"tbl_camping_tombe"}>
                                {(campeur.citoyen.job?.id ?? null) === 4 && (<React.Fragment>
                                    <input
                                        type="checkbox"
                                        id={`capuche-${index}`}
                                        checked={campeur.capuche}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const updatedCamper = {
                                                ...campeurs[index],
                                                capuche: event.target.checked,
                                            };
                                            updateSuccessChance(index, updatedCamper);
                                        }}
                                    />
                                    <TooltipGH>
                                        <span className={"infoBulle"}>
                                            <i className="fa-solid fa-circle-info"></i>
                                        </span>
                                        <span className={"info"}>{t("Capuche présente", {ns: "ville"})}</span>
                                    </TooltipGH>
                                </React.Fragment>)}
                            </td>
                            <td className={"tbl_camping_odc"}>{OptionsList(index)}</td>
                            <td className={"tbl_camping_chance"}>
                                {campeur.chance_camping} %
                            </td>
                            <td className={"tbl_camping_action"}>
                                <div className={"bouton_action_camping"}>
                                    {campeurs.length > 1 && (
                                        <button className={"btn btn-xs btn-danger"} onClick={() => deleteCamper(campeur.id)}>
                                            <i className="fa-solid fa-trash"></i>
                                        </button>
                                    )}
                                    {campeurs.length > 1 && (
                                        <button className={"btn btn-xs btn-secondary"}
                                                onClick={() => swapCampers(index, index - 1)}
                                                disabled={index === 0}
                                        >
                                            <TooltipGH>
                                                <span className="infoBulle">
                                                    <i className="fa-solid fa-circle-chevron-up"></i>
                                                </span>
                                                <span className="info">{t("Remonter le campeur", {ns: "ville"})}</span>
                                            </TooltipGH>
                                        </button>
                                    )}
                                    {campeurs.length > 1 && (
                                        <button
                                            className={"btn btn-xs btn-secondary"}
                                            onClick={() => swapCampers(index, index + 1)}
                                            disabled={index === campeurs.length - 1}
                                        >
                                            <TooltipGH>
                                                <span className="infoBulle">
                                                    <i className="fa-solid fa-circle-chevron-down"></i>
                                                </span>
                                                <span className="info">{t("Descendre le campeur", {ns: "ville"})}</span>
                                            </TooltipGH>
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <button onClick={addCampeur}>{t("Ajouter un campeur", {ns: "ville"})}</button>
            </div>
        </div>
    );
}
